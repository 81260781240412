import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { useModalState } from '../../../modules/modals';
import { getGoBackHistoryOffset } from '../../../modules/navigation/Navigation.utils';
import { buildEventCardExclusiveBadgeProps } from '../../molecules/ExclusiveBadge/ExclusiveBadge.utils';
import { CardholderExclusiveBadgeThemesByTheme } from './EventInfoHeader.constants';
import type { EventInfoHeaderPresenterProps, EventInfoHeaderProps } from './EventInfoHeader.types';

export const usePresenter = (props: EventInfoHeaderProps): EventInfoHeaderPresenterProps => {
  const { eventMetaData, theme, shouldFixEventInfoHeader } = props;

  const { isExclusiveEvent, tags } = eventMetaData;

  const navigate = useNavigate();

  const { trackEvent } = useAnalyticsManager();

  const { isSignedOut, accountCardDetails } = useContext(AuthContext);

  const { t } = useTranslation();

  const {
    isModalOpen: isInfoModalShown,
    openModal: onInfoButtonClick,
    closeModal: closeInfoModal,
  } = useModalState();

  const onBackButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Header',
      'Button',
      t('common.goBack'),
    );

    navigate(getGoBackHistoryOffset());
  }, [t, trackEvent, navigate]);

  const eventCardExclusiveBadgeProps = useMemo(() => isExclusiveEvent ? buildEventCardExclusiveBadgeProps({ isSignedOut, currentTags: tags, accountCardDetails, theme: CardholderExclusiveBadgeThemesByTheme[theme] }) : undefined, [accountCardDetails, isExclusiveEvent, isSignedOut, tags, theme]);

  const eventInfoHeaderRef = useRef<HTMLDivElement>(null);
  const fixedSpacerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Sync spacer height with header height if header is fixed-positioned
    if (shouldFixEventInfoHeader && eventInfoHeaderRef.current && fixedSpacerRef.current) {
      const eventInfoHeaderHeight: number = eventInfoHeaderRef.current.offsetHeight;
      fixedSpacerRef.current.style.height = `${eventInfoHeaderHeight}px`;
    }
  }, [shouldFixEventInfoHeader]);

  return {
    ...props,
    isInfoModalShown,
    onInfoButtonClick,
    closeInfoModal,
    onBackButtonClick,
    eventCardExclusiveBadgeProps,
    eventInfoHeaderRef,
    fixedSpacerRef,
  };
};
