import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getCurrentHistoryState } from '../../../modules/navigation/Navigation.utils';
import Text from '../../atoms/Text';
import { ExclusiveBadge } from '../ExclusiveBadge';
import { ListingCardPricing } from '../ListingCardPricing';
import { largerTextProps, smallerTextProps } from './ListingCard.constants';
import styles from './ListingCard.module.scss';
import { usePresenter } from './ListingCard.presenter';
import type { ListingCardProps } from './ListingCard.types';

const InternalListingCard: React.FC<ListingCardProps> = (props) => {
  const {
    isPayWithRewardsOnly,
    listingMetadata: {
      sectionName,
      row,
      ticketsTotal,
      formattedTicketsTotal,
      formattedCashPrice,
      formattedLoyaltyPrice,
      loyaltyUnitName,
    },
    listingHref,
    onListingCardClick,
    onListingCardTouchStart,
    onListingCardTouchEnd,
    onListingCardMouseEnter,
    onListingCardMouseLeave,
    className,
    listingCardExclusiveBadgeProps,
    isCashOnlyAccount,
  } = usePresenter(props);

  const { t } = useTranslation();

  const listingCardExclusiveBadge: ReactNode = listingCardExclusiveBadgeProps && (
    <ExclusiveBadge
      className={styles.cardholderExclusiveBadge}
      {...listingCardExclusiveBadgeProps}
    />
  );

  const leftContent: ReactNode = (<>
    {listingCardExclusiveBadge}
    <Text
      {...largerTextProps}
      value={sectionName}
    />
    <Text
      {...smallerTextProps}
      value={t('listingCard.row', { row })}
    />
    <Text
      {...smallerTextProps}
      value={t('listingCard.ticketsAvailable', { ticketsTotal: formattedTicketsTotal, count: ticketsTotal })}
    />
  </>);

  const rightContent: ReactNode = (
    <ListingCardPricing
      isPayWithRewardsOnly={isPayWithRewardsOnly}
      isCashOnlyAccount={isCashOnlyAccount}
      loyaltyUnitName={loyaltyUnitName}
      formattedLoyaltyPrice={formattedLoyaltyPrice}
      formattedCashPrice={formattedCashPrice}
    />);

  return (<>
    <Link
      to={listingHref}
      state={getCurrentHistoryState()}
      onClick={onListingCardClick}
      onTouchStart={onListingCardTouchStart}
      onTouchEnd={onListingCardTouchEnd}
      onMouseEnter={onListingCardMouseEnter}
      onMouseLeave={onListingCardMouseLeave}
      className={cx(styles.listingCard, className)}
      data-testid='listing-card' // Expected by NonGuestListEventPage.module.scss. Do not change accidentally.
      tabIndex={0} // Accessible through keyboard navigation for safari
    >
      <div className={styles.contentContainer}>
        {leftContent}
      </div>
      <div className={styles.rightContentContainer}>
        {rightContent}
      </div>
    </Link>
  </>);
};

/** Memoize listing card since event pages render a large number of listing cards */
export const ListingCard = React.memo(InternalListingCard);
