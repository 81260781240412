import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { TopNavContext } from '../../../modules/topNav';
import { getSearchSuggestionsResultsByCategories } from '../../blocks/SearchSuggestionBlock';
import type { SearchBarWithSuggestionsDropdownPresenterProps, SearchBarWithSuggestionsDropdownProps } from './SearchBarWithSuggestionsDropdown.types';
import { handleSearchBarInputKeyDown, onSearchSuggestionArrowNavigation } from './SearchBarWithSuggestionsDropdown.utils';
import type { ArrowDirectionEnum } from '../../../lib/accessibility';

export const usePresenter = (props: SearchBarWithSuggestionsDropdownProps): SearchBarWithSuggestionsDropdownPresenterProps => {
  const { setActiveTopNavMenuType, searchSuggestions, searchQuery } = useContext(TopNavContext);

  const { t } = useTranslation();
  const { trackEvent } = useAnalyticsManager();

  // State for screen reader announcements
  const [searchSuggestionResultScreenReaderText, setSearchSuggestionResultScreenReaderText] = useState<string>();

  // Refs for managing focus and keyboard navigation
  const searchSuggestionsDropdownRef = useRef<HTMLDivElement>(null);
  const currentSuggestionIndexRef = useRef<number>(-1);
  const searchSuggestionsItemsRef = useRef<HTMLAnchorElement[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Handles the dropdown toggle state and tracks analytics.
   * Only triggers if the active element is not within the dropdown.
   */
  const onSearchSuggestionsDropdownToggled = useCallback(() => {
    if (!searchSuggestionsDropdownRef.current?.contains(document.activeElement)) {
      trackSelectContentEvent(
        trackEvent,
        'Header',
        'SearchBar',
      );

      setActiveTopNavMenuType(undefined);
    }
  }, [trackEvent, setActiveTopNavMenuType]);

  /**
   * Callback for handling keyboard events in the search bar.
   * Manages focus movement between input and suggestions.
   */
  const handleSearchBarInputKeyDownCallback = useCallback((event: React.KeyboardEvent) => {
    handleSearchBarInputKeyDown({
      event,
      searchSuggestions,
      inputRef,
      itemRefs: searchSuggestionsItemsRef,
      currentSuggestionIndexRef,
    });
  }, [searchSuggestions]);

  /**
   * Callback for handling arrow key navigation between suggestions.
   * Manages focus movement up and down the suggestion list.
   */
  const onSearchSuggestionArrowNavigationCallback = useCallback((direction: ArrowDirectionEnum) => {
    onSearchSuggestionArrowNavigation({
      direction,
      searchSuggestions,
      inputRef,
      itemRefs: searchSuggestionsItemsRef,
      currentSuggestionIndexRef,
    });
  }, [searchSuggestions]);

  // Get categorized search suggestions
  const { events, performers, venues } = getSearchSuggestionsResultsByCategories(searchSuggestions, trackEvent);

  // Timer ref for debouncing screen reader announcements
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  /**
   * Effect to manage screen reader announcements for search results.
   * Debounces the announcement to prevent rapid updates.
   */
  useEffect(() => {
    setSearchSuggestionResultScreenReaderText(undefined);

    if (searchSuggestions || searchQuery) {
      timerRef.current = setTimeout(() => {
        setSearchSuggestionResultScreenReaderText(t('search.searchSuggestionResultScreenReaderText', {
          eventsCount: events?.searchSuggestionItems.length || 0,
          performersCount: performers?.searchSuggestionItems.length || 0,
          venuesCount: venues?.searchSuggestionItems.length || 0,
        }));
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, [t, searchSuggestions, searchQuery, events?.searchSuggestionItems.length, performers?.searchSuggestionItems.length, venues?.searchSuggestionItems.length]);

  // Return all necessary props and callbacks
  return {
    ...props,
    searchSuggestions,
    searchSuggestionsDropdownRef,
    onSearchSuggestionsDropdownToggled,
    events,
    performers,
    venues,
    searchSuggestionResultScreenReaderText,
    currentSuggestionIndexRef,
    searchSuggestionsItemsRef,
    inputRef,
    handleSearchBarInputKeyDownCallback,
    onSearchSuggestionArrowNavigationCallback,
  };
};
