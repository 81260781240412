import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import { Spinner } from '../../atoms/Spinner';
import Text from '../../atoms/Text';
import { TopNav } from '../../organisms/TopNav';
import { AccountCard, type AccountCardProps } from '../AccountCard';
import { IconText } from '../IconText';
import { LabelledContent } from '../LabelledContent';
import { DESCRIPTIONS_BY_STEP, LAST_STEP, ONBOARDING_STEPS, SUB_TITLES_BY_STEP, TITLES_BY_STEP } from './OnBoardingModalContent.constants';
import styles from './OnBoardingModalContent.module.scss';
import { usePresenter } from './OnBoardingModalContent.presenter';
import type { OnBoardingModalContentProps } from './OnBoardingModalContent.types';

export const OnBoardingModalContent: React.FC<OnBoardingModalContentProps> = (props) => {
  const {
    currentStep,
    isLoading,
    handleNextButtonClick,
    isMobile,
    email,
    shouldShowBillingAddress,
    billingAddress,
    backgroundImageStyle,
    accountCardProps,
    leftContainerRef,
    imageContainerRef,
  } = usePresenter(props);

  const { t } = useTranslation();

  if (isLoading) {
    return <Spinner className={styles.onBoardingModalContent} />;
  }

  const titleContent: ReactNode = (
    <Text
      type='Body'
      size='Large'
      style='Regular'
      colour='BaseLight'
      align='Left'
      value={t(TITLES_BY_STEP[currentStep], { firstName: billingAddress.firstName })}
      className={cx(styles.titleContent, styles[`titleContent-step${currentStep}`])}
    />
  );

  const dividerContent: ReactNode = (
    <Divider
      className={styles.dividerContent}
      type='Horizontal'
      style='Thin'
      colour='CP1Red'
    />
  );

  const subTitleContent: ReactNode = (
    <Text
      type='Body'
      size='Large'
      style='Regular'
      colour='BaseLight'
      align='Left'
      value={SUB_TITLES_BY_STEP[currentStep]}
      className={styles.subTitleContent}
    />
  );

  const descriptionContent: ReactNode = (
    <div className={styles.descriptionContent}>
      {DESCRIPTIONS_BY_STEP[currentStep]?.map((textIconDescription, index) => (
        <IconText
          key={index}
          text={t(textIconDescription.descriptionKey)}
          iconAsset={textIconDescription.asset}
          color='BaseLight'
          className={styles.textIconDescription}
          classes={{
            icon: styles.descriptionIcon,
            text: styles.descriptionText,
          }}
        />
      ))}
    </div>
  );

  const billingAddressContent: ReactNode = !!shouldShowBillingAddress && (
    <LabelledContent
      labelKey={'onBoardingModal.step3.billingAddress'}
      items={[billingAddress.streetAddress, billingAddress.streetAddressLine2, `${billingAddress.city}, ${billingAddress.state}`, billingAddress.postalCode]}
      labelTextColour='BaseLight'
      itemTextColour='BaseLight'
    />
  );

  const accountDetailsContent: ReactNode = (
    <div className={styles.accountDetailsContent}>
      <div className={styles.column}>
        <LabelledContent
          labelKey={'onBoardingModal.step3.name'}
          item={`${billingAddress.firstName} ${billingAddress.lastName}`}
          labelTextColour='BaseLight'
          itemTextColour='BaseLight'
        />
        <LabelledContent
          labelKey={'onBoardingModal.step3.emailAddress'}
          item={email}
          labelTextColour='BaseLight'
          itemTextColour='BaseLight'
        />
        <LabelledContent
          labelKey={'onBoardingModal.step3.contactNumber'}
          item={billingAddress.phoneNumber}
          labelTextColour='BaseLight'
          itemTextColour='BaseLight'
        />
        {billingAddressContent}
      </div>
      <div className={styles.column}>
        <LabelledContent
          labelKey={'onBoardingModal.step3.cardInfo'}
          labelTextColour='BaseLight'
        >
          {accountCardProps.map((currentAccountCardProps: AccountCardProps) => (
            <AccountCard
              {...currentAccountCardProps}
              key={currentAccountCardProps.key}
            />
          ))}
        </LabelledContent>
      </div>
    </div>
  );

  const footerContent: ReactNode = (
    <div className={styles.footer}>
      <Text
        type='Body'
        size='Medium'
        style='Regular'
        colour='BaseLight'
        align='Left'
        value={t('onBoardingModal.step', {
          currentStep,
          totalSteps: LAST_STEP,
        })}
        className={styles.stepText}
      />
      <Button
        type='Text'
        style='Contained'
        size='Medium'
        text={{ value: t('onBoardingModal.next') }}
        className={styles.nextButton}
        onClick={handleNextButtonClick}
      />
    </div>
  );

  const getStepContent = (): ReactNode => {
    return currentStep === ONBOARDING_STEPS.StepThree ? accountDetailsContent : descriptionContent;
  };

  const headerContent: ReactNode = (
    <div className={styles.headerContent}>
      {titleContent}
      {dividerContent}
      {subTitleContent}
    </div>
  );

  const imageContainer: ReactNode = (
    <div
      ref={imageContainerRef}
      className={cx(styles.imageContainer, styles[`imageContainer-step${currentStep}`])}
      style={backgroundImageStyle}
    >
      {isMobile && headerContent}
    </div>
  );

  const leftContent: ReactNode = (
    <div className={styles.leftContent}>
      {!isMobile && headerContent}
      {getStepContent()}
    </div>
  );

  const leftContainer: ReactNode = (
    <div
      ref={leftContainerRef}
      className={styles.leftContainer}
    >
      {!isMobile && (
        <TopNav
          topNavType='LogoOnly'
          className={styles.topNav}
          isLogoClickable={false}
        />
      )}
      {leftContent}
      {!isMobile && footerContent}
    </div>
  );

  if (isMobile) {
    return (
      <div className={styles.onBoardingModalContent}>
        <TopNav
          topNavType='LogoOnly'
          className={styles.topNav}
          isLogoClickable={false}
        />
        {imageContainer}
        {leftContainer}
        {footerContent}
      </div>
    );
  }

  return (
    <div className={styles.onBoardingModalContent}>
      {imageContainer}
      {leftContainer}
    </div>
  );
};
