import cx from 'classnames';
import React from 'react';

import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';

import styles from './MenuButton.module.scss';
import usePresenter from './MenuButton.presenter';
import getProps from './MenuButton.props';
import { MenuButtonCombinedProps } from './types';

const MenuButton: React.FC<MenuButtonCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    onClick,
    cardImage,
    icon,
    ariaLabel, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`menuButton${variantName}`];

  let componentView: JSX.Element;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <button
          aria-label={ariaLabel} // MANUAL OVERRIDE
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
          tabIndex={0}
        >
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.capitalOneCard, classes?.capitalOneCard)}>
              <Image
                className={cx(styles.cardImage, classes?.cardImage)}
                {...internalProps.cardImage}
                {...cardImage} />
            </div>
          </div>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon} />
        </button>
      );
      break;
    }
    case 'IconOnly': {
      componentView = (
        <button
          aria-label={ariaLabel} // MANUAL OVERRIDE
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
          tabIndex={0}
        >
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon} />
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default MenuButton;
