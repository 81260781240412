import { matchPath } from 'react-router-dom';
import type { PresetFilterOption } from '../../components/organisms/PresetFilter';
import type { RegionFilterState } from '../../components/organisms/RegionFilter';
import { buildAllEventsPageUrlWithParams, type AllEventsTabKey } from '../../components/pages/AllEventsPage';
import { CLIENT_ID, UNAUTHORIZED_URL } from '../../lib/config';
import { HISTORY_STATE_PARAM } from '../../lib/constants';
import { getPerformerUrl } from '../../lib/performerUtils';
import { addQueryParam, removeSessionCookie } from '../../lib/util';
import { CATEGORIES, THEATER_SUB_CATEGORIES } from '../categories';
import i18n from '../locale/i18n';
import type { Event, Performer, TopNavPerformersResponse, Venue } from '../partnership';
import { CATEGORIES_WITH_VIEW_EVENTS_LINK, TOP_NAV_PERFORMERS_DATA } from './Navigation.constants';
import type { CategoryMenuItemState, GoBackHistoryState, PerformerMenuItemState, SubCategoryMenuItemState } from './Navigation.types';

/**
 * Checks if the current path matches a given path or paths.
 *
 * @param currentPathName - The current path name to check against.
 * @param pathName - A single path or an array of paths to match.
 * @param exact - Whether the match should be exact.
 * @returns True if the path matches, otherwise false.
 */
export const checkIfPathMatch = (currentPathName: string, pathName: string | readonly string[], exact: boolean): boolean => {
  const paths = typeof pathName === 'string' ? [pathName] : pathName;
  return paths.some((path) => {
    const pattern = path.endsWith('/*') ? path : `${path}/*`;
    return !!matchPath({ path: pattern, end: exact }, currentPathName);
  });
};

export const getItemIfMatch = (
  text: string,
  results: Venue[] | Performer[] | Event[] | undefined,
): Venue | Performer | Event | undefined => {
  type SearchResult = { id: number; name: string; };
  const searchResult: SearchResult[] | undefined = results;
  const lowercaseText = text.toLowerCase();
  const result = searchResult?.find(
    (element) => element.name.toLowerCase() === lowercaseText,
  );
  return result;
};

export const buildViewEventsLink = (params: {
  tabKey: AllEventsTabKey;
  regionFilterState: RegionFilterState | undefined;
  categoryFilterState?: PresetFilterOption;
}): PerformerMenuItemState => {
  return {
    name: i18n.t('topnav.viewEvents'),
    slug: buildAllEventsPageUrlWithParams(params),
    teamCode: '',
    primaryColor: '',
    secondaryColor: '',
    isBoldText: true,
  };
};

/**
 * Adds performers for Comedy sub-category.
 * @returns {CategoryMenuItemState} - Updated category menu item state with added Comedy performers.
 */
const updatePerformersForComedySubCategory = (params: {
  /** Initial category menu item state without performers */
  categoryMenuItemState: CategoryMenuItemState;
  /** API response with all TopNav performers */
  topNavPerformersResponse: TopNavPerformersResponse;
  /** Region filter state for View Events link */
  regionFilterState: RegionFilterState;
}): CategoryMenuItemState => {
  const { categoryMenuItemState, topNavPerformersResponse, regionFilterState } = params;
  const { id: comedySubCategoryId } = categoryMenuItemState;

  const newPerformerMenuItemStates: PerformerMenuItemState[] = [];

  // Check if we need to prepend View Events link
  if (CATEGORIES_WITH_VIEW_EVENTS_LINK[comedySubCategoryId]) {
    newPerformerMenuItemStates.push(buildViewEventsLink({
      tabKey: 'comedy',
      regionFilterState,
    }));
  }

  // Add comedy performers from API response
  const apiComedyPerformers: readonly Performer[] | undefined = topNavPerformersResponse[CATEGORIES.theater]?.[comedySubCategoryId];
  if (apiComedyPerformers?.length) {
    apiComedyPerformers.forEach((apiComedyPerformer: Performer) => {
      newPerformerMenuItemStates.push({
        name: apiComedyPerformer.name,
        slug: getPerformerUrl(apiComedyPerformer.id),
        primaryColor: '',
        secondaryColor: '',
        teamCode: '',
      });
    });
  }

  const newCategoryMenuItemState: CategoryMenuItemState = { ...categoryMenuItemState, performers: newPerformerMenuItemStates };
  return newCategoryMenuItemState;
};

/**
  * Adds performers for non-Comedy sub-category.
  * @returns {CategoryMenuItemState} - Updated category menu item state with added performers.
  */
const updatePerformerForSubCategories = (params: {
  /** Initial category menu item state without performers */
  categoryMenuItemState: CategoryMenuItemState;
  /** API response with all TopNav performers */
  topNavPerformersResponse: TopNavPerformersResponse;
  /** Region filter state for View Events link */
  regionFilterState: RegionFilterState;
}): CategoryMenuItemState => {
  const { categoryMenuItemState, topNavPerformersResponse, regionFilterState } = params;

  const categoryId = Number(categoryMenuItemState.id) as keyof typeof CATEGORIES_WITH_VIEW_EVENTS_LINK;

  const newSubCategoryMenuItemStates: SubCategoryMenuItemState[] = [];

  categoryMenuItemState.subCategories.forEach((subCategoryMenuItemState: SubCategoryMenuItemState) => {
    const { subCategoryId, name: subCategoryName, performers: existingPerformerMenuItemStates } = subCategoryMenuItemState;

    const newPerformerMenuItemStates: PerformerMenuItemState[] = [...existingPerformerMenuItemStates];

    // Check if we need to prepend View Events link
    if (CATEGORIES_WITH_VIEW_EVENTS_LINK[categoryId]) {
      newPerformerMenuItemStates.unshift(buildViewEventsLink({
        tabKey: CATEGORIES_WITH_VIEW_EVENTS_LINK[categoryId],
        regionFilterState,
        categoryFilterState: {
          id: subCategoryId,
          name: subCategoryName,
        },
      }));
    }

    // Add unique sub-category performers from API response
    const apiSubCategoryPerformers: readonly Performer[] | undefined = topNavPerformersResponse[categoryId]?.[subCategoryId];
    if (apiSubCategoryPerformers?.length) {
      apiSubCategoryPerformers.forEach((apiSubCategoryPerformer: Performer) => {
        // Check if performer already exists
        const isExistingPerformer: boolean = newPerformerMenuItemStates.some(({ name }: PerformerMenuItemState) => name === apiSubCategoryPerformer.name);

        // Add performer only if it doesn't exist already
        if (!isExistingPerformer) {
          newPerformerMenuItemStates.push({
            name: apiSubCategoryPerformer.name,
            slug: getPerformerUrl(apiSubCategoryPerformer.id),
            teamCode: '',
            primaryColor: '',
            secondaryColor: '',
          });
        }
      });
    }

    // Add sub-category with updated performers
    newSubCategoryMenuItemStates.push({
      ...subCategoryMenuItemState,
      performers: newPerformerMenuItemStates,
    });
  });

  const newCategoryMenuItemState: CategoryMenuItemState = { ...categoryMenuItemState, subCategories: newSubCategoryMenuItemStates };
  return newCategoryMenuItemState;
};

/**
  * Updates the top navigation performers based on the response from getTopNavPerformers.
  * @returns {CategoryMenuItemState[]}
  */
export const updateCategoryMenuItemStates = (params: {
  topNavPerformersResponse: TopNavPerformersResponse;
  regionFilterState: RegionFilterState;
}): CategoryMenuItemState[] => {
  const { topNavPerformersResponse, regionFilterState } = params;

  // If topNavPerformersResponse is an empty object, return TOP_NAV_PERFORMERS_DATA as static data for categories and sub-categories.
  if (!Object.keys(topNavPerformersResponse).length) {
    return TOP_NAV_PERFORMERS_DATA;
  }

  // Map through each category in TOP_NAV_PERFORMERS_DATA array
  const newCategoryMenuItemStates = TOP_NAV_PERFORMERS_DATA.map((categoryMenuItemState: CategoryMenuItemState) => {
    if (categoryMenuItemState.id === THEATER_SUB_CATEGORIES.comedy.toString()) {
      return updatePerformersForComedySubCategory({ categoryMenuItemState, topNavPerformersResponse, regionFilterState });
    } else {
      return updatePerformerForSubCategories({ categoryMenuItemState, topNavPerformersResponse, regionFilterState });
    }
  });

  return newCategoryMenuItemStates;
};

export const getCurrentHistoryIndex = (): number => Number(window.history.state?.idx) || 0;

export const getCurrentHistoryState = (): unknown => window.history.state?.usr;

export const buildGoBackHistoryState = (): GoBackHistoryState => ({
  goBackHistoryIndex: getCurrentHistoryIndex(),
});

export const checkIsGoBackHistoryState = (state: unknown): state is GoBackHistoryState => {
  return typeof (state as GoBackHistoryState)?.goBackHistoryIndex === 'number';
};

export const getGoBackHistoryOffset = (): number => {
  const goBackHistoryState: unknown = getCurrentHistoryState();

  if (!checkIsGoBackHistoryState(goBackHistoryState)) {
    return -1;
  }

  const currentHistoryIndex: number = getCurrentHistoryIndex();
  return Math.min(goBackHistoryState.goBackHistoryIndex - currentHistoryIndex, -1);
};

export const getLocationHrefWithHistoryState = (): string => {
  let locationHref: string = window.location.href;

  const historyState: unknown = getCurrentHistoryState();

  // If history state is set then append it to the URL as a JSON stringified string.
  // This is required to restore history state after sign-in.
  if (historyState) {
    try {
      const historyStateJson: string = JSON.stringify(historyState);
      const joinChar: '&' | '?' = locationHref.includes('?') ? '&' : '?';
      locationHref += `${joinChar}${HISTORY_STATE_PARAM}=${historyStateJson}`;
    } catch {
      // Do nothing
    }
  }

  return locationHref;
};

// Helper function for SignIn redirection
export const redirectToSignInURL = () => {
  // Remove the session cookie to ensure the user is logged out.
  removeSessionCookie();

  // Define the client ID from a constant.
  const clientId: string = CLIENT_ID;

  // Get the origin URL (the base URL of the current page).
  const originUrl: string = window.location.origin;

  // Get the current location href along with any history state information.
  const redirectUrl: string = getLocationHrefWithHistoryState();

  // Build the unauthorized URL by adding query parameters for client ID, scope, 
  // response type, redirect URI, and state information.
  const unauthorizedUrl: string = addQueryParam(UNAUTHORIZED_URL as string,
    {
      client_id: clientId,
      scope: 'openid',
      response_type: 'code',
      redirect_uri: encodeURIComponent(originUrl), // Encoding the origin URL for safety.
      state: encodeURIComponent(redirectUrl), // Encoding the redirect URL for safety.
    });

  // Redirect the user to the unauthorized URL (the sign-in page).
  window.location.assign(unauthorizedUrl);
};
