import { useContext, useEffect, useMemo, useState } from 'react';
import { CATEGORY_PARAM, PERFORMER_PARAM } from '../../../lib/constants';
import { convertArrayToMap } from '../../../lib/objectUtils';
import { trackPageViewEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { CategoriesContext } from '../../../modules/categories';
import { EventListContext } from '../../../modules/eventList';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { RegionContext } from '../../../modules/region';
import { TopNavContext } from '../../../modules/topNav';
import { convertDateFilterStateToDateRange, useDateFilterStateFromUrl, type DateFilterState, type DateRange } from '../../organisms/DateFilter';
import { usePresetFilterStateFromUrl, type PresetFilterOption } from '../../organisms/PresetFilter';
import { checkIsCurrentRegionSelected, selectFilterOption, type RegionFilterState } from '../../organisms/RegionFilter';
import { useRegionFilterStateFromUrl } from '../../organisms/RegionFilter/RegionFilter.hooks';
import { useTabKeyFromUrl, type TabsMap } from '../../organisms/TabBar';
import { ALL_EVENTS_PAGE_TAB_KEYS, TABS_WITH_CATEGORY_FILTER, TABS_WITH_PERFORMER_FILTER, TRACKING_PAGE_NAME } from './AllEventsPage.constants';
import type { AllEventsPagePresenterProps, AllEventsPageProps, AllEventsTabKey, ExcludedSubCategoryName } from './AllEventsPage.types';
import { buildAllEventsPageStaticApiParams, checkShouldSuppressComedy, checkShouldSuppressMLBBaseball, getAllEventsPageTabs, getCategoryFilterOptions, getPerformerFilterOptions } from './AllEventsPage.utils';
import { getCardTypeFilterOptions } from '../../../lib/util';
import { SELECTED_CARD_INDEX_PARAM } from '../ExclusiveEventsPage';

export const usePresenter = (props: AllEventsPageProps): AllEventsPagePresenterProps => {
  const { accountTags, selectAccountCardByIndex, accountCardDetails, selectedAccountCardTags } = useContext(AuthContext);

  /** Static categories with sub-categories and performers */
  const { categoryMenuItemStates } = useContext(TopNavContext);

  const { setIsFetchEnabled, setStaticApiParams } = useContext(EventListContext);

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const {
    allRegions: regions,
    allRegionsMap: regionsMap,
    currentRegion,
    homePageRegionFilterState,
    setHomePageRegionFilterState,
    areRegionsLoading,
  } = useContext(RegionContext);

  const {
    categories,
    areCategoriesLoading,
  } = useContext(CategoriesContext);

  const { trackEvent } = useAnalyticsManager();

  // Track analytics for page visits
  useEffect(() => {
    trackPageViewEvent(trackEvent, TRACKING_PAGE_NAME.forPageVisits);
  }, [trackEvent]);

  /** Currently active tab key that is extracted from query parameters in the current URL */
  const activeTabKey: AllEventsTabKey = useTabKeyFromUrl<AllEventsTabKey>({ allowedTabKeys: ALL_EVENTS_PAGE_TAB_KEYS });

  /** Exclusives tab has a different logic compared to other tabs:
   * 1. Region and date filters should always be reset
   * 2. MLB Baseball events should always be suppressed when category is either not selected or it is Sports
   */
  const isExclusivesTabActive: boolean = useMemo(() => activeTabKey === 'exclusives', [activeTabKey]);

  /** 
 * Determines if the card type filter should be displayed.
 * The filter is shown only if there is more than one card available.
 */
  const shouldShowCardTypeFilter = useMemo(() => isExclusivesTabActive && accountCardDetails.length > 1, [accountCardDetails.length, isExclusivesTabActive]);

  /** Generates filter options for the card type filter. */
  const cardTypeFilterOptions: PresetFilterOption[] = useMemo(
    () => shouldShowCardTypeFilter ? getCardTypeFilterOptions({ accountCardDetails }) : [],
    [shouldShowCardTypeFilter, accountCardDetails],
  );

  /** Converts the card type filter options into a map.*/
  const cardTypeFilterOptionsMap: Record<string, PresetFilterOption> | undefined = useMemo(
    () => cardTypeFilterOptions.length
      ? convertArrayToMap(cardTypeFilterOptions)
      : undefined,
    [cardTypeFilterOptions],
  );

  /** Retrieves the currently selected card type filter state from the URL. */
  const cardTypeFilterState: PresetFilterOption | undefined = usePresetFilterStateFromUrl({
    queryParamName: SELECTED_CARD_INDEX_PARAM,
    presetFilterOptionsMap: cardTypeFilterOptionsMap,
  });

  /**
   * Determines the tags to display on the current page.
   * If a card type filter state is selected selectedAccountCardTags,
   * those tags are used. Otherwise, default account tags are passed to the api query params.
   */
  const currentPageTags = useMemo(
    () => cardTypeFilterState && selectedAccountCardTags ? selectedAccountCardTags : accountTags,
    [accountTags, cardTypeFilterState, selectedAccountCardTags],
  );

  /**
 * Updates the selected account card based on the current card type filter state.
 * - If the `cardTypeFilterState.id` is a valid number and differs from the currently selected card (`selectedAccountCardIndex`),
 *   it updates the selection to match the filter state.
 */
  useEffect(() => {
    if (cardTypeFilterState) {
      selectAccountCardByIndex(Number(cardTypeFilterState?.id)); // Updates the selected account card to match the filter.
    } else {
      selectAccountCardByIndex(undefined);
    }
  }, [cardTypeFilterState, selectAccountCardByIndex]);

  /** Region filter state that is extracted from query parameters in the current URL */
  const regionFilterState: RegionFilterState | undefined = useRegionFilterStateFromUrl({ regionsMap });
  const [isRegionFilterStateInitialised, setIsRegionFilterStateInitialised] = useState<boolean>(false);
  const [nonExclusivesTabRegionFilterState, setNonExclusivesTabRegionFilterState] = useState<RegionFilterState | undefined>(homePageRegionFilterState);

  // Make sure that region filter state is properly initialised
  useEffect(() => {
    if (!isRegionFilterStateInitialised && !areRegionsLoading) {
      // At this point 'regionFilterState' value will be set only in 2 cases:
      // 1. Region has been passed in via region query parameter directly in the URL, e.g. /all-events?region=7, or
      // 2. Region has been set after redirect below.
      // Please note that region is not required on Exclusives tab so mark region filter state as initialised if user is on Exclusives tab as well.
      if (regionFilterState || isExclusivesTabActive) {
        setIsRegionFilterStateInitialised(true);
      } else if (!isExclusivesTabActive) {
        // When user lands on non-Exclusives tab without any region query parameter in the URL, e.g. /all-events?region=7, then we need to set region filter state to the same region value as on the Home page.
        // So we update the current URL by adding region query parameter with the region value from the Home page.
        // This will eventually update 'regionFilterState' value, which will re-run this useEffect again but this time the 'if' condition above will be true.
        selectFilterOption({ regionFilterOptionValue: homePageRegionFilterState, appendQueryParamsToUrl });
      }
    }
  }, [isRegionFilterStateInitialised, areRegionsLoading, regionFilterState, isExclusivesTabActive, homePageRegionFilterState, appendQueryParamsToUrl]);

  /** Date filter state that is extracted from query parameters in the current URL */
  const dateFilterState: DateFilterState | undefined = useDateFilterStateFromUrl();
  const dateRange: Record<keyof DateRange, string> = useMemo(() => convertDateFilterStateToDateRange(dateFilterState), [dateFilterState]);
  const [nonExclusivesTabDateFilterState, setNonExclusivesTabDateFilterState] = useState<DateFilterState | undefined>();

  useEffect(() => {
    // Store region and date filter states on non-Exclusives tab so that these states could be persisted across other non-Exclusives tabs
    if (!isExclusivesTabActive) {
      setNonExclusivesTabRegionFilterState(regionFilterState);
      setNonExclusivesTabDateFilterState(dateFilterState);
    }
  }, [isExclusivesTabActive, regionFilterState, dateFilterState]);

  useEffect(() => {
    // If region filter state is set on non-Exclusives tabs then we must also update region filter state for the Home page
    if (nonExclusivesTabRegionFilterState) {
      setHomePageRegionFilterState(nonExclusivesTabRegionFilterState);
    }
  }, [nonExclusivesTabRegionFilterState, setHomePageRegionFilterState]);

  const shouldShowCategoryFilter: boolean = useMemo(
    () => !areCategoriesLoading && !!categoryMenuItemStates.length && TABS_WITH_CATEGORY_FILTER.includes(activeTabKey),
    [areCategoriesLoading, categoryMenuItemStates.length, activeTabKey],
  );

  const categoryFilterPlaceholderKey: string = useMemo(() => shouldShowCategoryFilter ? `categoryFilters.${activeTabKey}.label` : '', [shouldShowCategoryFilter, activeTabKey]);

  const categoryFilterAriaLabelKey: string = useMemo(() => shouldShowCategoryFilter ? `categoryFilters.${activeTabKey}.ariaLabel` : '', [shouldShowCategoryFilter, activeTabKey]);

  const categoryFilterOptions: PresetFilterOption[] = useMemo(
    () => shouldShowCategoryFilter ? getCategoryFilterOptions({ activeTabKey, categories, categoryMenuItemStates }) : [],
    [shouldShowCategoryFilter, activeTabKey, categories, categoryMenuItemStates],
  );

  const categoryFilterOptionsMap: Record<string, PresetFilterOption> | undefined = useMemo(
    () => categoryFilterOptions.length ? convertArrayToMap(categoryFilterOptions) : undefined,
    [categoryFilterOptions],
  );

  /** Category filter state that is extracted from query parameters in the current URL */
  const categoryFilterState: PresetFilterOption | undefined = usePresetFilterStateFromUrl({
    queryParamName: CATEGORY_PARAM,
    presetFilterOptionsMap: categoryFilterOptionsMap,
  });

  const shouldShowPerformerFilter: boolean = useMemo(
    () => shouldShowCategoryFilter && TABS_WITH_PERFORMER_FILTER.includes(activeTabKey),
    [shouldShowCategoryFilter, activeTabKey],
  );

  const performerFilterPlaceholderKey: string = useMemo(() => shouldShowPerformerFilter ? `performerFilters.${activeTabKey}.label` : '', [shouldShowPerformerFilter, activeTabKey]);

  const performerFilterAriaLabelKey: string = useMemo(() => shouldShowPerformerFilter ? `performerFilters.${activeTabKey}.ariaLabel` : '', [shouldShowPerformerFilter, activeTabKey]);

  const performerFilterOptions: PresetFilterOption[] = useMemo(
    () => shouldShowPerformerFilter && categoryFilterState ? getPerformerFilterOptions({ activeTabKey, categoryFilterState, categoryMenuItemStates }) : [],
    [shouldShowPerformerFilter, activeTabKey, categoryFilterState, categoryMenuItemStates],
  );

  const performerFilterOptionsMap: Record<string, PresetFilterOption> | undefined = useMemo(
    () => {
      if (performerFilterOptions.length) {
        return convertArrayToMap(performerFilterOptions);
      }

      // When category filter state is cleared then return empty map so that performer query param also gets cleared
      return shouldShowPerformerFilter && !categoryFilterState ? {} : undefined;
    },
    [performerFilterOptions, shouldShowPerformerFilter, categoryFilterState],
  );

  /** Performer filter state that is extracted from query parameters in the current URL */
  const performerFilterState: PresetFilterOption | undefined = usePresetFilterStateFromUrl({
    queryParamName: PERFORMER_PARAM,
    presetFilterOptionsMap: performerFilterOptionsMap,
  });

  const isPerformerFilterDisabled: boolean = useMemo(
    () => !categoryFilterState || !performerFilterOptions.length,
    [categoryFilterState, performerFilterOptions.length],
  );

  const excludedSubCategoryName: ExcludedSubCategoryName | undefined = useMemo(() => {
    // Suppress MLB Baseball events only on Exclusives tab when category is either not selected or it is Sports
    if (checkShouldSuppressMLBBaseball({ activeTabKey, categoryFilterState })) {
      return 'MLB Baseball';
    }

    // Suppress Comedy events only on Theater tab when category is not selected
    if (checkShouldSuppressComedy({ activeTabKey, categoryFilterState })) {
      return 'Comedy';
    }

    return undefined;
  }, [activeTabKey, categoryFilterState]);

  // Update Event List context with isFetchEnabled flag
  useEffect(
    () => setIsFetchEnabled(!areRegionsLoading && isRegionFilterStateInitialised && !areCategoriesLoading && !!categoryMenuItemStates.length),
    [areRegionsLoading, isRegionFilterStateInitialised, areCategoriesLoading, categoryMenuItemStates.length, setIsFetchEnabled],
  );

  // Update Event List context with static API parameters that will be merged with dynamic page index
  useEffect(() => {
    setStaticApiParams(buildAllEventsPageStaticApiParams({
      accountTags: currentPageTags,
      activeTabKey,
      region: checkIsCurrentRegionSelected(regionFilterState) ? currentRegion : regionFilterState,
      dateRange,
      categoryFilterState,
      excludedSubCategoryName,
      performerFilterState,
    }));
  }, [activeTabKey, categoryFilterState, currentPageTags, currentRegion, dateRange, excludedSubCategoryName, performerFilterState, regionFilterState, setStaticApiParams]);

  /** List of tabs for tab navigation */
  const tabs: TabsMap<AllEventsTabKey> = useMemo(() => {
    return getAllEventsPageTabs({
      regionFilterState: nonExclusivesTabRegionFilterState,
      dateFilterState: nonExclusivesTabDateFilterState,
    });
  }, [nonExclusivesTabRegionFilterState, nonExclusivesTabDateFilterState]);

  return {
    ...props,
    tabs,
    regions,
    regionFilterState,
    dateFilterState,
    shouldShowCategoryFilter,
    categoryFilterPlaceholderKey,
    categoryFilterAriaLabelKey,
    categoryFilterOptions,
    categoryFilterState,
    shouldShowPerformerFilter,
    performerFilterPlaceholderKey,
    performerFilterAriaLabelKey,
    performerFilterOptions,
    performerFilterState,
    isPerformerFilterDisabled,
    excludedSubCategoryName,
    shouldShowCardTypeFilter,
    cardTypeFilterOptions,
    cardTypeFilterState,
  };
};
