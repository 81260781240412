import { formatNumberToLocaleString, round } from '../../../lib/util';

export const getMainCharge = (params: {
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
  /**
   * Formatted string for the remaining cash price as total cash price less cash value of applied reward units, e.g. '$12,345', '$12,345.67', etc.
   * For whole dollar amounts it has no decimal places.
   * For non-whole dollar amounts it has 2 decimal places.
   */
  formattedRemainingCashPrice: string;
  /** Formatted total price in reward units (without unit name), e.g. '12,345' for miles, '$12,345.67' for cash rewards, etc. */
  formattedTotalPriceInRewardUnits: string;
  /** Lower cased loyalty unit name, e.g. 'miles', 'points', etc. */
  loyaltyUnitName: string;
  /** Currency code, e.g. 'USD' */
  currency: string;
}): string => {
  const {
    isPayWithRewardsOnly,
    formattedRemainingCashPrice,
    formattedTotalPriceInRewardUnits,
    loyaltyUnitName,
    currency,
  } = params;

  // If event only allows payment with rewards then show total price in reward units, e.g. '12,345 miles'.
  if (isPayWithRewardsOnly) {
    return `${formattedTotalPriceInRewardUnits} ${loyaltyUnitName}`.trim();
  }

  // For split payment events show the remaining cash price with currency, e.g. '$12,345 USD', '$12,345.67 USD', etc.
  return `${formattedRemainingCashPrice} ${currency}`.trim();
};

export const getRewardUnitCharge = (params: {
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
  /** Formatted string for the number of applied reward units (with unit name), e.g. '12,345 miles', '$12,345.67 cash rewards', etc. */
  formattedAppliedRewardUnitsWithUnitName: string;
  isCashOnlyAccountCard: boolean;
}): string | undefined => {
  const {
    isPayWithRewardsOnly,
    formattedAppliedRewardUnitsWithUnitName,
    isCashOnlyAccountCard,
  } = params;

  // If event only allows payment with rewards then do not show reward unit charge.
  // It is already displayed in the UI as Total Charge.
  if (isPayWithRewardsOnly || isCashOnlyAccountCard) {
    return undefined;
  }

  // For split payment events show the number of applied reward units (with unit name), e.g. '+12,345 miles', '+$12,345.67 cash rewards', etc.
  return `+${formattedAppliedRewardUnitsWithUnitName}`;
};

export const formatPrice = (params: {
  amount: number;
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
  /** Validated number of reward units that is equivalent to $1, e.g. 100. 1 if the value is not a number. */
  rewardUnitsPerDollar: number;
  /** Lower cased reward unit name, e.g. 'miles', 'points', etc. */
  rewardUnitName: string;
}): string => {
  const {
    amount,
    isPayWithRewardsOnly,
    rewardUnitsPerDollar,
    rewardUnitName,
  } = params;

  if (!isPayWithRewardsOnly) {
    return formatNumberToLocaleString({ num: amount, unitName: 'dollarsWithCents', shouldIncludeUnitName: false });
  }

  const amountInRewardUnits: number = round({ amount: amount * rewardUnitsPerDollar, rounding: 'up', unitName: rewardUnitName });
  return formatNumberToLocaleString({ num: amountInRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: true });
};
