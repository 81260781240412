import cx from 'classnames';
import React from 'react';

import Divider from '../../atoms/Divider';
import SportsMenuTabList from '../../molecules/SportsMenuTabList';
import SubmenuItemList from '../../molecules/SubmenuItemList';
import styles from './SubmenuWithTabs.module.scss';
import useSubmenuWithTabsPresenter from './SubmenuWithTabs.presenter';
import getProps from './SubmenuWithTabs.props';
import { SubmenuWithTabsCombinedProps } from './types';

const SubmenuWithTabs: React.FC<SubmenuWithTabsCombinedProps> = (props) => {
  const {
    className,
    classes,
    sportsMenuTabList,
    divider1,
    submenuItemList, // MANUAL OVERRIDE
    // MANUAL OVERRIDE STARTS
    selectLeague,
    hoveredLeague,
    isOpen,
    // MANUAL OVERRIDE ENDS
  } = useSubmenuWithTabsPresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenuWithTabs;

  return (
    <div
      className={cx(currentStyle, className)}
      role='menu'
    >
      <div
        className={cx(styles.content, classes?.content)}
        role='group'
      >
        <div
          className={cx(styles.leftContent, classes?.leftContent)}
          role='group'
        >
          <SportsMenuTabList
            activeTab={hoveredLeague} // MANUAL OVERRIDE
            setHoveredLeague={selectLeague} // MANUAL OVERRIDE
            focusable={isOpen} // MANUAL OVERRIDE
            className={cx(styles.sportsMenuTabList, classes?.sportsMenuTabList)}
            {...internalProps.sportsMenuTabList}
            {...sportsMenuTabList} />
        </div>
        <Divider
          className={cx(styles.divider1, classes?.divider1)}
          {...internalProps.divider1}
          {...divider1} />
        <div
          className={cx(styles.rightContent, classes?.rightContent)}
          role='group'
        >
          <SubmenuItemList
            className={cx(styles.submenuItemList, classes?.submenuItemList)}
            {...internalProps.submenuItemList}
            {...submenuItemList} />
        </div>
      </div>
    </div>
  );
};

export default SubmenuWithTabs;
