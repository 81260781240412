import { REGION_PARAM } from '../../../lib/constants';
import { filterRegionsByText } from '../../../lib/regionUtils';
import i18n from '../../../modules/locale/i18n';
import type { AppendQueryParamsToUrlFunc } from '../../../modules/navigation/Navigation.types';
import type { Region } from '../../../modules/partnership';
import type { FilterOption } from '../../atoms/BaseFilter';
import { SEARCH_QUERY_MIN_LENGTH } from './RegionFilter.constants';
import type { RegionFilterOptionValue, RegionFilterState } from './RegionFilter.types';

/**
 * Checks if the provided regionFilterState is equal to 'currentRegion'.
 * This function is useful for determining whether the current region is selected.
 * @returns {boolean} True if regionFilterState is 'currentRegion', otherwise false.
 */
export const checkIsCurrentRegionSelected = (
  /** Current state of the region filter component */
  regionFilterState: RegionFilterState | undefined,
): regionFilterState is 'currentRegion' => {
  return regionFilterState === 'currentRegion';
};

/**
 * Returns a display value based on the current filter state.
 * @returns {string} The display value for the current selected region.
 */
export const getDisplayValue = (params: {
  /** Current state of the region filter component */
  displayRegionFilterState: RegionFilterState;
  /** Current region based on user's IP address */
  currentRegion: Region | undefined;
}): string => {
  const { displayRegionFilterState, currentRegion } = params;

  const displayedRegion: Region | undefined = checkIsCurrentRegionSelected(displayRegionFilterState)
    ? currentRegion
    : displayRegionFilterState;

  return displayedRegion?.displayName || displayedRegion?.city || i18n.t('regionFilters.location');
};

/**
 * Returns filter options for region filter.
 * @returns {FilterOption<RegionFilterOptionValue>[]} Filter options for region filter.
 */
export const getRegionFilterOptions = (params: {
  /** Current state of the region filter component */
  regionFilterState: RegionFilterState | undefined;
  /** Indicates whether 'Use current location' option is shown */
  canUseCurrentRegion: boolean;
  /** Indicates whether a search field for region filtering is shown */
  canSearchRegion: boolean;
  /** Search query for filtering regions by display name */
  searchQuery: string;
  /** Array of all regions */
  regions: Region[];
}): { filterOptions: FilterOption<RegionFilterOptionValue>[], displayedRegions: Region[] } => {
  const {
    regionFilterState,
    canUseCurrentRegion,
    canSearchRegion,
    searchQuery,
    regions,
  } = params;

  const newFilterOptions: FilterOption<RegionFilterOptionValue>[] = [];

  // If applicable then add 'Use current location' option as the first region filter option
  if (canUseCurrentRegion) {
    newFilterOptions.push({
      value: 'currentRegion',
      iconAsset: 'MyLocation',
      textKey: 'regionFilters.useMyCurrentLocation',
      isSelected: checkIsCurrentRegionSelected(regionFilterState),
    });
  }

  let displayedRegions: Region[] = regions;

  if (canSearchRegion) {
    // If region search is enabled then display only regions that match the search query.
    // If search query is too short then do not display any regions.
    displayedRegions = searchQuery.trim().length >= SEARCH_QUERY_MIN_LENGTH
      ? filterRegionsByText(regions, searchQuery)
      : [];
  }

  // Transform all displayed regions to region filter options
  displayedRegions.forEach((region: Region) => {
    newFilterOptions.push({
      value: region,
      iconAsset: 'LocationPin',
      textKey: region.displayName || region.city,
      isSelected: typeof regionFilterState === 'object' && regionFilterState.id === region.id,
    });
  });

  return { filterOptions: newFilterOptions, displayedRegions: displayedRegions };
};

/** Handles the selection of a filter option by updating region query parameters in the current URL */
export const selectFilterOption = (params: {
  /** The selected filter option value */
  regionFilterOptionValue: RegionFilterOptionValue | undefined;
  /** Function that appends query parameters to the current URL */
  appendQueryParamsToUrl: AppendQueryParamsToUrlFunc;
}): void => {
  const { regionFilterOptionValue, appendQueryParamsToUrl } = params;

  // Clear the region filter
  if (!regionFilterOptionValue) {
    appendQueryParamsToUrl({ [REGION_PARAM]: '' });
    return;
  }

  // Handle 'Use my current region' selection
  if (checkIsCurrentRegionSelected(regionFilterOptionValue)) {
    appendQueryParamsToUrl({ [REGION_PARAM]: regionFilterOptionValue });
    return;
  }

  // Handle region selection
  appendQueryParamsToUrl({ [REGION_PARAM]: regionFilterOptionValue.id.toString() });
  return;
};

export const convertRegionFilterStateToQueryParams = (regionFilterState: RegionFilterState | undefined): Record<string, string> | undefined => {
  if (!regionFilterState) {
    return undefined;
  }

  return {
    // Region filter state may either be 'currentRegion' or Region object
    [REGION_PARAM]: checkIsCurrentRegionSelected(regionFilterState) ? regionFilterState : regionFilterState.id.toString(),
  };
};

export const getRegionFilterStateFromUrl = (params: {
  /** Map of allowed regions where key is region.id and value is region */
  regionsMap: Record<string, Region> | undefined;
  /** Value of region filter parameter from the current URL */
  regionParam: string | undefined;
  /** Function to clear region filter query parameter in the URL */
  clearRegionParamInUrl: () => void;
}): RegionFilterState | undefined => {
  const { regionsMap, regionParam, clearRegionParamInUrl } = params;

  if (!regionsMap || !regionParam) {
    return undefined;
  }

  let isRegionParamValid: boolean = false;

  try {
    // Accept only valid values for region parameter
    const validValues: RegionFilterState[] = ['currentRegion'];
    if (validValues.includes(regionParam as RegionFilterState)) {
      isRegionParamValid = true;
      return regionParam as RegionFilterState;
    }

    // Region parameter may contain a region Id, e.g. /all-events?region=123
    // Try to find an existing region by this Id.
    const region: Region | undefined = regionsMap[regionParam];
    if (region) {
      isRegionParamValid = true;
      return region;
    }
  } finally {
    // Remove invalid region parameter from the URL
    if (!isRegionParamValid) {
      clearRegionParamInUrl();
    }
  }

  return undefined;
};
