import type { Dispatch, SetStateAction } from 'react';
import type { ArrowDirectionEnum } from '../../../lib/accessibility';
import { runInNextCycle } from '../../../lib/util';
import type { CategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import type { SubmenuTabItemCombinedProps } from '../../molecules/SubmenuTabItem/types';

/**
 * Handles arrow key navigation for category elements.
 *
 * This function allows users to navigate through elements using up and down arrow keys.
 * It focuses on the next or previous element and optionally triggers a callback.
 *
 * @param {ArrowDirectionEnum} direction - The direction of navigation ('ArrowUp' or 'ArrowDown').
 * @param {number} index - The current index of the focused element.
 * @param {HTMLAnchorElement[]} arrayOfRefs - Array of element references to navigate through.
 * @param {(nextIndex: number) => void} [selectFunction] - Optional callback to handle selection.
 */
export const handleExploreArrowNavigation = (
  direction: ArrowDirectionEnum,
  index: number,
  arrayOfRefs: HTMLAnchorElement[],
  selectFunction?: (nextIndex: number) => void,
) => {
  // Guard clause: Return if refs array is invalid
  if (!arrayOfRefs || !Array.isArray(arrayOfRefs)) {
    return;
  }

  // Determine the next index based on navigation direction
  const nextIndex = direction === 'ArrowDown' ? index + 1 : direction === 'ArrowUp' ? index - 1 : undefined;

  // If a valid next index is found, focus the element and run the callback
  if (nextIndex !== undefined && nextIndex >= 0 && nextIndex < arrayOfRefs.length && arrayOfRefs[nextIndex]) {
    runInNextCycle(() => {
      // Focus the next element in the refs array
      arrayOfRefs[nextIndex]?.focus();
      // Call the selection function, if provided
      if (selectFunction) {
        selectFunction(nextIndex);
      }
    });
  }
};

/**
 * Builds the category tabs for the top navigation.
 *
 * This function maps the navigation data to the tab components and handles state, refs, and events.
 */
export const buildCategoryTabs = (
  topNavTabsData: CategoryMenuItemState[],
  onItemClicked: Dispatch<SetStateAction<string | undefined>>,
  onMouseOver: (id: string | undefined) => void,
  setCategoryRef: (element: HTMLAnchorElement) => void,
  arrayOfCategoryRefs: HTMLAnchorElement[],
  openDropdownId?: string,
): SubmenuTabItemCombinedProps[] => {
  // Map over the navigation data to build tab items
  return topNavTabsData.map((category, index): SubmenuTabItemCombinedProps => {
    return (
      {
        // Set tab state based on whether it's the currently open dropdown
        state: openDropdownId === category.id ? 'Selected' : 'Default',
        id: category.id,
        text: {
          value: category.name,
        },
        // Attach the category ref to the element
        elementRef: setCategoryRef,
        // Set the divider position
        dividerPosition: category.hasTopBorder ? 'Top' : 'None',
        // Handle click event to open the category
        onItemClicked: () => onItemClicked(category.id),
        // Handle hover event to preview the category
        onMouseOver: () => onMouseOver(category.id),
        // Handle arrow navigation for keyboard users
        handleArrowNavigation: (direction: ArrowDirectionEnum) =>
          handleExploreArrowNavigation(
            direction,
            index,
            arrayOfCategoryRefs,
          ),
        role: 'menu',
      }
    );
  });
};
