import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import type { ToolTipPresenterProps, ToolTipProps } from './ToolTip.types';

export const usePresenter = (props: ToolTipProps): ToolTipPresenterProps => {
  const {
    textKey,
    ariaLabelKey,
    placement = 'top',
    theme = 'dark',
  } = props;

  const { t } = useTranslation();

  // Make sure HTML Id starts with an alpha character
  const toolTipId: string = useMemo(() => `tooltip-${uuidv4()}`, []);

  const text: string = t(textKey);
  const ariaLabel: string = t(ariaLabelKey);

  return {
    ...props,
    toolTipId,
    text,
    ariaLabel,
    placement,
    theme,
  };
};
