import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon';
import TextInput from '../../atoms/TextInput';
import styles from './SearchBar.module.scss';
import { usePresenter } from './SearchBar.presenter';
import type { SearchBarProps } from './SearchBar.types';

export const SearchBar: React.FC<SearchBarProps> = (props) => {
  const {
    className,
    textInputRef,
    textInputState,
    textInputValue,
    textInputClassName,
    hasSearchSuggestions,
    onTextInputClick,
    onTextInputChanged,
    onTextInputKeyDown,
    onTextInputFocus,
    onTextInputBlur,
    onClearButtonClick,
  } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <div className={cx(styles.searchBar, className)}>
      <Icon
        asset='SearchSmall'
        style='White'
        className={styles.icon}
      />
      <TextInput
        inputRef={textInputRef}
        state={textInputState}
        style='Clear'
        size='Regular'
        textValue={textInputValue}
        textPlaceholder={t('search.searchBarPlaceholder')}
        ariaLabel={t('search.menuUnderneathSearchBarScreenReaderText')}
        // Add ARIA attributes only if hasSearchSuggestions is provided (not undefined)
        ariaRole={hasSearchSuggestions !== undefined ? 'combobox' : undefined}
        aria-haspopup={hasSearchSuggestions !== undefined ? true : undefined}
        aria-expanded={hasSearchSuggestions}
        aria-autocomplete='list'
        aria-controls='searchSuggestionsList'
        button={{
          type: 'Icon',
          style: 'Text',
          size: 'Small',
          icon: {
            asset: 'Close',
            style: 'White',
          },
          ariaLabel: t('search.eraseSearchScreenReaderText'),
          onClick: onClearButtonClick,
          focusable: !!textInputValue, // Only allow focus when there's text to clear
        }}
        className={styles.textInput}
        classes={{
          input: textInputClassName,
          button: styles.clearButton,
        }}
        onClick={onTextInputClick}
        onTextChanged={onTextInputChanged}
        onKeyDown={onTextInputKeyDown}
        onFocusChanged={onTextInputFocus}
        onBlurChanged={onTextInputBlur}
      />
    </div>
  );
};
