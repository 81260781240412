import { useCallback, useContext, useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react';
import { CHECKOUT_BRAINTREE_FORM } from '../../../../lib/constants';
import type { ListingDetailMetadata } from '../../../../lib/types';
import { delay, formatNumberToLocaleString, getNonExhaustiveCasesInSwitchStatementError, round } from '../../../../lib/util';
import { AuthContext } from '../../../../modules/auth';
import type { CardData } from '../../../../modules/partnership';
import { RewardPaymentOptions, type PaymentInfoType, type RewardPaymentOption, type RewardPaymentOptionErrorScope } from '../../../organisms/PaymentInfo';
import { INVALID_DECIMAL_CHARS_REGEX, INVALID_INTEGER_CHARS_REGEX } from '../CheckoutPage.constants';
import type { CheckoutPaymentInfoProps } from '../CheckoutPage.types';
import { getMaxRewardUnitsToBeUsed, getPaymentInfoType, validateEditedRewardUnitsToApply } from '../CheckoutPage.utils';
import { type PresetFilterOption, usePresetFilterStateFromUrl } from '../../../organisms/PresetFilter';
import { getPaymentFilterOptions } from '../../../organisms/PaymentInfo/PaymentInfo.utils';
import { convertArrayToMap } from '../../../../lib/objectUtils';
import { SELECTED_CARD_INDEX_PARAM } from '../../ExclusiveEventsPage';

export const useCheckoutPaymentInfo = (params: {
  listingDetailMetadata: ListingDetailMetadata | undefined;
}): CheckoutPaymentInfoProps => {
  const {
    selectedAccountCardDetail,
    accountCardDetails,
  } = useContext(AuthContext);

  const {
    accountCardImageUrl = '',
    accountCardLastFourDigits = '',
    rewardUnitsTotal = 0,
    rewardUnitsPerDollar = 1,
    rewardUnitName = '',
    isIntegerRewardUnit = false,
    formattedRewardUnitsTotal = '',
    accountProgramType = '',
  } = selectedAccountCardDetail ?? {};

  const {
    eventMetadata: {
      isPayWithRewardsOnly = false,
      isExclusiveEvent = false,
      tags: eventTags = [],
    } = {},
    pricing: {
      total: totalPriceInCash = 0,
      currency = 'USD',
    } = {},
    listingMetadata: {
      tags: listingTags = [],
    } = {},
  } = params.listingDetailMetadata ?? {};

  /** Formatted total price in cash (with cents), e.g. '$12,345.00' */
  const formattedTotalPriceInCash: string = useMemo(
    () => formatNumberToLocaleString({ num: totalPriceInCash, unitName: 'dollarsWithCents', shouldIncludeUnitName: false }),
    [totalPriceInCash],
  );

  /** Total price in reward units */
  const totalPriceInRewardUnits: number = useMemo(
    () => round({ amount: totalPriceInCash * rewardUnitsPerDollar, rounding: 'up', unitName: rewardUnitName }),
    [totalPriceInCash, rewardUnitsPerDollar, rewardUnitName],
  );

  const totalPricesInRewardsUnit: Record<string, number> | undefined = useMemo(() => isPayWithRewardsOnly ? accountCardDetails.reduce((acc, accountCardDetail) => {
    const { accountLoyaltyUnitTag, rewardUnitsPerDollar: currentRewardUnitsPerDollar = 1, rewardUnitName: currentRewardsUnitName } = accountCardDetail;
    const key = accountLoyaltyUnitTag || '';
    acc[key] = round({ amount: totalPriceInCash * currentRewardUnitsPerDollar, rounding: 'up', unitName: currentRewardsUnitName });
    return acc;
  }, {}) : undefined, [accountCardDetails, isPayWithRewardsOnly, totalPriceInCash]);

  /** Formatted total price in reward units (without unit name), e.g. '12,345' for miles, '$12,345.67' for cash rewards, etc. */
  const formattedTotalPriceInRewardUnits: string = useMemo(
    () => formatNumberToLocaleString({ num: totalPriceInRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: false }),
    [totalPriceInRewardUnits, rewardUnitName],
  );

  /** Formatted total price in reward units (with unit name), e.g. '12,345 miles', '$12,345.67 cash rewards', etc. */
  const formattedTotalPriceInRewardUnitsWithUnitName: string = useMemo(
    () => formatNumberToLocaleString({ num: totalPriceInRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: true }),
    [totalPriceInRewardUnits, rewardUnitName],
  );

  /**
   * Maximum number of reward units that can be used.
   * It is a minimum of rewardUnitsTotal and totalPriceInRewardUnits.
   */
  const maxRewardUnitsToBeUsed: number = useMemo(
    () => getMaxRewardUnitsToBeUsed({ isPayWithRewardsOnly, rewardUnitsTotal, totalPriceInRewardUnits }),
    [isPayWithRewardsOnly, rewardUnitsTotal, totalPriceInRewardUnits],
  );

  /** Reward payment option: 'ApplyRewards' or 'DoNotApplyRewards' */
  const [rewardPaymentOption, setRewardPaymentOption] = useState<RewardPaymentOption | undefined>(undefined);

  /** Translation key for reward payment option error */
  const [rewardPaymentOptionErrorKey, setRewardPaymentOptionErrorKey] = useState<string | undefined>(undefined);

  /** Error scope for reward payment options: 'all', 'applyRewards' or 'doNotApplyRewards' */
  const [rewardPaymentOptionErrorScope, setRewardPaymentOptionErrorScope] = useState<RewardPaymentOptionErrorScope | undefined>(undefined);

  /** Number of reward units to apply. Preserved even if 'DoNotApplyRewards' option is selected. */
  const [rewardUnitsToApply, setRewardUnitsToApply] = useState<number>(0);
  const prevRewardUnitsToApplyRef = useRef<number>(0);

  /** Formatted number of reward units to apply (without unit name), e.g. '123,456' for miles or points, '$123,456' for cash rewards */
  const formattedRewardUnitsToApply: string = useMemo(
    () => formatNumberToLocaleString({ num: rewardUnitsToApply, unitName: rewardUnitName, shouldIncludeUnitName: false }),
    [rewardUnitsToApply, rewardUnitName],
  );

  /** Number of applied reward units. Reset to 0 if 'DoNotApplyRewards' option is selected. */
  const [appliedRewardUnits, setAppliedRewardUnits] = useState<number>(0);

  useEffect(() => {
    setRewardUnitsToApply(maxRewardUnitsToBeUsed);

    if (isPayWithRewardsOnly) {
      setAppliedRewardUnits(maxRewardUnitsToBeUsed);
    }
  }, [maxRewardUnitsToBeUsed, isPayWithRewardsOnly]);


  /** Indicates whether user is editing the number of reward units to apply */
  const [isEditingRewardUnits, setIsEditingRewardUnits] = useState<boolean>(false);

  /**
   * Formatted string for the number of reward units to apply.
   * Used only in edit mode as a value for the text input.
   */
  const [editedRewardUnitsToApplyStr, setEditedRewardUnitsToApplyStr] = useState<string>('');

  /** Regex to remove invalid characters from the number of reward units to apply in edit mode */
  const editedRewardUnitsToApplyInvalidCharsRegex: RegExp = useMemo(
    () => isIntegerRewardUnit ? INVALID_INTEGER_CHARS_REGEX : INVALID_DECIMAL_CHARS_REGEX,
    [isIntegerRewardUnit],
  );

  /** Function to select reward payment option: 'ApplyRewards' or 'DoNotApplyRewards' */
  const selectRewardPaymentOption = useCallback((newRewardPaymentOption: RewardPaymentOption) => {
    if (newRewardPaymentOption === RewardPaymentOptions.DoNotApplyRewards && isEditingRewardUnits) {
      setRewardPaymentOptionErrorKey('paymentInfo.useYourRewards.error.saveRewardsSelection');
      setRewardPaymentOptionErrorScope('applyRewards');
    } else if (rewardPaymentOption !== newRewardPaymentOption) {
      setRewardPaymentOption(newRewardPaymentOption);
      setRewardPaymentOptionErrorKey(undefined);
      setRewardPaymentOptionErrorScope(undefined);
      setAppliedRewardUnits(newRewardPaymentOption === RewardPaymentOptions.ApplyRewards ? rewardUnitsToApply : 0);
    }
  }, [isEditingRewardUnits, rewardPaymentOption, rewardUnitsToApply]);

  /**
   * Function to start editing of the number of reward units to apply.
   * Used as onClick handler for Edit button.
   */
  const startEditingRewardUnits = useCallback(() => {
    prevRewardUnitsToApplyRef.current = rewardUnitsToApply;
    setEditedRewardUnitsToApplyStr(formatNumberToLocaleString({ num: rewardUnitsToApply, unitName: rewardUnitName, shouldIncludeUnitName: false }));
    setIsEditingRewardUnits(true);
  }, [rewardUnitsToApply, rewardUnitName]);

  /**
   * Function to store the edited version of the number of reward units to apply.
   * Used as onChange handler for the text input.
   */
  const onEditedRewardUnitsToApplyChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEditedRewardUnitsToApplyStr(event.target.value);
  }, []);

  /**
   * Function to validate and update the number of reward units to apply.
   * This updates Total Charge component to show remaining cash price and the number of reward units to be applied.
   * Change can still be cancelled by clicking on Cancel button.
   * Used as onBlur handler for the text input.
   */
  const onEditedRewardUnitsToApplyBlur = useCallback(() => {
    const newEditedRewardUnitsToApply: number = validateEditedRewardUnitsToApply({ editedRewardUnitsToApplyStr, isIntegerRewardUnit, maxRewardUnitsToBeUsed });
    setRewardUnitsToApply(newEditedRewardUnitsToApply);
    setEditedRewardUnitsToApplyStr(formatNumberToLocaleString({ num: newEditedRewardUnitsToApply, unitName: rewardUnitName, shouldIncludeUnitName: false }));
  }, [editedRewardUnitsToApplyStr, isIntegerRewardUnit, maxRewardUnitsToBeUsed, rewardUnitName]);


  const resetRewardUnitsErrorAndEditingStates = useCallback(() => {
    setIsEditingRewardUnits(false);
    setRewardPaymentOptionErrorKey(undefined);
    setRewardPaymentOptionErrorScope(undefined);
  }, []);

  /**
   * Function to update applied reward units and exit edit mode.
   * This updates Total Charge component to show remaining cash price and the number of applied reward units.
   * Used as onClick handler for Save button.
   */
  const saveEditedRewardUnits = useCallback(() => {
    setAppliedRewardUnits(rewardUnitsToApply);
    resetRewardUnitsErrorAndEditingStates();
  }, [rewardUnitsToApply, resetRewardUnitsErrorAndEditingStates]);

  /**
   * Function to revert changes to reward units to apply and exit edit mode.
   * This updates Total Charge component to show remaining cash price and the number of applied reward units.
   * Used as onClick handler for Cancel button.
   */
  const cancelEditingRewardUnits = useCallback(() => {
    setRewardUnitsToApply(prevRewardUnitsToApplyRef.current);
    setAppliedRewardUnits(prevRewardUnitsToApplyRef.current);
    resetRewardUnitsErrorAndEditingStates();
  }, [resetRewardUnitsErrorAndEditingStates]);

  /** Cash value of reward units to apply, e.g. 60.00 */
  const cashValueOfRewardUnitsToApply: number = useMemo(
    () => round({ amount: Math.min(rewardUnitsToApply / rewardUnitsPerDollar, totalPriceInCash), rounding: 'down', isInteger: false }),
    [rewardUnitsToApply, rewardUnitsPerDollar, totalPriceInCash],
  );

  /** Formatted cash value of reward units to apply (with cents), e.g. $60.00 */
  const formattedCashValueOfRewardUnitsToApply: string = useMemo(
    () => formatNumberToLocaleString({ num: cashValueOfRewardUnitsToApply, unitName: 'dollarsWithCents', shouldIncludeUnitName: false }),
    [cashValueOfRewardUnitsToApply],
  );

  /** Cash value of applied reward units, e.g. 60.00. Reset to 0 if 'DoNotApplyRewards' option is selected. */
  const cashValueOfAppliedRewardUnits: number = useMemo(
    () => round({ amount: Math.min(appliedRewardUnits / rewardUnitsPerDollar, totalPriceInCash), rounding: 'down', isInteger: false }),
    [appliedRewardUnits, rewardUnitsPerDollar, totalPriceInCash],
  );

  /** Remaining cash price as total cash price less cash value of applied reward units */
  const remainingCashPrice: number = useMemo(
    () => Math.max(0, totalPriceInCash - (isEditingRewardUnits ? cashValueOfRewardUnitsToApply : cashValueOfAppliedRewardUnits)),
    [totalPriceInCash, isEditingRewardUnits, cashValueOfRewardUnitsToApply, cashValueOfAppliedRewardUnits],
  );

  /**
   * Formatted string for the remaining cash price as total cash price less cash value of applied reward units, e.g. '$12,345', '$12,345.67', etc.
   * For whole dollar amounts it has no decimal places.
   * For non-whole dollar amounts it has 2 decimal places.
   */
  const formattedRemainingCashPrice: string = useMemo(
    () => formatNumberToLocaleString({ num: remainingCashPrice, unitName: 'dollars', shouldIncludeUnitName: false }),
    [remainingCashPrice],
  );

  /** Formatted string for the remaining cash price (with cents) as total cash price less cash value of applied reward units, e.g. '$12,345.00', '$12,345.67', etc. */
  const formattedRemainingCashPriceWithCents: string = useMemo(
    () => formatNumberToLocaleString({ num: remainingCashPrice, unitName: 'dollarsWithCents', shouldIncludeUnitName: false }),
    [remainingCashPrice],
  );

  /** Formatted string for the number of applied reward units (with unit name), e.g. '12,345 miles', '$12,345.67 cash rewards', etc. */
  const formattedAppliedRewardUnitsWithUnitName: string = useMemo(
    () => formatNumberToLocaleString({ num: isEditingRewardUnits ? rewardUnitsToApply : appliedRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: true }),
    [isEditingRewardUnits, rewardUnitsToApply, appliedRewardUnits, rewardUnitName],
  );

  /** Formatted string for the number of applied reward units (without unit name), e.g. '12,345' for miles, '$12,345.67' for cash rewards, etc. */
  const formattedAppliedRewardUnitsWithoutUnitName: string = useMemo(
    () => formatNumberToLocaleString({ num: isEditingRewardUnits ? rewardUnitsToApply : appliedRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: false }),
    [isEditingRewardUnits, rewardUnitsToApply, appliedRewardUnits, rewardUnitName],
  );

  /** Payment info type that determines what components to render */
  const paymentInfoType: PaymentInfoType = useMemo(
    () => getPaymentInfoType({
      isPayWithRewardsOnly,
      rewardUnitsTotal,
      rewardPaymentOption,
      cashValueOfAppliedRewardUnits,
      totalPriceInCash,
    }),
    [isPayWithRewardsOnly, rewardUnitsTotal, rewardPaymentOption, cashValueOfAppliedRewardUnits, totalPriceInCash],
  );

  /** Credit card data after Braintree validation */
  const [creditCardData, setCreditCardData] = useState<CardData | null>(null);

  /** Indicates whether user has entered valid credit card information (matching card number, expiry, CVV) */
  const isCreditCardInfoValidRef = useRef<boolean | undefined>(undefined);

  const validateCreditCardInfo = useCallback(async (options: { shouldCheckBraintree: boolean; }): Promise<boolean> => {
    if (!options.shouldCheckBraintree) {
      return !!creditCardData;
    }

    const creditCardInfoForm = document.getElementById(CHECKOUT_BRAINTREE_FORM);

    if (!creditCardInfoForm) {
      return false;
    }

    // Reset isCreditCardInfoValidRef flag before submitting credit card information form to Braintree
    isCreditCardInfoValidRef.current = undefined;

    // Submit credit card information form to Braintree
    (creditCardInfoForm as HTMLFormElement).requestSubmit();

    // Wait for Braintree to complete validation of the credit card information form
    for (let i = 0; i < 10; i += 1) {
      await delay(250);

      if (isCreditCardInfoValidRef.current === undefined) {
        continue;
      }

      return isCreditCardInfoValidRef.current;
    }

    return false;
  }, [creditCardData]);

  /** Function to check if the payment information is valid */
  const checkIsPaymentInfoValid = useCallback(async (options: { shouldCheckBraintree: boolean; }) => {
    switch (paymentInfoType) {
      case 'payWithRewardsOnly': return true;
      case 'creditCardOnly': {
        const isCreditCardInfoValid: boolean = await validateCreditCardInfo({ shouldCheckBraintree: options.shouldCheckBraintree });
        return isCreditCardInfoValid;
      }
      case 'rewardSelectionOnly': {
        if (!rewardPaymentOption) {
          setRewardPaymentOptionErrorKey('paymentInfo.useYourRewards.error.requiredField');
          setRewardPaymentOptionErrorScope('all');
          return false;
        }

        if (isEditingRewardUnits) {
          setRewardPaymentOptionErrorKey('paymentInfo.useYourRewards.error.saveRewardsSelection');
          setRewardPaymentOptionErrorScope('applyRewards');
          return false;
        }

        return true;
      }
      case 'rewardSelectionAndCreditCard': {
        if (isEditingRewardUnits) {
          setRewardPaymentOptionErrorKey('paymentInfo.useYourRewards.error.saveRewardsSelection');
          setRewardPaymentOptionErrorScope('applyRewards');
          return false;
        }

        const isCreditCardInfoValid: boolean = await validateCreditCardInfo({ shouldCheckBraintree: options.shouldCheckBraintree });
        return isCreditCardInfoValid;
      }
      default: {
        // If the code does not build here then it means that there are missing cases in the switch statement
        throw getNonExhaustiveCasesInSwitchStatementError(paymentInfoType);
      }
    }
  }, [paymentInfoType, rewardPaymentOption, isEditingRewardUnits, validateCreditCardInfo]);

  /** 
   * Determines if the card type filter should be displayed.
   * The filter is shown only if there is more than one card available.
  */
  const shouldShowCardTypeFilter = useMemo(() => accountCardDetails.length > 1, [accountCardDetails.length]);

  /** Generates filter options for the card type filter. */
  const cardTypeFilterOptions: PresetFilterOption[] = useMemo(
    () => shouldShowCardTypeFilter ? getPaymentFilterOptions({ accountCardDetails, listingTags, eventTags, totalPricesInRewardsUnit, isPayWithRewardsOnly }) : [],
    [accountCardDetails, eventTags, listingTags, shouldShowCardTypeFilter, totalPricesInRewardsUnit, isPayWithRewardsOnly],
  );

  /** Converts the card type filter options into a map.*/
  const cardTypeFilterOptionsMap: Record<string, PresetFilterOption> | undefined = useMemo(
    () => cardTypeFilterOptions.length
      ? convertArrayToMap(cardTypeFilterOptions)
      : undefined,
    [cardTypeFilterOptions],
  );

  /** Retrieves the currently selected card type filter state from the URL. */
  const cardTypeFilterState: PresetFilterOption | undefined = usePresetFilterStateFromUrl({
    queryParamName: SELECTED_CARD_INDEX_PARAM,
    presetFilterOptionsMap: cardTypeFilterOptionsMap,
  });

  /** This useEffect triggers whenever 'selectedAccountCardDetail' is changed.
   *  It resets all the payment option that is selected and error states on the page.
   */
  useEffect(() => {
    if (selectedAccountCardDetail) {
      /** Reset the selected reward payment radio button option */
      setRewardPaymentOption(undefined);
      /** Reset the Reward Units to apply */
      setRewardUnitsToApply(maxRewardUnitsToBeUsed);
      /** Reset the number of reward units that are already applied */
      if (isPayWithRewardsOnly) {
        setAppliedRewardUnits(maxRewardUnitsToBeUsed);
      } else {
        setAppliedRewardUnits(0);
      }
      /** Reset any error states and editing states related to reward units */
      resetRewardUnitsErrorAndEditingStates();
    }
  }, [maxRewardUnitsToBeUsed, resetRewardUnitsErrorAndEditingStates, selectedAccountCardDetail, isPayWithRewardsOnly]);

  return {
    listingTags,
    eventTags,
    isExclusiveEvent,
    isPayWithRewardsOnly,
    totalPricesInRewardsUnit,
    paymentInfoType,
    formattedRewardUnitsTotal,
    rewardUnitName,
    rewardPaymentOption,
    selectRewardPaymentOption,
    rewardPaymentOptionErrorKey,
    rewardPaymentOptionErrorScope,
    formattedRewardUnitsToApply,
    formattedCashValueOfRewardUnitsToApply,
    appliedRewardUnits,
    isEditingRewardUnits,
    editedRewardUnitsToApplyStr,
    editedRewardUnitsToApplyInvalidCharsRegex,
    startEditingRewardUnits,
    onEditedRewardUnitsToApplyChanged,
    onEditedRewardUnitsToApplyBlur,
    saveEditedRewardUnits,
    cancelEditingRewardUnits,
    formattedTotalPriceInCash,
    formattedTotalPriceInRewardUnits,
    formattedTotalPriceInRewardUnitsWithUnitName,
    remainingCashPrice,
    formattedRemainingCashPrice,
    formattedRemainingCashPriceWithCents,
    currency,
    formattedAppliedRewardUnitsWithUnitName,
    formattedAppliedRewardUnitsWithoutUnitName,
    accountCardImageUrl,
    accountCardLastFourDigits,
    accountProgramType,
    creditCardData,
    setCreditCardData,
    isCreditCardInfoValidRef,
    checkIsPaymentInfoValid,
    shouldShowCardTypeFilter,
    cardTypeFilterOptions,
    cardTypeFilterState,
  };
};
