import type { AccountCardDetail } from '../../../modules/auth';
import Step1Image from '../../../resources/images/Step1Image.png';
import Step2Image from '../../../resources/images/Step2Image.png';
import Step3Image from '../../../resources/images/Step3Image.png';
import type { AccountCardProps } from '../AccountCard';
import { ONBOARDING_STEPS } from './OnBoardingModalContent.constants';
import styles from './OnBoardingModalContent.module.scss';
import type { BackgroundImageStyle } from './OnBoardingModalContent.types';

/** Returns the URL of the image corresponding to the current step and device type (mobile or desktop) */
export const getImageUrl = (params: {
  /** The current step in the onboarding process */
  currentStep: number;
  /** A flag indicating whether the device is mobile or not */
  isMobile: boolean;
}): string => {
  const { currentStep } = params;

  // A record mapping each step to its corresponding image based on whether it's mobile or not.
  const stepImages: Record<number, string> = {
    [ONBOARDING_STEPS.StepOne]: Step1Image,
    [ONBOARDING_STEPS.StepTwo]: Step2Image,
    [ONBOARDING_STEPS.StepThree]: Step3Image,
  } as const;

  return stepImages[currentStep] || '';
};

/**
 * Returns the background image style object.
 * This includes a gradient overlay and a background image URL.
 */
export const getBackgroundImageStyle = (params: {
  /** The direction for the linear gradient background */
  direction: string;
  /** The URL of the background image to apply */
  imageUrl: string | null;
  /** True for mobile breakpoints */
  isMobile: boolean;
}): BackgroundImageStyle => {
  const { direction, imageUrl, isMobile } = params;

  if (isMobile) {
    return ({
      backgroundImage: `linear-gradient(${direction}, #10253F00 0%, #00132B 80%, #00132B 100%), url(${imageUrl})`,
    });
  }

  return ({
    backgroundImage: `linear-gradient(${direction}, #10253F00 0%, #00132B 100%), url(${imageUrl})`,
  });
};

/**
 * Builds an array of account card props.
 * @returns {AccountCardProps[]} Array of transformed account card props.
 */
export const getAccountCardProps = (params: {
  /** Account card details */
  accountCardDetails: AccountCardDetail[];
}): AccountCardProps[] => {
  const { accountCardDetails } = params;

  return accountCardDetails.map((accountCardDetail: AccountCardDetail, key: number) => ({
    ...accountCardDetail,
    key,
    theme: 'light',
    areRewardUnitsBold: true,
    className: styles.accountCard,
  } as AccountCardProps));
};
