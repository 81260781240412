import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LogoIconsByAsset } from './Logo.constants';
import styles from './Logo.module.scss';
import { usePresenter } from './Logo.presenter';
import type { LogoProps } from './Logo.types';

export const Logo: React.FC<LogoProps> = (props) => {
  const {
    asset,
    theme,
    href,
    onClick,
    ariaLabel,
    className,
  } = usePresenter(props);

  const LogoIcon = LogoIconsByAsset[asset];

  const logoImage: ReactNode = (
    <div
      role='img'
      aria-label={ariaLabel}
      className={cx(styles.logo, styles[`${asset}Logo`], styles[`${theme}Logo`], className)}
    >
      <LogoIcon style={{ width: '100%', height: '100%' }} />
    </div>
  );

  return href
    ? (
      <Link
        to={href}
        role='link'
        aria-label={ariaLabel}
        onClick={onClick}
        tabIndex={0} // Accessible through keyboard navigation for safari
      >
        {logoImage}
      </Link>
    )
    : logoImage;
};
