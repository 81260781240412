import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { EXCLUSIVE_LISTINGS_PARAM, LISTING_ID_PARAM, MAX_PRICE_FILTER_PARAM, MIN_PRICE_FILTER_PARAM, QUANTITY_FILTER_PARAM, QUANTITY_PARAM, UnableToAccessEventErrorProps, URLs } from '../../../lib/constants';
import { checkIsEventAccessible, checkIsEventExclusive, getEventMetadata } from '../../../lib/eventUtils';
import { getListingsMetadata } from '../../../lib/listingUtils';
import type { EventMetadata, ListingsMetadata } from '../../../lib/types';
import { isNumber, parseNumber, useNumericQueryParamFromUrl, useQueryParamFromUrl } from '../../../lib/util';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { useAppendQueryParamsToUrl, usePreserveHistoryState } from '../../../modules/navigation/Navigation.hooks';
import { redirectToSignInURL } from '../../../modules/navigation/Navigation.utils';
import type { ListingDetailsQueryParams, ListingsQueryParams } from '../../../modules/partnership';
import { getEvent, getListingDetails, getListings } from '../../../modules/partnership/api';
import { TopNavContext } from '../../../modules/topNav';
import { SELECTED_CARD_INDEX_PARAM } from '../ExclusiveEventsPage';
import { GET_LISTINGS_CACHE_KEY } from './EventPage.constants';
import type { EventPagePresenterProps, EventPageProps } from './EventPage.types';
import { getListingDetailQueryParams, getListingsQueryParams } from './EventPage.utils';

export const usePresenter = (props: EventPageProps): EventPagePresenterProps => {
  const { isAccountLoading, accountTags, isSignedOut, accountCardDetails, isSignedIn, selectAccountCardByIndex } = useContext(AuthContext);

  const { setShouldShowFooter } = useContext(TopNavContext);
  const { hasBusinessRestrictions } = useContext(AuthContext);

  const navigate = useNavigate();

  usePreserveHistoryState();

  const { trackEvent, onError } = useAnalyticsManager();

  const { t } = useTranslation();

  const { eventId: eventIdStr } = useParams<{ eventId: string; }>();

  /** Validated event Id. Undefined if event Id is invalid, e.g. when it is non-numeric or it is less than 1. */
  const eventId: number | undefined = useMemo(() => parseNumber(eventIdStr, { minValue: 1 }), [eventIdStr]);

  const prevEventIdStrRef = useRef<string | undefined>(eventId?.toString());

  // Always hide footer initially, i.e. during loading state of the event page.
  // After event gets fetched from APIs:
  // - If event turns out to be a guest list event then GuestListEventPage.presenter.ts will show the footer again.
  // - If event turns out to be a non-guest list event then the footer will remain hidden.
  useLayoutEffect(() => {
    setShouldShowFooter(false);
    return () => setShouldShowFooter(true);
  }, [eventId, setShouldShowFooter]); // Make sure that this logic re-runs whenever event Id changes

  // API call to fetch event
  // Enabled only if event Id is a valid positive number
  const { data: event, isLoading: isEventLoading, isError: isEventError } = useQuery(
    ['getEvent', eventId],
    () => getEvent(`${eventId}`),
    { enabled: !!eventId, onError },
  );

  /** Indicates whether the required data is loading, e.g. account and event data */
  const isRequiredDataLoading: boolean = useMemo(
    () => isAccountLoading || isEventLoading,
    [isAccountLoading, isEventLoading],
  );

  /** Detailed information about event */
  const eventMetadata: EventMetadata | undefined = useMemo(
    () => event ? getEventMetadata({ event }) : undefined,
    [event],
  );

  /** Indicates whether the event is valid */
  const isEventValid: boolean = useMemo(
    () => checkIsEventAccessible({ eventId, isEventError, eventMetadata, accountCardDetails }),
    [eventId, isEventError, eventMetadata, accountCardDetails],
  );

  /** Redirect to sign-in page if user is not signed in and event contains C1_UNAUTH tag */
  useEffect(() => {
    if (isSignedOut && eventMetadata?.isUnAuthEvent) {
      redirectToSignInURL();
    }
  }, [isSignedOut, eventMetadata?.isUnAuthEvent]);

  /** Validated quantity filter parameter. Undefined if quantity filter parameter is invalid, e.g. when it is non-numeric or it is less than 1. */
  const quantityFilterParam: number | undefined = useNumericQueryParamFromUrl(QUANTITY_FILTER_PARAM, { minValue: 1 });

  /** Validated min price filter parameter. Undefined if min price filter parameter is invalid, e.g. when it is non-numeric or it is less than 0. */
  const minPriceFilterParam: number | undefined = useNumericQueryParamFromUrl(MIN_PRICE_FILTER_PARAM, { minValue: 0 });

  /** Validated max price filter parameter. Undefined if max price filter parameter is invalid, e.g. when it is non-numeric or it is less than min price parameter. */
  const maxPriceFilterParam: number | undefined = useNumericQueryParamFromUrl(MAX_PRICE_FILTER_PARAM, { minValue: minPriceFilterParam ?? 0 });

  /**
   * Query parameters to fetch listings with event Id and optional filter parameters for quantity and min/max prices.
   * Undefined if:
   * - Either account or event has not been fetched yet.
   * - Or quantity filter parameter is provided but it is not a valid positive number.
   * - Or min price parameter is provided but it is not a valid number.
   * - Or max price parameter is provided but it is not a valid number.
   * - Or min price parameter is provided without max price parameter or vice versa.
   * - Or min price parameter is greater than max price parameter.
   */
  const listingsQueryParams: ListingsQueryParams | undefined = useMemo(
    () => getListingsQueryParams({ accountTags, eventMetadata, quantityFilterParam, minPriceFilterParam, maxPriceFilterParam }),
    [accountTags, eventMetadata, quantityFilterParam, minPriceFilterParam, maxPriceFilterParam],
  );

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  /** Function to reset all filters to default values */
  const resetFilters = useCallback(() => {
    appendQueryParamsToUrl({
      [QUANTITY_FILTER_PARAM]: '',
      [MIN_PRICE_FILTER_PARAM]: '',
      [MAX_PRICE_FILTER_PARAM]: '',
    });
  }, [appendQueryParamsToUrl]);

  // Reset filters if filter query parameters are invalid.
  // Note: listingsQueryParams is undefined if filter query parameters are invalid.
  useEffect(() => {
    if (eventMetadata
      && (isNumber(quantityFilterParam) || isNumber(minPriceFilterParam) || isNumber(maxPriceFilterParam))
      && !listingsQueryParams
    ) {
      resetFilters();
    }
  }, [eventMetadata, quantityFilterParam, minPriceFilterParam, maxPriceFilterParam, listingsQueryParams, resetFilters]);

  // API call to fetch event listings with filters applied (if any), e.g. by quantity, min/max price
  // Enabled only if account and event have been fetched
  const { data: listingsResponse, isFetching: areListingsLoading } = useQuery(
    [GET_LISTINGS_CACHE_KEY, ...Object.values(listingsQueryParams ?? {})],
    () => getListings(listingsQueryParams!),
    {
      enabled: !!listingsQueryParams,
      // Keep previous data if eventId is the same
      keepPreviousData: listingsQueryParams?.event_id === prevEventIdStrRef.current,
      // Update the ref to the new eventId after the query settles
      onSettled: () => prevEventIdStrRef.current = listingsQueryParams?.event_id,
      onError,
    },
  );

  /** Detailed information about event listings */
  const listingsMetadata: ListingsMetadata | undefined = useMemo(
    () => listingsResponse ? getListingsMetadata({ listingsResponse }) : undefined,
    [listingsResponse],
  );

  const unfilteredListingsQueryParams: ListingsQueryParams | undefined = useMemo(
    () => getListingsQueryParams({ accountTags, eventMetadata, quantityFilterParam: undefined, minPriceFilterParam: undefined, maxPriceFilterParam: undefined }),
    [accountTags, eventMetadata],
  );

  // API call to fetch all event listings without any filters applied
  // Enabled only if account and event have been fetched
  // All unfiltered event listings are required to compute min prices per section for SVG map regardless of filters
  const { data: { listings: unfilteredListings } = {} } = useQuery(
    // Use the same key GET_LISTINGS_CACHE_KEY as for fetching filtered event listings (see code above)
    // So that in case other parameters are the same we could avoid duplicate API calls
    [GET_LISTINGS_CACHE_KEY, ...Object.values(unfilteredListingsQueryParams ?? {})],
    () => getListings(unfilteredListingsQueryParams!),
    {
      enabled: !!unfilteredListingsQueryParams,
      // Keep previous data if eventId is the same
      keepPreviousData: unfilteredListingsQueryParams?.event_id === prevEventIdStrRef.current,
      // Update the ref to the new eventId after the query settles
      onSettled: () => prevEventIdStrRef.current = unfilteredListingsQueryParams?.event_id,
      onError,
    },
  );

  const listingId: string | undefined = useQueryParamFromUrl(LISTING_ID_PARAM);

  /** Validated quantity. Undefined if quantity is invalid, e.g. when it is non-numeric or it is less than 1. */
  const quantity: number | undefined = useNumericQueryParamFromUrl(QUANTITY_PARAM, { minValue: 1 });

  /** Validated exclusive_listings parameter. Undefined if exclusive_listings parameter is invalid, e.g. when it is neither 'true' nor 'false'. */
  const exclusiveListings: 'true' | 'false' | undefined = useQueryParamFromUrl<'true' | 'false'>(EXCLUSIVE_LISTINGS_PARAM, { allowedValues: ['true', 'false'] });

  /**
   * Query parameters to fetch listing detail with quantity and exclusive_listings properties.
   * Undefined if:
   * - Either account or event has not been fetched yet.
   * - Or listing Id parameter is missing.
   * - Or quantity parameter is not a valid positive number.
   * - Or exclusive_listings is neither 'true' nor 'false'.
   */
  const listingDetailQueryParams: ListingDetailsQueryParams | undefined = useMemo(
    () => getListingDetailQueryParams({ eventMetadata, listingId, quantity, exclusiveListings }),
    [eventMetadata, listingId, quantity, exclusiveListings],
  );

  /**
   * Indicates whether pre-checkout should be opened.
   * True only if:
   * - Account and event have been fetched.
   * - And listing Id parameter is not empty.
   * - And query parameters to fetch listing detail are valid.
   */
  const shouldOpenPreCheckout: boolean = useMemo(
    () => !!eventMetadata?.id && !!listingId && !!listingDetailQueryParams,
    [eventMetadata?.id, listingId, listingDetailQueryParams],
  );

  // Unselect selected account card when pre-checkout opens
  useEffect(() => {
    if (shouldOpenPreCheckout) {
      selectAccountCardByIndex(undefined);
      appendQueryParamsToUrl({ [SELECTED_CARD_INDEX_PARAM]: '' });
    }
  }, [shouldOpenPreCheckout, selectAccountCardByIndex, appendQueryParamsToUrl]);

  /** Function to clear query parameters and close pre-checkout */
  const closePreCheckout = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Precheckout',
      t('common.goBack'),
    );

    navigate(-1);
  }, [t, trackEvent, navigate]);

  // Close pre-checkout if query parameters to fetch listing detail are invalid.
  // Note: listingDetailQueryParams is undefined if query parameters to fetch listing detail are invalid.
  useEffect(() => {
    if (eventMetadata
      && (listingId || quantity || exclusiveListings)
      && !listingDetailQueryParams) {
      closePreCheckout();
    }
  }, [eventMetadata, listingId, quantity, exclusiveListings, listingDetailQueryParams, closePreCheckout]);

  // API call to fetch event listing detail
  // Enabled only if all preconditions for pre-checkout are met
  const { data: listingDetail, isLoading: isListingDetailLoading, isError: isListingDetailError } = useQuery(
    // Do not include listingDetailQueryParams into cache array so that listing detail is not re-fetched on quantity change in Quantity dropdown
    ['getListingDetails', eventMetadata?.id, listingId],
    () => getListingDetails(`${eventMetadata?.id}`, listingId!, listingDetailQueryParams!),
    { enabled: shouldOpenPreCheckout, onError },
  );

  // Close pre-checkout in case of API error
  useEffect(() => {
    if (isListingDetailError) {
      closePreCheckout();
    }
  }, [isListingDetailError, closePreCheckout]);

  const shouldOpenLimitedAccessBlock: boolean = useMemo(
    () => isSignedIn && !isEventValid,
    [isEventValid, isSignedIn]);

  /** Indicates if the event page is restricted */
  const restrictEventPage: boolean = useMemo(() => {
    return hasBusinessRestrictions && checkIsEventExclusive(event);
  }, [hasBusinessRestrictions, event]);

  useEffect(() => {
    if (isRequiredDataLoading) {
      return;
    }

    if (!eventMetadata || isEventError || restrictEventPage) {
      navigate(URLs.ErrorPage, { state: UnableToAccessEventErrorProps });
    }
  }, [isRequiredDataLoading, eventMetadata, isEventError, restrictEventPage]);

  return {
    ...props,
    isRequiredDataLoading,
    eventMetadata,
    areListingsLoading,
    listingsMetadata,
    resetFilters,
    unfilteredListings,
    shouldOpenPreCheckout,
    closePreCheckout,
    isListingDetailLoading,
    listingDetailQueryParams,
    listingDetail,
    isEventError,
    shouldOpenLimitedAccessBlock,
    restrictEventPage,
  };
};
