import React from 'react';
import { usePresenter } from './BlackBoxInput.presenter';
import type { BlackBoxInputProps } from './BlackBoxInput.types';

export const BlackBoxInput: React.FC<BlackBoxInputProps> = (props) => {
  const { ioBlackBoxRef } = usePresenter(props);

  return <input
    ref={ioBlackBoxRef}
    id='ioBlackBox'
    name='ioBlackBox'
    type='hidden'
  />;
};
