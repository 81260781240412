import { useNavigate } from 'react-router-dom';
import type { AccountCardDetail } from '../modules/auth/types';
import type { AllViewportImageUrls, Event, ImageType, PerformerImages, UserOrder, Viewport, ViewportImageUrls } from '../modules/partnership';
import { EVENT_TAGS, LOW_TICKETS_THRESHOLD, SUPPORTED_NETWORK_TYPE_TAGS, SUPPORTED_PROGRAM_TYPE_TAGS, SUPPORTED_REWARD_TYPE_TAGS } from './constants';
import * as eventUtils from './eventUtils';
import type { EventAccessValidationProps, EventDateTimeVenueDetails, EventMetadata, EventStatus } from './types';
import { isNumber, parseNumber } from './util';
import { useEffect } from 'react';

/**
 * Constructs a event URL for a given event Id.
 * @param {number} eventId Id of the event.
 * @returns {string} Event URL for a given event Id.
 */
export const getEventUrl = (eventId: number): string => `/events/${eventId}`;

/**
 * Retrieves image URL for an event based on the specified image type and viewport.
 * @returns {string} Image URL for the event.
 */
export const getEventImageUrl = (params: {
  /** Event object */
  event: Event;
  /** Type of image to retrieve, e.g. 'hero', 'modal', 'widget', 'carousel' */
  imageType: ImageType;
  /** Viewport type, e.g. 'desktop' or 'mobile' */
  viewport: Viewport;
}): string => {
  const { event, imageType, viewport } = params;

  const performerImagesJson: string | undefined = event.supplemental_data?.performer_images?.[0]?.url;
  if (performerImagesJson) {
    try {
      const allImageUrls: AllViewportImageUrls = JSON.parse(performerImagesJson);

      if (imageType !== 'carousel') {
        const imageUrls: ViewportImageUrls | string[] = allImageUrls[imageType];

        return Array.isArray(imageUrls)
          ? imageUrls[0]
          : imageUrls[viewport];
      }
    } catch {
      return performerImagesJson;
    }

    return '';
  }

  return event.image;
};

/**
 * Retrieves an array of carousel image URLs for an event.
 * @returns {string[]} Array of carousel image URLs for the event.
 */
export const getEventCarouselImageUrls = (params: {
  /** Event object */
  event: Event | undefined;
}): string[] => {
  const { event } = params;

  if (!event) {
    return [];
  }

  const performerImages: PerformerImages | undefined = event.supplemental_data?.performer_images?.[0];

  const performerImagesJson: string | undefined = performerImages?.url;
  if (performerImagesJson) {
    try {
      const allImageUrls: AllViewportImageUrls = JSON.parse(performerImagesJson);

      const imageUrls = (allImageUrls[event.id] || allImageUrls[performerImages?.performer_id as number]) as string[];
      if (Array.isArray(imageUrls) && imageUrls.every(imageUrl => typeof imageUrl === 'string')) {
        return imageUrls;
      }
    } catch {
      // Ignore exception
    }
  }

  return event.image ? [event.image] : [];
};

/**
 * Returns the formatted date, time and venue details for an event.
 * @param {Event} event Event object.
 * @returns {EventDateTimeVenueDetails} Formatted date, time and venue details for the event.
 */
export const getEventDateTimeAndVenueDetails = (event: Event): EventDateTimeVenueDetails => {
  const { local_date: localDate, venue } = event;
  const { name: venueName, city: venueCity, state_code: venueStateCode, country, country_code: countryCode } = venue;

  const shortWeekDay: string = new Date(localDate).toLocaleDateString('en-US', { weekday: 'short' });

  const shortDate: string = new Date(localDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

  const longDate: string = new Date(localDate).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const time: string = new Date(localDate).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  }).replace(' AM', 'am').replace(' PM', 'pm');

  return {
    shortWeekDay,
    shortDate,
    longDate,
    time,
    venueName,
    venueCity,
    venueStateCode: venueStateCode || country || countryCode,
  };
};

/**
 * Checks if an event is exclusive based on its tags.
 * @param {Event | undefined} event Event object.
 * @returns {boolean} True if the event is exclusive, otherwise false.
 */
export const checkIsEventExclusive = (event: Event | undefined): boolean => {
  return !!event?.tags?.includes(EVENT_TAGS.Exclusive);
};

/**
 * Checks if an event is sold out based on its tags and listing counts.
 * @returns {boolean} True if the event is sold out, otherwise false.
 */
export const checkIsEventSoldOut = (params: {
  /** Event object */
  event: Event;
  /**
   * Whether to check for exclusive events
   * @default false
   */
  shouldCheckForExclusiveEvents?: boolean;
}): boolean => {
  const { event, shouldCheckForExclusiveEvents = false } = params;

  let isEventSoldOut: boolean = false;

  if (event.tags) {
    const hasSoldOutTag: boolean = event.tags.includes(EVENT_TAGS.SoldOut);

    const isSuppressInventorySoldOut: boolean = hasSoldOutTag
      && event.exclusive_listing_count === 0
      && event.tags.includes(EVENT_TAGS.AlwaysSuppressVsInventory);

    if (isSuppressInventorySoldOut) {
      return true;
    }

    isEventSoldOut = hasSoldOutTag && event.listing_count === 0;

    // In some places we need to check for exclusive events
    if (shouldCheckForExclusiveEvents) {
      isEventSoldOut &&= eventUtils.checkIsEventExclusive(event);
    }
  }

  return isEventSoldOut;
};

/**
 * Checks if tickets for an event are running low based on listing counts and exclusivity.
 * @param {Event} event Event object.
 * @returns {boolean} True if tickets are low, otherwise false.
 */
export const checkAreTicketsLow = (event: Event): boolean => {
  return event.listing_count < LOW_TICKETS_THRESHOLD && eventUtils.checkIsEventExclusive(event);
};

/**
 * Determines the status of an event (sold out, low tickets, etc.).
 * @returns {EventStatus | undefined} Status of the event or undefined if there is no special status.
 */
export const getEventStatus = (params: {
  /** Event object */
  event: Event;
  /**
   * Whether to check for exclusive events
   * @default false
   */
  shouldCheckForExclusiveEvents?: boolean;
}): EventStatus | undefined => {
  const { event, shouldCheckForExclusiveEvents = false } = params;

  if (eventUtils.checkIsEventSoldOut({ event, shouldCheckForExclusiveEvents })) {
    return {
      status: 'soldOut',
      statusLabel: 'eventCard.soldOut',
      statusColor: 'InteractionRed60',
    };
  }

  if (eventUtils.checkAreTicketsLow(event)) {
    return {
      status: 'lowTickets',
      statusLabel: 'eventCard.lowTickets',
      statusColor: 'CustomYellow10',
    };
  }

  return undefined;
};

/**
 * Checks if an event only allows payment with rewards.
 * @param {Event | undefined} event Event object.
 * @returns {boolean} True if the event contains LOYALTY_REWARDS_PAYMENT tag.
 */
export const checkIsPayWithRewardsOnly = (event: Event | undefined): boolean => {
  return !!event?.tags?.includes(EVENT_TAGS.LoyaltyRewardsPayment);
};

/**
 * Checks if an event is guest list event.
 * @param {Event | undefined} event Event object.
 * @returns {boolean} True if the event contains GUEST_LIST tag.
 */
export const checkIsGuestListEvent = (event: Event | undefined): boolean => {
  return !!event?.tags?.includes(EVENT_TAGS.GuestList);
};

/**
 * Checks if an event contains C1_UNAUTH tag.
 * @param {Event | undefined} event Event object.
 * @returns {boolean} True if the event contains C1_UNAUTH tag.
 */
export const checkIsUnAuthEvent = (event: Event | undefined): boolean => {
  return !!event?.tags?.includes(EVENT_TAGS.UnAuth);
};

/**
 * Retrieves the appropriate label key for the 'Get Tickets' button based on whether the event is sold out.
 * @returns {string} Label key for the 'Get Tickets' button.
 */
export const getGetTicketsLabelKey = (params: {
  /** Whether the event is sold out */
  isEventSoldOut: boolean;
}): string => {
  return params.isEventSoldOut ? 'eventCard.learnMore' : 'eventCard.getTickets';
};

/**
 * Gets the maximum number of tickets that can be purchased for the event
 * @param {Event | undefined} event Event object
 * @returns {number | undefined} Maximum number of tickets that can be purchased for the event. Undefined if there is no limit.
 */
export const getEventMaxPurchaseLimit = (event: Event | undefined): number | undefined => {
  const purchaseLimitTag: string = event?.tags?.find((tag: string) => tag.startsWith(EVENT_TAGS.PurchaseLimit)) || '';

  if (purchaseLimitTag) {
    const purchaseLimitTagSplit: string[] = purchaseLimitTag.split(':');
    if (purchaseLimitTagSplit.length === 2) {
      const eventMaxPurchaseLimit: number | undefined = parseNumber(purchaseLimitTagSplit[1], { minValue: 0 });
      return isNumber(eventMaxPurchaseLimit) ? eventMaxPurchaseLimit : undefined;
    }
  }

  return undefined;
};

/**
 * Filters out MLB baseball events from an array of events.
 * @param {Event[]} events Array of events.
 * @returns {Event[]} Filtered array of events without MLB baseball events.
 */
export const filterOutMLBEvents = (events: Event[]) => {
  return events.filter((event: Event) => event.taxonomy.genre.toLowerCase() !== 'mlb baseball');
};

/**
 * Removes duplicate events from an array of events based on event name and date.
 * @param {Event[]} events Array of events.
 * @returns {Event[]} Array of events without duplicates.
 */
export const removeDuplicateEvents = (events: Event[]): Event[] => {
  const eventsMap: Record<string, Event> = {};

  events.forEach((event: Event) => {
    const trimmedEventName: string = event.name.replace(/ *\([^)]*\) */g, '');
    if (!eventsMap[trimmedEventName] || eventsMap[trimmedEventName].utc_date > event.utc_date) {
      eventsMap[trimmedEventName] = event;
    }
  });

  return Object.values(eventsMap);
};

/**
 * Removes past events from an array of events based on the current date.
 * @param {Event[]} events Array of events.
 * @returns {Event[]} Array of events without past events.
 */
export const removePastEvents = (events: Event[]): Event[] => {
  const currentDateMs: number = new Date().getTime();
  return events.filter((event: Event) => event.utc_date && new Date(event.utc_date).getTime() >= currentDateMs);
};

export const checkIsEventVenueInStates = (event: Event | undefined, stateCodes: string[]): boolean => {
  const eventStateCode: string | undefined = event?.venue.state_code;
  return !!eventStateCode && stateCodes.some((stateCode: string) => stateCode.toLowerCase() === eventStateCode.toLowerCase());
};

export const getEventMetadata = (params: {
  event: Event;
}): EventMetadata => {
  const { event } = params;

  const eventUrl: string = eventUtils.getEventUrl(event.id);
  const eventName: string = event.name;
  const eventDescription: string | undefined = event.supplemental_data?.event_description || event.description;
  const eventModalMobileImageUrl: string = eventUtils.getEventImageUrl({ event, imageType: 'modal', viewport: 'mobile' });
  const eventModalDesktopImageUrl: string = eventUtils.getEventImageUrl({ event, imageType: 'modal', viewport: 'desktop' });
  const eventHeroMobileImageUrl: string = eventUtils.getEventImageUrl({ event, imageType: 'hero', viewport: 'mobile' });
  const eventHeroDesktopImageUrl: string = eventUtils.getEventImageUrl({ event, imageType: 'hero', viewport: 'desktop' });
  const eventDateTimeAndVenueDetails: EventDateTimeVenueDetails = eventUtils.getEventDateTimeAndVenueDetails(event);
  const isExclusiveEvent: boolean = eventUtils.checkIsEventExclusive(event);
  const isGuestListEvent: boolean = eventUtils.checkIsGuestListEvent(event);
  const isSoldOut: boolean = eventUtils.checkIsEventSoldOut({ event });
  const isSoldOutWithExclusiveCheck: boolean = eventUtils.checkIsEventSoldOut({ event, shouldCheckForExclusiveEvents: isExclusiveEvent });
  const shouldShowGuestListEventPage: boolean = (isExclusiveEvent && isGuestListEvent) || isSoldOut;
  const isPayWithRewardsOnly: boolean = eventUtils.checkIsPayWithRewardsOnly(event);
  const isUnAuthEvent: boolean = eventUtils.checkIsUnAuthEvent(event);
  const carouselImageUrls: string[] = eventUtils.getEventCarouselImageUrls({ event });
  const eventMaxPurchaseLimit: number | undefined = eventUtils.getEventMaxPurchaseLimit(event);

  return {
    ...event,
    eventUrl,
    eventName,
    eventDescription,
    eventModalMobileImageUrl,
    eventModalDesktopImageUrl,
    eventHeroMobileImageUrl,
    eventHeroDesktopImageUrl,
    eventDateTimeAndVenueDetails,
    isExclusiveEvent,
    isGuestListEvent,
    isSoldOut,
    isSoldOutWithExclusiveCheck,
    shouldShowGuestListEventPage,
    isPayWithRewardsOnly,
    isUnAuthEvent,
    carouselImageUrls,
    eventMaxPurchaseLimit,
  };
};

/**
 * Filters tags based on their presence in a supportedTags record.
 * @example
 * const tags = ['tag1', 'tag2', 'tag3'];
 * const supportedTags = { tag1: true, tag3: true };
 * Output: ['tag1', 'tag3']
 */
export const filterTags = (params: {
  /** An array of tags to be filtered. If undefined, an empty array is returned */
  tags: string[] | undefined;
  /** A record where the keys are tag names and the values indicate if a tag is supported */
  supportedTags: Record<string, boolean>;
}): string[] => {
  const { tags, supportedTags } = params;

  // If 'tags' is undefined, return an empty array.
  // Otherwise, filter the tags array to include only those present in 'supportedTags' with a value of true.
  return tags?.filter((tag: string) => supportedTags[tag]) || [];
};

/**
 * Filters tags by category and applies fallback tags when necessary. 
 * @returns {Object} - An object containing filtered tags categorized by supported program, network, and reward types.
 * @property {string[]} supportedProgramTags - Tags filtered by supported program type tags.
 * @property {string[]} supportedNetworkTags - Tags filtered by supported network type tags.
 * @property {string[]} supportedRewardTags - Tags filtered by supported reward type tags.
 */
export const getFilteredTagsByCategoryWithFallbackTags = (params: {
  /** Primary tags to be filtered by supported categories */
  tagsToBeChecked: string[] | undefined;
  /** Fallback tags to use if no matching tags are found in the primary tags */
  fallbackTags: string[] | undefined;
}) => {
  const { tagsToBeChecked, fallbackTags } = params;
  // Filter tags based on specific categories
  const programTags = filterTags({ tags: tagsToBeChecked, supportedTags: SUPPORTED_PROGRAM_TYPE_TAGS });
  const networkTags = filterTags({ tags: tagsToBeChecked, supportedTags: SUPPORTED_NETWORK_TYPE_TAGS });
  const rewardTags = filterTags({ tags: tagsToBeChecked, supportedTags: SUPPORTED_REWARD_TYPE_TAGS });
  // Use fallbackTags if no tags are found in the respective category
  const supportedProgramTags = programTags.length ? programTags : filterTags({ tags: fallbackTags, supportedTags: SUPPORTED_PROGRAM_TYPE_TAGS });
  const supportedNetworkTags = networkTags.length ? networkTags : filterTags({ tags: fallbackTags, supportedTags: SUPPORTED_NETWORK_TYPE_TAGS });
  const supportedRewardTags = rewardTags.length ? rewardTags : filterTags({ tags: fallbackTags, supportedTags: SUPPORTED_REWARD_TYPE_TAGS });

  return { supportedProgramTags, supportedNetworkTags, supportedRewardTags };
};

/**
* Validates if an account card is valid based on provided tags and fallback tags.
*/
export const isAccountCardValidForTags = (params: {
  /** Details of the account card being validated */
  accountCardDetail: AccountCardDetail;
  /** Primary set of tags to validate against */
  tags: string[] | undefined;
  /** Secondary set of fallback tags to validate against if primary tags are not present */
  fallbackTags: string[] | undefined;
}): boolean => {
  const { accountCardDetail, tags, fallbackTags } = params;

  const {
    accountLoyaltyUnitTag,
    accountProgramTypeTag,
    accountProcessingNetworkTag,
  } = accountCardDetail;

  // If both tags and fallbackTags are empty or undefined, consider the card valid.
  if ((!tags || !tags.length) && (!fallbackTags || !fallbackTags?.length)) {
    return true;
  }

  // Get filtered tags by category using the provided tags and fallback tags.
  const { supportedProgramTags, supportedNetworkTags, supportedRewardTags } =
    getFilteredTagsByCategoryWithFallbackTags({
      tagsToBeChecked: tags,
      fallbackTags: fallbackTags,
    });

  // If no tags are filtered in any category, consider the card valid.
  if (!supportedProgramTags.length && !supportedNetworkTags.length && !supportedRewardTags.length) {
    return true;
  }

  // Validate only the categories that have tags
  const isProgramValid = !supportedProgramTags.length || (!!accountProgramTypeTag && supportedProgramTags.includes(accountProgramTypeTag));
  const isNetworkValid = !supportedNetworkTags.length || (!!accountProcessingNetworkTag && supportedNetworkTags.includes(accountProcessingNetworkTag));
  const isRewardValid = !supportedRewardTags.length || (!!accountLoyaltyUnitTag && supportedRewardTags.includes(accountLoyaltyUnitTag));

  return isProgramValid && isNetworkValid && isRewardValid;
};

/**
 * Returns true if all of the following is true:
 * - Event Id is a valid positive number.
 * - And API request to fetch event details succeeded.
 * - And event has expected tags.
 * @returns {boolean} True if event is valid.
 */
export const checkIsEventAccessible = (params: {
  /** Event Id */
  eventId: number | undefined;
  /** True if API request to fetch event failed */
  isEventError: boolean;
  /** Detailed information about event */
  eventMetadata: EventMetadata | undefined;
  /** Details of account card */
  accountCardDetails: AccountCardDetail[];
}): boolean => {
  const { eventId, isEventError, eventMetadata, accountCardDetails } = params;

  // Event is invalid if eventId is not a valid positive number or API request to fetch event details failed
  if (!eventId || eventId < 0 || isEventError) {
    return false;
  }

  if (!eventMetadata?.tags?.length || !eventMetadata.isExclusiveEvent || !accountCardDetails.length) {
    return true;
  }

  const isAnyCardValid = accountCardDetails.some((accountCardDetail) =>
    isAccountCardValidForTags({ accountCardDetail, tags: eventMetadata?.tags, fallbackTags: [] }),
  );

  return isAnyCardValid;
};

/**
 * Gets the total number of previously purchased tickets for the event
 * @returns {number} Total number of previously purchased tickets for the event
 */
export const getPreviouslyPurchasedTicketTotal = (params: {
  previousOrders: UserOrder[] | undefined;
  eventId: number | undefined;
}): number => {
  const { previousOrders, eventId } = params;

  if (!previousOrders?.length || !eventId) {
    return 0;
  }

  return previousOrders.reduce((previouslyPurchasedTicketTotal: number, previousOrder: UserOrder) => {
    if (previousOrder.event_id === eventId) {
      previouslyPurchasedTicketTotal += previousOrder.quantity;
    }
    return previouslyPurchasedTicketTotal;
  }, 0);
};

/**
 * Custom hook to validate and manage user access to an event.
 * Redirects the user if access is denied.
 * 
 * @param {EventAccessValidationProps} props - Object containing event and user account details.
 * 
 * This hook verifies if a user can access a particular event by evaluating
 * the provided event metadata and account information. If the user lacks access,
 * they are redirected to the event's page. 
 * 
 * @example
 * useEventAccessValidation({ accountCardDetails, eventMetadata, isSignedIn });
 */
export const useEventAccessValidation = (props: EventAccessValidationProps): void => {
  const { accountCardDetails, eventMetadata, isSignedIn } = props;
  const { id: eventId } = eventMetadata ?? {};

  const navigate = useNavigate();

  /**
   * Effect to monitor and handle redirection.
   * If the user is signed in but cannot access the event, redirect to the event's page.
   */
  useEffect(() => {
    if (
      isSignedIn &&
      eventId &&
      eventMetadata &&
      !checkIsEventAccessible({
        eventId,
        isEventError: false,
        eventMetadata,
        accountCardDetails,
      })
    ) {
      // Redirect the user to the event's URL if access is not granted
      navigate(getEventUrl(eventId));
    }
  }, [isSignedIn, eventId, eventMetadata, navigate, accountCardDetails]);
};