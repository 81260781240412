import type { MutableRefObject } from 'react';
import type { ArrowDirectionEnum } from '../../../lib/accessibility';
import type { SearchSuggestionsResponse } from '../../../modules/partnership';

export const handleSearchBarInputKeyDown = (params: {
  /** Keyboard event */
  event: React.KeyboardEvent;
  /** Search suggestions */
  searchSuggestions: SearchSuggestionsResponse | undefined;
  /** Ref to the search bar input */
  inputRef: MutableRefObject<HTMLInputElement | null>;
  /** Refs to the suggestion items */
  itemRefs: MutableRefObject<HTMLAnchorElement[]>;
  /** Ref to the current suggestion index */
  currentSuggestionIndexRef: MutableRefObject<number>;
}) => {
  const { event, searchSuggestions, inputRef, itemRefs, currentSuggestionIndexRef } = params;
  if (!searchSuggestions) return;

  const activeElement = document.activeElement;
  const isInputFocused = activeElement === inputRef.current;
  const items = itemRefs.current;

  // Handle arrow key navigation
  if (event.key === 'ArrowDown') {
    event.preventDefault();

    if (isInputFocused && items) {
      // Only allow moving to suggestions with down arrow
      if (items.length > 0) {
        currentSuggestionIndexRef.current = 0;
        items[0].focus();
        // Update aria-selected for VoiceOver
        items.forEach(s => s.setAttribute('aria-selected', 'false'));
        items[0].setAttribute('aria-selected', 'true');
      }
    }
  }

  // Handle tab navigation
  if (event.key === 'Tab') {
    const isSuggestionFocused = items?.includes(activeElement as HTMLAnchorElement);
    if (isSuggestionFocused && event.shiftKey) {
      // Shift+Tab backward from suggestion to input
      event.preventDefault();
      inputRef.current?.focus();
      // Clear aria-selected from all suggestions
      items?.forEach(s => s.setAttribute('aria-selected', 'false'));
      currentSuggestionIndexRef.current = -1;
    }
  }
};

export const onSearchSuggestionArrowNavigation = (params: {
  /** Direction of the arrow key */
  direction: ArrowDirectionEnum;
  /** Search suggestions */
  searchSuggestions: SearchSuggestionsResponse | undefined;
  /** Ref to the search bar input */
  inputRef: MutableRefObject<HTMLInputElement | null>;
  /** Refs to the suggestion items */
  itemRefs: MutableRefObject<HTMLAnchorElement[]>;
  /** Ref to the current suggestion index */
  currentSuggestionIndexRef: MutableRefObject<number>;
}) => {
  const { direction, searchSuggestions, inputRef, itemRefs, currentSuggestionIndexRef } = params;
  if (!searchSuggestions) return;

  const activeElement = document.activeElement;
  const items = itemRefs.current;
  const isSuggestionFocused = items?.includes(activeElement as HTMLAnchorElement);

  if (isSuggestionFocused && items) {
    // Update current index if not already set
    if (currentSuggestionIndexRef.current === -1) {
      currentSuggestionIndexRef.current = items.indexOf(activeElement as HTMLAnchorElement);
    }

    let nextIndex = currentSuggestionIndexRef.current;

    if (direction === 'ArrowDown') {
      // Move to next suggestion if not at the end
      if (currentSuggestionIndexRef.current < items.length - 1) {
        nextIndex = currentSuggestionIndexRef.current + 1;
      }
    } else {
      // Move to previous suggestion if not at the beginning
      if (currentSuggestionIndexRef.current > 0) {
        nextIndex = currentSuggestionIndexRef.current - 1;
      } else {
        // Move focus back to input when pressing up on first suggestion
        inputRef.current?.focus();
        // Clear aria-selected from all suggestions
        items.forEach(s => s.setAttribute('aria-selected', 'false'));
        currentSuggestionIndexRef.current = -1;
        return;
      }
    }

    // Update focus and aria-selected if we're moving to a new suggestion
    if (nextIndex !== currentSuggestionIndexRef.current) {
      currentSuggestionIndexRef.current = nextIndex;
      items[currentSuggestionIndexRef.current].focus();
      // Update aria-selected for VoiceOver
      items.forEach(s => s.setAttribute('aria-selected', 'false'));
      items[currentSuggestionIndexRef.current].setAttribute('aria-selected', 'true');
    }
  }
}; 