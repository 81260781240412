import { URLs } from '../../../lib/constants';
import { checkIfPathMatch } from '../../../modules/navigation/Navigation.utils';
import type { TopNavTypeEnum } from './TopNav.types';

/**
 * Determines the type of top navigation bar to display based on the provided parameters.
 * @returns {TopNavTypeEnum} The type of top navigation bar to display.
 */
export const getTopNavType = (params: {
  /**  The current path of the application. */
  pathname: string;
  /** True if page is scrolled vertically. */
  isScrolledVertically: boolean;
  /** Indicates if the device is a mobile device. */
  isMobile: boolean;
}): TopNavTypeEnum => {

  const { pathname, isScrolledVertically, isMobile } = params;

  // Checkout page should render a header with logo and close button
  const isCheckoutPage: boolean = checkIfPathMatch(pathname, URLs.CheckoutPage, true);
  if (isCheckoutPage) {
    return 'LogoWithClose';
  }

  // Order Confirmation page should render a header with logo only
  const isOrderConfirmationPage: boolean = checkIfPathMatch(pathname, URLs.OrderConfirmationPage, true);
  if (isOrderConfirmationPage) {
    return 'LogoOnly';
  }

  if (isMobile) {
    // Home page on mobiles should render a taller header with the search bar if page is not scrolled
    const isHomePage: boolean = checkIfPathMatch(pathname, URLs.HomePage, true);
    if (isHomePage) {
      // On mobile home page, render a taller header with search bar if the page is not scrolled
      return isScrolledVertically ? 'Mobile' : 'Mobile2Rows';
    }

    // Search Results page on mobiles should render the header with the search bar and the back button
    const isSearchResultsPage: boolean = checkIfPathMatch(pathname, URLs.SearchResultsPage, true);
    if (isSearchResultsPage) {
      // On mobile search results page, render the header with search bar and back button
      return 'MobileWithSearchAndBack';
    }

    // Default to a basic mobile header
    return 'Mobile';
  }

  // Default to desktop header for non-mobile devices
  return 'Desktop';
};
