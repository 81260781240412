import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { UnableToAccessEventErrorProps, URLs } from '../../../lib/constants';
import { getNonExhaustiveCasesInSwitchStatementError, useWindowSize } from '../../../lib/util';
import { Spinner } from '../../atoms/Spinner';
import { CheckoutStepper } from '../../molecules/CheckoutStepper';
import { CheckoutTotal } from '../../molecules/CheckoutTotal';
import { AddressInfo } from '../../organisms/AddressInfo';
import { ContactInfo } from '../../organisms/ContactInfo';
import { LimitedAccessModal } from '../../organisms/LimitedAccessModal';
import { ListingDetailInfo } from '../../organisms/ListingDetailInfo';
import { OrderInfo } from '../../organisms/OrderInfo';
import { PaymentInfo } from '../../organisms/PaymentInfo';
import { PriceBreakdownInfo } from '../../organisms/PriceBreakdownInfo';
import { TicketAlertModal } from '../../organisms/TicketAlertModal';
import { CheckoutStepperLabelKeys, StepConfigurations, Steps } from './CheckoutPage.constants';
import styles from './CheckoutPage.module.scss';
import { usePresenter } from './CheckoutPage.presenter';
import type { CheckoutPageProps } from './CheckoutPage.types';

export const CheckoutPage: React.FC<CheckoutPageProps> = (props) => {
  const {
    step,
    checkoutValidityState,
    listingDetailMetadata,
    contactInfoProps,
    paymentInfoProps,
    addressInfoProps,
    orderInfoProps,
    onNextStepButtonClick,
    isValidatingAndPlacingOrder,
    ticketAlertModalProps,
    isRightContentShown,
    isScrolledVertically,
    restrictCheckoutPage,
    className,
  } = usePresenter(props);

  const { isMobile } = useWindowSize();

  // API requests are in flight (account and listing detail)
  if (checkoutValidityState === 'processing') {
    return <Spinner />;
  }

  // Checkout is invalid, e.g. required data is missing or there are API errors or has business restrictions for c1 exclusive events
  if (checkoutValidityState === 'failure' || !listingDetailMetadata || !step || restrictCheckoutPage) {
    return (
      <Navigate
        to={URLs.ErrorPage}
        state={UnableToAccessEventErrorProps}
        replace={true}
      />
    );
  }

  const checkoutStepper: ReactNode = (
    <CheckoutStepper
      step={step}
      stepItemLabelKeys={CheckoutStepperLabelKeys}
      className={cx(styles.checkoutStepper, { [styles.withBoxShadow]: isScrolledVertically })}
    />
  );

  // Use NonNullable<ReactNode> to ensure we return a value
  const getLeftContent = (): NonNullable<ReactNode> => {
    switch (step) {
      case Steps.ContactInfo: return <ContactInfo {...contactInfoProps} />;
      case Steps.PaymentInfo: return <PaymentInfo {...paymentInfoProps} />;
      case Steps.Address: return <AddressInfo  {...addressInfoProps} />;
      case Steps.ConfirmOrder: return <OrderInfo {...orderInfoProps} />;
      default: {
        // If the code does not build here then it means that there are missing cases in the switch statement
        throw getNonExhaustiveCasesInSwitchStatementError(step);
      }
    }
  };

  const rightContent: ReactNode = isRightContentShown && (<>
    <PriceBreakdownInfo
      listingDetailMetadata={listingDetailMetadata}
      formattedRemainingCashPrice={paymentInfoProps.formattedRemainingCashPrice}
      formattedAppliedRewardUnitsWithUnitName={paymentInfoProps.formattedAppliedRewardUnitsWithUnitName}
      formattedTotalPriceInRewardUnits={paymentInfoProps.formattedTotalPriceInRewardUnits}
    />
    <ListingDetailInfo
      listingDetailMetadata={listingDetailMetadata}
      className={styles.listingDetailInfo}
    />
  </>
  );

  const checkoutTotal: ReactNode = (
    <CheckoutTotal
      listingDetailMetadata={listingDetailMetadata}
      step={step}
      formattedTotalPriceInCash={paymentInfoProps.formattedTotalPriceInCash}
      formattedTotalPriceInRewardUnits={paymentInfoProps.formattedTotalPriceInRewardUnits}
      formattedRemainingCashPriceWithCents={paymentInfoProps.formattedRemainingCashPriceWithCents}
      formattedAppliedRewardUnits={paymentInfoProps.formattedAppliedRewardUnitsWithoutUnitName}
      nextStepButtonLabelKey={StepConfigurations[step].nextStepButtonLabelKey}
      nextStepButtonColour={StepConfigurations[step].nextStepButtonColour}
      onNextStepButtonClick={onNextStepButtonClick}
      className={styles.checkoutTotal}
    />
  );

  const checkoutContentView = (
    <div className={styles.content}>
      <div
        className={cx(
          styles.column,
          isMobile ? styles.leftContent : styles.rightContent,
        )}
      >
        {isMobile ? getLeftContent() : rightContent}
      </div>
      <div
        className={cx(
          styles.column,
          isMobile ? styles.rightContent : styles.leftContent,
        )}
      >
        {isMobile ? rightContent : getLeftContent()}
      </div>
    </div>
  );

  return (<>
    {isValidatingAndPlacingOrder && <Spinner isOverlay={true} />}
    <div className={cx(styles.checkoutPage, className)}>
      <div className={cx(styles.column, styles.contentContainer)}>
        {checkoutStepper}
        {checkoutContentView}
      </div>
      {checkoutTotal}
    </div>
    {!!ticketAlertModalProps && <TicketAlertModal {...ticketAlertModalProps} />}
    <LimitedAccessModal
      theme={'light'}
      listingDetailMetadata={listingDetailMetadata}
    />
  </>);
};
