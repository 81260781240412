import { useEffect, useState } from 'react';
import type { ArrowDirectionEnum } from '../../../lib/accessibility';
import type { SubmenuItemCombinedProps } from '../../molecules/SubmenuItem/types';
import type { SubmenuItemListCombinedProps } from '../../molecules/SubmenuItemList/types';
import { handleExploreArrowNavigation } from '../SubmenuWithDoubleTabs/SubmenuWithDoubleTabs.utils';
import styles from './SubmenuWithoutTabs.module.scss';
import type { SubmenuWithoutTabsCombinedProps } from './types';

const usePresenter = (props: SubmenuWithoutTabsCombinedProps): SubmenuWithoutTabsCombinedProps => {
  const { data: categoryMenuItemStates, id: openCategoryId, onItemClicked, shouldAutoFocus = false } = props;
  const categoryObject = categoryMenuItemStates?.find(category => category.id === openCategoryId);

  // State to store performer element references for keyboard navigation
  const [arrayOfPerformerRefs, setArrayOfPerformerRefs] = useState<HTMLAnchorElement[]>([]);
  /**
   * Adds a performer element reference to the refs array.
   * Avoids adding duplicate refs.
   *
   * @param {HTMLAnchorElement} element - The performer element to add.
   */
  const addPerformerRef = (element: HTMLAnchorElement) => {
    if (element) {
      setArrayOfPerformerRefs((prevArrayOfPerformersRefs) => {
        // Check for duplicates and skip if element is already present
        return prevArrayOfPerformersRefs.includes(element)
          ? prevArrayOfPerformersRefs
          : [...prevArrayOfPerformersRefs, element];
      });
    }
  };

  /**
   * Auto-focuses the first performer element when the submenu opens.
   */
  useEffect(() => {
    if (openCategoryId && shouldAutoFocus && arrayOfPerformerRefs.length) {
      arrayOfPerformerRefs[0]?.focus();
    }
  }, [arrayOfPerformerRefs, openCategoryId, shouldAutoFocus]);

  // Builds the submenu item list with necessary handlers
  const performersList: SubmenuItemListCombinedProps = {
    submenuItems: categoryObject?.performers?.map((performer, index): SubmenuItemCombinedProps => {
      return {
        text: {
          value: performer.name,
          colour: 'SubduedDark',
        },
        elementRef: addPerformerRef,
        focusable: true,
        linkPath: performer.slug,
        externalLink: performer.external_link,
        onItemClicked: () => {
          onItemClicked?.(performer);
        },
        classes: {
          text: performer.isBoldText ? styles.boldText : '',
        },
        // Handle keyboard arrow navigation between performers
        handleArrowNavigation: (direction: ArrowDirectionEnum) => handleExploreArrowNavigation(direction, index, arrayOfPerformerRefs),
      };
    }),
  };

  return {
    ...props,
    submenuItemList: performersList,
  };
};

export default usePresenter;
