import { getEventDateTimeAndVenueDetails } from '../../../lib/eventUtils';
import { trackSelectContentEvent, type EventKeys } from '../../../modules/analytics';
import i18n from '../../../modules/locale/i18n';
import type { Event, Performer, SearchSuggestionsResponse, Venue } from '../../../modules/partnership';
import type { SearchSuggestionSectionProps } from '../../organisms/SearchSuggestionSection';
import { AnalyticsSources, SearchSuggestionSectionMaxItems, UrlGenerators } from './SearchSuggestionBlock.constants';
import type { SearchSuggestionSectionKey } from './SearchSuggestionBlock.types';

/**
 * Builds the props for a search suggestion section based on the provided section key and items.
 * @returns {SearchSuggestionSectionProps | undefined} Props for the search suggestion section or undefined if no items are provided.
 */
export const buildSearchSuggestionSectionProps = <TItem extends (Event | Performer | Venue)>(params: {
  /** Key of the section for which props are being built */
  sectionKey: SearchSuggestionSectionKey;
  /** Items to be displayed in the section */
  items: TItem[];
  /** Function to track analytics events */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown> | undefined) => void;
}): SearchSuggestionSectionProps | undefined => {
  const { sectionKey, items, trackEvent } = params;

  if (!items.length) {
    return undefined;
  }

  // Limit the number of items to the max number per section type
  const maxItems: number = SearchSuggestionSectionMaxItems[sectionKey];
  const slicedItems = items.length > maxItems
    ? items.slice(0, maxItems)
    : items;

  return {
    sectionTitle: i18n.t(`search.${sectionKey}`),
    searchSuggestionItems: slicedItems.map(item => ({
      title: item.name,
      description: sectionKey === 'events'
        ? i18n.t('event.shortDateTimeVenueNameCityStateCode', getEventDateTimeAndVenueDetails(item as Event))
        : '',
      href: UrlGenerators[sectionKey](item.id),
      onClick: () => {
        trackSelectContentEvent(
          trackEvent,
          'Search Suggestions',
          AnalyticsSources[sectionKey],
          item.name,
        );
      },
    })),
  };
};

/**
 * Generates search suggestion results categorized by section keys.
 *
 * @param {SearchSuggestionsResponse | undefined} searchSuggestions - The response containing search suggestions for various categories.
 * @param {Function} trackEvent - Function to track events, takes an event key and optional extra data.
 * @returns {Record<SearchSuggestionSectionKey, SearchSuggestionSectionProps | undefined>} - A record mapping section keys to their respective section properties.
 */
export const getSearchSuggestionsResultsByCategories = (
  searchSuggestions: SearchSuggestionsResponse | undefined,
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
): Record<SearchSuggestionSectionKey, SearchSuggestionSectionProps | undefined> => {
  // Build the search suggestion section for 'events'
  const events: SearchSuggestionSectionProps | undefined = buildSearchSuggestionSectionProps({
    sectionKey: 'events', // Key for the 'events' category
    items: searchSuggestions ? searchSuggestions.events : [], // Get items or use an empty array if undefined
    trackEvent, // Event tracking function
  });

  // Build the search suggestion section for 'performers'
  const performers: SearchSuggestionSectionProps | undefined = buildSearchSuggestionSectionProps({
    sectionKey: 'performers', // Key for the 'performers' category
    items: searchSuggestions ? searchSuggestions.performers : [], // Get items or use an empty array if undefined
    trackEvent, // Event tracking function
  });

  // Build the search suggestion section for 'venues'
  const venues: SearchSuggestionSectionProps | undefined = buildSearchSuggestionSectionProps({
    sectionKey: 'venues', // Key for the 'venues' category
    items: searchSuggestions ? searchSuggestions.venues : [], // Get items or use an empty array if undefined
    trackEvent, // Event tracking function
  });

  // Return the categorized search suggestion sections
  return {
    events,
    performers,
    venues,
  };
};
