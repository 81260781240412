import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../lib/util';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import { changeConfirmationModalContentProps } from './ChangeConfirmationModal.constants';
import styles from './ChangeConfirmationModal.module.scss';
import { usePresenter } from './ChangeConfirmationModal.presenter';
import type { ChangeConfirmationModalProps } from './ChangeConfirmationModal.types';

export const ChangeConfirmationModal: React.FC<ChangeConfirmationModalProps> = (props) => {
  const {
    title,
    message,
    primaryButtonLabel,
    onPrimaryButtonClick,
    onCloseButtonClick,
    className,
  } = usePresenter(props);
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const breakpointKey: 'mobile' | 'desktop' = isMobile ? 'mobile' : 'desktop';


  const titleView: ReactNode = (
    <Text
      type={changeConfirmationModalContentProps.titleTextType[breakpointKey]}
      size={changeConfirmationModalContentProps.titleTextSize[breakpointKey]}
      style='SemiBold'
      colour={changeConfirmationModalContentProps.titleTextColor}
      value={title}
    />
  );

  const closeButton: ReactNode = !isMobile && (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      className={styles.closeButton}
      focusable={true}
      icon={{
        asset: 'Close',
        style: changeConfirmationModalContentProps.closeIconColor,
        className: styles.closeButtonIcon,
      }}
      onClick={onCloseButtonClick}
    />
  );

  const content: ReactNode = (
    <Text
      size={changeConfirmationModalContentProps.contentTextSize[breakpointKey]}
      style='Regular'
      colour={changeConfirmationModalContentProps.contentTextColor}
      align={changeConfirmationModalContentProps.contentTextAlign[breakpointKey]}
      value={message}
    />
  );

  const primaryButton: ReactNode = (
    <Button
      type='Text'
      style={changeConfirmationModalContentProps.primaryButtonStyle}
      size='Large'
      focusable={true}
      text={{
        type: 'Subheading',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Left',
        value: primaryButtonLabel,
        className: styles.primaryButtonLabel,
      }}
      onClick={onPrimaryButtonClick}
      className={styles.primaryButton}
    />
  );

  const secondaryButton: ReactNode = (
    <Button
      type='Text'
      style='Text'
      size='Small'
      text={{
        className: styles.secondaryButtonLabel,
        value: t('common.cancel'),
      }}
      onClick={onCloseButtonClick}
      className={styles.secondaryButton}
    />
  );

  return (
    <Modal
      show={true}
      className={cx(styles.modal, className)}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      onHide={onCloseButtonClick}
    >
      <div className={styles.changeConfirmationModalContent}>
        <div className={styles.changeConfirmationModalHeader}>
          {titleView}
          {closeButton}
        </div>
        {content}
        <div className={styles.footer}>
          {primaryButton}
          {secondaryButton}
        </div>
      </div>
    </Modal>
  );
};
