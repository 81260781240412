import { checkIfPathMatch } from '../../../modules/navigation/Navigation.utils';
import { excludedPathsForSignInDisclaimer } from './SignInDisclaimer.constants';

/**
 * Checks if the provided path is valid for showing the sign-in disclaimer.
 *
 * This function evaluates the `pathname` against a list of excluded paths and returns `true` if the
 * path is not excluded, meaning the sign-in disclaimer can be shown. If the path is excluded, it 
 * returns `false`, indicating the disclaimer should not be shown.
 *
 * @param {string} pathname - The path (URL or route) to check for the disclaimer validity.
 * @returns {boolean} - `true` if the path is valid for showing the sign-in disclaimer, `false` otherwise.
 */
export const checkIsValidPathForSignInDisclaimer = (pathname: string): boolean => {
  return !checkIfPathMatch(pathname, excludedPathsForSignInDisclaimer, true);
};
