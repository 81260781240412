import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { getClickAccessibilityProps } from '../../../lib/accessibility';
import { buildGoBackHistoryState } from '../../../modules/navigation/Navigation.utils';
import Text from '../../atoms/Text';
import styles from './SearchSuggestionItem.module.scss';
import { usePresenter } from './SearchSuggestionItem.presenter';
import type { SearchSuggestionItemProps } from './SearchSuggestionItem.types';

const SearchSuggestionItemComponent = (props: SearchSuggestionItemProps, ref: React.Ref<HTMLAnchorElement>) => {
  const {
    title,
    description,
    href,
    onClick,
    onEsc,
    onArrowNavigation,

  } = usePresenter(props);

  const accessibilityProps = getClickAccessibilityProps({ actions: { onClick, onEsc, onArrowNavigation }, addIndex: true });

  return (
    <Link
      ref={ref}
      to={href}
      state={buildGoBackHistoryState()}
      className={styles.searchSuggestionItem}
      onClick={onClick}
      {...accessibilityProps}
      role='option'
      aria-selected={false}
    >
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <Text
            size='Medium'
            style='SemiBold'
            colour='BaseDark'
            value={title}
            className={styles.truncatable}
          />
          {!!description && (
            <Text
              size='Small'
              style='Regular'
              colour='Charcoal30'
              value={description}
              className={styles.truncatable}
            />
          )}
        </div>
      </div>
    </Link>
  );
};

export const SearchSuggestionItem = forwardRef(SearchSuggestionItemComponent);
