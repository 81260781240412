import cx from 'classnames';
import type { BaseComponentProps } from '../../../lib/types';
import type { CarouselSectionProps } from '../../organisms/CarouselSection';
import styles from './HomePage.module.scss';

export const TRACKING_PAGE_NAME = {
  forPageVisits: 'HomePage',
  forCarouselLinkClicks: 'HomePage',
  forEventCardClicks: 'HomePage',
  forPerformerCardClicks: 'HomePage',
  /** Used for clicks on MLB and Cardholder Exclusives banners */
  forBannerClicks: 'Homepage',
} as const;

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 8;

export const CARDHOLDER_EXCLUSIVE_EVENTS_PAGE_SIZE = 4;
export const MIN_CARDHOLDER_EXCLUSIVE_EVENTS_TO_SHOW = 3;

export const CommonCarouselSectionProps = {
  emptyStateTitleKey: 'homePage.emptyStateTitle',
  emptyStateDescriptionKey: 'homePage.emptyStateDescription',
  areBoxShadowsEnabled: true,
  pageName: TRACKING_PAGE_NAME.forCarouselLinkClicks,
} as const satisfies Partial<CarouselSectionProps<BaseComponentProps>>;

export const StaticCarouselSectionPropsByType = {
  cardholderExclusiveEvents: {
    carouselIndex: 1,
    titleKey: 'homePage.carouselSections.cardholderExclusiveEvents.label',
    linkLabelKey: 'homePage.viewAll',
    linkAriaLabelKey: 'homePage.carouselSections.cardholderExclusiveEvents.linkAriaLabel',
    isEmptyStateSupported: false,
    areMobileScrollButtonsVisible: true,
    theme: 'dark',
    classes: {
      carouselHeader: cx(styles.mobileCarouselHeader, styles.cardholderExclusiveEventsCarouselHeader),
      carouselTitle: styles.cardholderExclusiveEventsCarouselTitle,
      carouselLoadingOrEmptyStateContainer: styles.eventsCarouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: styles.carouselItemsContainer,
      mobileScrollButtonContainer: styles.mobileScrollButtonContainer,
    },
  },
  upcomingEvents: {
    carouselIndex: 2,
    titleKey: 'homePage.carouselSections.upcomingEvents.label',
    tooltipProps: {
      textKey: 'homePage.tooltips.cardholderExclusives',
      ariaLabelKey: 'homePage.ariaLabels.upcomingEvents',
    },
    linkLabelKey: 'homePage.viewAll',
    linkAriaLabelKey: 'homePage.carouselSections.upcomingEvents.linkAriaLabel',
    isEmptyStateSupported: true,
    theme: 'light',
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.eventsCarouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: styles.carouselItemsContainer,
    },
  },
  musicPerformers: {
    carouselIndex: 3,
    titleKey: 'homePage.carouselSections.musicPerformers.label',
    tooltipProps: {
      textKey: 'homePage.tooltips.cardholderExclusives',
      ariaLabelKey: 'homePage.ariaLabels.musicPerformers',
    },
    linkLabelKey: 'homePage.viewEvents',
    linkAriaLabelKey: 'homePage.carouselSections.musicPerformers.linkAriaLabel',
    isEmptyStateSupported: true,
    theme: 'light',
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.performersCarouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: cx(styles.carouselItemsContainer, styles.performersCarouselItemsContainer),
    },
  },
  sportsPerformers: {
    carouselIndex: 4,
    titleKey: 'homePage.carouselSections.sportsPerformers.label',
    tooltipProps: {
      textKey: 'homePage.tooltips.cardholderExclusives',
      ariaLabelKey: 'homePage.ariaLabels.sportsPerformers',
    },
    linkLabelKey: 'homePage.viewEvents',
    linkAriaLabelKey: 'homePage.carouselSections.sportsPerformers.linkAriaLabel',
    isEmptyStateSupported: true,
    theme: 'light',
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.performersCarouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: cx(styles.carouselItemsContainer, styles.performersCarouselItemsContainer),
    },
  },
  comedyPerformers: {
    carouselIndex: 5,
    titleKey: 'homePage.carouselSections.comedyPerformers.label',
    tooltipProps: {
      textKey: 'homePage.tooltips.cardholderExclusives',
      ariaLabelKey: 'homePage.ariaLabels.comedyPerformers',
    },
    linkLabelKey: 'homePage.viewEvents',
    linkAriaLabelKey: 'homePage.carouselSections.comedyPerformers.linkAriaLabel',
    isEmptyStateSupported: true,
    theme: 'light',
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.performersCarouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: cx(styles.carouselItemsContainer, styles.performersCarouselItemsContainer),
    },
  },
  theaterPerformers: {
    carouselIndex: 6,
    titleKey: 'homePage.carouselSections.theaterPerformers.label',
    tooltipProps: {
      textKey: 'homePage.tooltips.cardholderExclusives',
      ariaLabelKey: 'homePage.ariaLabels.theaterPerformers',
    },
    linkLabelKey: 'homePage.viewEvents',
    linkAriaLabelKey: 'homePage.carouselSections.theaterPerformers.linkAriaLabel',
    isEmptyStateSupported: true,
    theme: 'light',
    classes: {
      carouselHeader: styles.mobileCarouselHeader,
      carouselLoadingOrEmptyStateContainer: styles.performersCarouselLoadingOrEmptyStateContainer,
      carouselItemsContainer: cx(styles.carouselItemsContainer, styles.performersCarouselItemsContainer),
    },
  },
} as const satisfies Record<string, Partial<CarouselSectionProps<BaseComponentProps>> & { carouselIndex: number; }>;
