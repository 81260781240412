import React from 'react';
import ReactDOM from 'react-dom';
import { AriaLiveRegions } from '../../../lib/types';
import styles from './AccessibleLiveMessage.module.scss';
import { usePresenter } from './AccessibleLiveMessage.presenter';
import type { AccessibleLiveMessageProps } from './AccessibleLiveMessage.types';

export const AccessibleLiveMessage: React.FC<AccessibleLiveMessageProps> = (props) => {
  const {
    message,
    id,
    ariaLiveRegion = AriaLiveRegions.ASSERTIVE,
    role = 'alert',
    ariaRelevant,
  } = usePresenter(props);

  const liveMessageComponent = (
    <div
      id={id}
      className={styles.accessibleLiveMessage}
      aria-live={ariaLiveRegion}
      aria-atomic='true'
      aria-relevant={ariaRelevant}
      role={role}
    >
      {message}
    </div>
  );

  // Using React Portals to render the live message component as a direct child of <body>.
  // This ensures that assistive technologies (like screen readers, voice-over) detect updates but this component does not interrupt the reader.
  return ReactDOM.createPortal(liveMessageComponent, document.body);
};
