import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { BaseComponentProps } from '../../../lib/types';
import Button from '../../atoms/Button';
import { CarouselButton } from '../../atoms/CarouselButton';
import Icon from '../../atoms/Icon';
import { Spinner } from '../../atoms/Spinner';
import { EmptyState } from '../EmptyState';
import { NoOp, SpinnerThemesByCarouselTheme } from './Carousel.constants';
import styles from './Carousel.module.scss';
import { usePresenter } from './Carousel.presenter';
import type { CarouselProps } from './Carousel.types';

export const Carousel = <TComponentProps extends BaseComponentProps>(
  props: CarouselProps<TComponentProps>,
) => {
  const {
    carouselItemsContainerRef,
    isLoading,
    isError,
    isEmptyStateSupported,
    emptyStateTitleKey,
    emptyStateDescriptionKey,
    component: Component,
    componentProps,
    onGoBackwardClick,
    onGoForwardClick,
    areMobileScrollButtonsVisible,
    mobileScrollDotDetails,
    areDesktopScrollButtonsVisible,
    shouldRenderLeftBoxShadow,
    shouldRenderRightBoxShadow,
    theme,
    classes,
  } = usePresenter(props);

  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={cx(styles.carousel, styles.carouselLoadingOrEmptyStateContainer, classes?.carouselLoadingOrEmptyStateContainer)}>
        <Spinner theme={SpinnerThemesByCarouselTheme[theme]} />
      </div>
    );
  }

  if (!componentProps.length || isError) {
    // If empty state is not supported then do not render anything
    return isEmptyStateSupported
      ? (
        <div className={cx(styles.carousel, styles.carouselLoadingOrEmptyStateContainer, classes?.carouselLoadingOrEmptyStateContainer)}>
          <EmptyState
            titleKey={emptyStateTitleKey}
            descriptionKey={emptyStateDescriptionKey}
            theme={theme}
            className={styles.carouselEmptyState}
          />
        </div>
      )
      : null;
  }

  const carouselItems: React.ReactNode = componentProps.map((currentComponentProps: TComponentProps) => (
    <Component
      {...currentComponentProps}
      key={currentComponentProps.key}
      className={cx(styles.carouselItem, currentComponentProps.className)}
    />
  ));

  const mobileScrollDots: React.ReactNode = mobileScrollDotDetails
    ? [...Array(mobileScrollDotDetails.totalDots)].map((_, index) => (
      <Icon
        key={index}
        asset='Circle'
        style='BaseLight'
        className={cx({ [styles.unselectedMobileScrollDot]: index !== mobileScrollDotDetails.currentDotIndex })}
      />
    ))
    : null;

  return (
    <div className={cx(
      styles.carousel,
      {
        [styles.carouselWithLeftBoxShadow]: shouldRenderLeftBoxShadow,
        [styles.carouselWithRightBoxShadow]: shouldRenderRightBoxShadow,
      },
    )}
    >
      <div
        ref={carouselItemsContainerRef}
        className={cx(styles.carouselItemsContainer, classes?.carouselItemsContainer)}
      >
        {carouselItems}
      </div>
      {!!areMobileScrollButtonsVisible && (
        <div className={cx(styles.mobileScrollButtonContainer, classes?.mobileScrollButtonContainer)}>
          <Button
            type='Icon'
            style='Text'
            size='Small'
            icon={{ asset: 'LeftArrow', style: 'BaseLight' }}
            ariaLabel={t('carouselButton.backward')}
            // Need to pass in an empty function as a fallback
            onClick={onGoBackwardClick || NoOp}
            disabled={!onGoBackwardClick}
            className={cx(styles.mobileScrollButton, { [styles.mobileScrollButtonDisabled]: !onGoBackwardClick })}
            classes={{ icon: styles.mobileScrollButtonIcon }}
          />
          {mobileScrollDots}
          <Button
            type='Icon'
            style='Text'
            size='Small'
            icon={{ asset: 'RightArrow', style: 'BaseLight' }}
            ariaLabel={t('carouselButton.forward')}
            // Need to pass in an empty function as a fallback
            onClick={onGoForwardClick || NoOp}
            disabled={!onGoForwardClick}
            className={cx(styles.mobileScrollButton, { [styles.mobileScrollButtonDisabled]: !onGoForwardClick })}
            classes={{ icon: styles.mobileScrollButtonIcon }}
          />
        </div>
      )}
      {areDesktopScrollButtonsVisible && (<>
        {!!onGoBackwardClick && (
          <CarouselButton
            type='backward'
            onClick={onGoBackwardClick}
            className={classes?.carouselBackwardButton}
          />
        )}
        {!!onGoForwardClick && (
          <CarouselButton
            type='forward'
            onClick={onGoForwardClick}
            className={classes?.carouselForwardButton}
          />
        )}
      </>)}
    </div>
  );
};
