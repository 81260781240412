import type { ListingDetailMetadata, ListingMetadata } from '../../../lib/types';
import { formatNumberToLocaleString, isNotNullOrUndefined } from '../../../lib/util';
import i18n from '../../../modules/locale/i18n';
import type { TextDropdownOption } from '../TextDropdown';
import type { TicketsByLogo } from './PreCheckoutDetails.types';

/**
 * Gets an array of quantity dropdown options sorted by quantity values in ascending order
 * @returns {TextDropdownOption[]} Array of quantity dropdown options sorted by quantity values in ascending order
 */
export const getQuantityDropdownOptions = (params: {
  /** Array of numeric quantities */
  quantities: readonly number[];
}): TextDropdownOption[] => {
  const { quantities } = params;

  // Use .slice not to mutate the original array of quantities
  const sortedQuantities: number[] = quantities.slice().sort((a, b) => a - b);

  return sortedQuantities.map((quantity: number) => ({
    value: quantity.toString(),
    label: i18n.t('preCheckoutDetails.tickets', { quantity, count: quantity }),
  }));
};

/**
 * Gets logo to display in 'tickets by' section (capitalOne or vividSeats)
 * @returns {TicketsByLogo} Logo to display in 'tickets by' section (capitalOne or vividSeats)
 */
export const getTicketsByLogo = (params: {
  /** Indicates if the listing has CP1 broker Id 29014 */
  hasCp1BrokerId: boolean;
}): TicketsByLogo => {
  return params.hasCp1BrokerId ? 'capitalOne' : 'vividSeats';
};

/**
 * Calculates the total service fee for a ticket listing based on quantity and price breakdown.
 * The service fee consists of a delivery fee plus a per-ticket service fee multiplied by quantity.
 * @returns The total calculated service fee (delivery fee + per-ticket service fee * quantity), 
 *          or undefined if price breakdown data is missing
 */
export const calculateServiceFee = (listingMetadata: ListingMetadata | undefined, quantity: number): number | undefined => {
  // Extract price breakdown from listing metadata, defaulting to empty object if undefined
  const { price_breakdown: priceBreakdown } = listingMetadata ?? {};

  // Return undefined if either listing metadata or price breakdown is missing
  if (!priceBreakdown || !listingMetadata) {
    return undefined;
  }

  // Extract the per-ticket service fee and delivery fee from price breakdown
  const { service_fee: priceServiceFee, delivery_fee: deliveryFee } = priceBreakdown;

  // Calculate total service fee: delivery fee + (per-ticket service fee * quantity)
  const calculatedServiceFee = deliveryFee + (priceServiceFee * quantity);

  return calculatedServiceFee;
};

/**
 * Gets a displayed text for listing service fees based on AIP status and event details:
 * For non-AIP prices:
 *   - Returns undefined for exclusive events
 *   - Returns 'Plus fees' for non-exclusive events
 * For AIP prices:
 *   - Returns 'Includes fees' if showAipIncludedPrices is false
 *   - Returns 'Includes fees' if service fee cannot be calculated
 *   - Returns 'Includes $X.XX in fees' with the calculated service fee amount
 */
export const getServiceFeeText = (params: {
  /**
   * - If true then all formatted prices (e.g. formattedCashPrice, formattedLoyaltyPrice) are AIP prices.
   * - If false then all formatted prices (e.g. formattedCashPrice, formattedLoyaltyPrice) are non-AIP prices.
   */
  shouldShowAip: boolean;
  /** Detailed information about event listing detail */
  listingDetailMetadata: ListingDetailMetadata;
  /** Validated quantity */
  quantity: number;
}): string | undefined => {
  const { shouldShowAip, listingDetailMetadata, quantity } = params;
  const { eventMetadata, listingMetadata } = listingDetailMetadata;
  const { isExclusiveEvent } = eventMetadata;
  const { show_aip_included_prices: showAipIncludedPrices } = listingMetadata;
  if (!shouldShowAip) {
    // If event is exclusive then show nothing
    // Otherwise show 'Plus fees'
    return isExclusiveEvent ? undefined : i18n.t('preCheckoutDetails.plusFees');
  }
  if (!showAipIncludedPrices) {
    return i18n.t('preCheckoutDetails.includesFees');
  }

  const calculatedServiceFee: number | null | undefined = calculateServiceFee(listingMetadata, quantity);

  return !isNotNullOrUndefined(calculatedServiceFee)
    ? i18n.t('preCheckoutDetails.includesFees')
    : i18n.t('preCheckoutDetails.includesXInFees', { fees: formatNumberToLocaleString({ num: calculatedServiceFee, unitName: 'dollarsWithCents', shouldIncludeUnitName: false }) });
};
