import { useCallback } from 'react';
import { trackOptimizelyEvent } from '../../../modules/analytics';
import { redirectToSignInURL } from '../../../modules/navigation/Navigation.utils';
import type { SignInBannerPresenterProps, SignInBannerProps } from './SignInBanner.types';

export const usePresenter = (props: SignInBannerProps): SignInBannerPresenterProps => {
  const onSignInBannerClick = useCallback(() => {
    trackOptimizelyEvent('mobile_nav_menu_sign_in');
    redirectToSignInURL();
  }, []);

  return {
    ...props,
    onSignInBannerClick,
  };
};
