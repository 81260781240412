import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { getStringFromReactNode } from '../../../lib/util';
import { ChangeConfirmationModal } from '../../organisms/ChangeConfirmationModal';
import Button from '../Button';
import Icon from '../Icon';
import Image from '../Image';
import Text from '../Text';
import { NON_TOGGLABLE } from './BaseFilter.constants';
import styles from './BaseFilter.module.scss';
import { usePresenter } from './BaseFilter.presenter';
import type { BaseFilterProps, FilterOption } from './BaseFilter.types';

export const BaseFilter = <TFilterState, TFilterOptionValue>(props: BaseFilterProps<TFilterState, TFilterOptionValue>) => {
  const {
    filterState,
    displayValue,
    displayValueColour,
    toggleFilterDropdown,
    isExpanded,
    filterDropdownTopContent,
    filterOptions,
    onFilterOptionSelected,
    isClearFilterButtonShown,
    clearFilter,
    filterIconStyle,
    isDisabled,
    isHighlightOnExpandOnly = false,
    theme,
    className,
    classes,
    ariaLabel,
    filterStateImageUrl,
    toggleButtonType,
    changeConfirmationModalViewProps,
    isChangeConfirmationModalShown,
    dropdownMenuHtmlId,
  } = usePresenter(props);

  const { t } = useTranslation();

  const dropdownToggle: React.ReactNode = (
    <Dropdown.Toggle
      as='div'
      aria-haspopup={undefined}
      aria-expanded={undefined}
      className={cx(styles.filterToggleWrapper, { [styles.highlighted]: isHighlightOnExpandOnly ? isExpanded : (filterState || isExpanded) }, classes?.filterToggleWrapper)}
      disabled={isDisabled}
    >
      <Button
        type={toggleButtonType}
        style='Text'
        size='Medium'
        text={{
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: displayValueColour,
          align: 'Left',
          value: displayValue,
          className: styles.displayValue,
          classes: classes?.displayValue ? { value: classes.displayValue } : undefined,
        }}
        icon={{
          asset: !isDisabled && isExpanded ? 'ChevronUp' : 'ChevronDown',
          style: filterIconStyle,
          className: cx(styles.filterIcon, classes?.filterIcon),
        }}
        image={{
          imageSrc: filterStateImageUrl,
          className: styles.dropdownMenuItemImage,
        }}
        // Need to pass in an empty function to render as a button
        // Dropdown toggle is handled by Dropdown component
        onClick={() => { }}
        className={cx(styles.filterButton, styles.filterToggle, { [styles.split]: isClearFilterButtonShown }, classes?.filterToggle)}
        classes={{ content: styles.filterToggleContent }}
        ariaLabel={ariaLabel || getStringFromReactNode(displayValue)}
        aria-controls={dropdownMenuHtmlId}
        aria-haspopup={true}
        aria-expanded={isExpanded}
        disabled={isDisabled}
      />
      {isClearFilterButtonShown && (
        <Button
          type='Icon'
          style='Text'
          size='Medium'
          icon={{
            asset: 'Close',
            style: filterIconStyle,
            className: cx(styles.filterIcon, NON_TOGGLABLE, classes?.filterIcon),
          }}
          onClick={clearFilter}
          className={cx(styles.filterButton, styles.clearFilterButton, NON_TOGGLABLE)}
          ariaLabel={t('baseFilter.clearFilter', { ariaLabel })}
          disabled={isDisabled}
        />
      )}
    </Dropdown.Toggle>
  );

  // Do not apply dropdown menu CSS class if there are no filter options
  const dropdownMenuClassName: string | undefined = filterOptions.length ? styles.dropdownMenu : undefined;

  /**
   * Wrapping the Dropdown Menu with React Portal to ensure it is rendered outside of the scrollable container
   * This allows the dropdown to open correctly, without being constrained by any overflow or scrolling behavior
   * of its parent container. The dropdown will be rendered directly into the document body instead of inside the scrollable container.
   */
  const dropdownMenu: React.ReactNode = isExpanded && (
    createPortal(
      <Dropdown.Menu
        id={dropdownMenuHtmlId}
        role='menu'
        aria-label={t('baseFilter.menuAriaLabel', { ariaLabel })}
        className={cx(styles.dropdownMenuBase, dropdownMenuClassName, NON_TOGGLABLE, classes?.dropdownMenuBase)}
      >
        {/* Render optional content above filter options when filter dropdown is expanded */}
        {filterDropdownTopContent}

        {/* Loop through filter options and render each filter option */}
        {filterOptions.map((filterOption: FilterOption<TFilterOptionValue>, index: number) => {
          const { value, iconAsset, textKey, isSelected, imageUrl, isDisabled: isDisabledOption, imageAriaLabel, role } = filterOption;
          const text: ReactNode = typeof textKey === 'string' ? t(textKey) : textKey;

          /** Icon (optional) + text */
          const dropdownMenuItemContent: ReactNode = (
            <div className={styles.dropdownMenuItemContent}>
              {/* Render image if imageUrl is provided */}
              {!!imageUrl && (
                <Image
                  imageSrc={imageUrl}
                  className={cx(styles.dropdownMenuItemImage, { [styles.isDisabledImage]: isDisabledOption })}
                  ariaLabel={imageAriaLabel}
                />
              )}
              {/* Render icon if icon asset is provided */}
              {!!iconAsset && (
                <Icon
                  asset={iconAsset}
                  style={isSelected ? 'ActionBase' : 'SubduedDark'}
                  className={styles.dropdownMenuItemIcon}
                />
              )}
              <Text
                size='Large'
                style='Regular'
                colour={isSelected ? 'ActionBase' : 'SubduedDark'}
                value={text}
                className={cx(styles.dropdownMenuItemText, { [styles.isDisabledText]: isDisabledOption })}
              />
            </div>
          );

          /** Green checkmark if the option is selected */
          const selectedIcon = isSelected && (
            <Icon
              className={styles.checkMarkIcon}
              asset='CheckmarkInCircleFilled'
              style='Green60'
            />
          );

          // Apply onClick to the entire menu option to handle selection
          return (
            <Dropdown.Item
              key={index}
              as='button'
              role={role || 'menuitem'}
              aria-checked={role === 'menuitemradio' ? isSelected : undefined}
              // The first item in the dropdown should be focusable by default (tabIndex={0}),
              // while all other items should be set to -1 to prevent unintended tab navigation.
              // This ensures keyboard users start from the first option when navigating via the keyboard.
              tabIndex={index === 0 ? 0 : -1}
              className={cx(styles.dropdownMenuItem, { [styles.selected]: isSelected }, { [styles.isDisabledOption]: isDisabledOption })}
              onClick={() => !isDisabledOption && onFilterOptionSelected(value, text)}
              disabled={isDisabledOption}
            >
              {/* Render the content */}
              {dropdownMenuItemContent}
              {/* Render green checkmark if the option is selected */}
              {selectedIcon}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>,
      document.body,
    )
  );

  const changeConfirmationModal = (changeConfirmationModalViewProps && isChangeConfirmationModalShown) && <ChangeConfirmationModal {...changeConfirmationModalViewProps} />;

  return (
    <>
      <Dropdown
        show={isExpanded}
        className={cx(styles.filter, styles[theme], className)}
        onToggle={toggleFilterDropdown}
      >
        {dropdownToggle}
        {dropdownMenu}
      </Dropdown>
      {changeConfirmationModal}
    </>
  );
};
