import type { ListingDetailMetadata } from '../../lib/types';
import { getTicketListingTranslationKey } from '../../lib/util';
import i18n from '../locale/i18n';
import type { OrderResponse } from '../partnership';
import type { EventKeys } from './types';

export const trackOptimizelyEvent = (eventKey: EventKeys) => {
  // Optimizely values are added to window in cmd/stitch-deprecated-and-latest-code.js
  if (window.optimizelyUserContext && window.optimizelyEventKeys?.includes(eventKey)) {
    window.optimizelyUserContext.trackEvent(eventKey);
  }
};

/**
 * Tracks a content selection event.
 *
 * @param trackEvent - Function to track the event.
 * @param contentType - Type of the content selected.
 * @param clickLocation - Location where the content was clicked.
 * @param clickText - Optional text of the clicked content.
 * @param extraData - Optional additional data to include in the event.
 */
export const trackSelectContentEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  contentType: string,
  clickLocation: string,
  clickText?: string,
  extraData?: Record<string, unknown>,
) => {
  const data = {
    content_type: contentType,
    click_location: clickLocation,
    click_text: clickText,
    ...extraData,
  };
  trackEvent('select_content', data);
};

/**
 * Tracks an error event.
 *
 * @param trackEvent - Function to track the event.
 * @param code - Error code.
 * @param message - Error message.
 * @param extraData - Optional additional data to include in the event.
 */
export const trackErrorEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  code: string | number,
  message: string,
  extraData?: Record<string, unknown>,
) => {
  const errorPageUrl = window.location.href;
  const data = {
    error_code: code,
    error_message: message,
    error_url: errorPageUrl,
    ...extraData,
  };
  trackEvent('error', data);
};

/**
 * Tracks a page view event.
 *
 * @param trackEvent - Function to track the event.
 * @param pageType - Type of the page viewed.
 * @param extraData - Optional additional data to include in the event.
 */
export const trackPageViewEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  pageType: string,
  extraData?: Record<string, unknown>,
) => {
  const pageTitle = document.title;
  const pageUrl = window.location.href;
  const data = {
    page_title: pageTitle,
    url_path: pageUrl,
    page_type: pageType,
    ...extraData,
  };
  trackEvent('page_view', data);
};

/**
 * Tracks a screen view event.
 *
 * @param trackEvent - Function to track the event.
 * @param pageType - Type of the page viewed.
 * @param extraData - Optional additional data to include in the event.
 */
export const trackScreenViewEvent = (
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void,
  pageType: string,
  extraData?: Record<string, unknown>,
) => {
  const pageTitle = document.title;
  const pageUrl = window.location.href;
  const data = {
    page_title: pageTitle,
    url_path: pageUrl,
    page_type: pageType,
    ...extraData,
  };
  trackEvent('screen_view', data);
};

/** Tracks select_item and select_content analytics events on card click */
export const trackCardClickEvent = (params: {
  /** Function to track analytics events */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void;
  /** Page name to use for analytics on card click */
  pageName: string;
  /** Optional translation key for section title (e.g. carousel title) to use for analytics on card click */
  sectionTitleKey: string | undefined;
  /** Optional 1-based section index (e.g. carousel index) to use for analytics on card click */
  sectionIndex: number | undefined;
  /** 0-based card index to use for analytics on card click */
  cardIndex: number;
  /** Card name to use for analytics on card click */
  cardName: string;
}): void => {
  const { trackEvent, pageName, sectionTitleKey, sectionIndex, cardIndex, cardName } = params;

  trackEvent('select_item');

  trackSelectContentEvent(
    trackEvent,
    pageName,
    sectionTitleKey ? i18n.t(sectionTitleKey) : '',
    cardName,
    {
      carousel_location: sectionIndex,
      click_tile_location: cardIndex,
    },
  );
};

/** Tracks various checkout-related events such as 'add-to-cart', 'begin_checkout', 'checkout_login', 'add_payment_info', 'add_shipping_info', 'purchase' */
export const trackCheckoutEvents = (params: {
  /** Function to track analytics events */
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void;
  /** Event key that specifies the type of event being tracked */
  eventKey: EventKeys;
  /** (Optional) Detailed information about event listing detail */
  listingDetailMetadata?: ListingDetailMetadata;
  /** (Optional) Order response. Required for 'purchase' event only. */
  orderResponse?: OrderResponse;
  /** (Optional) Total number of previously purchased tickets. Required for 'purchase' event only. */
  previouslyPurchasedTicketTotal?: number;
}): void => {
  const {
    trackEvent,
    eventKey,
    listingDetailMetadata,
    orderResponse,
    previouslyPurchasedTicketTotal,
  } = params;

  const { listingMetadata, pricing } = listingDetailMetadata ?? {};

  const commonListingProps = {
    items: listingMetadata,
  };

  const commonPricingProps = {
    currency: pricing?.currency,
    subtotal: pricing?.subtotal,
    shipping: pricing?.delivery_fee,
    service_fee: pricing?.fulfillment_fee,
    tax: pricing?.taxes,
    value: pricing?.price_per,
    total: pricing?.total,
  };

  let eventData: Record<string, unknown> = {};

  switch (eventKey) {
    case 'add-to-cart': {
      eventData = {
        ecommerce: commonListingProps,
      };
      break;
    }
    case 'begin_checkout': {
      eventData = {
        ecommerce: {
          ...commonListingProps,
          ...commonPricingProps,
        },
      };
      break;
    }
    case 'checkout_login': {
      eventData = {
        ecommerce: {
          step_name: 'LoginCompleted',
        },
      };
      break;
    }
    case 'add_payment_info': {
      eventData = {
        ecommerce: {
          step_name: 'PaymentAdded',
          ...commonListingProps,
          ...commonPricingProps,
        },
      };
      break;
    }
    case 'add_shipping_info': {
      eventData = {
        ecommerce: {
          step_name: 'ShippingAdded',
          shipping_tier: listingDetailMetadata ? i18n.t(getTicketListingTranslationKey(listingDetailMetadata, 'delivery_method')) : '',
          ...commonListingProps,
          ...commonPricingProps,
        },
      };
      break;
    }
    case 'purchase': {
      eventData = {
        ecommerce: {
          ...commonListingProps,
          ...commonPricingProps,
          transaction_id: orderResponse?.id,
          loyalty_amount: orderResponse?.loyalty?.paid,
          payment_amount: orderResponse?.payment?.paid,
          production_previously_purchased: previouslyPurchasedTicketTotal !== undefined && previouslyPurchasedTicketTotal > 0,
        },
      };
      break;
    }
    default:
      break;
  }

  trackEvent(eventKey, eventData);
};
