import React, { type ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { EventListProvider } from '../../../modules/eventList';
import type { SearchQueryParams } from '../../../modules/partnership';
import { getSearch } from '../../../modules/partnership/api';
import Text from '../../atoms/Text';
import type { EmptyStateProps } from '../../molecules/EmptyState';
import { DateFilter } from '../../organisms/DateFilter';
import { BaseEventListPage } from '../BaseEventListPage';
import baseEventListPageStyles from '../BaseEventListPage/BaseEventListPage.module.scss';
import { TRACKING_PAGE_NAME } from './SearchResultsPage.constants';
import styles from './SearchResultsPage.module.scss';
import { usePresenter } from './SearchResultsPage.presenter';
import type { SearchResultsPageProps } from './SearchResultsPage.types';

const InternalSearchResultsPage: React.FC<SearchResultsPageProps> = (props) => {
  const {
    eventsTotal,
    searchQuery,
    dateFilterState,
    trackSearchAnalytics,
    allEventsPageLinkHref,
    className,
  } = usePresenter(props);

  const topEventListContent: ReactNode = (<>
    <div className={styles.eventsTotalContainer}>
      <Text
        type='Subheading'
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Right'
        value={<Trans
          i18nKey='searchResultsPage.resultsForSearchQuery'
          values={{ eventsTotal, searchQuery, count: eventsTotal }}
          components={{ span: <span className={styles.searchQuery} /> }} />}
        className={styles.resultsMessage}
      />
    </div>
    <div className={baseEventListPageStyles.filtersContainer}>
      <div className={baseEventListPageStyles.filter}>
        <DateFilter dateFilterState={dateFilterState} />
      </div>
    </div>
  </>);

  const emptyStateProps: EmptyStateProps = {
    titleKey: 'searchResultsPage.noResultsTitle',
    descriptionKey: 'searchResultsPage.noResultsDescription',
    actionButtonProps: {
      labelKey: 'searchResultsPage.seeAllEvents',
      href: allEventsPageLinkHref,
    },
  };

  return (
    <BaseEventListPage<SearchQueryParams>
      topContent={null}
      topEventListContent={topEventListContent}
      fetchEventsFunc={getSearch}
      trackFetchAnalytics={trackSearchAnalytics}
      emptyStateProps={emptyStateProps}
      pageName={TRACKING_PAGE_NAME.forEventCardClicks}
      className={className}
    />
  );
};

export const SearchResultsPage: React.FC<SearchResultsPageProps> = (props) => {
  return (
    <EventListProvider>
      <InternalSearchResultsPage {...props} />
    </EventListProvider>
  );
};
