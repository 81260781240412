import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsManager } from '../../../modules/analytics';
import { TopNavContext } from '../../../modules/topNav';
import { getSearchSuggestionsResultsByCategories } from '../../blocks/SearchSuggestionBlock';
import type { SearchSuggestionsModalPresenterProps } from './SearchSuggestionsModal.types';

export const usePresenter = (): SearchSuggestionsModalPresenterProps => {
  const { activeTopNavMenuType, searchSuggestions, searchQuery } = useContext(TopNavContext);

  const { t } = useTranslation();

  const [searchSuggestionResultScreenReaderText, setSearchSuggestionResultScreenReaderText] = useState<string>();

  const { trackEvent } = useAnalyticsManager();

  const { events, performers, venues } = useMemo(
    () => getSearchSuggestionsResultsByCategories(searchSuggestions, trackEvent),
    [searchSuggestions, trackEvent],
  );

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    setSearchSuggestionResultScreenReaderText(undefined);

    if (searchSuggestions || searchQuery) {
      timerRef.current = setTimeout(() => {
        setSearchSuggestionResultScreenReaderText(t('search.searchSuggestionResultScreenReaderText', {
          eventsCount: events?.searchSuggestionItems.length || 0,
          performersCount: performers?.searchSuggestionItems.length || 0,
          venuesCount: venues?.searchSuggestionItems.length || 0,
        }));
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, [t, searchSuggestions, searchQuery, events?.searchSuggestionItems.length, performers?.searchSuggestionItems.length, venues?.searchSuggestionItems.length]);

  return {
    activeTopNavMenuType,
    searchSuggestions,
    searchSuggestionResultScreenReaderText,
    events,
    performers,
    venues,
  };
};
