import cx from 'classnames';
import React from 'react';
import { Overlay, OverlayTrigger, Tooltip as BootstrapToolTip, type OverlayTriggerProps } from 'react-bootstrap';
import type { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { usePresenter } from './ToolTip.presenter';
import './ToolTip.scss';
import type { ToolTipPresenterProps, ToolTipProps } from './ToolTip.types';

// Fix warning about default props to be deprecated in React in the future.
// We have no control over react-bootstrap so we save their default props to a variable and delete them from react-bootstrap.
const overlayTriggerProps: Omit<OverlayTriggerProps, 'placement' | 'overlay' | 'children'> = {
  ...(Overlay.defaultProps ?? {}),
  ...(OverlayTrigger.defaultProps ?? {}),
  show: undefined,
  transition: false,
  trigger: OverlayTrigger.defaultProps.trigger as OverlayTriggerType[],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Overlay as any).defaultProps = undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(OverlayTrigger as any).defaultProps = undefined;

const renderToolTip = (props: ToolTipPresenterProps) => {
  const { toolTipId, text, ariaLabel, ariaLabelKey, theme, className, textKey, ...otherToolTipProps } = props;

  return (
    <BootstrapToolTip
      id={toolTipId}
      role='tooltip'
      className={cx(`${theme}-tooltip`, className)}
      {...otherToolTipProps}
    >
      {text}
    </BootstrapToolTip>
  );
};

export const ToolTip: React.FC<ToolTipProps> = (props) => {
  const presenterProps: ToolTipPresenterProps = usePresenter(props);

  const {
    toolTipId,
    ariaLabel,
    placement,
    children,
  } = presenterProps;

  return (
    <OverlayTrigger
      {...overlayTriggerProps}
      placement={placement}
      overlay={renderToolTip(presenterProps)}
    >
      {({ ...toolTipProps }) => (
        <button
          aria-labelledby={`${toolTipId}-label`}
          tabIndex={0}
          {...toolTipProps}
        >
          {children}
          <div
            id={`${toolTipId}-label`}
            className='tooltip-accessibility-content'
          >
            {ariaLabel}
          </div>
        </button>
      )}
    </OverlayTrigger>
  );
};
