import { useContext, useMemo } from 'react';
import type { CheckoutTotalPresenterProps, CheckoutTotalProps } from './CheckoutTotal.types';
import { getFormattedTotalCashAndLoyaltyPrices } from './CheckoutTotal.utils';
import { AuthContext } from '../../../modules/auth';

export const usePresenter = (props: CheckoutTotalProps): CheckoutTotalPresenterProps => {
  const {
    listingDetailMetadata: {
      eventMetadata: {
        isPayWithRewardsOnly,
      },
      listingMetadata: {
        loyaltyUnitName = '',
      },
    },
    step,
    formattedTotalPriceInCash,
    formattedTotalPriceInRewardUnits,
    formattedRemainingCashPriceWithCents,
    formattedAppliedRewardUnits,
  } = props;

  const { selectedAccountCardDetail } = useContext(AuthContext);
  const { isCashOnlyAccountCard = false } = selectedAccountCardDetail ?? {};

  /** Formatted total cash price (with cents) and loyalty price for selected quantity in Quantity dropdown, e.g. $123,456.00 or 123,456 miles */
  const formattedTotalCashAndLoyaltyPrices: string = useMemo(
    () => getFormattedTotalCashAndLoyaltyPrices({
      step,
      formattedTotalPriceInCash,
      formattedTotalPriceInRewardUnits,
      formattedRemainingCashPriceWithCents,
      formattedAppliedRewardUnits,
      loyaltyUnitName,
      isPayWithRewardsOnly,
      isCashOnlyAccountCard,
    }),
    [step, formattedTotalPriceInCash, formattedTotalPriceInRewardUnits, formattedRemainingCashPriceWithCents, formattedAppliedRewardUnits, loyaltyUnitName, isPayWithRewardsOnly, isCashOnlyAccountCard],
  );

  return {
    ...props,
    formattedTotalCashAndLoyaltyPrices,
  };
};
