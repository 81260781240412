import { PRIVACY_URL } from '../../../lib/config';
import { URLs } from '../../../lib/constants';
import i18n from '../../../modules/locale/i18n';
import type { ButtonCombinedProps } from '../../atoms/Button';
import type { TextColourEnum } from '../../atoms/Text';
import { FooterButtonTextColoursByFooterTheme } from './Footer.constants';
import styles from './Footer.module.scss';
import type { FooterTheme } from './Footer.types';
/**
 * Returns dark theme for Exclusive Events or other event pages. For all other pages returns light theme.
 * @returns {FooterTheme} Footer theme.
 */
export const getFooterTheme = (params: {
  /** Current path name */
  pathname: string;
}): FooterTheme => {
  const { pathname } = params;
  return pathname.startsWith(URLs.ExclusiveEventsPage) || pathname.startsWith('/events/') ? 'dark' : 'light';
};

/**
 * Generates an array of footer button props for rendering the footer buttons.
 * @returns {ButtonCombinedProps[]} Array of footer button props for rendering the footer buttons.
 */
export const getFooterButtons = (params: {
  /** If true then function returns empty array */
  shouldExcludeFooterButtons: boolean;
  /** Light or dark theme */
  theme: FooterTheme;
  /** Function to open terms of use modal */
  openTermsOfUseModal: () => void;
}): ButtonCombinedProps[] => {
  const { shouldExcludeFooterButtons, theme, openTermsOfUseModal } = params;

  if (shouldExcludeFooterButtons) {
    return [];
  }

  // Determine the text color based on the footer theme
  const textColour: TextColourEnum = FooterButtonTextColoursByFooterTheme[theme];

  // Common properties for all buttons
  const commonButtonProps: Partial<ButtonCombinedProps> = {
    type: 'Text',
    style: 'TextWhite',
    size: 'Small',
    className: styles.footerMenuButton,
  };

  return [
    {
      ...commonButtonProps,
      text: {
        value: i18n.t('footer.help'),
        colour: textColour,
      },
      href: URLs.HelpPage,
    },
    {
      ...commonButtonProps,
      text: {
        value: i18n.t('footer.legal'),
        colour: textColour,
      },
      onClick: openTermsOfUseModal,
    },
    {
      ...commonButtonProps,
      text: {
        value: i18n.t('footer.privacy'),
        colour: textColour,
      },
      href: PRIVACY_URL,
      target: '_blank',
    },
  ];
};
