import { ChangeConfirmationModalContentProps } from './ChangeConfirmationModal.types';

export const changeConfirmationModalContentProps: ChangeConfirmationModalContentProps = {
  titleTextType: {
    mobile: 'Subheading',
    desktop: 'Body',
  },
  titleTextSize: {
    mobile: 'Medium',
    desktop: 'Large',
  },
  titleTextColor: 'BaseDark',
  closeIconColor: 'BaseDark',
  contentTextSize: {
    mobile: 'Large',
    desktop: 'Medium',
  },
  contentTextColor: 'BaseDark',
  contentTextAlign: {
    mobile: 'Center',
    desktop: 'Left',
  },
  primaryButtonStyle: 'Contained',
};
