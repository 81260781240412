import { useEffect, useMemo, useRef, useState } from 'react';
import { getArrayOfLength } from '../../../lib/objectUtils';
import type { BaseComponentProps } from '../../../lib/types';
import { useHtmlElementSize, useWindowSize } from '../../../lib/util';
import { VISIBILITY_PERCENTAGE_90, VISIBILITY_PERCENTAGE_97 } from './Carousel.constants';
import type { CarouselItemsState, CarouselPresenterProps, CarouselProps, MobileScrollDotDetails } from './Carousel.types';
import { checkAreDesktopScrollButtonsVisible, checkAreMobileScrollButtonsVisible, checkShouldRenderLeftBoxShadow, checkShouldRenderRightBoxShadow, getCarouselIntersectionObserverCallback, getCarouselItemsState, getMobileScrollDotDetails, getOnGoBackwardClick, getOnGoForwardClick } from './Carousel.utils';

export const usePresenter = <TComponentProps extends BaseComponentProps>(
  props: CarouselProps<TComponentProps>,
): CarouselPresenterProps<TComponentProps> => {
  const { componentProps, areMobileScrollButtonsVisible: initialAreMobileScrollButtonsVisible = false, areBoxShadowsEnabled } = props;

  const { isDesktop } = useWindowSize();

  const carouselItemsContainerRef = useRef<HTMLDivElement>(null);

  const { width: carouselItemsContainerWidthPx } = useHtmlElementSize({ htmlElement: carouselItemsContainerRef.current });

  const itemsLength: number = componentProps.length;

  /** This flag is true if user is on mobiles, there is at least one carousel item and override flag is true */
  const areMobileScrollButtonsVisible: boolean = useMemo(
    () => checkAreMobileScrollButtonsVisible({ isDesktop, itemsLength, initialAreMobileScrollButtonsVisible }),
    [isDesktop, itemsLength, initialAreMobileScrollButtonsVisible],
  );

  /** This flag is true if user is on desktops and there is at least one carousel item */
  const areDesktopScrollButtonsVisible: boolean = useMemo(
    () => checkAreDesktopScrollButtonsVisible({ isDesktop, itemsLength }),
    [isDesktop, itemsLength],
  );

  const areScrollButtonsVisible: boolean = useMemo(
    () => areMobileScrollButtonsVisible || areDesktopScrollButtonsVisible,
    [areMobileScrollButtonsVisible, areDesktopScrollButtonsVisible],
  );

  // Boolean array where true means that the carousel item at that index (0-based) is fully visible width-wise.
  // Carousel scrolls horizontally so we are interested only in the visible width of carousel items, not their heights.
  const [carouselItemVisibilities, setCarouselItemVisibilities] = useState<boolean[]>([]);

  useEffect(
    () => setCarouselItemVisibilities(getArrayOfLength({ arrayLength: itemsLength, itemValue: false })),
    [itemsLength],
  );

  /** State of visible carousel items that contains the following:
   * - Index of the first fully visible carousel item
   * - Index of the last fully visible carousel item
   */
  const carouselItemsState: CarouselItemsState = useMemo(
    () => getCarouselItemsState({ carouselItemVisibilities }),
    [carouselItemVisibilities],
  );

  const [mobileScrollDotDetails, setMobileScrollDotDetails] = useState<MobileScrollDotDetails | undefined>();

  useEffect(() => {
    if (areMobileScrollButtonsVisible && carouselItemsState.lowestCarouselVisibleItemIndex >= 0) {
      const newMobileScrollDotDetails = getMobileScrollDotDetails({ areMobileScrollButtonsVisible, carouselItemsState, carouselItemVisibilities });
      setMobileScrollDotDetails(newMobileScrollDotDetails);
    }
  }, [areMobileScrollButtonsVisible, carouselItemsState, carouselItemVisibilities]);

  // Using intersection observer to keep track of what carousel items are visible on the screen and update the visibility array of carousel items
  useEffect(() => {
    const carouselItems: Element[] = Array.from(carouselItemsContainerRef.current?.children ?? []);

    const visibilityPercentage: number = isDesktop ? VISIBILITY_PERCENTAGE_90 : VISIBILITY_PERCENTAGE_97;

    const intersectionObserver = new IntersectionObserver(
      getCarouselIntersectionObserverCallback({ carouselItems, setCarouselItemVisibilities, visibilityPercentage }),
      {
        rootMargin: '100% 0% 100% 0%', // Track only horizontal scrolling
        threshold: [0, visibilityPercentage],
      },
    );

    if (areScrollButtonsVisible) {
      for (const carouselItem of carouselItems) {
        intersectionObserver.observe(carouselItem);
      }
    }

    return () => {
      if (areScrollButtonsVisible) {
        for (const carouselItem of carouselItems) {
          intersectionObserver.unobserve(carouselItem);
        }
        intersectionObserver.disconnect();
      }
    };
  }, [areScrollButtonsVisible, itemsLength, isDesktop]);

  /** Function to scroll carousel to the left */
  const onGoBackwardClick: (() => void) | undefined = useMemo(
    () => getOnGoBackwardClick({
      areScrollButtonsVisible,
      carouselItemsState,
      carouselItemsContainer: carouselItemsContainerRef.current,
      isDesktop,
    }),
    [areScrollButtonsVisible, carouselItemsState, isDesktop],
  );

  /** Function to scroll carousel to the right */
  const onGoForwardClick: (() => void) | undefined = useMemo(
    () => getOnGoForwardClick({
      areScrollButtonsVisible,
      carouselItemsState,
      carouselItemsContainer: carouselItemsContainerRef.current,
      isDesktop,
    }),
    [areScrollButtonsVisible, carouselItemsState, isDesktop],
  );

  /** This flag is true if areBoxShadowsEnabled is true and there is at least one carousel item */
  const shouldRenderLeftBoxShadow: boolean = useMemo(
    () => checkShouldRenderLeftBoxShadow({ areBoxShadowsEnabled, itemsLength }),
    [areBoxShadowsEnabled, itemsLength],
  );

  /** This flag is true if areBoxShadowsEnabled is true and there are enough carousel items to fill the entire screen width */
  const shouldRenderRightBoxShadow: boolean = useMemo(
    () => checkShouldRenderRightBoxShadow({
      areBoxShadowsEnabled,
      maxAvailableScreenWidthPx: carouselItemsContainerWidthPx,
      carouselItems: carouselItemsContainerRef.current?.children,
    }),
    // Need to explicitly include dependency on items length
    [areBoxShadowsEnabled, carouselItemsContainerWidthPx, itemsLength], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    ...props,
    carouselItemsContainerRef,
    onGoBackwardClick,
    onGoForwardClick,
    areMobileScrollButtonsVisible,
    mobileScrollDotDetails,
    areDesktopScrollButtonsVisible,
    shouldRenderLeftBoxShadow,
    shouldRenderRightBoxShadow,
  };
};
