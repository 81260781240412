import cx from 'classnames';
import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Spinner } from '../../atoms/Spinner';
import AccountMenu from '../../molecules/AccountMenu';
import NavMenuItem from '../../molecules/NavMenuItem';
import { SignInBanner } from '../../molecules/SignInBanner';
import NavMenuList from '../../organisms/NavMenuList';
import styles from './AccountMenuBlock.module.scss';
import usePresenter from './AccountMenuBlock.presenter';
import customStyles from './Custom.module.scss';
import type { AccountMenuBlockCombinedProps } from './types';

// This component will be rendered on mobile devices whereas AccountMenu is rendered on desktop
const AccountMenuBlock: React.FC<AccountMenuBlockCombinedProps> = (props) => {
  const {
    hasCategoryMenuItemStates,
    accountMenu,
    isSignedIn,
    isSignedOut,
    className,
    navMenuList,
    isModalOpen,
    isSelectedNavMenuItem,
    myAccount,
    logOut,
    myTickets,
  } = usePresenter(props);

  const signInBannerView = isSignedOut && <SignInBanner />;

  const bottomNavMenuListView = !isSelectedNavMenuItem && isSignedIn
    ? (
      <div
        role='menu'
        className={styles.profileMenuList}
      >
        <NavMenuItem
          className={styles.myAccount}
          {...myAccount} />
        <NavMenuItem
          className={styles.myTickets}
          {...myTickets} />
        <NavMenuItem
          className={styles.logOut}
          {...logOut} />
      </div>
    )
    : signInBannerView;

  const pageTopRef = useRef<HTMLDivElement>(null);

  return (
    <Modal className={cx(styles.accountMenuBlock, customStyles.accountMenuBlock, className)}
      dialogClassName={customStyles.modalDialog}
      contentClassName={customStyles.modalContent}
      show={isModalOpen}
    >
      <Modal.Header className={customStyles.modalHeader}>
        <div className={cx(styles.topContent, styles.stickyTopContent)}>
          <AccountMenu
            className={styles.accountMenu}
            {...accountMenu}
            renderType='Element'
          />
        </div>
        <div
          ref={pageTopRef}
          className={cx(styles.topContent, 'topContentSpacer')}
        />
      </Modal.Header>
      <Modal.Body className={customStyles.modalBody}>
        {hasCategoryMenuItemStates
          ? (<>
            <NavMenuList
              className={styles.navMenuList}
              {...navMenuList}
              pageTopRef={pageTopRef} />
            {bottomNavMenuListView}
          </>)
          : <Spinner />
        }
      </Modal.Body>
    </Modal>
  );
};

export default AccountMenuBlock;
