import { useNavigate, useSearchParams } from 'react-router-dom';
import type { LimitedAccessModalPresenterProps, LimitedAccessModalProps } from './LimitedAccessModal.types';
import { useCallback, useContext, useEffect } from 'react';
import { AuthContext } from '../../../modules/auth';
import { useModalState } from '../../../modules/modals';
import { checkIsListingAccessible } from '../../../lib/listingUtils';
import { getEventUrl } from '../../../lib/eventUtils';

export const usePresenter = (props: LimitedAccessModalProps): LimitedAccessModalPresenterProps => {
  const navigate = useNavigate();
  const { accountCardDetails, isSignedIn } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const { listingDetailMetadata } = props;
  const { listingMetadata, eventMetadata } = listingDetailMetadata ?? {};
  const { tags: listingTags } = listingMetadata ?? {};
  const { tags: eventTags, id: eventId } = eventMetadata ?? {};

  // Modal state management for Limited Access Modal
  const {
    isModalOpen: isLimitedAccessModalShown,
    openModal: openLimitedAccessModal,
    closeModal: defaultCloseLimitedAccessModal,
  } = useModalState();

  /**
   * If the user is signed in but cannot access the listing, openLimitedAccessModal.
   * A modal indicating limited access is triggered.
   */
  useEffect(() => {
    if (
      isSignedIn &&
      listingDetailMetadata &&
      !checkIsListingAccessible({
        accountCardDetails,
        listingTags,
        eventTags,
      })
    ) {
      openLimitedAccessModal();
    }
  }, [accountCardDetails, eventTags, isSignedIn, listingDetailMetadata, listingTags, openLimitedAccessModal]);

  const closeLimitedAccessModal = useCallback(() => {
    defaultCloseLimitedAccessModal();
    if (!eventId) return;
    const eventUrl = getEventUrl(eventId);
    // Construct query string if currentRewardsUnit parameter exists
    // This preserves the rewards unit preference when redirecting
    const queryString = searchParams.has('currentRewardsUnit')
      ? `?currentRewardsUnit=${searchParams.get('currentRewardsUnit')}`
      : '';
    navigate(`${eventUrl}${queryString}`);
  }, [eventId, navigate, defaultCloseLimitedAccessModal, searchParams]);

  return {
    ...props,
    isLimitedAccessModalShown,
    closeLimitedAccessModal,
  };
};
