import cx from 'classnames';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClickAccessibilityProps } from '../../../lib/accessibility';
import { TopNavContext } from '../../../modules/topNav';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import styles from './NavMenuItem.module.scss';
import usePresenter from './NavMenuItem.presenter';
import getProps from './NavMenuItem.props';
import type { NavMenuItemCombinedProps } from './types';

const NavMenuItem: React.FC<NavMenuItemCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    backButton,
    title,
    nextButton,
    onClick,
    linkPath,
    newTab,
    expansionProps,
    dividerPosition = 'None',
    id,
    ariaLabel,
    ariaControls,
    ariaHasPopup,
    ariaExpanded,
  } = usePresenter(props);

  const { setActiveTopNavMenuType } = useContext(TopNavContext);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`navMenuItem${variantName}`];

  const dividerView = dividerPosition !== 'None'
    ? <Divider
      type='Horizontal'
      style='Thin'
      colour='Grey20'
    />
    : undefined;

  let componentView: JSX.Element;

  const navigate = useNavigate();

  const clickAction = () => {
    if (linkPath) {
      setActiveTopNavMenuType(undefined);
      if (newTab) {
        window.open(linkPath, '_blank');
      } else {
        navigate(linkPath);
      }
    }
    onClick?.();
  };

  const accessibilityProps = getClickAccessibilityProps({
    actions: {
      onClick: clickAction,
    }, addIndex: true, role: 'menuitem',
  });

  switch (variantName) {
    case 'Back': {
      componentView = (
        <div className={cx(currentStyle, className)}
          onClick={clickAction}
        >
          <div className={cx(styles.content, classes?.content)}
            {...accessibilityProps}
            id={id}
            aria-label={ariaLabel}
            aria-controls={ariaControls}
            aria-haspopup={ariaHasPopup}
            aria-expanded={ariaExpanded}
          >
            <Button
              className={cx(styles.backButton, classes?.backButton)}
              {...internalProps.backButton}
              {...backButton}
              classes={{ icon: styles.backButtonIcon }} />
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
          </div>
        </div>
      );
      break;
    }
    case 'Critical':
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}
          onClick={clickAction}
        >
          <div className={cx(styles.content, classes?.content)}
            {...accessibilityProps}
            id={id}
            aria-label={ariaLabel}
            aria-controls={ariaControls}
            aria-haspopup={ariaHasPopup}
            aria-expanded={ariaExpanded}
          >
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
          </div>
        </div>
      );
      break;
    }
    case 'Collapsed':
    case 'Next': {
      componentView = (
        <div className={cx(currentStyle, className)}
          onClick={clickAction}
        >
          <div className={cx(styles.content, classes?.content)}
            {...accessibilityProps}
            id={id}
            aria-label={ariaLabel}
            aria-controls={ariaControls}
            aria-haspopup={ariaHasPopup}
            aria-expanded={ariaExpanded}
          >
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
            <Button
              className={cx(styles.nextButton, classes?.nextButton)}
              {...internalProps.nextButton}
              {...nextButton}
              classes={{ icon: styles.nextButtonIcon }} />
          </div>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}
          onClick={clickAction}
        >
          <div className={cx(styles.content, classes?.content)}
            {...accessibilityProps}
            id={id}
            aria-label={ariaLabel}
            aria-controls={ariaControls}
            aria-haspopup={ariaHasPopup}
            aria-expanded={ariaExpanded}
          >
            <Text
              className={cx(styles.title, classes?.title)}
              {...internalProps.title}
              {...title} />
            <Button
              className={cx(styles.nextButton, classes?.nextButton)}
              {...internalProps.nextButton}
              {...nextButton}
              classes={{ icon: styles.nextButtonIcon }} />
          </div>
          {!!expansionProps && (
            expansionProps.expandedContent
          )}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return <>
    {dividerPosition === 'Top' && dividerView}
    {componentView}
    {dividerPosition === 'Bottom' && dividerView}
  </>;
};

export default NavMenuItem;
