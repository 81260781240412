import { useEffect, useRef } from 'react';
import type { StepItemPresenterProps, StepItemProps } from './StepItem.types';

export const usePresenter = (props: StepItemProps): StepItemPresenterProps => {
  const { stepItemState } = props;

  const isActive: boolean = stepItemState === 'active'; // Check if current step is in active state

  const stepItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (stepItemRef.current && isActive) {
      stepItemRef.current.focus();
    }
  }, [isActive]);

  return {
    ...props,
    isActive,
    stepItemRef,
  };
};
