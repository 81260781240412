import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HISTORY_STATE_PARAM, PAGE_TYPES, URLs } from '../../lib/constants';
import { addQueryParam } from '../../lib/util';
import type { AppendQueryParamsToUrlFunc, AppendQueryParamsToUrlOptions, UseAppendQueryParamsToUrlResponse } from './Navigation.types';
import { checkIfPathMatch, getCurrentHistoryState } from './Navigation.utils';

/** Hook to append new query parameters to the URL (current URL by default) */
export const useAppendQueryParamsToUrl = (): UseAppendQueryParamsToUrlResponse => {
  const navigate = useNavigate();

  const response: UseAppendQueryParamsToUrlResponse = useMemo(() => {
    const appendQueryParamsToUrl: AppendQueryParamsToUrlFunc = (queryParams: Record<string, string>, options?: AppendQueryParamsToUrlOptions) => {
      const { url: inputUrl, ...navigateOptions } = options ?? {};

      // Fallback to the current URL if no URL is provided
      const url: string = inputUrl ?? window.location.search;

      // Amend query string and navigate
      navigate(addQueryParam(url, queryParams, { skipEmptyParams: true }), { state: getCurrentHistoryState(), replace: true, ...navigateOptions });
    };

    return { appendQueryParamsToUrl };
  }, [navigate]);

  return response;
};

/** Hook that preserves history state for the current path name even when query string parameters change */
export const usePreserveHistoryState = (params?: { isDisabled: boolean; }): void => {
  const { isDisabled = false } = params ?? {};

  const location = useLocation();
  const navigate = useNavigate();

  const prevHistoryStateRef = useRef<unknown>();

  useEffect(() => {
    if (!isDisabled) {
      // If state is provided then store it and return
      if (location.state) {
        prevHistoryStateRef.current = location.state;
        return;
      }

      const query: URLSearchParams = new URLSearchParams(location.search);
      const historyStateJson: string | null = query.get(HISTORY_STATE_PARAM);

      // If state is provided in 'historyState' query string parameter then store it and return
      if (historyStateJson) {
        query.delete(HISTORY_STATE_PARAM);

        try {
          const historyState: unknown = JSON.parse(historyStateJson);
          if (historyState) {
            prevHistoryStateRef.current = historyState;
          }
        } catch {
          // Do nothing
        }
      }

      // If no state is provided but we have a previous state then navigate to the same location but add the previous state
      if (prevHistoryStateRef.current) {
        navigate({ ...location, search: query.toString() }, { state: prevHistoryStateRef.current, replace: true });
      }
    }
  }, [isDisabled, location, navigate]);
};

/** Returns page type for the current URL, e.g. 'all_events_page' for '/all-events' URL */
export const usePageType = (): string | undefined => {
  const { pathname } = useLocation();

  const pageType: string | undefined = useMemo(() => {
    // Loop through all known URL templates, e.g. '/all-events', '/events/:eventId', etc.
    // If a match for the current URL is found then return its corresponding page type.
    for (const [urlKey, urlTemplate] of Object.entries(URLs)) {
      if (checkIfPathMatch(pathname, urlTemplate, true)) {
        const typedUrlKey = urlKey as keyof typeof URLs;
        return PAGE_TYPES[typedUrlKey];
      }
    }

    return undefined;
  }, [pathname]);

  return pageType;
};
