import type { TFunction } from 'i18next';
import Cookies, { type CookieAttributes } from 'js-cookie';
import { useEffect, useLayoutEffect, useMemo, useState, type ReactNode } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import type { TextColourEnum } from '../components/atoms/Text';
import { getAccountCardImageUrl } from '../components/molecules/AccountCard';
import type { PresetFilterOption } from '../components/organisms/PresetFilter';
import type { Account, AccountCardDetail, AccountTags, AssociatedAccount } from '../modules/auth/types';
import { getTodaysDate } from '../modules/date/utils';
import i18n from '../modules/locale/i18n';
import type { DeliveryOption, Event, EventsQueryParams, Listing, ListingDetails, ListingsQueryParams, Venue } from '../modules/partnership';
import { DOMAIN, SESSION_COOKIE_NAME } from './config';
import { BREAKPOINTS, C1_BROKER_ID, CLEAR_CATEGORY_PARAM_FOR_TAGS, EVENT_TAGS, EXCLUSIVE_CATEGORY, IsIntegerRewardUnit, MAX_INTEGER, MIN_INTEGER, PROTECTED_ROUTES } from './constants';
import { checkAreTicketsLow, checkIsEventExclusive, checkIsEventSoldOut } from './eventUtils';
import { areObjectsEqual } from './objectUtils';
import type { EventStatus, HtmlElementSize, ListingDetailMetadata, MobileDeviceInfo, UnitDisplaySettings, WindowSize } from './types';

export interface TicketListingMapping {
  ticket_listing: string;
  ticket_details: string;
  delivery_method: string;
  confimation_page: string[];
  requiresShippingInfo?: string;
}

/**
 * Retrieves two-digit country code for the given three-digit country code.
 * If the provided country code is not found in the predefined map, it returns the original code.
 *
 * @param {string} code Three-digit country code.
 * @returns {string} Two-digit country code or the original code if not found.
 */
export const getTwoDigitsCountryCode = (code: string): string => {
  /** Map for converting three-digit country codes to two-digit country codes */
  const twoDigitsCountryCodeMap: Record<string, string> = {
    USA: 'US',
    CAN: 'CA',
  };

  // Return the corresponding two-digit code or the original code if not found
  return twoDigitsCountryCodeMap[code.toUpperCase()] || code;
};

/**
 * Checks if the provided country code corresponds to the US.
 *
 * @param {string} countryCode Country code to check (either 'USA' or 'US').
 * @returns {boolean} True if the country code matches 'USA' or 'US', false otherwise.
 */
export const handleCountryCodeUS = (countryCode: string): boolean => {
  // Check if the country code (in uppercase) matches 'USA' or 'US'
  return ['USA', 'US'].includes(countryCode.toUpperCase());
};

/**
 * Trims the postal code to five digits if the country code corresponds to the US.
 * If the country code is not 'USA' or 'US', the original postal code is returned.
 * @returns {string} Truncated postal code (if US) or the original postal code.
 */
export const getFiveDigitsZipCode = (params: {
  /** Country code to determine if truncation is necessary */
  countryCode: string;
  /** Postal code to potentially truncate */
  postalCode: string;
}): string => {
  const { countryCode, postalCode } = params;

  // If the country code corresponds to the US, return the first 5 digits of the postal code
  if (handleCountryCodeUS(countryCode)) {
    return postalCode.substring(0, 5);
  }

  // Return the full postal code for non-US countries
  return postalCode;
};

export const getQueryParams = (url: string): Record<string, string> => {
  const results: Record<string, string> = {};
  const queryParams = url.split('?');
  if (queryParams.length > 1) {
    const params = queryParams[1].split('&');

    for (let i = 0, len = params.length; i < len; i += 1) {
      const parts = params[i].split('=');
      if (parts.length > 1) {
        const [key, value] = parts;
        results[key] = value;
      }
    }
  }
  return results;
};

export const removeIgnoredParams = (params: Record<string, string>, ignoredParams: string[]): Record<string, string> => {
  ignoredParams.forEach(param => {
    params[param] = '';
  });

  return params;
};

export const mapQueryString = (payload: Record<string, unknown>, options?: { skipEmptyParams: boolean; }): string => {
  // By default do not skip empty params
  const { skipEmptyParams = false } = options ?? {};

  const payloadEntries: [string, unknown][] = skipEmptyParams
    ? Object.entries(payload).filter(([key, value]) => key && value)
    : Object.entries(payload);

  return payloadEntries
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const addQueryParam = (
  url: string,
  payload: Record<string, string>,
  options?: { skipEmptyParams: boolean; },
): string => {
  let queryParams = getQueryParams(url);
  queryParams = {
    ...queryParams,
    ...payload,
  };
  const queryString = mapQueryString(queryParams, options);
  const baseUrl = url.split('?')[0];
  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
};

export type DatePartsType = {
  [key in Intl.DateTimeFormatPartTypes]?: string;
};

export const formatDateToMonthDay = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.month} ${dateParts.day}`;
};

export const formatDateToApiDate = (date: Date, format: 'numeric' | '2-digit' = 'numeric'): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    day: format,
    month: format,
    year: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
};

export const formatDateToMonthDayYear = (date: Date, format: 'numeric' | '2-digit' = 'numeric'): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: format,
    year: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.month} ${dateParts.day}, ${dateParts.year}`;
};

export const getLocalDate = (time: string): string => {
  const localDate = new Date(time).toLocaleDateString('en-us', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  return localDate;
};

export const getLocalTime = (time: string): string => {
  const localTime = new Date(time).toLocaleTimeString('en-us', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  return localTime;
};

export enum DateFormats {
  // example: Fri, Jul 19 at 7:30pm
  TICKETINFO = 'ticketInfoFormat',
  // example: Jul 18, 2020
  CONFIRMATION = 'confirmationFormat',
}

/**
 * Formats a date to one of the following formats:
 * TICKETINFO:
 * Fri, Jul 19 at 7:30pm
 * CONFIRMATION:
 * Jul 18, 2020
 */
export function formatDate(
  dateAndTime: Date,
  t: TFunction,
  format: DateFormats,
): string {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
  })
    .formatToParts(dateAndTime)
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return t(`ticketInfo.dateTemplate.${format}`, {
    day_week: dateParts.weekday,
    month: dateParts.month,
    day: dateParts.day,
    hour: dateParts.hour,
    minute: dateParts.minute,
    period: dateParts.dayPeriod?.toLocaleLowerCase(),
    year: dateParts.year,
  });
}

export const isValidUrl = (input: string): boolean => {
  let url;
  try {
    url = new URL(input);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const handleDateFormat = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.weekday}, ${dateParts.month} ${dateParts.day}`;
};

export const handleTimeFormat = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
};

export const setEventDescription = (event: Event, mobile = false): string => {
  const eventDate = handleDateFormat(event.local_date);
  const eventTime = handleTimeFormat(event.local_date);

  if (mobile) {
    return ` ${eventDate} at ${eventTime} \n ${event.venue.name}\n ${event.venue.city} ${event.venue.state_code}`;
  }
  return ` ${eventDate} at ${eventTime} - ${event.venue.name}, ${event.venue.city} ${event.venue.state_code}`;
};

export const getWeekday = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
  }).format(new Date(date));
};

export const getWeekdayAndTime = (date: Date): string => {
  return `${getWeekday(date)} ${getLocalTime(date.toString())}`;
};

export const getYear = (date: Date): string => {
  return String(new Date(date).getFullYear());
};

export enum CheckoutSteps {
  CUSTOMERINFO = 'customerInfo',
  BILLINGINFO = 'billingInfo',
  SHIPPINGINFO = 'shippingInfo',
  PAYMENTINFO = 'paymentInfo',
  CONFIRMATION = 'confirmation',
}

/**
 * Gets loyalty unit tag for associated account's loyalty program, e.g. C1_MILES, C1_CASH_REWARDS, C1_POINTS
 * @returns {string} Loyalty unit tag for associated account's loyalty program, e.g. C1_MILES, C1_CASH_REWARDS, C1_POINTS
 */
export const getLoyaltyUnitTag = (associatedAccount: AssociatedAccount | undefined): string | undefined => {
  switch (associatedAccount?.loyalty_program?.loyalty_unit_name?.toLowerCase().replace(/ /g, '_')) {
    case 'miles': return 'C1_MILES';
    case 'cash':
    case 'cash_rewards':
    case 'rewards_cash': return 'C1_CASH_REWARDS';
    case 'points': return 'C1_POINTS';
    default: return undefined;
  }
};

/**
 * Gets program type tag for associated account's loyalty program, e.g. VENTURE
 * @returns {string} Program type tag for associated account's loyalty program, e.g. VENTURE
 */
export const getProgramTypeTag = (associatedAccount: AssociatedAccount | undefined): string | undefined => {
  return associatedAccount?.loyalty_program?.program_type_tag
    || associatedAccount?.loyalty_program?.program_type?.trim().toUpperCase().replace(/\s/g, '_')
    || undefined;
};

/**
 * Gets processing network tag for associated account's loyalty program, e.g. VISA
 * @returns {string} Processing network tag for associated account's loyalty program, e.g. VISA
 */
export const getProcessingNetworkTag = (associatedAccount: AssociatedAccount | undefined): string | undefined => {
  return associatedAccount?.loyalty_program?.processing_network?.toUpperCase() || undefined;
};

export const capitalize = (title: string): string => {
  return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
};

export const titleCase = (title: string): string => {
  return title.charAt(0).toUpperCase() + title.slice(1);
};


export enum RewardUnit {
  MILES = 'MILES',
  CASH_REWARDS = 'REWARDS CASH',
  CASH = 'CASH',
  POINTS = 'POINTS',
}

export const isValidPhoneNumber = (value: string): boolean => {
  return /^\d{10}$/.test(value);
};

export const removeWhiteSpacesFromString = (str: string): string => {
  return str.replace(/\s/g, '');
};

/**
 * Adjusts a given string value for decimal display.
 * If `useDecimals` is true, ensures the value has two decimal places.
 *
 * @param value - The value to format. Assumes it is already a string.
 * @param useDecimals - Boolean flag indicating whether to use decimal places.
 * @returns The formatted value as a string.
 *
 * !! Potential issue with inputs like '100.0.1'
 */
export const handleDecimalValuesForDisplay = (value: string, useDecimals: boolean): string => {
  const trimmedValue: string = value.trim();

  if (!useDecimals) {
    return trimmedValue;
  }

  const splitValues: Array<string | undefined> = trimmedValue.split('.');

  // Dot may appear maximum once
  if (![1, 2].includes(splitValues.length)) {
    return trimmedValue;
  }

  const firstPart: string = splitValues[0] || '0';

  // In case of integer input the second part will be undefined
  let secondPart: string = splitValues[1]?.substring(0, 2) || '00';

  if (secondPart.length === 1) {
    secondPart += '0';
  }

  return `${firstPart}.${secondPart}`;
};

/**
 * Formats cash price by adding 2 decimal places to non-whole dollar amounts.
 * For whole dollar amounts it returns value as an integer.
 * @param {string | number | undefined} cashPrice Cash price to be formatted.
 * @returns {string | undefined} Formatted cash price.
 * @throws If input cash price cannot be converted to a number.
 */
export const formatCashPrice = (cashPrice: string | number | undefined): string | undefined => {
  if (cashPrice === undefined || (typeof cashPrice === 'string' && !cashPrice.trim())) {
    return undefined;
  }

  const cashPriceAsNumber = Number(cashPrice);

  if (isNaN(cashPriceAsNumber)) {
    throw new Error('Invalid cash price');
  }

  // For whole dollar amounts do not add decimal places
  return cashPriceAsNumber % 1 === 0
    ? cashPriceAsNumber.toFixed(0)
    : cashPriceAsNumber.toFixed(2);
};

export const handleDecimalValuesForDisplayReturnFreeIfZero = (value: string, useDecimals: boolean, t: TFunction, displayFree?: boolean): string => {
  const retVal = handleDecimalValuesForDisplay(value, useDecimals);
  if (displayFree && retVal === '$0.00') {
    return t('breakdown.deliveryFeeFree');
  }
  return retVal;
};

/**
 * Formats a given value to a locale-specific string representation
 * Optionally, trims decimal values if `trimDecimals` is set to true.
 * @param value
 * @param trimDecimals
 * @returns Formatted value as a string.
 */
export const handleAmountFormattingString = (value: number | string | undefined, trimDecimals = false): string => {
  let valueAsNumber = Number(value);

  if (isNaN(valueAsNumber)) {
    return '';
  }

  if (trimDecimals) {
    valueAsNumber = Math.floor(valueAsNumber);
  }

  return valueAsNumber.toLocaleString();
};

/**
 * To remove commas from formattedNumber
 * @param formattedNumber
 * @returns number string without commas
 */
export const handleFormattedNumberToNumberString = (formattedNumber: string): string => {
  // removed commas from string
  return formattedNumber.replace(/[^0-9.]/g, '');
};

/**
 * Retrieves the display name for the loyalty unit from an account.
 * If `unit_display_name` is unavailable, falls back to `loyalty_unit_name`.
 *
 * @param account - The account containing loyalty program information. Can be undefined.
 * @returns The display unit name as a string.
 */
export const handleDisplayUnitName = (account: Account | undefined): string => {
  return account?.loyalty_program?.unit_display_name
    || account?.loyalty_program?.loyalty_unit_name
    || '';
};

export const getFaceValue = (listing: Listing | undefined, t: TFunction): string => {
  if (listing && listing?.face_value && listing.face_value > 0) {
    return t('ticketInfo.orderTotal.faceValuePresent', { amount: String(listing?.face_value) });
  }
  return t('ticketInfo.orderTotal.faceValueAbsent');
};

export const isEventGuestList = (tags: string[], stockType: string, deliveryId: number) => {
  return (tags.includes(EVENT_TAGS.GuestList)
    && stockType.toUpperCase() === 'HARD'
    && deliveryId === 8);
};

export const getTicketListingTranslationKey = (
  listingDetail: ListingDetails | ListingDetailMetadata | undefined,
  fieldName: string,
): string => {
  if (!listingDetail) {
    return '';
  }

  const event: Event = 'event' in listingDetail ? listingDetail.event : listingDetail.eventMetadata;
  const listing: Listing = 'listing' in listingDetail ? listingDetail.listing : listingDetail.listingMetadata;
  const { pricing, delivery_options: deliveryOptions } = listingDetail;

  if (isEventGuestList(event.tags || [], listing.stock_type.value, pricing.delivery.id)) {
    return 'preCheckoutDetails.guestListTicketNotes';
  }

  const deliveryMethod: string = deliveryOptions.some((deliveryOption: DeliveryOption) => deliveryOption.id === pricing.delivery.id)
    ? pricing.delivery.id.toString()
    : '';

  return `deliveryInformation.${listing.stock_type.value.toUpperCase()}.${deliveryMethod}.${fieldName}`;
};

export const getOrderDetailsTranslationKey = (
  stockType: string,
  deliveryMethod: string,
  fieldName: string,
): string => {
  return `deliveryInformation.${stockType && stockType.toUpperCase()}.${deliveryMethod}.${fieldName}`;
};

/**
 * Returns unit display settings based on the provided loyalty unit name.
 *
 * @param loyaltyUnitName - Lower cased loyalty unit name, e.g. 'miles', 'points', etc.
 * @returns The unit display settings as an object.
 */
export const getUnitDisplaySettings = (loyaltyUnitName: string | undefined): UnitDisplaySettings => {
  const isIntegerRewardUnit: boolean = loyaltyUnitName ? IsIntegerRewardUnit[loyaltyUnitName.toLowerCase()] : false;

  return {
    rewardSign: isIntegerRewardUnit ? '' : '$',
    useDecimals: !isIntegerRewardUnit,
  };
};

export const setUpdatedPhoneNumber = (phoneNumber: string): void => {
  window.sessionStorage.setItem('updatedPhoneNumber', phoneNumber);
};

export const getUpdatedPhoneNumber = (): string => {
  return window.sessionStorage.getItem('updatedPhoneNumber') || '';
};

/**
 * Safety check to ensure that switch statement has all possible cases defined.
 * If the code does not build here then it means that there are missing cases in the switch statement.
 */
export const getNonExhaustiveCasesInSwitchStatementError = (value: never): Error => {
  return new Error(`Non-exhaustive cases in a switch statement. Case is not defined for "${value}"`);
};

/**
 * Rounds number up or down.
 * Accepts arguments for 'amount', 'rounding' and either 'unitName' or 'isInteger'.
 */
export const round = (params: {
  amount: number;
  rounding: 'half-up' | 'up' | 'down';
} & ({
  /**
   * Lower cased unit name, e.g. 'miles', 'points', etc.
   * Miles and points are rounded to integers. All other units are rounded to 2 decimal places.
   */
  unitName: string | undefined;
} | {
  /** If true then the amount is rounded to integer. If false then the amount is rounded to 2 decimal places. */
  isInteger: boolean;
})): number => {
  const { amount, rounding } = params;

  /** If true then the amount is rounded to integer. If false then the amount is rounded to 2 decimal places. */
  const isInteger: boolean = 'isInteger' in params
    ? params.isInteger
    : !!params.unitName && IsIntegerRewardUnit[params.unitName.toLowerCase()]; // True only for miles and points units

  const multiplier: number = isInteger ? 1 : 100;
  const amountTimesMultiplier: number = amount * multiplier;

  switch (rounding) {
    case 'half-up': return Math.round(amountTimesMultiplier) / multiplier;
    case 'up': return Math.ceil(amountTimesMultiplier * (1 - Number.EPSILON)) / multiplier;
    case 'down': return Math.floor(amountTimesMultiplier * (1 + Number.EPSILON)) / multiplier;
  }
};

export const handleOrderTotalMiles = (amount: number, loyaltyUnitName?: string): number => {
  return round({ amount, rounding: 'half-up', unitName: loyaltyUnitName });
};

export const isValidDate = (date: Date = new Date()): boolean => {
  return isNaN(date.getTime()) ? false : true;
};

export const handleLocation = (venue: Venue): string => {
  const location: string[] = [];
  if (venue.name) {
    location.push(venue.name);
  }
  if (venue.city) {
    location.push(venue.city);
  }
  if (venue.state_code) {
    location.push(venue.state_code);
  }
  return location.join(', ');
};

export const handleLocationShort = (venue: Venue): string => {
  const location: string[] = [];
  if (venue.name) {
    location.push(venue.name);
  }
  if (venue.city) {
    location.push(venue.city);
  }
  if (venue.state_code) {
    location.push(venue.state_code);
  }
  return location.join(', ');
};

export const useProgressiveImage = (src) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    setSourceLoaded(null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourceLoaded;
};

export const useProgressiveFonts = (): boolean => {
  const [webFontsLoaded, setWebFontsLoaded] = useState(false);
  useEffect(() => {
    const areFontsReady = async () => {
      await document.fonts.ready;
      setWebFontsLoaded(true);
    };
    void areFontsReady();
  });

  return webFontsLoaded;
};

export const shouldShowTermsAndConditionsCheckbox = (ticket: ListingDetails): boolean => {
  // price breakdown is returned only when feature flag
  // is enabled, no other mechanism exists to check for
  // feature flags in this repo, at this point
  const hasCp1BrokerId: boolean = ticket?.listing.broker_id === C1_BROKER_ID;
  const isCp1Exclusive: boolean | undefined = checkIsEventExclusive(ticket?.event) && hasCp1BrokerId;
  const isNY = ticket?.event?.venue?.state_code === 'NY';
  const hasPriceBreakdown = !!ticket?.listing?.price_breakdown;

  return isNY && hasPriceBreakdown && !isCp1Exclusive;
};

const getWindowSize = (): WindowSize => ({
  isMobile: window.innerWidth < BREAKPOINTS.desktop,
  isTablet: window.innerWidth >= BREAKPOINTS.tablet && window.innerWidth < BREAKPOINTS.desktop,
  isDesktop: window.innerWidth >= BREAKPOINTS.desktop,
});

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>(() => getWindowSize());

  useEffect(() => {
    const onResize = () => {
      setWindowSize((prevWindowSize: WindowSize) => {
        const newWindowSize: WindowSize = getWindowSize();
        return areObjectsEqual(prevWindowSize, newWindowSize)
          ? prevWindowSize
          : newWindowSize;
      });
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return windowSize;
};

export const getMobileDeviceInfo = (): MobileDeviceInfo | undefined => {
  // iPhone
  // userAgent: "Mozilla/5.0 (iPhone; CPU iPhone OS 17_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.2 Mobile/15E148 Safari/604.1"
  // platform: "iPhone"
  // maxTouchPoints: 5

  // iPad 13+
  // userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Safari/605.1.15"
  // platform: "MacIntel"
  // maxTouchPoints: 5

  // Desktop Safari
  // userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Safari/605.1.15"
  // platform: "MacIntel"
  // maxTouchPoints: 0

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isMsStream: boolean = (window as any).MSStream;

  const isIPad: boolean = (/iPad/i.test(navigator.userAgent) || (/Mac/i.test(navigator.platform) && navigator.maxTouchPoints > 0)) && !isMsStream;
  const isOtherIOS: boolean = /iPhone|iPod/i.test(navigator.userAgent) && !isMsStream;
  if (isIPad || isOtherIOS) {
    return { isIOS: true, isIPad, isAndroid: false };
  }

  const isAndroid: boolean = /Mobi|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  if (isAndroid) {
    return { isIOS: false, isIPad: false, isAndroid: true };
  }

  return undefined;
};

export const useIsScrolledVertically = (): boolean => {
  const [isScrolledVertically, setIsScrolledVertically] = useState<boolean>(() => window.scrollY > 0);

  useEffect(() => {
    const onScroll = () => setIsScrolledVertically(window.scrollY > 0);

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return isScrolledVertically;
};

export const useHtmlElementSize = (params: {
  htmlElement: HTMLElement | null;
  extraDependency?: unknown;
}): HtmlElementSize => {
  const { htmlElement, extraDependency } = params;

  const [htmlElementSize, setHtmlElementSize] = useState<HtmlElementSize>(() => ({ width: 0, height: 0, scrollWidth: 0, scrollHeight: 0 }));

  useLayoutEffect(() => {
    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target === htmlElement && entry.borderBoxSize.length) {
          const { inlineSize: width, blockSize: height } = entry.borderBoxSize[0];
          const { scrollWidth, scrollHeight } = htmlElement;
          setHtmlElementSize({ width, height, scrollWidth, scrollHeight });
          break;
        }
      }
    });

    // Start observing HTML element
    if (htmlElement) {
      resizeObserver.observe(htmlElement);
    } else {
      setHtmlElementSize({ width: 0, height: 0, scrollWidth: 0, scrollHeight: 0 });
    }

    // Cleanup
    return () => {
      if (htmlElement) {
        resizeObserver.unobserve(htmlElement);
        resizeObserver.disconnect();
      }
    };
  }, [htmlElement, extraDependency]);

  return htmlElementSize;
};

export const getEventStatus = (event: Event, t: TFunction, shouldCheckForExclusiveEvents = false): EventStatus => {
  let statusColor: TextColourEnum | undefined = undefined;
  let status: string | undefined;

  if (checkIsEventSoldOut({ event, shouldCheckForExclusiveEvents })) {
    status = t('eventCard.soldOut');
    statusColor = 'Negative';
  } else if (checkAreTicketsLow(event)) {
    status = t('eventCard.lowTickets');
    statusColor = 'Warning';
  }
  return {
    statusLabel: status,
    statusColor,
  };
};

export const handleEventCardDateFormat = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    minute: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.weekday}, ${dateParts.month} ${dateParts.day}, ${dateParts.year} at ${dateParts.hour}:${dateParts.minute}${dateParts.dayPeriod?.toLowerCase()}`;
};

export const removeHtmlTags = (value?: string) => {
  const htmlTagRegex = /<[^>]*>/g;
  return value ? value.replace(htmlTagRegex, '') : '';
};

export const isVividEvent = (listing?: ListingDetails): boolean => {
  return !(listing?.listing?.broker_id === C1_BROKER_ID) || false;
};

export const isNYEvent = (listing?: ListingDetails): boolean => {
  return listing?.event?.venue?.state_code === 'NY';
};

interface MaintenanceWindow {
  start: Date;
  end: Date;
}

const MAINTENANCE_WINDOWS: MaintenanceWindow[] = [
  {
    // March 18 2025, 2:00 AM EDT to 6:00 AM EDT
    start: new Date(Date.UTC(2025, 2, 18, 6, 0)),
    end: new Date(Date.UTC(2025, 2, 18, 10, 0)),
  },
  {
    // April 15 2025, 2:00 AM EDT to 6:00 AM EDT
    start: new Date(Date.UTC(2025, 3, 15, 6, 0)),
    end: new Date(Date.UTC(2025, 3, 15, 10, 0)),
  },
  // Add more maintenance windows as needed
];

export const displayMaintenanceWindow = (): boolean => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes();
  const seconds = now.getUTCSeconds();
  const milliseconds = now.getUTCMilliseconds();
  const nowUtc = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));

  return MAINTENANCE_WINDOWS.some(window => 
    nowUtc >= window.start && nowUtc <= window.end,
  );
};

const getUserAgenet = (): string => {
  return navigator.userAgent.toLowerCase();
};

export const isAndroidDevice = (): boolean => {
  const userAgent = getUserAgenet();
  return userAgent.indexOf('android') > -1;
};

export const isSafariBrowser = (): boolean => {
  const userAgent = getUserAgenet();
  return /^((?!chrome|android).)*safari/i.test(userAgent);
};

export const isFirefoxBrowser = (): boolean => {
  const userAgent = getUserAgenet();
  return /firefox/i.test(userAgent);
};

/**
 * Function that converts a function into an intervalled function.
 * An intervalled function is a function that can be invoked at most once within the specified interval.
 *
 * Examples where interval is 5 seconds and the function was last invoked at 10:00:00:
 * - If function is invoked again at 10:00:03 (less than 5 seconds since the last invocation) then the function execution will be delayed by 2 seconds (i.e. 10:00:05 - 10:00:03 = 2 seconds).
 * - If function is invoked again at 10:00:07 (more than 5 seconds since the last invocation) then the function will be executed without delay. The next earliest invocation time will be 10:00:12 (i.e. 10:00:07 + 5 seconds = 10:00:12).
 *
 * @param func Function to be converted into an intervalled function.
 * @param intervalMs Minimum interval between function invocations (in milliseconds).
 * @returns Intervalled function.
 */
export const getIntervalledFunc = <TFunc extends ((...args: unknown[]) => void)>(func: TFunc, intervalMs: number): TFunc => {
  const getCurrentTime = () => new Date().getTime();

  let earliestAllowedExecutionTime: number = getCurrentTime() + intervalMs;
  let timer: NodeJS.Timeout | undefined;

  const intervalledFunc = ((...args: unknown[]) => {
    if (earliestAllowedExecutionTime <= getCurrentTime()) {
      func(...args);
      earliestAllowedExecutionTime = getCurrentTime() + intervalMs;
    } else {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        func(...args);
        earliestAllowedExecutionTime = getCurrentTime() + intervalMs;
      }, Math.max(0, earliestAllowedExecutionTime - getCurrentTime()));
    }
  }) as TFunc;

  return intervalledFunc;
};

/** Executes the provided function with setTimeout with 0 delay */
export const runInNextCycle = (func: (() => void)): void => {
  setTimeout(func, 0);
};

/** Function that removes focus from the currently focused element on the page */
export const blurFocusedElement = (): void => {
  (document.activeElement as HTMLElement | undefined)?.blur?.();
};

export const isNotNullOrUndefined = <TValue>(value: TValue): value is NonNullable<TValue> => {
  return value !== null && value !== undefined;
};

/** Returns true only for valid numbers */
export const isNumber = (num: unknown): num is number => {
  return typeof num === 'number' && !isNaN(num);
};

/**
 * Parses provided value and returns it as a number if it can be parsed as a number. Otherwise returns undefined.
 * @param {string | number | null | undefined} value Value to be parsed. Could be string, number, null or undefined.
 * @returns {number | undefined} Parsed value as a number or undefined if provided value cannot be parsed as a number.
 */
export const parseNumber = (
  value: string | number | null | undefined,
  options?: {
    minValue?: number | null;
    maxValue?: number | null;
    allowDecimals?: boolean;
  },
): number | undefined => {
  if (value === null || value === undefined) {
    return undefined;
  }

  let parsedNumber: number | undefined;

  if (typeof value === 'number') {
    parsedNumber = value;
  } else if (typeof value === 'string') {
    const trimmedValue: string = value.trim();
    if (trimmedValue) {
      parsedNumber = Number(trimmedValue);
    }
  }

  // Check that value is a valid number
  if (!isNumber(parsedNumber)) {
    return undefined;
  }

  // Check that value is equal or greater than min value
  const minValue: number = typeof options?.minValue === 'number' ? Math.max(MIN_INTEGER, options.minValue) : MIN_INTEGER; // -2,147,483,648
  if (parsedNumber < minValue) {
    return undefined;
  }

  // Check that value is equal or less than max value
  const maxValue: number = typeof options?.maxValue === 'number' ? Math.min(MAX_INTEGER, options.maxValue) : MAX_INTEGER; // 2,147,483,647
  if (parsedNumber > maxValue) {
    return undefined;
  }

  // If needed, check that value is a valid integer
  if (!options?.allowDecimals && (parsedNumber % 1) !== 0) {
    return undefined;
  }

  return parsedNumber;
};

/** Hook to extract a query parameter by its name from the current URL and trim its value */
export const useQueryParamFromUrl = <TValue extends string = string>(
  queryParamName: string,
  options?: {
    allowedValues: TValue[];
  },
): TValue | undefined => {
  const { search } = useLocation();

  const queryParams: Record<string, string> = useMemo(() => getQueryParams(search), [search]);
  const queryParam: string | undefined = useMemo(() => queryParams[queryParamName]?.trim(), [queryParams, queryParamName]);

  // If allowed values are provided then validate that query parameter value is one of allowed values.
  // Return undefined if query parameter value is not allowed.
  if (queryParam && options?.allowedValues && !options.allowedValues.includes(queryParam as TValue)) {
    return undefined;
  }

  return queryParam as TValue;
};

/** Hook to extract a numeric query parameter by its name from the current URL */
export const useNumericQueryParamFromUrl = (
  queryParamName: string,
  options?: {
    minValue?: number | null;
    maxValue?: number | null;
    allowDecimals?: boolean;
    shouldRemoveIfInvalid?: boolean;
  },
): number | undefined => {
  const navigate = useNavigate();
  const { search, state } = useLocation();

  const queryParam: string | undefined = useQueryParamFromUrl(queryParamName);

  const numericQueryParam: number | undefined = useMemo(
    () => parseNumber(queryParam, { minValue: options?.minValue, maxValue: options?.maxValue, allowDecimals: options?.allowDecimals }),
    [queryParam, options?.minValue, options?.maxValue, options?.allowDecimals],
  );

  // Remove invalid numeric query parameter from the current URL
  useEffect(() => {
    if (queryParam && (options?.shouldRemoveIfInvalid ?? true) && numericQueryParam === undefined) {
      const newQueryString: string = addQueryParam(search, { [queryParamName]: '' }, { skipEmptyParams: true });
      navigate({ search: newQueryString }, { state, replace: true });
    }
  }, [queryParam, options?.shouldRemoveIfInvalid, numericQueryParam, search, state, queryParamName, navigate]);

  return numericQueryParam;
};

export const getTranslatedUnitName = (unitName: string): string => {
  const lowerCaseUnitName: string = unitName.toLowerCase().replace(/ /g, '_');
  const translationKey: string = `rewardUnitNames.${lowerCaseUnitName}`;
  return i18n.exists(translationKey) ? i18n.t(translationKey) : unitName;
};

/**
 * Formats the provided number so that:
 * 1. If number is integer then this number is formatted as locale formatted string, e.g. 123456 => '123,456'.
 * 2. If number is not integer then this number is trimmed to exactly 2 decimal points and then formatted as locale formatted string, e.g. 123456.7 => '123,456.70', 123456.789 => '123,456.79'.
 *
 * If unit name is not in ['noUnits', 'miles', 'points'] then the result is prepended with '$', e.g. '$123,456.78', '$123,456.78 rewards cash'.
 *
 * If unit name is not in ['noUnits', 'dollars', 'dollarsWithCents'] then the unit name is appended to the result, e.g. '123,456 miles', '123,456 points'.
 *
 * @returns {string} Formatted number as locale formatted string.
 */
export const formatNumberToLocaleString = (params: {
  /** Number to be formatted */
  num: number;
  /** Unit name, e.g. 'noUnits', 'dollars', 'dollarsWithCents', 'miles', 'points', etc. */
  unitName: 'noUnits' | 'dollars' | 'dollarsWithCents' | (string & {}) | undefined;
  /** Indicates if unit name should be included in the result */
  shouldIncludeUnitName: boolean;
}): string => {
  const { num, unitName = 'noUnits', shouldIncludeUnitName } = params;

  /**
   * - True only for 'miles' and 'points' units.
   * - False only for 'cash' and 'rewards cash' units.
   * - Undefined for everything else, e.g. 'noUnits', 'dollars', 'dollarsWithCents'.
   */
  const isIntegerRewardUnit: boolean | undefined = IsIntegerRewardUnit[unitName.toLowerCase()];

  /** 'miles' and 'points' are not dollar amounts so do not prepend '$' sign */
  const isCurrencyUnitName: boolean = unitName !== 'noUnits' && !isIntegerRewardUnit;

  let numberFormatOptions: Intl.NumberFormatOptions = isCurrencyUnitName
    ? { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }
    : {};

  /**
   * - Unit name 'dollarsWithCents' instructs to always add 2 decimal points.
   * - 'miles' and 'points' must always be rounded to integer values.
   * - 'cash' and 'rewards cash' must always have 2 decimal points.
   * - For all other units if there are no cents then round to integer values, otherwise to 2 decimal points.
   */
  const isInteger: boolean = unitName !== 'dollarsWithCents' && (isIntegerRewardUnit === true || (isIntegerRewardUnit === undefined && num % 1 === 0));
  const decimalPoints: 0 | 2 = isInteger ? 0 : 2;

  numberFormatOptions = {
    ...numberFormatOptions,
    minimumFractionDigits: decimalPoints,
    maximumFractionDigits: decimalPoints,
  };

  const formattedNumber: string = num.toLocaleString('en-US', numberFormatOptions);

  // 'noUnits', 'dollars' and 'dollarsWithCents' unit names should not be displayed
  const formattedUnitName: string = !shouldIncludeUnitName || ['noUnits', 'dollars', 'dollarsWithCents'].includes(unitName)
    ? ''
    : getTranslatedUnitName(unitName);

  return `${formattedNumber} ${formattedUnitName}`.trim();
};

/**
 * Processes raw HTML content:
 * 1. If content is string then handles new line characters, double quotes and list items <li>.
 * 2. In all other cases returns initial content as-is.
 * @returns {ReactNode} Processed HTML content content.
 */
export const processRawHtmlContent = (params: {
  /** Initial raw HTML content that may contain HTML tags */
  initialRawHtmlContent: ReactNode;
}): ReactNode => {
  const { initialRawHtmlContent } = params;

  if (typeof initialRawHtmlContent === 'string') {
    return initialRawHtmlContent
      .replace(/\\n/g, '\n') // Handle new line characters
      .replace(/\\"/g, '"') // Handle double quotes
      .replace(/<li>[ ]*●[ ]*/g, '<li>'); // Remove dots from list items <li> because <li> adds "list-style: disc"
  }

  return initialRawHtmlContent;
};

export const toHtmlId = (value: string): string => {
  return `id-${value.toLowerCase().replace(/[^a-zA-Z0-9\-_]/g, '-')}`;
};

/**
 * Checks if the given array of numbers forms a continuous sequence without duplicates or gaps.
 * @param {number[]} numbers - The array of numbers to check.
 * @returns {boolean} True if the numbers form a continuous sequence; otherwise, false.
 */
export const checkAreNumbersInSequence = (numbers: number[]): boolean => {
  // Iterate through the array up to the second to last element
  for (let i = 0; i < numbers.length - 1; i++) {
    if (numbers[i] !== (numbers[i + 1] - 1)) {
      return false; // Return false if a duplicate or gap is found
    }
  }

  // If no gaps or duplicates are found, return true
  return true;
};

export const delay = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const checkIsProtectedRoute = (pathname: string): boolean => {
  return PROTECTED_ROUTES.some((route) => matchPath(route, pathname));
};

export const getSessionCookie = (): string | undefined => {
  const sessionCookieName: string = SESSION_COOKIE_NAME;

  return Cookies.get(sessionCookieName) || Cookies.get(`${sessionCookieName}-legacy`);
};

export const createSessionCookie = (params: { jwtToken: string; }): void => {
  const { jwtToken } = params;

  const sessionCookieName: string = SESSION_COOKIE_NAME;

  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + 45 * 60 * 1000); // 45 minutes

  const cookieAttributes: CookieAttributes = {
    path: '/',
    secure: true,
    sameSite: 'none',
    expires: expiryDate,
  };

  Cookies.set(sessionCookieName, jwtToken, cookieAttributes);
  Cookies.set(`${sessionCookieName}-legacy`, jwtToken, cookieAttributes);
};

export const removeSessionCookie = (): void => {
  const sessionCookieName: string = SESSION_COOKIE_NAME;

  Cookies.remove(sessionCookieName);
  Cookies.remove(sessionCookieName, { domain: DOMAIN, secure: true });

  Cookies.remove(`${sessionCookieName}-legacy`);
  Cookies.remove(`${sessionCookieName}-legacy`, { domain: DOMAIN, secure: true });
};

/** Converts a given string to lower case and replaces all spaces with underscores */
export const convertStringToSnakeCase = (stringValue: string): string => {
  return stringValue.toLowerCase().replace(/\s+/g, '_');
};

/**
 * Creates an OR filter string from an array of values.
 * - Returns a single value directly if the array has only one value.
 * - Returns an empty string if the array is empty or undefined.
 * - Wraps multiple values in parentheses, joined by 'OR'.
 * @example
 * // Example: Multiple values in array
 * generateORTagFilterString(['tag1', 'tag2', 'tag3']);
 * // Returns: "(tag1 OR tag2 OR tag3)"
 */
export const generateORTagFilterString = (tags: readonly string[] | undefined): string => {
  // Check if the array is empty or undefined, return an empty string
  if (!tags?.length) {
    return '';
  }

  // If the array has only one value, return the value directly without parentheses
  if (tags.length === 1) {
    return tags[0];
  }

  // For multiple values, join them with ' OR ' and wrap them in parentheses
  return `(${tags.join(' OR ')})`;
};

/**
 * Creates an AND filter string from an array of values.
 * @example
 * // Example: Multiple values in array
 * generateANDTagFilterString(['tag1', 'tag2', 'tag3']);
 * // Returns: "tag1 AND tag2 AND tag3"
 */
export const generateANDTagFilterString = (tags: readonly string[] | undefined): string => {
  // Check if the array is empty or undefined, return an empty string
  if (!tags?.length) {
    return '';
  }

  // For multiple values, join them with ' AND '
  return tags.join(' AND ');
};

/**
 * Helper function to safely append account tags to an existing filter string.
 * - Uses the `generateORTagFilterString` function to convert account tags into an OR condition.
 * - If the generated OR condition is not empty, it appends it to the current filter tags array.
 */
export const appendAccountTagsToTagFilter = (params: {
  /** The current array of filter tags to which new tags will be added */
  currentFilterTags: string[];
  /** The array of account tags to be appended, converted into an OR condition */
  accountTags: readonly string[];
}): void => {
  const { currentFilterTags, accountTags } = params;

  const tag: string = generateORTagFilterString(accountTags);

  // If a valid OR tag is generated, append it to the current filter tags
  if (tag) {
    currentFilterTags.push(tag);
  }
};

/**
 * Function that appends tag and secondary tag filter parameters for the /events API request.
 * - This function modifies the event query parameters by adding tag filters, including optional secondary tag filters and specific event exclusions based on conditions.
 * - It supports conditional inclusion of secondary tag filters, exclusion of collapsed events, and handling special cases for C1X landing pages.
 * @returns {EventsQueryParams} - The input query parameters, now updated with appended tag and secondary tag filters.
 */
export const addTagFilterToEventQueryParams = (params: {
  /** Input parameters (query params) to which tag filters will be appended */
  eventQueryParams: EventsQueryParams;
  /** Object that contains loyalty, program, and processing network tags for the account */
  accountTags: AccountTags;
  /** (Optional) If true, adds 'NOT C1_EXCLUSIVE' to the secondary tag filter */
  includeSecondaryTagFilter?: boolean;
  /** (Optional) If true and the event is related to Sports, adds 'NOT COLLAPSED' to the filter */
  isC1XLandingPage?: boolean;
  /** (Optional) If true, adds 'NOT COLLAPSED' to exclude events marked for exclusion from the frontend display */
  shouldExcludeCollapsedEvents?: boolean;
}): EventsQueryParams => {
  const {
    eventQueryParams,
    accountTags,
    includeSecondaryTagFilter,
    isC1XLandingPage,
    shouldExcludeCollapsedEvents,
  } = params;

  const { accountLoyaltyUnitTags, accountProgramTypeTags, accountProcessingNetworkTags } = accountTags;

  // Initialize tag filters array with the default 'C1_EXCLUSIVE' tag
  const tagFilters: string[] = [EVENT_TAGS.Exclusive];

  // Append loyalty unit tags to tag filters
  appendAccountTagsToTagFilter({ currentFilterTags: tagFilters, accountTags: accountLoyaltyUnitTags });
  // Append program type tags to tag filters
  appendAccountTagsToTagFilter({ currentFilterTags: tagFilters, accountTags: accountProgramTypeTags });
  // Append processing network tags to tag filters
  appendAccountTagsToTagFilter({ currentFilterTags: tagFilters, accountTags: accountProcessingNetworkTags });

  // Add 'NOT COLLAPSED' tag if the event should exclude collapsed events
  if (shouldExcludeCollapsedEvents) {
    tagFilters.push(EVENT_TAGS.NotCollapsed);
  }

  /** Flag to determine if we need to clear the category parameter in the event query */
  let shouldClearCategoryParam: boolean = false;

  // Iterate over each parameter in the event query params and append relevant tags
  for (const param in eventQueryParams) {
    const paramValue = eventQueryParams[param] as string;

    // Skip if the parameter value is not part of the CLEAR_CATEGORY_PARAM_FOR_TAGS set
    if (!CLEAR_CATEGORY_PARAM_FOR_TAGS.includes(paramValue)) {
      continue;
    }

    // If this parameter value triggers a category clear, set the flag
    shouldClearCategoryParam = true;

    // Append the current parameter value as a tag if it's not the EXCLUSIVE_CATEGORY
    if (paramValue !== EXCLUSIVE_CATEGORY) {
      tagFilters.push(paramValue);
    }

    // Special case for Sports category on C1X landing page: add 'NOT COLLAPSED' tag if not excluded
    if (paramValue === EVENT_TAGS.Sports && isC1XLandingPage && !shouldExcludeCollapsedEvents) {
      tagFilters.push(EVENT_TAGS.NotCollapsed);
    }
  }

  const updatedEventQueryParams: EventsQueryParams = {
    ...eventQueryParams,
    tag_filter: generateANDTagFilterString(tagFilters),
    ...(shouldClearCategoryParam ? { category: '' } : {}),
    ...(includeSecondaryTagFilter ? { secondary_tag_filter: EVENT_TAGS.NotExclusive } : {}),
    ...(!eventQueryParams.date_start ? { date_start: formatDateToApiDate(getTodaysDate(), '2-digit') } : {}),
  };

  return updatedEventQueryParams;
};

/**
 * Function to append tag filters to the listings query parameters.
 * @returns {ListingsQueryParams} - The updated listings query parameters with the appended tag filter.
 */
export const addTagFilterToListingsQueryParams = (params: {
  /** Input parameters (query params) to which tag filters will be appended */
  listingsQueryParams: ListingsQueryParams;
  /** Object that contains loyalty, program, and processing network tags for the account */
  accountTags: AccountTags;
}): ListingsQueryParams => {
  const { listingsQueryParams, accountTags } = params;

  const { accountProgramTypeTags } = accountTags;

  // Create the updated listings query parameters by adding the tag filter based on account program type tags.
  return {
    ...listingsQueryParams,
    tag_filter: generateORTagFilterString(accountProgramTypeTags), // Add the OR tag filter string
  };
};

/** Generates a tag filter string for search queries by combining various account tags */
export const generateTagFilterForSearch = (params: {
  /** Object that contains loyalty, program, and processing network tags for the account */
  accountTags: AccountTags;
}): string => {
  const { accountTags } = params;

  const { accountLoyaltyUnitTags, accountProgramTypeTags, accountProcessingNetworkTags } = accountTags;

  // Initialize an empty array to hold the tag filters.
  const tagFilters: string[] = [];

  // Append loyalty unit tags to tag filters
  appendAccountTagsToTagFilter({ currentFilterTags: tagFilters, accountTags: accountLoyaltyUnitTags });
  // Append program type tags to tag filters
  appendAccountTagsToTagFilter({ currentFilterTags: tagFilters, accountTags: accountProgramTypeTags });
  // Append processing network tags to tag filters
  appendAccountTagsToTagFilter({ currentFilterTags: tagFilters, accountTags: accountProcessingNetworkTags });

  /**
   * As per VS API documentation for /search and /search-suggestions endpoint, "tag_filter" parameter allows parentheses for "OR" grouping, e.g. "TAG1 AND (TAG2 OR TAG3)".
   * But if its value starts with opening parenthesis "(" then VS APIs return 400 Bad Request, e.g. "(TAG2 OR TAG3) AND TAG1" does not work.
   * To work around it, if we detect that the first "OR" group starts with opening parenthesis "(" then we prepend "CAPITAL_ONE" tag.
   * So "(TAG2 OR TAG3) AND TAG1" becomes "CAPITAL_ONE AND (TAG2 OR TAG3) AND TAG1" (which VS APIs accept).
   * "CAPITAL_ONE" tag is present on all required events so adding it to the tag filter does not cause any consequences.
   */
  if (tagFilters[0]?.startsWith('(')) {
    tagFilters.unshift(EVENT_TAGS.CapitalOne);
  }

  return generateANDTagFilterString(tagFilters);
};

/**
 * Formats a string by trimming it, converting to uppercase, and replacing underscores with spaces.
 * @param {string | undefined} input - The input string to format.
 */
export const formatStringToUpperSpace = (input: string | undefined): string => {
  if (!input) {
    return '';
  }
  return input.trim().toUpperCase().replace(/_/g, ' ');
};

/**
 * Generates filter options for account card types based on the provided account card details.
 * This function maps each account card detail to a preset filter option, which includes the
 * card's last four digits, total reward units, and an associated image URL.
 * If undefined or empty, the function will return an empty array.
 * If no account card details are provided or the array is empty, it returns an empty array.
 */
export const getCardTypeFilterOptions = (params: {
  accountCardDetails: AccountCardDetail[] | undefined;
}): PresetFilterOption[] => {
  const { accountCardDetails } = params;

  if (!accountCardDetails?.length) {
    return [];
  }

  return accountCardDetails.map((accountCardDetail, index) => ({
    id: index,
    name: `${formatStringToUpperSpace(accountCardDetail.accountProgramType)} •••• ${accountCardDetail.accountCardLastFourDigits}`,
    imageUrl: getAccountCardImageUrl({ accountCardImageUrl: accountCardDetail.accountCardImageUrl, theme: 'subduedDark' }),
  }));
};

export const getStringFromReactNode = (variable: ReactNode): string | undefined => {
  return typeof variable === 'string' ? variable : undefined;
};

export const useScrollToTop = () => {
  function scrollToTop() {
    let position = window.scrollY;
    const step = () => {
      if (position > 0) {
        position -= 50;
        window.scrollTo(0, position);
        requestAnimationFrame(step);
      }
    };
    step();
  }

  useEffect(() => {
    scrollToTop();
  }, []);
};
