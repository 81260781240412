import { SyntheticEvent, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TopNavContext } from '../../../modules/topNav';
import type { SubmenuItemCombinedProps } from './types';

const usePresenter = (props: SubmenuItemCombinedProps): SubmenuItemCombinedProps => {
  const { linkPath, externalLink, onItemClicked: initialOnItemClicked } = props;

  const navigate = useNavigate();

  const { setActiveTopNavMenuType } = useContext(TopNavContext);

  const onItemClicked = useCallback((event: SyntheticEvent) => {
    event.preventDefault();

    if (linkPath) {
      if (externalLink) {
        window.open(linkPath, '_blank');
      } else {
        navigate(linkPath);
      }

      setActiveTopNavMenuType(undefined);
    }

    initialOnItemClicked?.(event);
  }, [linkPath, externalLink, initialOnItemClicked, navigate, setActiveTopNavMenuType]);

  return {
    ...props,
    onItemClicked,
  };
};

export default usePresenter;
