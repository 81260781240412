import axios from 'axios';
import { BFF_URL } from '../../lib/config';
import { ApiError } from '../error/types';
import { handleError } from '../error/utils';
import { resetTimer } from '../userIdle/sessionTimeout';

import type {
  CategoriesPageResponse,
  Event,
  EventPageResponse,
  EventsQueryParams,
  ListingDetails,
  ListingDetailsQueryParams,
  ListingsQueryParams,
  ListingsResponse,
  LoyaltyCurrencies,
  MarketingOptinMlbPayload,
  MarketingOptinMlbResponse,
  Order,
  OrderResponse,
  PageQueryParams,
  PaymentTokenResponse,
  Performer,
  PerformerDetail,
  PerformerPageResponse,
  PerformersQueryParams,
  Region,
  RegionsQueryParams,
  SearchPageResponse,
  SearchQueryParams,
  SearchSuggestionsQueryParams,
  SearchSuggestionsResponse,
  SvgMapData,
  TopNavPerformersResponse,
  UserOrdersResponse,
  Venue,
} from './types';
import { filerSearchResults, filterEventPage, filterSearchSuggestions, matchesEvent, matchesListingDetails, matchesPerformer } from './utils';

const stripEmptyParams = (params) => {
  if (!params) {
    return undefined;
  }
  const results = {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      results[key] = value;
    }
  });
  return results;
};

export const getPerformers = async (
  params?: PerformersQueryParams,
): Promise<PerformerPageResponse> => {
  try {
    const { data } = await axios.get<PerformerPageResponse>(
      `${BFF_URL}/performers`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTopPerformers = async (
  params?: PerformersQueryParams,
): Promise<Performer[]> => {
  try {
    const { data } = await axios.get<Performer[]>(
      `${BFF_URL}/performers/top`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPerformer = async (performerId: string): Promise<PerformerDetail> => {
  try {
    const { data } = await axios.get<PerformerDetail>(
      `${BFF_URL}/performers/${performerId}`,
      {
        withCredentials: true,
      },
    );
    if (matchesPerformer(data)) {
      throw new ApiError({
        code: 404,
        type: 'not found',
        message: 'not found',
      });
    }
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPerformerOpponents = async (
  performerId: string,
): Promise<PerformerPageResponse> => {
  try {
    const { data } = await axios.get<PerformerPageResponse>(
      `${BFF_URL}/performers/${performerId}/opponents`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getEvents = async (params?: EventsQueryParams): Promise<EventPageResponse> => {
  try {
    const { data } = await axios.get<EventPageResponse>(
      `${BFF_URL}/events`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return filterEventPage(data);
  } catch (error) {
    throw handleError(error);
  }
};

export const getEvent = async (eventId: string): Promise<Event> => {
  try {
    const { data } = await axios.get<Event>(
      `${BFF_URL}/events/${eventId}`,
      {
        withCredentials: true,
      },
    );
    if (matchesEvent(data)) {
      throw new ApiError({
        code: 404,
        type: 'not found',
        message: 'not found',
      });
    }
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRegions = async (params?: RegionsQueryParams): Promise<Region[]> => {
  try {
    const { data } = await axios.get<Region[]>(
      `${BFF_URL}/regions`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRegionsByIp = async (): Promise<Region[]> => {
  try {
    const { data } = await axios.get<Region[]>(
      `${BFF_URL}/regions/by_ip`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getVenue = async (venueId: string): Promise<Venue> => {
  try {
    const { data } = await axios.get<Venue>(
      `${BFF_URL}/venues/${venueId}`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSearch = async (params: SearchQueryParams): Promise<SearchPageResponse> => {
  try {
    const { data } = await axios.get<SearchPageResponse>(
      `${BFF_URL}/search`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return filerSearchResults(data);
  } catch (error) {
    throw handleError(error);
  }
};

export const getSearchSuggestions = async (
  params: SearchSuggestionsQueryParams,
): Promise<SearchSuggestionsResponse> => {
  try {
    const { data } = await axios.get<SearchSuggestionsResponse>(
      `${BFF_URL}/search-suggestions`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return filterSearchSuggestions(data);
  } catch (error) {
    throw handleError(error);
  }
};

export const getListings = async (params: ListingsQueryParams): Promise<ListingsResponse> => {
  try {
    const { data } = await axios.get<ListingsResponse>(
      `${BFF_URL}/listings`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

/**
 * Retrieves detailed information about a specific listing for an event.
 * 
 * @param eventId - The unique identifier of the event
 * @param listingId - The unique identifier of the listing within the event
 * @param params - Query parameters for filtering and customizing the listing details
 * @param quoteOptions - Optional object containing account IDs for retrieving account-specific pricing
 * @param jwtToken - Optional JWT token for authenticated requests
 * @returns Promise<ListingDetails> - A promise that resolves to the listing details
 * @throws {ApiError} - Throws a 404 error if the listing is not found
 *                      or other API-related errors that are handled by handleError
 */
export const getListingDetails = async (
  eventId: string,
  listingId: string,
  params: ListingDetailsQueryParams,
  associatedAccountJwt?: string,
): Promise<ListingDetails> => {
  try {
    const headers = associatedAccountJwt ? { Authorization: `Bearer ${associatedAccountJwt}` } : {};

    // Make the API request to get listing details
    const { data } = await axios.get<ListingDetails>(
      `${BFF_URL}/events/${eventId}/listings/${listingId}`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
        headers,
      },
    );

    // Validate the response data matches the expected structure
    if (matchesListingDetails(data)) {
      throw new ApiError({
        code: 404,
        type: 'not found',
        message: 'not found',
      });
    }
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPaymentToken = async (): Promise<PaymentTokenResponse> => {
  try {
    const { data } = await axios.get<PaymentTokenResponse>(
      `${BFF_URL}/payment/client-token`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createOrder = async (params: {
  orderRequest: Order;
  associatedAccountJwt: string;
}): Promise<OrderResponse> => {
  try {
    const { data } = await axios.post<OrderResponse>(
      `${BFF_URL}/orders`,
      params.orderRequest,
      {
        headers: {
          Authorization: `Bearer ${params.associatedAccountJwt}`,
        },
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyOrders = async (): Promise<UserOrdersResponse> => {
  try {
    const { data } = await axios.get<UserOrdersResponse>(
      `${BFF_URL}/user/orders`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getFilesByName = async (params: { orderId?: string, fileName?: string; }): Promise<UserOrdersResponse> => {
  try {
    const { data } = await axios.get<UserOrdersResponse>(
      `${BFF_URL}/orders/${params.orderId}/ticket/${params.fileName}`,
      {
        withCredentials: true,
      },
    );
    resetTimer();
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getCategories = async (
  params: PageQueryParams,
): Promise<CategoriesPageResponse> => {
  try {
    const { data } = await axios.get<CategoriesPageResponse>(
      `${BFF_URL}/categories`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const marketingOptinMlb = async (payload: MarketingOptinMlbPayload): Promise<MarketingOptinMlbResponse> => {
  try {
    const { data } = await axios.post<MarketingOptinMlbResponse>(
      `${BFF_URL}/marketing/optin/mlb`,
      payload,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSvgMapData = async (params: {
  /** SVG map JSON URL, e.g. https://d3pz2kc9e7xd94.cloudfront.net/fc95dc65-8d37-4976-bf37-02a9e0f515a8.json */
  svgMapJsonUrl: string;
}): Promise<SvgMapData> => {
  const { svgMapJsonUrl } = params;

  try {
    const { data } = await axios.get<SvgMapData>(svgMapJsonUrl);
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getTopNavPerformers = async (): Promise<TopNavPerformersResponse> => {
  try {
    const { data } = await axios.get<TopNavPerformersResponse>(
      `${BFF_URL}/top-nav-performers`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

/**
 * Gets supported loyalty currencies with conversion rates and display names.
 * @example
 * {
 *   "miles": {
 *     id: "miles",
 *     rate: 0.008,
 *     display_name: "miles",
 *   },
 *   "rewards_cash": {
 *     id: "rewards_cash",
 *     rate: 1,
 *     display_name: "rewards cash",
 *   },
 *   "points": {
 *     id: "points",
 *     rate: 0.008,
 *     display_name: "points",
 *   },
 * }
 */
export const getLoyaltyCurrencies = async (): Promise<LoyaltyCurrencies> => {
  try {
    const { data } = await axios.get<LoyaltyCurrencies>(
      `${BFF_URL}/loyalty/currency`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};
