import printJS from 'print-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { URLs } from '../../../lib/constants';
import { checkIsPayWithRewardsOnly } from '../../../lib/eventUtils';
import { addQueryParam, capitalize, DateFormats, formatDate, formatNumberToLocaleString, getFiveDigitsZipCode, getOrderDetailsTranslationKey, getProcessingNetworkTag, getTwoDigitsCountryCode, handleDateFormat, handleLocation, handleTimeFormat, isAndroidDevice, isEventGuestList, isFirefoxBrowser, isSafariBrowser } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackErrorEvent, trackPageViewEvent, trackScreenViewEvent, trackSelectContentEvent } from '../../../modules/analytics/util';
import { ApiError } from '../../../modules/error/types';
import type { CategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import type { Event, LoyaltyTransaction, UserOrder } from '../../../modules/partnership';
import CardAsset from '../../../resources/legacyIcons/CreditCardBlack.svg';
import type { TabCombinedProps } from '../../atoms/Tab/types';
import type { CardCombinedProps } from '../../molecules/Card/types';
import type { IconTextDescriptionValueProps } from '../../molecules/IconTextDescription';
import type { OrderDetailsValueProps } from '../../organisms/OrderDetails';
import useInteractor from './MyTicketsPage.interactor';
import myTicketsStyles from './MyTicketsPage.module.scss';
import type { IconDescription, MyTicketsPageCombinedProps, MyTicketsPageValueProps } from './types';
import { formatter, formatTitle, ticketStatus } from './utils';

const usePresenter = (props: MyTicketsPageCombinedProps): MyTicketsPageCombinedProps => {
  const { data, account, downloadTickets, getEvent, pageLoading } = useInteractor();

  const navigate = useNavigate();
  const { search } = useLocation();

  const { t } = useTranslation();

  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    trackPageViewEvent(
      trackEvent,
      'My Tickets Page',
    );
  }, []);

  const shouldDownloadPDF = isAndroidDevice() || isSafariBrowser() || isFirefoxBrowser();

  const upComingEvents: CategoryMenuItemState = {
    id: '', // If no id then use upcoming events
    name: t('myTickets.upComingEvents'),
    subCategories: [],
    performers: [],
  };

  const pastEvents: CategoryMenuItemState = {
    id: 'past',
    name: t('myTickets.pastEvents'),
    subCategories: [],
    performers: [],
  };

  const categories: CategoryMenuItemState[] = [upComingEvents, pastEvents];

  const [selectedTab, setSelectedTab] = useState(categories ? categories[0].id : 'all');

  const [myTickets, setMyTickets] = useState<UserOrder[]>([]);

  const isUpComingTab = useRef(false); // Component wise variable whose value remains consistent on re-renders

  const [pdfurl, setUrl] = useState('');

  const [viewOrderModal, setViewOrderModal] = useState(false);

  const [selectedTicket, setSelectedTicket] = useState<{ order: UserOrder; orderConfirmed: boolean; } | undefined>();

  const formattedRedeemedRewardUnitsWithUnitName: string | undefined = useMemo(
    () => {
      if (!selectedTicket) {
        return undefined;
      }

      const loyaltyTransaction: LoyaltyTransaction | undefined = selectedTicket.order.loyalty_details.transactions[0];
      const redeemedRewardUnits: number = loyaltyTransaction?.transaction_amount ?? 0;
      const rewardUnitName: string = loyaltyTransaction?.transaction_currency ?? '';

      return redeemedRewardUnits > 0
        ? formatNumberToLocaleString({ num: redeemedRewardUnits, unitName: rewardUnitName, shouldIncludeUnitName: true })
        : undefined;
    },
    [selectedTicket],
  );

  const [isGuestList, setIsGuestList] = useState<boolean>();

  /* isRewardsOnly is used to check if the event has LOYALTY_REWARDS_PAYMENT tag
  and if it does then we are hiding PaymentMethod and Amount in $ from MyTicket Modal.*/
  const [isRewardsOnly, setIsRewardsOnly] = useState<boolean>();

  /* isRewardsOnlyPayment check is added if payment is made using only the loyalty unit (for Split Payment Events)
  in this case also need to hide both blocks of payment and amount in $ from MyTicket Modal.*/
  const [isRewardsOnlyPayment, setIsRewardsOnlyPayment] = useState<boolean>();

  const handleAdditonalInfo = (order: UserOrder): IconTextDescriptionValueProps[] => {
    const confirmationPagePath: string = getOrderDetailsTranslationKey(
      order.stock_type,
      String(order.delivery.id),
      'confirmation_page',
    );
    let iconDescriptionList: IconDescription[] = [];
    const confirmationPageOptions: string[] = t(`${confirmationPagePath}`, {
      customerEmail: order.email,
      customerMobilePhone: account?.phone,
      customerShippingAddress: t(
        'deliveryInformation.ticketDelivery.customerAddressTemplate',
        {
          address: account?.address.address_line1,
          city: account?.address.city,
          state: account?.address.state_code,
          postalCode: account
            ? getFiveDigitsZipCode({ countryCode: account.address.country_code, postalCode: account.address.postal_code })
            : '',
          country: getTwoDigitsCountryCode(
            account?.address.country_code || '',
          ),
        },
      ),
      returnObjects: true,
    });
    if (isGuestList) {
      iconDescriptionList = [
        {
          asset: undefined,
          description: `${t(
            'orderConfirmation.guestListEventOrderNotes.title',
          )}`,
        },
        {
          asset: undefined,
          description: `• ${t(
            'orderConfirmation.guestListEventOrderNotes.email',
            {
              email: order.email,
            },
          )}`,
        },
        {
          asset: undefined,
          description: `• ${t(
            'orderConfirmation.guestListEventOrderNotes.name',
          )}`,
        },
        {
          asset: undefined,
          description: `• ${t(
            'orderConfirmation.guestListEventOrderNotes.onSiteEvent',
          )}`,
        },
        {
          asset: undefined,
          description: `• ${t(
            'orderConfirmation.guestListEventOrderNotes.virtualEvent',
          )}`,
        },
      ];
    } else if (
      confirmationPageOptions &&
      typeof confirmationPageOptions !== 'string'
    ) {
      // No stocktype for old orders
      iconDescriptionList = confirmationPageOptions?.map((option) => {
        return {
          asset: undefined,
          description: `• ${option}`,
        };
      });
      iconDescriptionList.unshift({
        asset: undefined,
        description: t(
          getOrderDetailsTranslationKey(
            order.stock_type,
            String(order.delivery.id),
            'delivery_header',
          ),
        ),
      });
    }

    const iconTextDescriptionProps: IconTextDescriptionValueProps[] =
      iconDescriptionList?.map((iconDescription): IconTextDescriptionValueProps => {
        return {
          icon: iconDescription.asset
            ? {
              asset: iconDescription.asset,
            }
            : {
              className: myTicketsStyles.hideIcons,
            },
          description: {
            value: iconDescription.description,
          },
        };
      });
    return iconTextDescriptionProps;
  };

  useEffect(() => {
    const doFetchEvent = async () => {
      try {
        if (getEvent && selectedTicket) {
          const response: Event = await getEvent(
            selectedTicket.order.event_id.toString(),
          );
          setIsRewardsOnly(checkIsPayWithRewardsOnly(response));
          setIsGuestList(
            isEventGuestList(
              response?.tags || [],
              selectedTicket.order.stock_type,
              selectedTicket.order.delivery.id,
            ),
          );
        }
      } catch (error) {
        if (error && ApiError.isApiError(error)) {
          trackErrorEvent(
            trackEvent,
            error.code,
            error.message,
          );
        }
        // TODO
      }
    };
    void doFetchEvent();
  }, [selectedTicket, getEvent]);

  useEffect(() => {
    let filterdTickets: UserOrder[] | undefined = [];
    const currentDate = new Date().getTime();
    if (search === '?category=past') {
      setSelectedTab('past');
      isUpComingTab.current = false;
      filterdTickets = data?.orders.filter(
        (item) =>
          new Date(item?.event?.utc_date || '').getTime() < currentDate,
      );
    } else {
      setSelectedTab('');
      isUpComingTab.current = true;
      filterdTickets = data?.orders.filter(
        (item) =>
          new Date(item?.event?.utc_date || '').getTime() >= currentDate,
      );
    }
    setMyTickets(filterdTickets || []);
  }, [search, data]);

  const handleTabClick = (id: string) => {
    setSelectedTab(id);
    const newSearch: string = addQueryParam(search, { category: id });
    navigate({ search: newSearch });
  };

  const tabList: TabCombinedProps[] = [];

  if (categories) {
    categories.forEach((item) => {
      const selectedCategory = item.id === selectedTab;
      tabList.push({
        type: selectedCategory ? 'Active' : 'Default',
        text: {
          value: item.name,
        },
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'MyTickets Page',
            'Tab',
            item.name,
          );
          handleTabClick(item.id);
        },
      });
    });
  }

  const handleCardClick = (order: UserOrder) => {
    trackSelectContentEvent(
      trackEvent,
      'MyTickets Page',
      'Order',
      order.event?.name,
    );
    const orderConfirmed =
      (order.ticket_files && order.ticket_files.length) ||
        order.delivery.tracking
        ? true
        : false;
    if (
      order.loyalty_details?.transactions?.length > 0 &&
      order.total_charge.toFixed(2) ===
      order.loyalty_details.transactions[0].transaction_amount_usd.toFixed(
        2,
      )
    ) {
      setIsRewardsOnlyPayment(true);
    } else {
      setIsRewardsOnlyPayment(false);
    }
    setSelectedTicket({ order: order, orderConfirmed: orderConfirmed });
    setViewOrderModal(true);
    trackScreenViewEvent(
      trackEvent,
      'Ticket Details',
    );
  };

  const eventCards = myTickets.map((item): CardCombinedProps => {
    const [ticketStatusText, ticketStatusType, statusTextColor] = ticketStatus(
      item.order_status.orderStatusId,
    );
    return {
      handleCardClick: () => handleCardClick(item),
      type: 'TicketStatus',
      image: {
        imageSrc: item.event?.image,
      },
      ticketStatus: {
        state: 'Negative',
        date: {
          className: myTicketsStyles.dateCreated,
          value: `${t('myTickets.orderDateTitle')} ${new Date(
            item.order_date,
          ).toLocaleDateString('en-US')}`,
        },
        title: {
          value: item.event?.name,
        },
        eventDateStatus: {
          type: 'Default',
          date: {
            value: handleDateFormat(item.event?.local_date || new Date()),
          },
          time: {
            value: t('exclusiveWidget.atTime', {
              time: handleTimeFormat(item.event?.local_date || new Date()),
            }),
          },
        },
        location: {
          value: item.event && handleLocation(item.event.venue),
        },
        quantity: {
          value: t('myTickets.quantity'),
        },
        amount: {
          value: item.quantity,
        },
        tag: {
          type: ticketStatusType,
          label: {
            value: t(ticketStatusText),
            colour: statusTextColor,
          },
        },
      },
      linkPath: `/events/${item.id}`,
    };
  });

  let orderDetailsView: OrderDetailsValueProps = {};

  if (selectedTicket) {
    const downloadButtonText = selectedTicket.order.mobile_scan_enabled
      ? t('orderDetails.download')
      : t('orderDetails.trackOrder');
    orderDetailsView = {
      showCloseButton: true,
      closeButton: {
        icon: {
          asset: 'Close',
          style: 'DigitalGrey80',
        },
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'MyTickets Page',
            'Order Details Modal',
            t('topnav.close'),
          );
          setViewOrderModal(false);
        },
        ariaLabel: t('common.close'),
      },
      sellerNotes: {
        label: {
          value: selectedTicket.order.ticket_description
            ? t('orderConfirmation.sellerNotes')
            : '',
        },
        description: {
          value: selectedTicket.order.ticket_description,
        },
      },
      infoHeader: {
        title: {
          value: t('orderConfirmation.header'),
        },
        details: {
          value: t('orderDetails.detailSubHeader', {
            ticketId: selectedTicket.order.id,
            orderDate: formatDate(
              new Date(selectedTicket.order.order_date),
              t,
              DateFormats.CONFIRMATION,
            ),
          }),
        },
      },
      ticketInfoTitle: {
        value: t('orderConfirmation.ticketInfoTitle'),
      },
      showHighlight: true,
      highlightMessage: {
        message: {
          value: t('orderDetails.highlightMessage'),
        },
      },
      printButton: {
        icon: {
          asset: 'Printer',
          style: 'ActionBase',
        },
        text: {
          value: t('orderConfirmation.print'),
        },
        onClick: () => {
          trackSelectContentEvent(
            trackEvent,
            'MyTickets Page',
            'Order Details Modal',
            t('orderConfirmation.print'),
          );
          if (pdfurl) {
            // for android devices the blob pdf url will not open a print dialog on the same browser tab,
            // also, for safari and firefox browsers, print button shows empty as that's a known issue,
            // in order to make it work
            // we are downloading the pdf file so user can open that and print using native print dialog
            if (shouldDownloadPDF) {
              const a = document.createElement('a');
              a.href = pdfurl;
              a.download = `VividSeats_receipt_${selectedTicket?.order?.event?.id}`;
              a.style.position = 'fixed';
              a.target = '_blank';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else { // for rest of the platforms (desktop, ios)
              printJS(pdfurl);
            }
          }
        },
      },
      infoDetails: {
        title: {
          value: selectedTicket.order.event?.name,
        },
        eventDateStatus: {
          type: 'Default',
          date: {
            value: selectedTicket.order.event?.local_date
              ? formatDate(
                new Date(selectedTicket.order.event?.local_date),
                t,
                DateFormats.TICKETINFO,
              )
              : '',
          },
        },
        venue: {
          value: formatTitle(
            selectedTicket.order.event?.venue.name || '',
            selectedTicket.order.event?.venue.city || '',
            selectedTicket.order.event?.venue.state_code || '',
          ),
        },
      },
      seatInfo: {
        section: {
          label: {
            value: t('ticketInfo.seatInfo.section'),
          },
          description: {
            value: selectedTicket.order.section,
          },
        },
        row: {
          label: {
            value: t('ticketInfo.seatInfo.row'),
          },
          description: {
            value: selectedTicket.order.row,
          },
        },
        quantity: {
          label: {
            value: t('ticketInfo.seatInfo.quantity'),
          },
          description: {
            value: selectedTicket.order.quantity,
          },
        },
      },
      showLogo: false,
      deliveryInfoTitle: {
        value: t('orderConfirmation.deliveryInfoTitle'),
      },
      additionalTicketInformationList: {
        iconTextDescriptions: handleAdditonalInfo(selectedTicket.order),
      },
      deliveryDateInfo: selectedTicket.order.delivery.tracking
        ? {
          style: 'SubduedLabel',
          label: {
            value: 'Tracking Number',
          },
          description: {
            value: selectedTicket.order.delivery.tracking,
          },
        }
        : {},
      showDownloadButton: selectedTicket.orderConfirmed,
      downloadButton: {
        text: {
          value: downloadButtonText,
        },
        href: selectedTicket.order.delivery.tracking
          ? `https://www.ups.com/track?tracknum=${selectedTicket.order.delivery.tracking}`
          : '',
        onClick:
          selectedTicket.order.ticket_files &&
            selectedTicket.order.ticket_files.length
            ? () => {
              trackSelectContentEvent(
                trackEvent,
                'Order Deatails Modal',
                'Button',
                downloadButtonText,
              );
              selectedTicket.order.ticket_files?.map((file) => {
                return void downloadTickets({
                  orderId: String(selectedTicket.order.id),
                  fileName: file,
                });
              });
            }
            : undefined,
        newTab: selectedTicket?.order?.delivery?.tracking ? true : false,
      },
      billingInfoTitle: {
        value: t('orderConfirmation.billingInfoTitle'),
      },
      infoOnMultipleLine: {
        label: {
          value: t('billingInfo.title'),
        },
        address: {
          value: t('billingInfo.smallVersionTemplate', {
            firstName: selectedTicket.order.billingAddress.firstName,
            lastName: selectedTicket.order.billingAddress.lastName,
            address: selectedTicket.order.billingAddress.address1,
            city: selectedTicket.order.billingAddress.city,
            state: selectedTicket.order.billingAddress.state,
            postalCode: getFiveDigitsZipCode({ countryCode: selectedTicket.order.billingAddress.country, postalCode: selectedTicket.order.billingAddress.zip }),
            country: selectedTicket.order.billingAddress.country,
          }),
        },
      },
      phoneNumber: {
        label: {
          value: t('billingInfo.phoneNumber'),
        },
        description: {
          value: selectedTicket.order.billingAddress.phoneNumber,
        },
      },
      paymentMethod:
        isRewardsOnly || isRewardsOnlyPayment
          ? undefined
          : {
            cardImage: {
              imageSrc:
                selectedTicket.order.loyalty_details.program_logo_url,
              imageFallback: CardAsset,
            },
            label: {
              value: t('billingInfo.paymentMethod'),
            },
            description: {
              value: capitalize(getProcessingNetworkTag(account) || ''),
            },
          },
      totalCardPayment:
        isRewardsOnly || isRewardsOnlyPayment
          ? undefined
          : {
            label: {
              value: t('billingInfo.totalCardPayment'),
            },
            description: {
              value: formatter.format(
                (selectedTicket.order.total_charge || 0) -
                (selectedTicket.order.loyalty_details.transactions[0]
                  ?.transaction_amount_usd || 0),
              ),
            },
          },
      rightContent:
        formattedRedeemedRewardUnitsWithUnitName
          ? {
            label: {
              value: t('billingInfo.rewardsRedeemed'),
            },
            description: {
              value: formattedRedeemedRewardUnitsWithUnitName,
            },
          }
          : {},
    };
  }

  const pageProps: MyTicketsPageValueProps = {
    myTicketsBlock: {
      isLoading: pageLoading,
      state: eventCards.length ? 'Filled' : 'Empty',
      title: {
        value: t('myTickets.title'),
      },
      tabList: {
        tabs: tabList,
      },
      eventsCardList: {
        cards: eventCards,
      },
      emptyStateBanner: {
        title: {
          value: isUpComingTab.current
            ? t('myTickets.emptyUpComingTitle')
            : t('myTickets.emptyPastTitle'),
        },
        message: {
          value: isUpComingTab.current
            ? t('myTickets.emptyUpComingDescription')
            : t('myTickets.emptyPastDescription'),
        },
        button: {
          text: {
            value: t('myTickets.emptyUpComingButtonText'),
          },
          href: URLs.HomePage,
        },
      },
    },
    viewOrderModal: viewOrderModal,
    orderDetailsProps: orderDetailsView,
    setViewOrderModal: () => setViewOrderModal(false),
    setUrl: setUrl,
    isCp1Exclusive:
      selectedTicket?.order?.event?.tags?.includes('C1_EXCLUSIVE'),
    cardHolderExclusiveLabel: t('orderConfirmation.cardHolderLabel'),
    ticketNotice: t('orderConfirmation.ticketNotice'),
    orderDetailsTitle: t('orderConfirmation.title'),
    isMobileTicket: selectedTicket?.order.mobile_scan_enabled, // Discard once VSCO-433 is implemented
  };

  return {
    ...props,
    ...pageProps,
  };
};

export default usePresenter;
