import type { TextCombinedProps } from '../../atoms/Text';
import styles from './ListingCard.module.scss';

export const largerTextProps: TextCombinedProps = {
  size: 'Medium',
  style: 'Regular',
  colour: 'SubduedDark',
  className: styles.lineHeight18px,
};

export const smallerTextProps: TextCombinedProps = {
  size: 'Small',
  style: 'Regular',
  colour: 'SubduedLight',
  className: styles.lineHeight16px,
};