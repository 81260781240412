import { useCallback, useEffect, useMemo, useState, type ReactNode, type SyntheticEvent } from 'react';
import type { Dropdown } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { getStringFromReactNode } from '../../../lib/util';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { useModalState } from '../../../modules/modals';
import type { ChangeConfirmationModalProps } from '../../organisms/ChangeConfirmationModal';
import type { ButtonTypeEnum } from '../Button';
import type { IconStyleEnum } from '../Icon';
import type { TextColourEnum } from '../Text';
import { NON_TOGGLABLE } from './BaseFilter.constants';
import type { BaseFilterPresenterProps, BaseFilterProps } from './BaseFilter.types';
import { calculateDisplayValue, calculateDisplayValueColour, calculateFilterIconStyle, getFilterStateImageUrl, getToggleButtonType, isFilterOptionWithId } from './BaseFilter.utils';

export const usePresenter = <TFilterState, TFilterOptionValue>(props: BaseFilterProps<TFilterState, TFilterOptionValue>): BaseFilterPresenterProps<TFilterState, TFilterOptionValue> => {
  const {
    filterState,
    getDisplayValue,
    placeholder,
    onFilterOptionSelected: initialOnFilterOptionSelected,
    onFilterDropdownToggled,
    isClearable,
    isDisabled,
    isHighlightOnExpandOnly = false,
    theme,
    changeConfirmationModalProps,
    isImageDisplayedInSelectedState,
  } = props;

  const { trackEvent } = useAnalyticsManager();

  // States for managing next selected filter option value and text
  const [nextOptionValue, setNextOptionValue] = useState<TFilterOptionValue>();
  const [nextOptionText, setNextOptionText] = useState<ReactNode>('');

  // Helper function to update the next selected option
  const handleUpdateNextOption = useCallback((filterOptionValue: TFilterOptionValue | undefined, filterOptionText: ReactNode) => {
    setNextOptionValue(filterOptionValue);
    setNextOptionText(filterOptionText);
  }, []);

  // Modal state management for change confirmation
  const {
    isModalOpen: isChangeConfirmationModalShown,
    openModal: openChangeConfirmationModal,
    closeModal: closeChangeConfirmationModal,
  } = useModalState();

  // Indicates whether the filter dropdown is expanded
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  /** Display value of the filter state */
  const displayValue: ReactNode = useMemo(() => {
    return calculateDisplayValue({ filterState, placeholder, getDisplayValue });
  }, [filterState, placeholder, getDisplayValue]);

  // Close filter dropdown whenever filter state changes
  useEffect(() => {
    setIsExpanded(false);
  }, [filterState]);

  /** Function to toggle filter dropdown */
  const toggleFilterDropdown = useCallback((isOpen: boolean, event: SyntheticEvent<Dropdown>) => {
    // Prevent toggling if the clicked element has a `NON_TOGGLABLE` class
    if (event.target instanceof Element) {
      const isNonTogglable: boolean = !!event.target.closest(`.${NON_TOGGLABLE}`);
      if (isNonTogglable) {
        return;
      }
    }

    setIsExpanded(isOpen);

    // Trigger the optional callback if provided
    onFilterDropdownToggled?.(isOpen);

    // Track analytics when dropdown is opened
    if (isOpen) {
      trackSelectContentEvent(
        trackEvent,
        'Filter',
        'Button',
        getStringFromReactNode(displayValue),
      );
    }
  }, [displayValue, onFilterDropdownToggled, trackEvent]);

  // Handle selecting a filter option
  const proceedSelectFilterOption = useCallback((filterOptionValue: TFilterOptionValue | undefined, filterOptionText: ReactNode) => {
    initialOnFilterOptionSelected(filterOptionValue, filterOptionText);

    // Track analytics for selected option
    trackSelectContentEvent(
      trackEvent,
      'Filter',
      'FilterOption',
      getStringFromReactNode(filterOptionText),
    );
  }, [initialOnFilterOptionSelected, trackEvent]);

  // Wrapper for handling filter option selection with or without modal
  const onFilterOptionSelected = useCallback(
    (filterOptionValue: TFilterOptionValue | undefined,
      filterOptionText: ReactNode,
    ) => {
      // Check if filterOptionValue.id is different from filterState.id
      if (
        isFilterOptionWithId(filterOptionValue) &&
        isFilterOptionWithId(filterState) &&
        filterOptionValue.id === filterState.id
      ) {
        return;
      }

      if (changeConfirmationModalProps) {
        handleUpdateNextOption(filterOptionValue, filterOptionText);
        openChangeConfirmationModal();
      } else {
        proceedSelectFilterOption(filterOptionValue, filterOptionText);
      }
    },
    [handleUpdateNextOption, openChangeConfirmationModal, proceedSelectFilterOption, changeConfirmationModalProps, filterState],
  );

  /** Indicates whether clear filter button should be shown */
  const isClearFilterButtonShown: boolean = useMemo(() => isClearable && !!filterState, [isClearable, filterState]);

  /** Function to clear filter state */
  const clearFilter = useCallback(() => {
    setIsExpanded(false);
    onFilterOptionSelected(undefined, placeholder);
  }, [onFilterOptionSelected, placeholder]);

  /** Colour for the display value of the filter state */
  const displayValueColour: TextColourEnum = useMemo(() => {
    return calculateDisplayValueColour({ theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly });
  }, [theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly]);

  /** Icon style for the filter */
  const filterIconStyle: IconStyleEnum = useMemo(() => {
    return calculateFilterIconStyle({ theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly });
  }, [theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  const filterStateImageUrl: string | undefined = useMemo(() => isImageDisplayedInSelectedState ? getFilterStateImageUrl(filterState) : undefined, [filterState, isImageDisplayedInSelectedState]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  const toggleButtonType: ButtonTypeEnum = useMemo(() => getToggleButtonType(filterStateImageUrl, isClearFilterButtonShown), [filterStateImageUrl, isClearFilterButtonShown]);

  // Props for the change confirmation modal
  const changeConfirmationModalViewProps: ChangeConfirmationModalProps | undefined = changeConfirmationModalProps && {
    onPrimaryButtonClick: () => {
      proceedSelectFilterOption(nextOptionValue, nextOptionText);
      closeChangeConfirmationModal();
    },
    onCloseButtonClick: closeChangeConfirmationModal,
    ...changeConfirmationModalProps,
  };

  // Make sure HTML Id starts with an alpha character
  const dropdownMenuHtmlId: string = useMemo(() => `menu-${uuidv4()}`, []);

  return {
    ...props,
    displayValue,
    displayValueColour,
    isExpanded,
    toggleFilterDropdown,
    onFilterOptionSelected,
    isClearFilterButtonShown,
    clearFilter,
    filterIconStyle,
    filterStateImageUrl,
    toggleButtonType,
    changeConfirmationModalViewProps,
    isChangeConfirmationModalShown,
    dropdownMenuHtmlId,
  };
};
