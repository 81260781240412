import React from 'react';
import { usePresenter } from './SvgMapSectionNames.presenter';
import type { SvgMapSectionNameProps, SvgMapSectionNamesProps } from './SvgMapSectionNames.types';

const InternalSvgMapSectionNames: React.FC<SvgMapSectionNamesProps> = (props) => {
  const { svgMapSectionNameProps } = usePresenter(props);

  return (<>
    {svgMapSectionNameProps.map((currentSvgMapSectionNameProps: SvgMapSectionNameProps) => {
      const { tspanDy, tspanText, ...otherSvgMapSectionNameProps } = currentSvgMapSectionNameProps;

      return (
        <text
          {...otherSvgMapSectionNameProps}
          key={otherSvgMapSectionNameProps.key}>
          <tspan dy={tspanDy}>{tspanText}</tspan>
        </text>
      );
    })}
  </>);
};

/** Memoize SVG map section names since map renders a large number of them */
export const SvgMapSectionNames = React.memo(InternalSvgMapSectionNames);
