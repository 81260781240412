import type { HomeAwayToggleState } from './PerformerPage.types';

export const TRACKING_PAGE_NAME = {
  forPageVisits: 'Performer',
  nonSports: {
    /** Used for view_item_list tracking */
    forViewItemList: 'performer_landing_page_View',
    forEventCardClicks: 'Performer Landing Page',
  },
  sports: {
    /** Used for view_item_list tracking */
    forViewItemList: 'sport_teams_landing_page_View',
    forEventCardClicks: 'Sports Performer Landing Page',
  },
} as const;

export const HomeAwayToggleStates = ['all', 'home', 'away'] as const;

export const HomeAwayToggleOptions = HomeAwayToggleStates.map((id: HomeAwayToggleState) => ({ id, labelKey: `homeAwayToggle.${id}.label`, ariaLabelKey: `homeAwayToggle.${id}.ariaLabel` }));
