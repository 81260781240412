import { isAccountCardValidForTags } from '../../../lib/eventUtils';
import type { AccountCardDetail } from '../../../modules/auth/types';
import type { AccountCardProps } from '../AccountCard';
import { AccountCardThemesByRewardsBalanceTheme } from './RewardsBalance.constants';
import type { RewardsBalanceTheme } from './RewardsBalance.types';

/**
 * Builds an array of account card props.
 */
export const getAccountCardProps = (params: {
  /** Account card details */
  accountCardDetails: AccountCardDetail[];
  /** The theme for rewards balance */
  theme: RewardsBalanceTheme;
  /** Listing tags for filtering cards */
  listingTags: string[] | undefined;
  /** Event tags for filtering cards */
  eventTags: string[] | undefined;
}): AccountCardProps[] => {
  const { accountCardDetails, theme: rewardsBalanceTheme, listingTags, eventTags } = params;
  return accountCardDetails.map((accountCardDetail: AccountCardDetail, key: number) => ({
    ...accountCardDetail,
    key,
    theme: AccountCardThemesByRewardsBalanceTheme[rewardsBalanceTheme],
    /** Indicates if the reward units should be bold */
    areRewardUnitsBold: true,
    /** Indicates if the account card is disabled based on tag validity */
    isDisabled: !isAccountCardValidForTags({ accountCardDetail, tags: listingTags, fallbackTags: eventTags }),
  }));
};
