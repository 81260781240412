import type { ReactNode } from 'react';
import type { ButtonTypeEnum } from '../Button';
import type { IconStyleEnum } from '../Icon/types';
import type { TextColourEnum } from '../Text/types';
import type { FilterTheme } from './BaseFilter.types';

/**
 * Calculates display value of the filter state.
 * @template TFilterState Type of the filter state.
 * @returns {string} Display value of the filter state.
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const calculateDisplayValue = <TFilterState,>(params: {
  /** Current state of the filter component. */
  filterState: TFilterState | undefined;
  /** Placeholder text for the filter component which will be displayed when filter state is undefined. */
  placeholder: string;
  /** Function to get the display value of the filter state. */
  getDisplayValue: (filterState: TFilterState) => ReactNode;
}): ReactNode => {
  const { filterState, placeholder, getDisplayValue } = params;

  return filterState
    ? getDisplayValue(filterState)
    : placeholder; // If filterState is undefined, return the placeholder
};

/**
 * Calculates text colour of the display value based on theme, state, and other parameters.
 * @template TFilterState Type of the filter state.
 * @returns {TextColourEnum} Text colour of the display value.
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const calculateDisplayValueColour = <TFilterState,>(params: {
  /** The theme of the filter, either 'light' or 'dark'. */
  theme: FilterTheme;
  /** A flag indicating if the filter is disabled. */
  isDisabled: boolean;
  /** Current state of the filter component. */
  filterState: TFilterState | undefined;
  /** A flag indicating if the filter is expanded. */
  isExpanded: boolean;
  /** Indicates whether the filter should only be highlighted when expanded */
  isHighlightOnExpandOnly: boolean;
}): TextColourEnum => {
  const { theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly } = params;

  // Display value is always white for dark theme
  if (theme === 'dark') {
    return 'BaseLight';
  }

  // For light theme display value is blue when filter has a selected value or it is expanded
  return !isDisabled && (isHighlightOnExpandOnly ? isExpanded : (filterState || isExpanded)) ? 'ActionBase' : 'SubduedDark';
};

/**
 * Calculates filter icon style based on theme, state, and other parameters.
 * @template TFilterState Type of the filter state.
 * @returns {IconStyleEnum} Filter icon style.
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const calculateFilterIconStyle = <TFilterState,>(params: {
  /** The theme of the filter, either 'light' or 'dark'. */
  theme: FilterTheme;
  /** A flag indicating if the filter is disabled. */
  isDisabled: boolean;
  /** Current state of the filter component. */
  filterState: TFilterState | undefined;
  /** A flag indicating if the filter is expanded. */
  isExpanded: boolean;
  /** Indicates whether the filter should only be highlighted when expanded */
  isHighlightOnExpandOnly: boolean;
}): IconStyleEnum => {
  const { theme, isDisabled, filterState, isExpanded, isHighlightOnExpandOnly } = params;

  // Icon is always white for dark theme
  if (theme === 'dark') {
    return 'White';
  }

  // For light theme the icon is blue when filter has a selected value or it is expanded
  return !isDisabled && (isHighlightOnExpandOnly ? isExpanded : (filterState || isExpanded)) ? 'ActionBase' : 'SubduedDark';
};

export const getFilterStateImageUrl = <TFilterState>(
  value: TFilterState | null | undefined,
): string | undefined => {
  if (
    value !== null &&
    typeof value === 'object' &&
    'imageUrl' in value &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (value as any).imageUrl === 'string'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value as any).imageUrl;
  }
  return undefined;
};

/**
 * Determines the toggle button type based on the filter state and clear button visibility.
 * @param imageUrl - The image URL for the filter state.
 * @param isClearButtonVisible - Indicates if the clear filter button is shown.
 * @returns The toggle button type.
 */
export const getToggleButtonType = (
  imageUrl: string | undefined,
  isClearButtonVisible: boolean,
): ButtonTypeEnum => {
  if (imageUrl) return 'ImageTextIcon';
  return isClearButtonVisible ? 'Text' : 'TextIcon';
};

/**
 * Type guard to check if a given value has an 'id' property of type string or number.
 * This function helps to safely narrow the type of an object that may contain an 'id' field.
 * Example:
 * if (isFilterOptionWithId(value)) {
 *   // Inside this block, TypeScript knows that 'value' has an 'id' of type string or number.
 * }
 */
export const isFilterOptionWithId = (value: unknown): value is { id: string | number } => {
  // Check if the value is an object and not null, then ensure it has an 'id' property
  return typeof value === 'object' &&
    value !== null &&
    'id' in value &&
    (typeof (value as { id: unknown }).id === 'string' || typeof (value as { id: unknown }).id === 'number');
};