import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { titleCase } from '../../../lib/util';
import Button from '../../atoms/Button';
import { Logo } from '../../atoms/Logo';
import { Spinner } from '../../atoms/Spinner';
import Text from '../../atoms/Text';
import { LimitedAccessModal } from '../../organisms/LimitedAccessModal';
import { TicketAlertModal } from '../../organisms/TicketAlertModal';
import { ExclusiveBadge } from '../ExclusiveBadge';
import { IconText } from '../IconText';
import { RewardsBalance } from '../RewardsBalance';
import { SignInDialog } from '../SignInDialog';
import { SubTotal } from '../SubTotal';
import { TextDropdown } from '../TextDropdown';
import { IconTextColoursByTheme, PrimaryColoursByTheme, SecondaryColoursByTheme, SellerNotesIconAssetsByTheme, SubTotalThemesByTheme, TicketNotesIconAssetsByTheme, TicketsByLogoThemesByTheme, TicketsGuaranteedIconAssetsByTheme } from './PreCheckoutDetails.constants';
import styles from './PreCheckoutDetails.module.scss';
import { usePresenter } from './PreCheckoutDetails.presenter';
import type { PreCheckoutDetailsProps } from './PreCheckoutDetails.types';

export const PreCheckoutDetails: React.FC<PreCheckoutDetailsProps> = (props) => {
  const {
    listingDetailMetadata,
    ticketsByLogo,
    ticketNotesKey,
    quantityDropdownOptions,
    selectedQuantity,
    onQuantityChanged,
    formattedTotalCashPrice,
    formattedTotalLoyaltyPrice,
    loyaltyUnitName,
    serviceFeeText,
    handleCheckout,
    isValidatingCheckout,
    ticketAlertModalProps,
    onCloseButtonClicked,
    isDesktopResponsive,
    theme,
    className,
    classes,
    eventCardExclusiveBadgeProps,
    isGuestListEvent,
  } = usePresenter(props);

  const {
    eventMetadata: {
      eventName,
      eventDateTimeAndVenueDetails,
      isPayWithRewardsOnly,
      tags: eventTags,
    },
    listingMetadata: {
      sectionName,
      row,
      sellerNotes,
      tags: listingTags,
    },
  } = listingDetailMetadata ?? {};

  const { t } = useTranslation();

  const cardholderExclusiveBadge: ReactNode = eventCardExclusiveBadgeProps && (
    <ExclusiveBadge
      {...eventCardExclusiveBadgeProps}
      className={cx({ [styles.exclusiveBadge]: isGuestListEvent })}
    />
  );

  const sectionAndRow: ReactNode = (
    <Text
      type='NewEventDetailsTitle'
      size='Large'
      style='SemiBold'
      colour={PrimaryColoursByTheme[theme]}
      value={t('preCheckoutDetails.sectionAndRow', { sectionName, row })}
      className={styles.sectionAndRow}
    />
  );

  const eventNameView: ReactNode = (
    <Text
      size='Medium'
      style='Regular'
      colour={SecondaryColoursByTheme[theme]}
      value={eventName}
    />
  );

  const eventDateTime: ReactNode = (
    <IconText
      iconAsset='CalenderNew'
      text={t('event.shortWeekDayLongDateTime', eventDateTimeAndVenueDetails)}
      color={IconTextColoursByTheme[theme]}
    />
  );

  const eventVenue: ReactNode = (
    <IconText
      iconAsset='LocationPinFilled'
      text={t('event.venueNameCityStateCode', eventDateTimeAndVenueDetails)}
      color={IconTextColoursByTheme[theme]}
    />
  );

  const payWithRewardsOnly: ReactNode = isPayWithRewardsOnly && (
    <IconText
      iconAsset='Trophy'
      text={t('event.payWithRewardsOnly')}
      color={IconTextColoursByTheme[theme]}
    />
  );

  const ticketsByContent: ReactNode = (
    <div className={styles.ticketsBy}>
      <Text
        size='Medium'
        style='Regular'
        colour={IconTextColoursByTheme[theme]}
        value={t(`preCheckoutDetails.ticketsBy${titleCase(ticketsByLogo)}`)}
        className={cx(styles.ticketsByText, styles[`ticketsBy${titleCase(ticketsByLogo)}Text`])}
      />
      <Logo
        asset={ticketsByLogo}
        theme={TicketsByLogoThemesByTheme[theme]}
        className={cx(styles.ticketsByLogo, styles[`ticketsBy${titleCase(ticketsByLogo)}Logo`])}
      />
    </div>
  );

  const ticketsBy: ReactNode = (
    <IconText
      iconAsset='TicketWithLines'
      text={ticketsByContent}
      color={IconTextColoursByTheme[theme]}
    />
  );

  const sellerNotesView: ReactNode = !!sellerNotes && (
    <IconText
      iconAsset={SellerNotesIconAssetsByTheme[theme]}
      text={t('preCheckoutDetails.sellerNotes', { sellerNotes })}
      color={IconTextColoursByTheme[theme]}
    />
  );

  const ticketNotes: ReactNode = (
    <IconText
      iconAsset={TicketNotesIconAssetsByTheme[theme]}
      text={t(ticketNotesKey)}
      color={IconTextColoursByTheme[theme]}
    />
  );

  const quantityDropdown: ReactNode = (
    <TextDropdown
      label={t('preCheckoutDetails.quantityDropdownLabel')}
      options={quantityDropdownOptions}
      selectedValue={selectedQuantity}
      onChange={onQuantityChanged}
      theme={theme}
      ariaLabel={t('preCheckoutDetails.quantityDropdownAriaLabel')}
    />
  );

  const rewardsBalance: ReactNode = (
    <RewardsBalance
      theme={theme}
      listingTags={listingTags}
      eventTags={eventTags}
    />
  );

  const subTotal: ReactNode = (
    <SubTotal
      formattedTotalCashPrice={formattedTotalCashPrice}
      formattedTotalLoyaltyPrice={formattedTotalLoyaltyPrice}
      loyaltyUnitName={loyaltyUnitName}
      serviceFeeText={serviceFeeText}
      isPayWithRewardsOnly={isPayWithRewardsOnly}
      theme={SubTotalThemesByTheme[theme]}
    />
  );

  const checkoutButton: ReactNode = (
    <Button
      type='Text'
      style='Contained'
      size='Medium'
      text={{
        type: 'ButtonText',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Center',
        value: t('preCheckoutDetails.goToCheckout'),
        className: styles.checkoutButtonText,
      }}
      onClick={handleCheckout}
      className={styles.checkoutButton}
    />
  );

  const ticketsGuaranteedDescription: ReactNode = (
    <IconText
      iconAsset={TicketsGuaranteedIconAssetsByTheme[theme]}
      text={t('preCheckoutDetails.ticketsGuaranteed')}
      color={PrimaryColoursByTheme[theme]}
      className={styles.ticketsGuaranteedDescription}
      classes={{
        icon: styles.ticketsGuaranteedDescriptionIcon,
        text: styles.ticketsGuaranteedDescriptionText,
      }}
    />
  );

  const closeButton: ReactNode = !!onCloseButtonClicked && (
    <Button
      type='Icon'
      style='Text'
      size='Medium'
      icon={{
        asset: 'Close',
        style: 'White',
      }}
      ariaLabel={t('preCheckoutDetails.closePreCheckoutModal')}
      onClick={onCloseButtonClicked}
      className={styles.closeButton}
    />
  );

  return (<>
    {isValidatingCheckout && <Spinner isOverlay={true} />}
    <div className={cx(styles.preCheckoutDetails, styles[`${theme}PreCheckoutDetails`], className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <div className={styles.header}>
          <div className={cx(styles.headerTopContent, { [styles.headerTopContentDesktop]: isDesktopResponsive })}>
            {cardholderExclusiveBadge}
            {sectionAndRow}
          </div>
          {eventNameView}
          {closeButton}
        </div>
        <div className={styles.infoContainer}>
          {eventDateTime}
          {eventVenue}
          {payWithRewardsOnly}
        </div>
        <div className={styles.infoContainer}>
          {ticketsBy}
          {sellerNotesView}
          {ticketNotes}
        </div>
        <div className={styles.quantityAndRewardsBalance}>
          <SignInDialog
            placement='pre_checkout_page'
            className={styles.signInDialog}
          />
          {quantityDropdown}
          {rewardsBalance}
        </div>
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        {subTotal}
        {checkoutButton}
        {ticketsGuaranteedDescription}
      </div>
    </div>
    {!!ticketAlertModalProps && <TicketAlertModal {...ticketAlertModalProps} />}
    <LimitedAccessModal
      theme={theme}
      listingDetailMetadata={listingDetailMetadata}
    />
  </>);
};
