import React, { ReactNode } from 'react';
import { usePresenter } from './ListingCardPricing.presenter';
import type { ListingCardPricingProps } from './ListingCardPricing.types';
import styles from './ListingCardPricing.module.scss';
import Text from '../../atoms/Text';
import { largerTextProps, smallerTextProps } from '../ListingCard/ListingCard.constants';
import { useTranslation } from 'react-i18next';

export const ListingCardPricing: React.FC<ListingCardPricingProps> = (props) => {
  const {
    isPayWithRewardsOnly,
    isCashOnlyAccount,
    formattedLoyaltyPrice,
    loyaltyUnitName,
    formattedCashPrice,
  } = usePresenter(props);

  const { t } = useTranslation();

  // Render small "each" text
  const eachText: ReactNode = (
    <Text
      {...smallerTextProps}
      value={t('listingCard.each')}
    />
  );

  // Conditionally render "or" text if the account allows mixed payment methods
  const orText: ReactNode = !isCashOnlyAccount && <Text
    {...smallerTextProps}
    value={t('listingCard.or')}
  />;

  const loyaltyPriceContent: ReactNode = <Text
    {...largerTextProps}
    value={formattedLoyaltyPrice}
  />;

  const cashPriceContent: ReactNode = <Text
    {...largerTextProps}
    value={formattedCashPrice}
  />;

  // Render pricing if payment is restricted to rewards only
  if (isPayWithRewardsOnly) {
    return (<>
      <div className={styles.listingCardPricingContainer}>
        {loyaltyPriceContent}
        {!!loyaltyUnitName && (
          <Text
            {...smallerTextProps}
            value={loyaltyUnitName}
          />
        )}
      </div>
      {eachText}
    </>);
  }

  // Render pricing if the account can only pay with cash
  if (isCashOnlyAccount) {
    return (<>
      <div className={styles.listingCardPricingContainer}>
        {cashPriceContent}
      </div>
    </>);
  }

  // For accounts that can pay with cash or loyalty points
  return (
    <>
      <div className={styles.listingCardPricingContainer}>
        {cashPriceContent}
        {orText}
      </div>
      <>
        <Text
          {...smallerTextProps}
          value={`${formattedLoyaltyPrice} ${loyaltyUnitName}`.trim()}
        />
        {eachText}
      </>
    </>
  );
};
