import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { LAST_VISITED_URL_KEY, SECOND_LAST_VISITED_URL_KEY } from './localstorage.constants';

/**
 * A custom hook to track the user's navigation history by storing the last visited
 * and second last visited URLs in local storage.
 *
 * Why track the second last URL?
 * - When performing redirects (e.g., to a sign-in page), `LAST_VISITED_URL` will reflect
 *   the current page (right before the redirect) rather than the page the user visited before that.
 * - Storing the second last URL ensures you can accurately reconstruct the user's navigation flow
 *   and go to the last valid page when user has no access to the event.
 */
export const useUrlTracker = (): {
  lastVisitedUrl: string;
  secondLastVisitedUrl: string;
} => {
  const location = useLocation();

  const [lastVisitedUrl, setLastVisitedUrl] = useLocalStorage(LAST_VISITED_URL_KEY, '');
  const [secondLastVisitedUrl, setSecondLastVisitedUrl] = useLocalStorage(SECOND_LAST_VISITED_URL_KEY, '');

  useEffect(() => {
    // Do nothing if URL contains 'state' query parameter.
    // This happens when CP1 identity service redirects user back to the home page after sign-in.
    // We do not want to track that redirect so we exit this useEffect.
    const currentQueryParams = new URLSearchParams(location.search);
    if (currentQueryParams.get('state')) {
      return;
    }

    const currentUrl: string = window.location.href;
    const previousUrl: string = lastVisitedUrl;

    // Store the current URL as the last visited URL in local storage
    setLastVisitedUrl(currentUrl);

    // Extract path name from the previous URL
    let previousUrlPathName: string | undefined;
    try {
      previousUrlPathName = new URL(previousUrl).pathname;
    } catch {
      // Do nothing
    }

    // If path names change then store the last visited URL as the second last visited URL in local storage
    if (previousUrlPathName && previousUrlPathName !== window.location.pathname) {
      setSecondLastVisitedUrl(previousUrl);
    }
  }, [location, lastVisitedUrl, setLastVisitedUrl, setSecondLastVisitedUrl]);

  return { lastVisitedUrl, secondLastVisitedUrl };
};
