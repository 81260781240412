import type { ArrowDirectionEnum } from '../../../lib/accessibility';
import { runInNextCycle } from '../../../lib/util';
import type { AccountCardDetail } from '../../../modules/auth/types';
import type { AccountCardProps } from '../AccountCard';
import styles from './AccountCards.module.scss';

/**
 * Builds an array of account card props.
 * @returns {AccountCardProps[]} Array of account card props.
 */
export const getAccountCardProps = (params: {
  /** Account card details */
  accountCardDetails: AccountCardDetail[];
  /** Array of refs for card elements */
  arrayOfCardRefs: HTMLElement[];
}): AccountCardProps[] => {
  const { accountCardDetails, arrayOfCardRefs } = params;

  return accountCardDetails.map((accountCardDetail: AccountCardDetail, key: number) => ({
    ...accountCardDetail,
    key,
    theme: 'subduedDark',
    areRewardUnitsBold: false,
    className: styles.accountCard,
    onArrowNavigation: (direction: ArrowDirectionEnum) => {
      const nextCardIndex = direction === 'ArrowDown' ? key + 1 : direction === 'ArrowUp' ? key - 1 : undefined;
      if (nextCardIndex !== undefined) {
        // Move to the next card
        const nextCard = accountCardDetails?.[nextCardIndex];
        if (nextCard) {
          // Focus the next card's element
          runInNextCycle(() => {
            const nextRef = arrayOfCardRefs[nextCardIndex];
            if (nextRef) {
              nextRef.focus();
            }
          });
        }
      }
    },
  }));
};
