import { useMemo } from 'react';
import type { SearchSuggestionSectionProps } from '../../organisms/SearchSuggestionSection';
import type { SearchSuggestionBlockPresenterProps, SearchSuggestionBlockProps } from './SearchSuggestionBlock.types';

export const usePresenter = (props: SearchSuggestionBlockProps): SearchSuggestionBlockPresenterProps => {
  const { events, performers, venues } = props;

  /**
   * Combines and filters the search suggestion sections (events, performers, venues)
   * into a single array, maintaining their specific order.
   * Sections that are undefined are filtered out.
   */
  const searchSuggestionSections: SearchSuggestionSectionProps[] = useMemo(() => {
    return [events, performers, venues].filter(Boolean) as SearchSuggestionSectionProps[];
  }, [events, performers, venues]);

  return {
    ...props,
    searchSuggestionSections,
  };
};
