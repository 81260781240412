import type { IconProps } from '../Icon';
import type { ImageProps } from '../Image';
import type { TextProps } from '../Text';

type ButtonInternalProps = {
  leadingIcon?: IconProps;
  icon?: IconProps;
  text?: TextProps;
  image?: ImageProps;
};

type ButtonPropsValues = {
  'IconTextMedium': ButtonInternalProps;
  'IconTextSmall': ButtonInternalProps;
  'IconTextTextOnGreyExtraSmall': ButtonInternalProps;
  'IconTextTextExtraSmall': ButtonInternalProps;
  'IconTextTextSmall': ButtonInternalProps;
  'TextIconTextMedium': ButtonInternalProps;
  'TextIconTextSmall': ButtonInternalProps;
  'TextIconTextUnderlineSmall': ButtonInternalProps;
  'TextTextUnderlineSmall': ButtonInternalProps;
  'TextIconTextWhiteSmall': ButtonInternalProps;
  'TextIconTextCharcoal10Small': ButtonInternalProps;
  'TextIconTextWhiteExtraSmall': ButtonInternalProps;
  'TextContainedGreenLarge': ButtonInternalProps;
  'TextContainedGreenMedium': ButtonInternalProps;
  'TextContainedLarge': ButtonInternalProps;
  'TextContainedMedium': ButtonInternalProps;
  'TextContainedWhiteLarge': ButtonInternalProps;
  'TextContainedWhiteMedium': ButtonInternalProps;
  'TextOutlinePillMedium': ButtonInternalProps;
  'TextOutlineMedium': ButtonInternalProps;
  'TextOutlineSmall': ButtonInternalProps;
  'TextTextDarkMedium': ButtonInternalProps;
  'TextTextGreySmall': ButtonInternalProps;
  'TextTextMedium': ButtonInternalProps;
  'TextTextSmall': ButtonInternalProps;
  'TextTextWhiteMedium': ButtonInternalProps;
  'TextTextWhiteSmall': ButtonInternalProps;
  'IconTextIconContainedMedium': ButtonInternalProps;
  'ImageTextIconTextMedium': ButtonInternalProps;
};

const propValues: ButtonPropsValues = {
  'IconTextMedium': {
    icon: {
      asset: 'CloseCircle',
      style: 'White',
    },
  },
  'IconTextSmall': {
    icon: {
      asset: 'SearchSmall',
      style: 'White',
    },
  },
  'IconTextTextOnGreyExtraSmall': {
    icon: {
      asset: 'ChevronLeft',
      style: 'ActionHover',
    },
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
  },
  'IconTextTextExtraSmall': {
    icon: {
      asset: 'ChevronLeft',
      style: 'ActionBase',
    },
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'IconTextTextSmall': {
    icon: {
      asset: 'ChevronLeft',
      style: 'ActionBase',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionActive',
      align: 'Left',
    },
  },
  'TextIconTextMedium': {
    text: {
      type: 'Decoration',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
  'TextIconTextSmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
  'TextIconTextUnderlineSmall': {
    text: {
      type: 'Decoration',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionHover',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
  'TextTextUnderlineSmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'TextIconTextWhiteSmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
  'TextIconTextCharcoal10Small': {
    text: {
      size: 'Medium',
      style: 'Regular',
      colour: 'Charcoal10',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'Charcoal10',
    },
  },
  'TextIconTextWhiteExtraSmall': {
    text: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
  'TextContainedGreenLarge': {
    text: {
      type: 'ButtonText',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'TextContainedGreenMedium': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'TextContainedLarge': {
    text: {
      type: 'ButtonText',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'TextContainedMedium': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'TextContainedWhiteLarge': {
    text: {
      type: 'ButtonText',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'TextContainedWhiteMedium': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'TextOutlinePillMedium': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'TextOutlineMedium': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'TextOutlineSmall': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'TextTextDarkMedium': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
  },
  'TextTextGreySmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  },
  'TextTextMedium': {
    text: {
      type: 'ButtonText',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'TextTextSmall': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  },
  'TextTextWhiteMedium': {
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'TextTextWhiteSmall': {
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'IconTextIconContainedMedium': {
    leadingIcon: {
      asset: 'Explore',
      style: 'White',
    },
    icon: {
      asset: 'ChevronRight',
      style: 'White',
    },
    text: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  },
  'ImageTextIconTextMedium': {
    text: {
      type: 'Decoration',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'ActionHover',
    },
  },
};

const getProps = (type: string): ButtonInternalProps => {
  const values: ButtonInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
