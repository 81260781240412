import type { AriaRole, KeyboardEvent, KeyboardEventHandler, SyntheticEvent } from 'react';

export type ArrowDirectionEnum = 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight';

export type AccessibilityActions = {
  onClick?: (event: SyntheticEvent) => void;
  onTab?: (forward: boolean) => void;
  onEsc?: () => void;
  onArrowNavigation?: (direction: ArrowDirectionEnum) => void;
};

export type AccessibilityProps = {
  tabIndex?: number;
  role?: AriaRole;
  onKeyDown?: KeyboardEventHandler<unknown>;
};
/**
 * Generates accessibility properties for clickable elements.
 * @param {boolean} [addIndex] -
 * @param {AriaRole} [role='button'] - ARIA role attribute to be assigned to the element.
 * @returns {AccessibilityProps}
 */

export const getClickAccessibilityProps = (params: {
  /** Object containing action handlers for various key events. */
  actions: AccessibilityActions;
  /** If true, sets the tabIndex to 0 to make the element focusable. */
  addIndex?: boolean;
  /** Accessibility properties including ARIA role and key event handlers. */
  role?: AriaRole;
}): AccessibilityProps => {
  const { actions, addIndex, role } = params;
  // Initialize the element properties with default role 'button' or provided role
  const elementProps: AccessibilityProps = {
    role: role || 'button',
    onKeyDown: (event: KeyboardEvent) => {
      // Handle different key events
      switch (event.key) {
        case 'Enter':
        case ' ':
        case 'Spacebar':
          // Trigger onClick action if defined
          if (actions.onClick) {
            actions.onClick(event);
          }
          break;
        case 'Escape':
          // Trigger onEsc action if defined
          if (actions.onEsc) {
            actions.onEsc();
          }
          break;
        case 'Tab':
          // Trigger onTab action if defined, passing a boolean indicating the direction of tabbing
          if (actions.onTab) {
            actions.onTab(!event.shiftKey);
          }
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          if (actions.onArrowNavigation) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            actions.onArrowNavigation(event.key);
          }
          break;
      }
    },
  };

  // Add tabIndex property if addIndex is true to make the element focusable
  if (addIndex) {
    elementProps.tabIndex = 0;
  }

  // Return the constructed accessibility properties
  return elementProps;
};
