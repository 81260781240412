import { useCallback, useContext, useMemo } from 'react';
import { AuthContext } from '../../../modules/auth';
import type { AccountCardProps } from '../AccountCard';
import type { RewardsBalancePresenterProps, RewardsBalanceProps } from './RewardsBalance.types';
import { getAccountCardProps } from './RewardsBalance.utils';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { SELECTED_CARD_INDEX_PARAM } from '../../pages/ExclusiveEventsPage';

export const usePresenter = (props: RewardsBalanceProps): RewardsBalancePresenterProps => {
  const { theme, listingTags, eventTags } = props;

  const { selectedAccountCardIndex, selectAccountCardByIndex, accountCardDetails } = useContext(AuthContext);
  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  const accountCardProps: AccountCardProps[] = useMemo(() => getAccountCardProps({ accountCardDetails, theme, listingTags, eventTags }), [accountCardDetails, theme, listingTags, eventTags]);

  /** Callback function to handle changes in quantity selection */
  const onAccountCardSelectionChange = useCallback((value: number) => {
    selectAccountCardByIndex(value);
    appendQueryParamsToUrl({ [SELECTED_CARD_INDEX_PARAM]: String(value) });
  }, [appendQueryParamsToUrl, selectAccountCardByIndex]);

  return {
    ...props,
    selectedAccountCardIndex,
    onAccountCardSelectionChange,
    accountCardProps,
  };
};
