import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { AriaRoles } from '../../../lib/types';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import styles from './HighlightedMessage.module.scss';
import { usePresenter } from './HighlightedMessage.presenter';
import type { HighlightedMessageProps } from './HighlightedMessage.types';

export const HighlightedMessage: React.FC<HighlightedMessageProps> = (props) => {
  const {
    iconAsset,
    iconStyle,
    content,
    onClick,
    className,
    classes,
  } = usePresenter(props);

  const details: ReactNode = <>
    <Icon
      asset={iconAsset}
      style={iconStyle}
      className={cx(styles.icon, classes?.icon)}
    />
    <Text
      size='Medium'
      style='Regular'
      colour='BaseDark'
      value={content}
      ariaRole={AriaRoles.ALERT}
      ariaAtomic={true}
      className={classes?.content}
    />
  </>;

  return !!onClick
    ? <button
      className={className}
      onClick={onClick}>
      {details}
    </button>
    : <div
      className={className}>
      {details}
    </div>;
};
