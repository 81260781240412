import cx from 'classnames';
import React from 'react';
import { CATEGORIES_WITH_NO_SUB_CATEGORIES } from '../../../modules/navigation/Navigation.constants';
import Divider from '../../atoms/Divider';
import { Spinner } from '../../atoms/Spinner';
import SportsMenuTabList from '../../molecules/SportsMenuTabList';
import SubmenuWithoutTabs from '../SubmenuWithoutTabs';
import SubmenuWithTabs from '../SubmenuWithTabs';
import styles from './SubmenuWithDoubleTabs.module.scss';
import useSubmenuWithDoubleTabsPresenter from './SubmenuWithDoubleTabs.presenter';
import getProps from './SubmenuWithDoubleTabs.props';
import type { SubmenuWithDoubleTabsCombinedProps } from './types';

const SubmenuWithDoubleTabs: React.FC<SubmenuWithDoubleTabsCombinedProps> = (props) => {
  const {
    hasCategoryMenuItemStates,
    sportsMenuTabList,
    dividerOne,
    activeMenu,
    submenuWithTabs,
    submenuWithoutTabs,
    className,
    classes,
  } = useSubmenuWithDoubleTabsPresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.submenuWithDoubleTabs;

  const isCategoryWithoutSubcategories = CATEGORIES_WITH_NO_SUB_CATEGORIES.includes(String(activeMenu?.id));

  const activeMenuView = isCategoryWithoutSubcategories ? <SubmenuWithoutTabs {...submenuWithoutTabs} /> : <SubmenuWithTabs {...submenuWithTabs} />;

  return (
    <div
      className={cx(currentStyle, className)}
      role='menu'
      aria-label={activeMenu?.name || 'Menu'}
    >
      {hasCategoryMenuItemStates
        ? (
          <div className={cx(styles.content, classes?.content)}>
            <div
              className={cx(styles.leftContent, classes?.leftContent)}
              role='group'
            >
              <SportsMenuTabList
                className={cx(styles.sportsMenuTabList, classes?.sportsMenuTabList)}
                {...internalProps.sportsMenuTabList}
                {...sportsMenuTabList} />
            </div>
            <Divider
              className={cx(styles.dividerOne, classes?.dividerOne)}
              {...internalProps.dividerOne}
              {...dividerOne} />
            <div
              className={cx(styles.rightContent, classes?.rightContent)}
              role='group'
            >
              {activeMenuView}
            </div>
          </div>
        )
        : (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        )
      }
    </div>
  );
};

export default SubmenuWithDoubleTabs;
