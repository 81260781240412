import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import styles from './SearchButton.module.scss';
import { usePresenter } from './SearchButton.presenter';
import type { SearchButtonProps } from './SearchButton.types';

export const SearchButton: React.FC<SearchButtonProps> = (props) => {
  const { onSearchButtonClick } = usePresenter(props);

  const { t } = useTranslation();

  return (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      icon={{ asset: 'SearchSmall' }}
      ariaLabel={t('topnav.searchButtonReaderText')}
      className={styles.searchButton}
      classes={{ icon: styles.searchButtonIcon }}
      onClick={onSearchButtonClick}
    />
  );
};
