import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import customStyles from './Custom.module.scss';
import styles from './Text.module.scss';
import usePresenter from './Text.presenter';
import { TextCombinedProps } from './types';

const Text: React.FC<TextCombinedProps> = (props) => {
  const {
    type = 'Body',
    size,
    style,
    colour,
    align = 'Left',
    className,
    classes,
    value,
    id,
    textRef,
    ariaLabel,
    ariaRole,
    ariaAtomic = false,
    shouldFocus = false,
  } = usePresenter(props);

  const variantName = `${type}${size}${style}${colour}${align}`;
  const currentStyle = styles[`text${variantName}`];

  const { t } = useTranslation();

  let componentView: JSX.Element;

  switch (variantName) {
    case 'BodyExtraExtraSmallRegularDisabledCenter':
    case 'BodyExtraExtraSmallRegularSubduedDarkCenter':
    case 'BodyExtraSmallRegularActionBaseLeft':
    case 'BodyExtraSmallRegularBaseDarkLeft':
    case 'BodyExtraSmallRegularCoreBlue50Left':
    case 'BodyExtraSmallRegularInteractionBlue70Left':
    case 'BodyExtraSmallSemiBoldDisabledCenter':
    case 'BodyExtraSmallSemiBoldNegativeLeft':
    case 'BodyExtraSmallSemiBoldSubduedDarkCenter':
    case 'BodyExtraSmallSemiBoldSubduedDarkLeft':
    case 'BodyExtraSmallSemiBoldWarningLeft':
    case 'BodyLargeRegularActionActiveLeft':
    case 'BodyLargeRegularActionBaseCenter':
    case 'BodyLargeRegularActionBaseLeft':
    case 'BodyLargeRegularActionBaseRight':
    case 'BodyLargeRegularActionHoverLeft':
    case 'BodyLargeRegularBaseDarkCenter':
    case 'BodyLargeRegularBaseDarkLeft':
    case 'BodyLargeRegularBaseLightCenter':
    case 'BodyLargeRegularBaseLightLeft':
    case 'BodyLargeRegularDisabledCenter':
    case 'BodyLargeRegularDisabledLeft':
    case 'BodyLargeRegularNegativeLeft':
    case 'BodyLargeRegularSubduedDarkCenter':
    case 'BodyLargeRegularSubduedDarkLeft':
    case 'BodyLargeRegularSubduedLightCenter':
    case 'BodyLargeRegularSubduedLightLeft':
    case 'BodyLargeRegularCoreBlue40Center':
    case 'BodyLargeSemiBoldActionBaseLeft':
    case 'BodyLargeSemiBoldActionBaseCenter':
    case 'BodyLargeSemiBoldBaseDarkLeft':
    case 'BodyLargeSemiBoldBaseDarkRight':
    case 'BodyLargeSemiBoldBaseLightLeft':
    case 'BodyLargeSemiBoldBaseLightRight':
    case 'BodyLargeSemiBoldBaseLightCenter':
    case 'BodyLargeSemiBoldDisabledLeft':
    case 'BodyLargeSemiBoldSubduedDarkLeft':
    case 'BodyLargeSemiBoldSubduedLightLeft':
    case 'BodyMediumRegularActionBaseLeft':
    case 'BodyMediumRegularActionHoverLeft':
    case 'BodyMediumRegularBaseDarkCenter':
    case 'BodyMediumRegularBaseDarkLeft':
    case 'BodyMediumRegularBaseLightCenter':
    case 'BodyMediumRegularBaseLightLeft':
    case 'BodyMediumRegularBrandLeft':
    case 'BodyMediumRegularCharcoal10Left':
    case 'BodyMediumRegularDisabledLeft':
    case 'BodyMediumRegularNegativeLeft':
    case 'BodyMediumRegularPositiveLeft':
    case 'BodyMediumRegularSubduedDarkLeft':
    case 'BodyMediumRegularSubduedDarkRight':
    case 'BodyMediumRegularSubduedLightCenter':
    case 'BodyMediumRegularSubduedLightLeft':
    case 'BodyMediumSemiBoldDisabledCenter':
    case 'BodyMediumSemiBoldSubduedDarkCenter':
    case 'BodyMediumSemiBoldSubduedDarkLeft':
    case 'BodySmallRegularActionBaseLeft':
    case 'BodySmallRegularActionBaseCenter':
    case 'BodySmallRegularBaseLightCenter':
    case 'BodySmallRegularBaseLightLeft':
    case 'BodySmallRegularCharcoal10Right':
    case 'BodySmallRegularCharcoal20Left':
    case 'BodySmallRegularCharcoal30Left':
    case 'BodySmallRegularDisabledCenter':
    case 'BodySmallRegularNegativeLeft':
    case 'BodySmallRegularSubduedDarkCenter':
    case 'BodySmallRegularSubduedDarkLeft':
    case 'BodySmallRegularSubduedLightCenter':
    case 'BodySmallRegularSubduedLightLeft':
    case 'BodySmallRegularSubduedLightRight':
    case 'BodySmallSemiBoldActionLeft':
    case 'BodySmallSemiBoldBaseLightLeft':
    case 'BodySmallSemiBoldDisabledCenter':
    case 'BodySmallSemiBoldNegativeLeft':
    case 'BodySmallSemiBoldNegativeCenter':
    case 'BodySmallSemiBoldPositiveLeft':
    case 'BodySmallSemiBoldSubduedDarkCenter':
    case 'BodySmallSemiBoldSubduedDarkLeft':
    case 'BodySmallSemiBoldWarningLeft':
    case 'BodySmallSemiBoldCustomYellow10Left':
    case 'BodySmallSemiBoldInteractionRed60Left':
    case 'ButtonTextMediumRegularActionActiveLeft':
    case 'ButtonTextMediumRegularActionBaseLeft':
    case 'ButtonTextMediumRegularActionHoverLeft':
    case 'ButtonTextMediumRegularBaseLightLeft':
    case 'ButtonTextMediumRegularBaseLightCenter':
    case 'ButtonTextMediumRegularDisabledLeft':
    case 'ButtonTextMediumRegularUnderlineActionActiveLeft':
    case 'ButtonTextMediumRegularUnderlineActionHoverLeft':
    case 'CardBodyLargeRegularSubduedDarkLeft':
    case 'CardBodyMediumRegularBaseLightLeft':
    case 'CardBodyMediumRegularBaseDarkLeft':
    case 'CardBodyMediumRegularPositiveLeft':
    case 'CardBodyMediumRegularSubduedLightLeft':
    case 'CardBodyMediumRegularSubduedDarkLeft':
    case 'CardBodyMediumRegularCharcoal10Left':
    case 'CardBodySmallRegularSubduedLightLeft':
    case 'ContactBodyLargeRegularSubduedDarkLeft':
    case 'ContactBodyLargeSemiBoldSubduedDarkLeft':
    case 'DecorationLargeRegularSubduedDarkLeft':
    case 'DecorationLargeRegularSubduedDarkRight':
    case 'DecorationMediumRegularActionActiveLeft':
    case 'DecorationMediumRegularActionHoverLeft':
    case 'DecorationMediumRegularBaseLightLeft':
    case 'DecorationMediumSemiBoldBaseLightLeft':
    case 'DecorationMediumSemiBoldSubduedDarkLeft':
    case 'DecorationSmallRegularActionActiveLeft':
    case 'DecorationSmallRegularBaseLightLeft':
    case 'MLBCardTitleMediumSemiBoldBaseLightLeft':
    case 'NewBannerBodyMediumRegularBaseLightLeft':
    case 'BodyMediumRegularSubduedDarkCenter':
    case 'BodyLargeRegularUnderlineActionHoverLeft':
    case 'BodyMediumSemiBoldBaseLightLeft':
    case 'BodyMediumSemiBoldBaseDarkLeft': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          tabIndex={shouldFocus ? 0 : -1}
          id={id}
          ref={textRef}>
          <p
            aria-label={ariaLabel}
            role={ariaRole}
            aria-atomic={ariaAtomic}
            className={value === t('breakdown.deliveryFeeFree') ? customStyles.valueFree : cx(styles.value, classes?.value)}>
            {value}
          </p>
        </div>
      );
      break;
    }
    case 'BannerHeadingSmallSemiBoldActionBaseLeft':
    case 'BannerSubheadingLargeLightBaseLightLeft':
    case 'HeadingSmallLightBaseDarkLeft':
    case 'HeadingSmallRegularBaseLightCenter':
    case 'HeadingSmallRegularSubduedDarkLeft':
    case 'NewBannerHeadingMediumRegularBaseDarkLeft':
    case 'NewBannerHeadingMediumRegularBaseLightLeft':
    case 'NewEventDetailsTitleMediumSemiBoldBaseLightLeft':
    case 'SubheadingLargeLightSubduedDarkCenter':
    case 'SubheadingLargeLightSubduedDarkLeft':
    case 'SubheadingLargeRegularBaseLightCenter':
    case 'SubheadingLargeRegularBaseLightLeft':
    case 'SubheadingLargeRegularSubduedDarkLeft':
    case 'SubheadingLargeRegularSubduedLightLeft':
    case 'SubheadingLargeSemiBoldSubduedDarkLeft':
    case 'SubheadingLargeSemiBoldActionBaseLeft':
    case 'SubheadingLargeSemiBoldBaseLightLeft':
    case 'BannerSubheadingLargeRegularBaseLightLeft':
    case 'HeadingSmallLightBaseLightLeft':
    case 'HeadingSmallSemiBoldActionBaseLeft':
    case 'HeadingSmallSemiBoldBaseLightLeft':
    case 'NewBannerHeadingLargeRegularBaseDarkLeft':
    case 'NewBannerHeadingLargeRegularBaseLightLeft':
    case 'NewEventDetailsTitleLargeSemiBoldSubduedDarkLeft':
    case 'NewEventDetailsTitleLargeSemiBoldSubduedLightLeft':
    case 'NewEventDetailsTitleLargeSemiBoldBaseLightLeft': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          tabIndex={shouldFocus ? 0 : -1}
          id={id}
          ref={textRef}>
          <h1
            aria-label={ariaLabel}
            role={ariaRole}
            aria-atomic={ariaAtomic}
            className={cx(styles.value, classes?.value)}>
            {value}
          </h1>
        </div>
      );
      break;
    }
    case 'SubheadingMediumLightSubduedDarkLeft':
    case 'SubheadingMediumLightSubduedLightLeft':
    case 'SubheadingMediumLightSubduedLightRight':
    case 'SubheadingMediumRegularActionActiveLeft':
    case 'SubheadingMediumRegularActionBaseLeft':
    case 'SubheadingMediumRegularActionHoverLeft':
    case 'SubheadingMediumRegularBaseLightLeft':
    case 'SubheadingMediumRegularDisabledLeft':
    case 'SubheadingMediumRegularSubduedDarkLeft':
    case 'SubheadingMediumRegularSubduedDarkRight':
    case 'SubheadingMediumRegularUnderlineActionHoverLeft':
    case 'SubheadingMediumSemiBoldActionBaseLeft':
    case 'SubheadingMediumSemiBoldBaseLightCenter':
    case 'SubheadingMediumSemiBoldBaseLightLeft':
    case 'SubheadingMediumSemiBoldBaseDarkCenter':
    case 'SubheadingMediumSemiBoldBaseDarkLeft':
    case 'SubheadingMediumSemiBoldSubduedDarkCenter':
    case 'SubheadingMediumSemiBoldSubduedDarkLeft':
    case 'SubheadingSmallRegularBaseLightCenter':
    case 'SubheadingSmallRegularSubduedDarkCenter':
    case 'SubheadingSmallRegularSubduedDarkLeft':
    case 'SubheadingExtraSmallRegularSubduedLightLeft':
    case 'SubheadingExtraSmallRegularSubduedDarkLeft':
    case 'SubheadingSmallRegularBaseLightLeft':
    case 'SubheadingSmallRegularBaseDarkLeft': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          tabIndex={shouldFocus ? 0 : -1}
          id={id}
          ref={textRef}>
          <h2
            aria-label={ariaLabel}
            role={ariaRole}
            aria-atomic={ariaAtomic}
            className={cx(styles.value, classes?.value)}>
            {value}
          </h2>
        </div>
      );
      break;
    }
    case 'Subheading2MediumSemiBoldSubduedDarkLeft': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          tabIndex={shouldFocus ? 0 : -1}
          id={id}
          ref={textRef}>
          <h3
            aria-label={ariaLabel}
            role={ariaRole}
            aria-atomic={ariaAtomic}
            className={cx(styles.value, classes?.value)}>
            {value}
          </h3>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Text;
