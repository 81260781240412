import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { getFiveDigitsZipCode, getTwoDigitsCountryCode, useHtmlElementSize, useProgressiveImage, useWindowSize } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { updateNewUserExperience } from '../../../modules/auth/AuthApi';
import type { BillingAddress } from '../../organisms/AddressInfo';
import { getFormattedPhoneNumber } from '../../pages/CheckoutPage';
import type { AccountCardProps } from '../AccountCard';
import { DESKTOP_LINEAR_BACKGROUND_ORIENTATION, LAST_STEP, MAX_NUMBER_OF_CARDS_TO_DISPLAY_BILLING_ADDRESS, MOBILE_LINEAR_BACKGROUND_ORIENTATION, ONBOARDING_STEPS } from './OnBoardingModalContent.constants';
import type { BackgroundImageStyle, OnBoardingModalContentPresenterProps, OnBoardingModalContentProps } from './OnBoardingModalContent.types';
import { getAccountCardProps, getBackgroundImageStyle, getImageUrl } from './OnBoardingModalContent.utils';

export const usePresenter = (props: OnBoardingModalContentProps): OnBoardingModalContentPresenterProps => {
  const { closeOnBoardingModal } = props;

  const { account, isAccountLoading, accountCardDetails } = useContext(AuthContext);

  const { onError } = useAnalyticsManager();

  const { isMobile } = useWindowSize();

  // Track the current step of the onboarding process
  const [currentStep, setCurrentStep] = useState<number>(ONBOARDING_STEPS.StepOne);

  const [isContentLoading, setIsContentLoading] = useState(false);

  const isOnLastStep: boolean = useMemo(() => currentStep === LAST_STEP, [currentStep]);

  // Trigger an API call to update the new user experience if the user is on the first step
  const { isLoading: isUpdating } = useQuery(['updateNewUserExperience'], () => updateNewUserExperience(),
    {
      enabled: account?.new_user,
      onError,
    },
  );

  const handleNextButtonClick = useCallback(() => {
    // If it's the last step, close the modal
    if (isOnLastStep) {
      closeOnBoardingModal();
      return;
    }

    // Otherwise, proceed to the next step
    setIsContentLoading(true);
    setCurrentStep((prevStep: number) => prevStep + 1);
  }, [isOnLastStep, closeOnBoardingModal]);

  // Reset the loading state after 200ms delay
  useEffect(() => {
    if (isContentLoading) {
      setTimeout(() => setIsContentLoading(false), 200);
    }
  }, [isContentLoading]);

  const shouldShowBillingAddress: boolean = useMemo(() => accountCardDetails.length <= MAX_NUMBER_OF_CARDS_TO_DISPLAY_BILLING_ADDRESS, [accountCardDetails.length]);

  /** User's billing address object using data from the account, filling missing fields with empty strings */
  const billingAddress: BillingAddress = useMemo(
    () => ({
      firstName: account?.first_name || '',
      lastName: account?.last_name || '',
      streetAddress: account?.address?.address_line1 || '',
      streetAddressLine2: account?.address?.address_line2 || '',
      city: account?.address?.city || '',
      state: account?.address?.state_code || '',
      postalCode: getFiveDigitsZipCode({ countryCode: account?.address?.country_code || '', postalCode: account?.address?.postal_code || '' }),
      country: getTwoDigitsCountryCode(account?.address?.country_code || ''),
      phoneNumber: getFormattedPhoneNumber(account?.phone || ''),
    }),
    [account],
  );

  /** Fetch the current step's image URL using the `useProgressiveImage` hook */
  const currentStepImageUrl = useProgressiveImage(getImageUrl({ currentStep, isMobile }));

  /** Background image style based on the device type (mobile/desktop) */
  const backgroundImageStyle: BackgroundImageStyle = useMemo(() => getBackgroundImageStyle({
    direction: isMobile ? MOBILE_LINEAR_BACKGROUND_ORIENTATION : DESKTOP_LINEAR_BACKGROUND_ORIENTATION,
    imageUrl: currentStepImageUrl,
    isMobile,
  }), [isMobile, currentStepImageUrl]);

  /** Properties for the account cards */
  const accountCardProps: AccountCardProps[] = useMemo(() => getAccountCardProps({ accountCardDetails }), [accountCardDetails]);

  const leftContainerRef = useRef<HTMLDivElement>(null);
  const { scrollHeight: leftContainerScrollHeight } = useHtmlElementSize({ htmlElement: leftContainerRef.current });

  const imageContainerRef = useRef<HTMLDivElement>(null);

  // On desktops make image height the same as left container height so that image uses all available height (including the case when page is scrollable).
  useEffect(() => {
    if (imageContainerRef.current && leftContainerScrollHeight > 0) {
      // On mobiles reset height so that browser uses value from CSS files as normally
      imageContainerRef.current.style.height = isMobile ? '' : `${leftContainerScrollHeight}px`;
    }
  }, [isMobile, leftContainerScrollHeight]);

  return {
    ...props,
    currentStep,
    isLoading: isContentLoading || isAccountLoading || isUpdating,
    handleNextButtonClick,
    isMobile,
    email: account?.email || '',
    shouldShowBillingAddress,
    billingAddress,
    backgroundImageStyle,
    accountCardProps,
    leftContainerRef,
    imageContainerRef,
  };
};
