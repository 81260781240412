import { useCallback, type KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type { PaymentInfoPresenterProps, PaymentInfoProps } from './PaymentInfo.types';
import { ChangeConfirmationModalTextProps } from '../../atoms/BaseFilter';

export const usePresenter = (props: PaymentInfoProps): PaymentInfoPresenterProps => {
  const { t } = useTranslation();


  const onActionButtonKeyDown = useCallback((event: KeyboardEvent<HTMLElement>) => event.stopPropagation(), []);

  const changeCardConfirmationModalProps: ChangeConfirmationModalTextProps = {
    title: t('paymentInfo.changeCardConfirmation.title'),
    message: t('paymentInfo.changeCardConfirmation.message'),
    primaryButtonLabel: t('paymentInfo.changeCardConfirmation.primaryButtonLabel'),
  };

  return {
    ...props,
    onActionButtonKeyDown,
    changeCardConfirmationModalProps,
  };
};
