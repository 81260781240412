import cx from 'classnames';
import React from 'react';
import Text from '../../atoms/Text';
import { SearchSuggestionItem } from '../../molecules/SearchSuggestionItem';
import styles from './SearchSuggestionSection.module.scss';
import { usePresenter } from './SearchSuggestionSection.presenter';
import type { SearchSuggestionSectionProps } from './SearchSuggestionSection.types';

export const SearchSuggestionSection: React.FC<SearchSuggestionSectionProps> = (props) => {
  const {
    sectionTitle,
    searchSuggestionItems,
    itemRefs,
    onArrowNavigation,
  } = usePresenter(props);

  return (
    <div className={styles.searchSuggestionSection}>
      {/* Section Title */}
      <Text
        type='Subheading'
        size='Medium'
        style='SemiBold'
        colour='BaseDark'
        value={sectionTitle}
        className={cx(styles.sectionTitle)}
      />
      {/* Suggestion Items List */}
      {searchSuggestionItems.map((searchSuggestionItemProps, index) => (
        <SearchSuggestionItem
          key={index}
          ref={(el: HTMLAnchorElement | null) => {
            // Store references to suggestion items for keyboard navigation
            if (itemRefs?.current && el) {
              itemRefs.current.push(el);
            }
          }}
          {...searchSuggestionItemProps}
          onArrowNavigation={onArrowNavigation}
        />
      ))}
    </div>
  );
};
