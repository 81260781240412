import React from 'react';
import { isAccountCardValidForTags } from '../../../lib/eventUtils';
import type { AccountCardDetail } from '../../../modules/auth';
import { getAccountCardImageUrl } from '../../molecules/AccountCard';
import type { PresetFilterOption } from '../PresetFilter';
import styles from './PaymentInfo.module.scss';

export const checkIsPaymentOptionValidForCheckout = (params: {
  accountCardDetail: AccountCardDetail;
  isPayWithRewardsOnly: boolean;
  listingTags: string[];
  eventTags: string[];
  totalPricesInRewardsUnit: Record<string, number> | undefined;
}): boolean => {
  const { accountCardDetail, listingTags, eventTags, totalPricesInRewardsUnit, isPayWithRewardsOnly } = params;
  const { rewardUnitsTotal, accountLoyaltyUnitTag = '' } = accountCardDetail;

  const isAccountCardValid = isAccountCardValidForTags({ accountCardDetail, tags: listingTags, fallbackTags: eventTags });
  if (isAccountCardValid) {
    if (isPayWithRewardsOnly && totalPricesInRewardsUnit) {
      return (rewardUnitsTotal >= totalPricesInRewardsUnit[accountLoyaltyUnitTag]);
    }
    return true;
  }
  return false;
};

export const getPaymentFilterOptions = (params: {
  accountCardDetails: AccountCardDetail[];
  isPayWithRewardsOnly: boolean;
  listingTags: string[];
  eventTags: string[];
  totalPricesInRewardsUnit: Record<string, number> | undefined;
}): PresetFilterOption[] => {
  const { accountCardDetails, listingTags, eventTags, totalPricesInRewardsUnit, isPayWithRewardsOnly } = params;

  return accountCardDetails.map((accountCardDetail, index) => ({
    id: index,
    name: accountCardDetail.isCashOnlyAccountCard
      ? `•••• ${accountCardDetail.accountCardLastFourDigits}`
      : (
        <>
          {`•••• ${accountCardDetail.accountCardLastFourDigits} | `}
          <span className={styles.formattedRewardUnitsTotal}>{accountCardDetail.formattedRewardUnitsTotal}</span>
        </>
      ),
    imageUrl: getAccountCardImageUrl({ accountCardImageUrl: accountCardDetail.accountCardImageUrl, theme: 'subduedDark' }),
    imageAriaLabel: accountCardDetail.accountProgramType,
    isDisabled: !checkIsPaymentOptionValidForCheckout({ accountCardDetail, listingTags, eventTags, totalPricesInRewardsUnit, isPayWithRewardsOnly }),
    role: 'menuitemradio',
  }));
};