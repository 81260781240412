import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AriaLiveRegions, AriaRelevant } from '../../../lib/types';
import Text from '../../atoms/Text';
import { AccessibleLiveMessage } from '../../molecules/AccessibleLiveMessage';
import commonStyles from '../../pages/CheckoutPage/CheckoutPage.module.scss';
import styles from './PriceBreakdownInfo.module.scss';
import { usePresenter } from './PriceBreakdownInfo.presenter';
import type { PriceBreakdownInfoProps } from './PriceBreakdownInfo.types';

export const PriceBreakdownInfo: React.FC<PriceBreakdownInfoProps> = (props) => {
  const {
    mainCharge,
    rewardUnitCharge,
    ticketsLabel,
    formattedTotalTicketsPrice,
    formattedServiceFee,
    formattedDeliveryFee,
    formattedTax,
    formattedOrderTotal,
    faceValue,
    priceBreakdownText,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const priceBreakdownTitle: ReactNode = (
    <Text
      type='Body'
      size='Medium'
      style='SemiBold'
      colour='SubduedDark'
      value={t('priceBreakdownInfo.totalCharge')}
      className={styles.totalCharge}
    />
  );

  const mainChargeView: ReactNode = (
    <Text
      type='Decoration'
      size='Large'
      style='Regular'
      colour='SubduedDark'
      align='Right'
      value={mainCharge}
    />
  );

  const rewardUnitChargeView: ReactNode = !!rewardUnitCharge && (
    <Text
      size='Medium'
      style='Regular'
      colour='SubduedDark'
      align='Right'
      value={rewardUnitCharge}
    />
  );

  const tickets: ReactNode = (
    <div className={cx(commonStyles.row, styles.row)}>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedLight'
        value={ticketsLabel}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Right'
        value={formattedTotalTicketsPrice}
      />
    </div>
  );

  const serviceFee: ReactNode = (
    <div className={cx(commonStyles.row, styles.row)}>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedLight'
        value={t('priceBreakdownInfo.serviceFee')}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Right'
        value={formattedServiceFee}
      />
    </div>
  );

  const deliveryFee: ReactNode = (
    <div className={cx(commonStyles.row, styles.row)}>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedLight'
        value={t('priceBreakdownInfo.deliveryFee')}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Right'
        value={formattedDeliveryFee}
      />
    </div>
  );

  const tax: ReactNode = (
    <div className={cx(commonStyles.row, styles.row)}>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedLight'
        value={t('priceBreakdownInfo.tax')}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Right'
        value={formattedTax}
      />
    </div>
  );

  const orderTotal: ReactNode = (
    <div className={cx(commonStyles.row, styles.row)}>
      <Text
        size='Medium'
        style='SemiBold'
        colour='BaseDark'
        value={t('priceBreakdownInfo.orderTotal')}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        align='Right'
        value={formattedOrderTotal}
      />
    </div>
  );

  const faceValueView: ReactNode = !!faceValue && (
    <div className={styles.faceValue}>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedLight'
        value={t('priceBreakdownInfo.faceValue', { faceValue })}
      />
    </div>
  );

  return (<>
    <div className={cx(commonStyles.column, styles.priceBreakdownInfo, className)}>
      <div className={cx(commonStyles.row, styles.row)}>
        {priceBreakdownTitle}
        <div className={commonStyles.column}>
          {mainChargeView}
          {rewardUnitChargeView}
        </div>
      </div>
      <div className={cx(commonStyles.column, styles.priceBreakdown)}>
        {tickets}
        {serviceFee}
        {deliveryFee}
        {tax}
        {orderTotal}
      </div>
      {faceValueView}
    </div>
    {!!priceBreakdownText && (
      <AccessibleLiveMessage
        message={priceBreakdownText}
        ariaLiveRegion={AriaLiveRegions.POLITE}
        ariaRelevant={AriaRelevant.TEXT}
      />
    )}
  </>);
};
