import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CATEGORY_PARAM, PERFORMER_PARAM } from '../../../lib/constants';
import { EventListProvider } from '../../../modules/eventList';
import { AllEventsPageMLBBannerProps, Banner } from '../../molecules/Banner';
import type { EmptyStateProps } from '../../molecules/EmptyState';
import { DateFilter } from '../../organisms/DateFilter';
import type { PaginatedListClasses } from '../../organisms/PaginatedList';
import { PresetFilter } from '../../organisms/PresetFilter';
import { RegionFilter } from '../../organisms/RegionFilter';
import { TabBar } from '../../organisms/TabBar';
import { BaseEventListPage } from '../BaseEventListPage';
import baseEventListPageStyles from '../BaseEventListPage/BaseEventListPage.module.scss';
import { SELECTED_CARD_INDEX_PARAM } from '../ExclusiveEventsPage';
import { TRACKING_PAGE_NAME } from './AllEventsPage.constants';
import styles from './AllEventsPage.module.scss';
import { usePresenter } from './AllEventsPage.presenter';
import type { AllEventsPageProps } from './AllEventsPage.types';

const InternalAllEventsPage: React.FC<AllEventsPageProps> = (props) => {
  const {
    tabs,
    regions,
    regionFilterState,
    dateFilterState,
    shouldShowCategoryFilter,
    categoryFilterPlaceholderKey,
    categoryFilterAriaLabelKey,
    categoryFilterOptions,
    categoryFilterState,
    shouldShowPerformerFilter,
    performerFilterPlaceholderKey,
    performerFilterAriaLabelKey,
    performerFilterOptions,
    performerFilterState,
    isPerformerFilterDisabled,
    excludedSubCategoryName,
    className,
    shouldShowCardTypeFilter,
    cardTypeFilterState,
    cardTypeFilterOptions,
  } = usePresenter(props);

  const { t } = useTranslation();

  const categoryFilter: ReactNode = shouldShowCategoryFilter && (
    <div className={baseEventListPageStyles.filter}>
      <PresetFilter
        queryParamName={CATEGORY_PARAM}
        presetFilterState={categoryFilterState}
        presetFilterOptions={categoryFilterOptions}
        placeholderKey={categoryFilterPlaceholderKey}
        ariaLabel={t(`${categoryFilterAriaLabelKey}`)}
      />
    </div>
  );

  const performerFilter: ReactNode = shouldShowPerformerFilter && (
    <div className={baseEventListPageStyles.filter}>
      <PresetFilter
        queryParamName={PERFORMER_PARAM}
        presetFilterState={performerFilterState}
        presetFilterOptions={performerFilterOptions}
        placeholderKey={performerFilterPlaceholderKey}
        isDisabled={isPerformerFilterDisabled}
        ariaLabel={t(`${performerFilterAriaLabelKey}`)}
      />
    </div>
  );

  const cardTypeFilter: ReactNode = shouldShowCardTypeFilter && (
    <div className={baseEventListPageStyles.filter}>
      <PresetFilter
        queryParamName={SELECTED_CARD_INDEX_PARAM}
        presetFilterState={cardTypeFilterState}
        presetFilterOptions={cardTypeFilterOptions}
        theme='light'
        placeholderKey='cardFilter.card'
        isClearable={true}
        className={styles.filter}
        ariaLabel={t('cardFilter.ariaLabel')}
      />
    </div>
  );

  const topContent: ReactNode = (<>
    <div className={styles.tabsContainer}>
      <div className={styles.leftSpacer} />
      <TabBar tabs={tabs} />
      <div className={styles.rightSpacer} />
    </div>
    <div className={baseEventListPageStyles.filtersContainer}>
      <div className={baseEventListPageStyles.filter}>
        <RegionFilter
          regions={regions}
          regionFilterState={regionFilterState}
          canSearchRegion={true}
          canUseCurrentRegion={true}
        />
      </div>
      <div className={baseEventListPageStyles.filter}>
        <DateFilter dateFilterState={dateFilterState} />
      </div>
      {categoryFilter}
      {performerFilter}
      {cardTypeFilter}
    </div>
  </>);

  const mlbBanner: ReactNode = excludedSubCategoryName === 'MLB Baseball' && (
    <Banner {...AllEventsPageMLBBannerProps} />
  );

  const emptyStateProps: EmptyStateProps = {
    titleKey: 'allEventsPage.noResultsTitle',
    descriptionKey: 'allEventsPage.noResultsDescription',
  };

  const paginatedListClasses: PaginatedListClasses | undefined = excludedSubCategoryName === 'MLB Baseball'
    ? { itemList: styles.paginatedItemListWithBanner }
    : undefined;

  return (
    <BaseEventListPage
      topContent={topContent}
      topEventListContent={mlbBanner}
      // TODO: Check if we need to track analytics for fetch requests
      trackFetchAnalytics={undefined}
      emptyStateProps={emptyStateProps}
      pageName={TRACKING_PAGE_NAME.forEventCardClicks}
      className={className}
      classes={paginatedListClasses}
    />
  );
};

export const AllEventsPage: React.FC<AllEventsPageProps> = (props) => {
  return (
    <EventListProvider>
      <InternalAllEventsPage {...props} />
    </EventListProvider>
  );
};
