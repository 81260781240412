import React from 'react';
import { SearchSuggestionSection } from '../../organisms/SearchSuggestionSection';
import styles from './SearchSuggestionBlock.module.scss';
import { usePresenter } from './SearchSuggestionBlock.presenter';
import type { SearchSuggestionBlockProps } from './SearchSuggestionBlock.types';

export const SearchSuggestionBlock: React.FC<SearchSuggestionBlockProps> = (props) => {
  const {
    searchSuggestionSections,
    itemRefs,
    onArrowNavigation,
  } = usePresenter(props);

  if (itemRefs?.current) {
    itemRefs.current = []; // Clear previous refs
  }

  return (
    <div
      id='searchSuggestionsList'
      role='listbox'
      className={styles.searchSuggestionBlock}
    >
      <div className={styles.content}>
        {searchSuggestionSections.map((searchSuggestionSectionProps, index) => (
          <SearchSuggestionSection
            key={index}
            {...searchSuggestionSectionProps}
            itemRefs={itemRefs}
            onArrowNavigation={onArrowNavigation}
          />
        ))}
      </div>
    </div>
  );
};
