import 'flatpickr/dist/flatpickr.css';
import React, { type ReactNode } from 'react';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import { BaseFilter } from '../../atoms/BaseFilter';
import { usePresenter } from './DateFilter.presenter';
import type { DateFilterOptionValue, DateFilterProps, DateFilterState } from './DateFilter.types';
import { getDisplayValue } from './DateFilter.utils';
import './Flatpickr.custom.css';

export const DateFilter: React.FC<DateFilterProps> = (props) => {
  const {
    dateFilterState,
    filterOptions,
    onFilterOptionSelected,
    onFilterDropdownToggled,
    shouldShowDatePicker,
    onDateRangeChanged,
    isClearable = true,
    isDisabled = false,
    theme = 'light',
    className,
    classes,
    ariaLabel,
  } = usePresenter(props);

  const { t } = useTranslation();

  const datePicker: ReactNode = (
    <Flatpickr
      options={{ mode: 'range', inline: true, minDate: 'today' }}
      onChange={onDateRangeChanged}
    />
  );

  return (
    <BaseFilter<DateFilterState, DateFilterOptionValue>
      filterState={dateFilterState}
      getDisplayValue={getDisplayValue}
      placeholder={t('dateFilters.date')}
      filterDropdownTopContent={shouldShowDatePicker ? datePicker : undefined}
      filterOptions={filterOptions}
      onFilterOptionSelected={onFilterOptionSelected}
      onFilterDropdownToggled={onFilterDropdownToggled}
      isClearable={isClearable}
      isDisabled={isDisabled}
      theme={theme}
      className={className}
      classes={classes}
      ariaLabel={ariaLabel || t('dateFilters.ariaLabel')}
    />
  );
};
