import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AriaRoles } from '../../../lib/types';
import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';
import { LabelledContent } from '../../molecules/LabelledContent';
import { RadioButton } from '../../molecules/RadioButton';
import { RewardsOnlyPaymentInfo } from '../../molecules/RewardsOnlyPaymentInfo';
import commonStyles from '../../pages/CheckoutPage/CheckoutPage.module.scss';
import { SELECTED_CARD_INDEX_PARAM } from '../../pages/ExclusiveEventsPage';
import { CreditCardInfo } from '../CreditCardInfo';
import { PresetFilter } from '../PresetFilter';
import { RewardPaymentOptions } from './PaymentInfo.constants';
import styles from './PaymentInfo.module.scss';
import { usePresenter } from './PaymentInfo.presenter';
import type { PaymentInfoProps, RewardPaymentOption } from './PaymentInfo.types';

export const PaymentInfo: React.FC<PaymentInfoProps> = (props) => {
  const {
    paymentInfoType,
    formattedRewardUnitsTotal,
    rewardUnitName,
    rewardPaymentOption,
    selectRewardPaymentOption,
    rewardPaymentOptionErrorKey,
    rewardPaymentOptionErrorScope,
    formattedRewardUnitsToApply,
    formattedCashValueOfRewardUnitsToApply,
    shouldShowCardTypeFilter,
    cardTypeFilterState,
    cardTypeFilterOptions,
    isEditingRewardUnits,
    editedRewardUnitsToApplyStr,
    editedRewardUnitsToApplyInvalidCharsRegex,
    startEditingRewardUnits,
    onEditedRewardUnitsToApplyChanged,
    onEditedRewardUnitsToApplyBlur,
    saveEditedRewardUnits,
    cancelEditingRewardUnits,
    onActionButtonKeyDown,
    formattedTotalPriceInCash,
    formattedTotalPriceInRewardUnitsWithUnitName,
    accountCardLastFourDigits,
    setCreditCardData,
    isCreditCardInfoValidRef,
    className,
    changeCardConfirmationModalProps,
  } = usePresenter(props);

  const { t } = useTranslation();

  const cardTypeFilter: ReactNode = shouldShowCardTypeFilter && (
    <PresetFilter
      queryParamName={SELECTED_CARD_INDEX_PARAM}
      presetFilterState={cardTypeFilterState}
      presetFilterOptions={cardTypeFilterOptions}
      theme='light'
      placeholderKey='cardFilter.card'
      isClearable={false}
      className={styles.filter}
      isHighlightOnExpandOnly={true}
      isImageDisplayedInSelectedState={true}
      classes={{
        filterToggleWrapper: styles.filterToggleWrapper,
        filterToggle: styles.filterToggle,
        dropdownMenuBase: styles.dropdownMenuBase,
      }}
      changeCardConfirmationModalProps={changeCardConfirmationModalProps}
      ariaLabel={t('cardFilter.ariaLabel')}
    />
  );

  if (paymentInfoType === 'payWithRewardsOnly') {
    return (
      <>
        <div className={styles.cardTypeFilter}>{cardTypeFilter}</div>
        <RewardsOnlyPaymentInfo
          formattedTotalPriceInRewardUnitsWithUnitName={formattedTotalPriceInRewardUnitsWithUnitName}
          formattedTotalPriceInCash={formattedTotalPriceInCash}
        />
      </>
    );
  }

  if (paymentInfoType === 'creditCardOnly') {
    return (
      <>
        <div className={styles.cardTypeFilter}>{cardTypeFilter}</div>
        <CreditCardInfo
          accountCardLastFourDigits={accountCardLastFourDigits}
          setCreditCardData={setCreditCardData}
          isCreditCardInfoValidRef={isCreditCardInfoValidRef}
          cardTypeFilterState={cardTypeFilterState}
        />
      </>
    );
  }

  const paymentInfoTitleDescription: ReactNode = (
    <LabelledContent
      labelKey='paymentInfo.useYourRewards.title'
      labelTextType='Body'
      labelTextColour='SubduedDark'
      item={t('paymentInfo.useYourRewards.subTitle')}
      labelClassName={styles.paymentInfo}
    />
  );

  const editButton: ReactNode = !isEditingRewardUnits && (
    <Button
      type='Text'
      style='Text'
      size='Medium'
      focusable={true}
      text={{ value: t('paymentInfo.useYourRewards.edit.label') }}
      ariaLabel={t('paymentInfo.useYourRewards.edit.ariaLabel')}
      onClick={startEditingRewardUnits}
      onKeyDown={onActionButtonKeyDown}
      className={commonStyles.actionButton}
      classes={{ text: commonStyles.actionButtonText }}
    />
  );

  const saveButton: ReactNode = isEditingRewardUnits && (
    <Button
      type='Text'
      style='Text'
      size='Medium'
      focusable={true}
      text={{ value: t('paymentInfo.useYourRewards.save.label') }}
      ariaLabel={t('paymentInfo.useYourRewards.save.ariaLabel')}
      onClick={saveEditedRewardUnits}
      onKeyDown={onActionButtonKeyDown}
      className={commonStyles.actionButton}
      classes={{ text: commonStyles.actionButtonText }}
    />
  );

  const cancelButton: ReactNode = isEditingRewardUnits && (
    <Button
      type='Text'
      style='Text'
      size='Medium'
      focusable={true}
      text={{ value: t('paymentInfo.useYourRewards.cancel.label') }}
      ariaLabel={t('paymentInfo.useYourRewards.cancel.ariaLabel')}
      onClick={cancelEditingRewardUnits}
      onKeyDown={onActionButtonKeyDown}
      className={commonStyles.actionButton}
      classes={{ text: commonStyles.actionButtonText }}
    />
  );

  const actionButtons: ReactNode = (<>
    {isEditingRewardUnits
      ? (<div className={styles.noWrapContainer}>
        {saveButton}
        <Divider
          type='Vertical'
          style='Thin'
          colour='DigitalGrey10'
          className={styles.verticalDivider}
        />
        {cancelButton}
      </div>)
      : editButton
    }
  </>);

  const applyRewardsRadioButtonContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.radioButtonContent)}>
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedLight'
        value={(
          <Trans
            i18nKey='paymentInfo.useYourRewards.applyRewardsTitle'
            values={{ formattedRewardUnitsTotal }}
            components={{ bold: (<span className={styles.boldText} />) }}
          />
        )}
        ariaLabel={t('paymentInfo.useYourRewards.applyRewardsTitleScreenReaderText', { formattedRewardUnitsTotal })}
        classes={{ value: styles.radioButtonText }}
      />
      <div
        aria-label={t('paymentInfo.useYourRewards.applyRewardsDescriptionScreenReaderText', {
          formattedRewardUnitsToApply,
          rewardUnitName,
          formattedCashValueOfRewardUnitsToApply,
        })}
        className={cx(commonStyles.row, commonStyles.withWrap, styles.applyRewardsRadioButtonContent)}
      >
        <Text
          size='Medium'
          style='Regular'
          colour='SubduedLight'
          value={t('paymentInfo.useYourRewards.redeem')}
          classes={{ value: styles.radioButtonText }}
        />
        {isEditingRewardUnits
          ? (
            <TextInput
              state='Filled'
              style='Default'
              size='Regular'
              textValue={editedRewardUnitsToApplyStr}
              onTextChanged={onEditedRewardUnitsToApplyChanged}
              onBlurChanged={onEditedRewardUnitsToApplyBlur}
              removeChar={editedRewardUnitsToApplyInvalidCharsRegex}
              isAutoFocused={true}
              ariaLabel={t('paymentInfo.useYourRewards.rewardsTextInputScreenReaderText')}
              className={cx(commonStyles.textInput, styles.rewardsTextInput)}
            />
          )
          : (
            <Text
              size='Medium'
              style='Regular'
              colour='SubduedLight'
              value={formattedRewardUnitsToApply}
              classes={{ value: cx(styles.radioButtonText, styles.boldText, commonStyles.greenText) }}
            />
          )}
        <Text
          size='Medium'
          style='Regular'
          colour='SubduedLight'
          value={rewardUnitName}
          classes={{ value: styles.radioButtonText }}
        />
        <Text
          size='Medium'
          style='Regular'
          colour='SubduedLight'
          value={t('common.for')}
          classes={{ value: styles.radioButtonText }}
        />
        <Text
          size='Medium'
          style='Regular'
          colour='SubduedLight'
          value={formattedCashValueOfRewardUnitsToApply}
          classes={{ value: cx(styles.radioButtonText, styles.boldText, commonStyles.greenText) }}
        />
        {actionButtons}
      </div>
    </div>
  );

  const applyRewardsRadioButton: ReactNode = (
    <RadioButton<RewardPaymentOption>
      value={RewardPaymentOptions.ApplyRewards}
      isSelected={rewardPaymentOption === RewardPaymentOptions.ApplyRewards}
      onClick={selectRewardPaymentOption}
      theme='dark'
      ariaLabel={t('paymentInfo.useYourRewards.applyRewardsTitleScreenReaderText', { formattedRewardUnitsTotal })}
      className={cx(styles.radioButton, styles.applyRewardsRadioButton, {
        [commonStyles.boxError]: rewardPaymentOptionErrorScope === 'all' || rewardPaymentOptionErrorScope === 'applyRewards',
      })}
    >
      {applyRewardsRadioButtonContent}
    </RadioButton>
  );

  const doNotApplyRewardsRadioButtonContent: ReactNode = (
    <div className={styles.radioButtonContent}>
      <Text
        size='Medium'
        style='SemiBold'
        colour='BaseDark'
        value={t('paymentInfo.useYourRewards.doNotApplyMyRewards')}
        classes={{ value: styles.radioButtonText }}
      />
    </div>
  );

  const doNotApplyRewardsRadioButton: ReactNode = (
    <RadioButton<RewardPaymentOption>
      value={RewardPaymentOptions.DoNotApplyRewards}
      isSelected={rewardPaymentOption === RewardPaymentOptions.DoNotApplyRewards}
      onClick={selectRewardPaymentOption}
      theme='dark'
      ariaLabel={t('paymentInfo.useYourRewards.doNotApplyMyRewards')}
      className={cx(styles.radioButton, styles.doNotApplyRewardsRadioButton, {
        [commonStyles.boxError]: rewardPaymentOptionErrorScope === 'all' || rewardPaymentOptionErrorScope === 'doNotApplyRewards',
      })}
    >
      {doNotApplyRewardsRadioButtonContent}
    </RadioButton>
  );

  const rewardPaymentOptions: ReactNode = (
    <div className={styles.rewardPaymentOptions}>
      {applyRewardsRadioButton}
      {doNotApplyRewardsRadioButton}
    </div>
  );

  const topContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.topContent)}>
      {paymentInfoTitleDescription}
      {cardTypeFilter}
      {rewardPaymentOptions}
      {!!rewardPaymentOptionErrorKey && (
        <Text
          size='Medium'
          style='Regular'
          colour='Negative'
          value={t(rewardPaymentOptionErrorKey)}
          ariaRole={AriaRoles.ALERT}
          ariaAtomic={true}
        />
      )}
    </div>
  );

  const bottomContent: ReactNode = paymentInfoType === 'rewardSelectionAndCreditCard' && (
    <CreditCardInfo
      accountCardLastFourDigits={accountCardLastFourDigits}
      setCreditCardData={setCreditCardData}
      isCreditCardInfoValidRef={isCreditCardInfoValidRef}
      cardTypeFilterState={cardTypeFilterState}
    />
  );

  return (
    <div className={cx(commonStyles.column, className)}>
      {topContent}
      {bottomContent}
    </div>
  );
};
