import { useCallback, useContext } from 'react';
import { trackOptimizelyEvent } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { redirectToSignInURL } from '../../../modules/navigation/Navigation.utils';
import type { SignInDialogPresenterProps, SignInDialogProps } from './SignInDialog.types';

export const usePresenter = (props: SignInDialogProps): SignInDialogPresenterProps => {
  const { placement } = props;

  const { isSignedOut } = useContext(AuthContext);

  const onSignInDialogClick = useCallback(() => {
    trackOptimizelyEvent(`${placement}_dialog_box_sign_in`);
    redirectToSignInURL();
  }, [placement]);

  return {
    ...props,
    isSignInDialogShown: isSignedOut,
    onSignInDialogClick,
  };
};
