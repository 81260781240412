import type { SpinnerTheme } from '../../atoms/Spinner';
import type { CarouselTheme } from './Carousel.types';

/** If 90% of the element width is visible then we consider that element as fully visible */
export const VISIBILITY_PERCENTAGE_90 = 0.90;

/** If 97% of the element width is visible then we consider that element as fully visible */
export const VISIBILITY_PERCENTAGE_97 = 0.97;

export const SpinnerThemesByCarouselTheme = {
  light: 'dark',
  dark: 'light',
} as const satisfies Record<CarouselTheme, SpinnerTheme>;

export const NoOp = () => { };
