import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { BaseComponentProps } from '../../../lib/types';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { ToolTip } from '../../atoms/ToolTip';
import { Carousel } from '../../molecules/Carousel';
import { CarouselLinkColoursByTheme, CarouselLinkIconStylesByTheme, CarouselTitleColoursByTheme, ToolTipThemesByTheme } from './CarouselSection.constants';
import styles from './CarouselSection.module.scss';
import { usePresenter } from './CarouselSection.presenter';
import type { CarouselSectionProps } from './CarouselSection.types';

export const CarouselSection = <TComponentProps extends BaseComponentProps>(
  props: CarouselSectionProps<TComponentProps>,
) => {
  const presenterProps = usePresenter(props);

  const {
    htmlId,
    titleKey,
    linkLabelKey,
    linkHref,
    linkAriaLabelKey = '',
    onLinkClick,
    tooltipProps,
    isLoading,
    isError,
    isEmptyStateSupported,
    componentProps,
    theme,
    classes,
  } = presenterProps;

  const { t } = useTranslation();

  if (!isLoading && !isEmptyStateSupported && (!componentProps.length || isError)) {
    return null;
  }

  const title: React.ReactNode = (
    <Text
      type='Subheading'
      size='Small'
      style='Regular'
      colour={CarouselTitleColoursByTheme[theme]}
      value={t(titleKey)}
      className={classes?.carouselTitle}
    />
  );

  const tooltip: React.ReactNode = !!tooltipProps && (<>
    <ToolTip
      {...tooltipProps}
      theme={tooltipProps.theme || ToolTipThemesByTheme[theme]}
    >
      <Icon
        asset='InfoFilled'
        style='ActionBase'
      />
    </ ToolTip>
  </>);

  const linkButton: React.ReactNode = !!linkHref && (
    <Button
      type='TextIcon'
      style='Text'
      size='Small'
      text={{
        size: 'Large',
        style: 'Regular',
        colour: CarouselLinkColoursByTheme[theme],
        value: t(linkLabelKey),
      }}
      icon={{
        asset: 'ChevronRight',
        style: CarouselLinkIconStylesByTheme[theme],
        className: styles.carouselLinkIcon,
      }}
      href={linkHref}
      onClick={onLinkClick}
      ariaLabel={t(linkAriaLabelKey)}
    />
  );

  return (
    <div
      id={htmlId}
      className={styles.carouselSection}>
      <div className={cx(styles.carouselHeader, classes?.carouselHeader)}>
        <div className={styles.carouselTitleContainer}>
          {title}
          {tooltip}
        </div>
        {linkButton}
      </div>
      <Carousel<TComponentProps> {...presenterProps} />
    </div>
  );
};
