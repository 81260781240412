import React from 'react';
import { usePresenter } from './SvgMapSections.presenter';
import type { SvgMapSectionProps, SvgMapSectionsProps } from './SvgMapSections.types';

const InternalSvgMapSections: React.FC<SvgMapSectionsProps> = (props) => {
  const { svgMapSectionProps } = usePresenter(props);

  return (<>
    {svgMapSectionProps.map((currentSvgMapSectionProps: SvgMapSectionProps) => (
      <path
        {...currentSvgMapSectionProps}
        key={currentSvgMapSectionProps.key}
      />
    ))}
  </>);
};

/** Memoize SVG map sections since map renders a large number of them */
export const SvgMapSections = React.memo(InternalSvgMapSections);
