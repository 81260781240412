import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import styles from './PerformerCard.module.scss';
import { usePresenter } from './PerformerCard.presenter';
import type { PerformerCardProps } from './PerformerCard.types';

const InternalPerformerCard: React.FC<PerformerCardProps> = (props) => {
  const {
    performer,
    performerUrl,
    performerImage,
    className,
    onPerformerCardClick,
  } = usePresenter(props);

  return (
    <Link
      to={performerUrl}
      className={cx(styles.performerCard, className)}
      onClick={onPerformerCardClick}
      tabIndex={0} // Accessible through keyboard navigation for safari
    >
      <div className={styles.topContent}>
        <Image
          imageSrc={performerImage}
          className={styles.performerImage}
        />
      </div>
      <div className={styles.bottomContent}>
        <Text
          size='Medium'
          style='SemiBold'
          colour='BaseDark'
          value={performer.name}
          className={styles.performerName}
        />
      </div>
      <div className={styles.overlay} />
    </Link>
  );
};

/** Memoize performer card since the home page renders a large number of performer cards in carousels */
export const PerformerCard = React.memo(InternalPerformerCard);
