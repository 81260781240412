import React from 'react';
import Text from '../Text';
import styles from './TopDisclaimer.module.scss';
import { usePresenter } from './TopDisclaimer.presenter';
import type { TopDisclaimerProps } from './TopDisclaimer.types';

export const TopDisclaimer: React.FC<TopDisclaimerProps> = (props) => {
  const {
    topDisclaimerRef,
    topDisclaimer,
  } = usePresenter(props);

  if (!topDisclaimer?.trim()) {
    return null;
  }

  return (
    <div
      ref={topDisclaimerRef}
      className={styles.topDisclaimer}>
      <Text
        size='Small'
        style='Regular'
        colour='BaseLight'
        value={topDisclaimer}
      />
    </div>
  );
};
