import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { displayMaintenanceWindow } from '../../../lib/util';
import { AuthContext } from '../../../modules/auth';
import { useUrlTracker } from '../../../modules/localstorage/useUrlTracker';
import { TopNavContext } from '../../../modules/topNav';
import { useInitialiseHistory } from './MainLayout.hooks';
import type { MainLayoutPresenterProps, MainLayoutProps } from './MainLayout.types';

export const usePresenter = (props: MainLayoutProps): MainLayoutPresenterProps => {
  const {
    pathname,
    hash,
    state: locationMaintenance,
    state: errorBlock,
  } = useLocation();

  const { isAccountLoading, account, refetchAccount, isSignedIn } = useContext(AuthContext);

  useInitialiseHistory();
  useUrlTracker();

  const { shouldShowTopNav, shouldShowFooter } = useContext(TopNavContext);

  useEffect(() => {
    if (!hash) {
      // Using 'auto' behaviour to scroll instantly without scroll transition
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
  }, [pathname, hash]);

  const inMaintenanceMode: boolean = displayMaintenanceWindow();

  const [isOnBoardingModalOpen, setIsOnBoardingModalOpen] = useState<boolean>(false);

  const closeOnBoardingModal = useCallback(() => {
    refetchAccount();
    setIsOnBoardingModalOpen(false);
  }, [refetchAccount]);

  useEffect(() => {
    if (isSignedIn && account?.new_user && !isOnBoardingModalOpen) {
      setIsOnBoardingModalOpen(account.new_user);
    }
  }, [isSignedIn, account?.new_user, isOnBoardingModalOpen]);

  return {
    ...props,
    isAccountLoading,
    shouldShowTopNav,
    shouldShowFooter,
    inMaintenanceMode,
    locationMaintenance,
    isOnBoardingModalOpen,
    closeOnBoardingModal,
    errorBlock,
  };
};
