import React, { type ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AriaLiveRegions } from '../../../lib/types';
import { useWindowSize } from '../../../lib/util';
import { Backdrop } from '../../atoms/Backdrop';
import { SearchSuggestionBlock } from '../../blocks/SearchSuggestionBlock';
import { AccessibleLiveMessage } from '../../molecules/AccessibleLiveMessage';
import { SearchBar } from '../../molecules/SearchBar';
import styles from './SearchBarWithSuggestionsDropdown.module.scss';
import { usePresenter } from './SearchBarWithSuggestionsDropdown.presenter';
import type { SearchBarWithSuggestionsDropdownProps } from './SearchBarWithSuggestionsDropdown.types';

export const SearchBarWithSuggestionsDropdown: React.FC<SearchBarWithSuggestionsDropdownProps> = (props) => {
  const {
    searchSuggestions,
    searchSuggestionsDropdownRef,
    onSearchSuggestionsDropdownToggled,
    events,
    performers,
    venues,
    searchSuggestionResultScreenReaderText,
    searchSuggestionsItemsRef,
    inputRef,
    handleSearchBarInputKeyDownCallback,
    onSearchSuggestionArrowNavigationCallback,
  } = usePresenter(props);

  // Only show on desktop
  const { isDesktop } = useWindowSize();

  // Render search suggestions block if we have suggestions and are on desktop
  const searchSuggestionsView: ReactNode = isDesktop && !!searchSuggestions && (
    <SearchSuggestionBlock
      events={events}
      performers={performers}
      venues={venues}
      itemRefs={searchSuggestionsItemsRef}
      onArrowNavigation={onSearchSuggestionArrowNavigationCallback}
    />
  );

  return <>
    {isDesktop && <>
      {/* Main dropdown container with keyboard navigation */}
      <Dropdown
        aria-describedby='searchSuggestionResultScreenReaderText'
        ref={searchSuggestionsDropdownRef}
        show={!!searchSuggestionsView}
        onToggle={onSearchSuggestionsDropdownToggled}
        className={styles.dropdown}
        onKeyDown={handleSearchBarInputKeyDownCallback}
        role="combobox"
        aria-expanded={!!searchSuggestionsView}
        aria-haspopup="listbox"
        aria-controls="searchSuggestionsList"
      >
        {/* Search input toggle */}
        <Dropdown.Toggle
          as='div'
          aria-haspopup={undefined}
          aria-expanded={undefined}
          className={styles.dropdownToggle}
        >
          <SearchBar
            className={styles.searchBar}
            isTextInputFocusedOnMount={false}
            isTextInputFocusedOnClear={true}
            isTextInputBlurredOnFocus={false}
            hasSearchSuggestions={!!searchSuggestionsView}
            inputRef={inputRef}
          />
        </Dropdown.Toggle>
        {/* Suggestions dropdown menu */}
        {!!searchSuggestions && (<>
          <Dropdown.Menu
            className={styles.dropdownMenu}
            role="listbox"
            id="searchSuggestionsList"
            aria-label="Search suggestions"
            tabIndex={-1} // Prevent tab focus on the menu itself
          >
            {searchSuggestionsView}
          </Dropdown.Menu>
          <Backdrop onClose={onSearchSuggestionsDropdownToggled} />
        </>)}
      </Dropdown>
      {/* Screen reader announcements */}
      {!!searchSuggestionResultScreenReaderText && (
        <AccessibleLiveMessage
          id='searchSuggestionResultScreenReaderText'
          message={searchSuggestionResultScreenReaderText}
          ariaLiveRegion={AriaLiveRegions.POLITE}
        />
      )}
    </>}
  </>;
};
