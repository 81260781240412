import cx from 'classnames';
import React, { useRef, type ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { URLs } from '../../../lib/constants';
import { Backdrop } from '../../atoms/Backdrop';
import Button from '../../atoms/Button';
import { Logo } from '../../atoms/Logo';
import Text from '../../atoms/Text';
import AccountMenuBlock from '../../blocks/AccountMenuBlock';
import NavMenuItem from '../NavMenuItem';
import { ACCOUNT_MENU_ID } from './AccountMenu.constants';
import styles from './AccountMenu.module.scss';
import usePresenter from './AccountMenu.presenter';
import type { AccountMenuCombinedProps } from './types';

// This component will be rendered on desktop whereas AccountMenuBlock is rendered on mobile devices
const AccountMenu: React.FC<AccountMenuCombinedProps> = (props) => {
  const {
    state = 'Collapsed',
    className,
    greetingFirstName,
    isSignedOut,
    expandButton,
    collapseButton,
    closeButton,
    renderType,
    accountMenuBlock,
    handleToggle,
    onKeyDown,
    myAccount,
    myTickets,
    logOut,
    isDropdownOpen,
    onLogoClick,
    dropdownToggleAriaLabel,
  } = usePresenter(props);

  const toggleRef = useRef<HTMLDivElement | null>(null);

  const currentStyle: string = cx(styles.accountMenu, styles[`accountMenu${state}`]);

  const greetingFirstNameView: ReactNode = !!greetingFirstName
    ? (
      <div className={styles.greetingFirstNameContainer}>
        <Text
          size='Medium'
          style='SemiBold'
          colour='BaseLight'
          className={styles.greetingFirstName}
          {...greetingFirstName}
        />
      </div>
    )
    : <>
      {isSignedOut && (
        <div className={styles.logoContainer}>
          <Logo
            asset='capitalOneEntertainment'
            theme='light'
            href={URLs.HomePage}
            onClick={onLogoClick}
            className={styles.logo}
          />
        </div>
      )}
    </>;

  const toggleButtonView: ReactNode = (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      focusable={false}
      className={styles.toggleButton}
      {...(state === 'Collapsed' ? expandButton : collapseButton)}
    />
  );

  const closeButtonView: ReactNode = state === 'Expanded' && (
    <Button
      type='Icon'
      style='Text'
      size='Small'
      className={styles.closeButton}
      {...closeButton}
    />
  );

  const accountInfo: ReactNode = (
    <div className={cx(currentStyle, className)}>
      {greetingFirstNameView}
      {toggleButtonView}
      {closeButtonView}
    </div>
  );

  if (renderType) {
    return (
      <div className={cx(currentStyle, className)}>
        {accountInfo}
      </div>
    );
  }

  return (
    <>
      <Dropdown
        show={isDropdownOpen}
        onToggle={handleToggle}
        className={styles.dropdown}
      >
        <Dropdown.Toggle
          ref={toggleRef}
          as='div'
          tabIndex={0}
          id='menu-button'
          role='button'
          aria-label={dropdownToggleAriaLabel}
          aria-controls={ACCOUNT_MENU_ID}
          aria-haspopup={true}
          aria-expanded={isDropdownOpen}
          onKeyDown={onKeyDown}
          className={styles.dropdownToggle}
        >
          <div className={cx(currentStyle, className)}>
            {accountInfo}
          </div>
        </Dropdown.Toggle>
        {!!isDropdownOpen && (<>
          <Dropdown.Menu
            id={ACCOUNT_MENU_ID}
            role='menu'
            align='right'
            aria-labelledby='menu-button'
            data-popper-reference-hidden='false'
            data-popper-escaped='true'
            data-popper-placement='bottom-end'
            tabIndex={-1}
            className={styles.dropdownMenu}
          >
            <NavMenuItem
              className={styles.myAccount}
              {...myAccount}
            />
            <NavMenuItem
              className={styles.myTickets}
              {...myTickets}
            />
            <NavMenuItem
              className={styles.logOut}
              {...logOut}
            />
          </Dropdown.Menu>
          <Backdrop onClose={() => handleToggle?.(false)} />
        </>)}
      </Dropdown>
      <AccountMenuBlock {...accountMenuBlock} />
    </>
  );
};

export default AccountMenu;
