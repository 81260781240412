import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AriaLiveRegions } from '../../../lib/types';
import { BaseFilter } from '../../atoms/BaseFilter';
import TextInput from '../../atoms/TextInput';
import { AccessibleLiveMessage } from '../../molecules/AccessibleLiveMessage';
import styles from './RegionFilter.module.scss';
import { usePresenter } from './RegionFilter.presenter';
import type { RegionFilterOptionValue, RegionFilterProps, RegionFilterState } from './RegionFilter.types';

export const RegionFilter: React.FC<RegionFilterProps> = (props) => {
  const {
    regionFilterState,
    getDisplayValue,
    canSearchRegion,
    searchFieldRef,
    searchQuery,
    onSearchQueryChanged,
    filterOptions,
    onFilterOptionSelected,
    onFilterDropdownToggled,
    isClearable = true,
    isDisabled = false,
    theme = 'light',
    className,
    classes,
    ariaLabel,
    locationFilterResultScreenReaderText,
  } = usePresenter(props);

  const { t } = useTranslation();

  const searchField: ReactNode = (
    <div className={styles.searchFieldContainer}>
      <TextInput
        inputRef={searchFieldRef}
        state='Empty'
        style='ClearWithIcon'
        size='Regular'
        textValue={searchQuery}
        textPlaceholder={t('regionFilters.searchPlaceHolder')}
        onTextChanged={onSearchQueryChanged}
        className={styles.searchField}
      />
    </div>
  );

  return (<>
    <BaseFilter<RegionFilterState, RegionFilterOptionValue>
      filterState={regionFilterState}
      getDisplayValue={getDisplayValue}
      placeholder={t('regionFilters.location')}
      filterDropdownTopContent={canSearchRegion ? searchField : undefined}
      filterOptions={filterOptions}
      onFilterOptionSelected={onFilterOptionSelected}
      onFilterDropdownToggled={onFilterDropdownToggled}
      isClearable={isClearable}
      isDisabled={isDisabled}
      theme={theme}
      className={className}
      classes={classes}
      ariaLabel={ariaLabel || t('regionFilters.ariaLabel')}
      aria-describedby='locationFilterResultScreenReaderText'
    />
    {!!locationFilterResultScreenReaderText && (
      <AccessibleLiveMessage
        id='locationFilterResultScreenReaderText'
        message={locationFilterResultScreenReaderText}
        ariaLiveRegion={AriaLiveRegions.POLITE}
      />
    )}
  </>);
};
