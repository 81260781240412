import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { BaseComponentProps } from '../../../lib/types';
import Button from '../../atoms/Button';
import styles from './PaginatedList.module.scss';
import { usePresenter } from './PaginatedList.presenter';
import type { PaginatedListProps } from './PaginatedList.types';

export const PaginatedList = <TComponentProps extends BaseComponentProps>(
  props: PaginatedListProps<TComponentProps>,
) => {
  const {
    component: Component,
    componentProps,
    isSeeMoreShown,
    onSeeMoreClick,
    isSeeMoreDisabled,
    classes,
    firstItemIndex,
    firstNewItemRef,
  } = usePresenter(props);

  const { t } = useTranslation();

  const itemList: React.ReactNode = componentProps.map((currentComponentProps: TComponentProps, index: number) => (
    <Component
      {...currentComponentProps}
      key={currentComponentProps.key}
      ref={firstItemIndex === index ? firstNewItemRef : null}
    />
  ));

  const seeMore: React.ReactNode = isSeeMoreShown && (
    <div className={cx(styles.seeMoreContainer, classes?.seeMoreContainer)}>
      <Button
        type='Text'
        style='OutlinePill'
        size='Medium'
        text={{ value: t(`paginatedList.${isSeeMoreDisabled ? 'loadingMore' : 'seeMore'}`) }}
        onClick={onSeeMoreClick}
        disabled={isSeeMoreDisabled}
      />
    </div>
  );

  return (
    <div className={cx(styles.paginatedListContainer, classes?.paginatedListContainer)}>
      <div className={cx(styles.itemList, classes?.itemList)}>
        {itemList}
      </div>
      {seeMore}
    </div>
  );
};
