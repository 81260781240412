import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AriaLiveRegions, AriaRoles } from '../../../lib/types';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';
import { HighlightedMessage } from '../../molecules/HighlightedMessage';
import { LabelledContent } from '../../molecules/LabelledContent';
import { TextDropdown, type TextDropdownOption } from '../../molecules/TextDropdown';
import { INVALID_INTEGER_CHARS_REGEX, PHONE_NUMBER_LENGTH } from '../../pages/CheckoutPage';
import commonStyles from '../../pages/CheckoutPage/CheckoutPage.module.scss';
import { CITY_INPUT_FIELD_MAX_LENGTH, CITY_INPUT_FIELD_REMOVE_INVALID_CHAR_REGEX, POSTAL_CODE_INPUT_FIELD_REMOVE_INVALID_CHAR_REGEX } from './AddressInfo.constants';
import styles from './AddressInfo.module.scss';
import { usePresenter } from './AddressInfo.presenter';
import type { AddressInfoProps, BillingAddress } from './AddressInfo.types';

export const AddressInfo: React.FC<AddressInfoProps> = (props) => {
  const {
    billingAddress,
    isShippingAddressRequired,
    isSameAsBillingAddress,
    sameAsBillingAddressCheckboxIconAsset,
    toggleIsSameAsBillingAddress,
    shippingAddress,
    countryOptions,
    stateProvinceOptions,
    onShippingAddressChanged,
    onShippingAddressBlurred,
    isInvalidShippingAddressError,
    isInvalidPostalCodeError,
    shippingAddressErrors,
    postalCodeMaxLength,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const billingAddressTitle: ReactNode = (
    <Text
      type='Body'
      size='Medium'
      style='SemiBold'
      colour='SubduedDark'
      value={t('addressInfo.billingAddress.title')}
      className={styles.billingAddressTitle}
    />
  );

  const getBillingAddressField = (fieldName: keyof BillingAddress): ReactNode => {
    return !!billingAddress[fieldName] && (
      <LabelledContent
        labelKey={`addressInfo.fields.${fieldName}.label`}
        item={billingAddress[fieldName]}
      />
    );
  };

  const billingAddressContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.section, commonStyles.withGap)}>
      {billingAddressTitle}
      {getBillingAddressField('firstName')}
      {getBillingAddressField('lastName')}
      {getBillingAddressField('streetAddress')}
      {getBillingAddressField('streetAddressLine2')}
      {getBillingAddressField('city')}
      {getBillingAddressField('state')}
      {getBillingAddressField('postalCode')}
      {getBillingAddressField('country')}
      {getBillingAddressField('phoneNumber')}
    </div>
  );

  if (!isShippingAddressRequired) {
    return (
      <div className={cx(commonStyles.column, className)}>
        {billingAddressContent}
      </div>
    );
  }

  const shippingAddressTitle: ReactNode = (
    <Text
      type='Subheading'
      size='Medium'
      style='SemiBold'
      colour='SubduedDark'
      value={t('addressInfo.shippingAddress.title')}
    />
  );

  const sameAsBillingAddressCheckbox: ReactNode = (
    <button
      type='button'
      onClick={toggleIsSameAsBillingAddress}
      aria-label={t('addressInfo.shippingAddress.sameAsBillingAddress')}
      aria-pressed={isSameAsBillingAddress}
      className={commonStyles.checkbox}
      tabIndex={0}
    >
      <Icon
        asset={sameAsBillingAddressCheckboxIconAsset}
        style='ActionBase'
        className={commonStyles.checkboxIcon}
      />
      <Text
        size='Medium'
        style='Regular'
        colour='SubduedDark'
        value={t('addressInfo.shippingAddress.sameAsBillingAddress')}
      />
    </button>
  );

  const invalidShippingAddressErrorContent: ReactNode = !!isInvalidShippingAddressError && (
    <HighlightedMessage
      iconAsset='Warning'
      iconStyle='SubduedDark'
      content={t('addressInfo.errors.invalidShippingAddress')}
      background='red'
    />
  );

  const invalidPostalCodeErrorContent: ReactNode = !!isInvalidPostalCodeError && (
    <HighlightedMessage
      iconAsset='Warning'
      iconStyle='SubduedDark'
      content={t('addressInfo.errors.invalidPostalCode')}
      background='red'
    />
  );

  const getShippingAddressInput = (
    fieldName: keyof BillingAddress,
    options?: {
      maxLength?: number;
      removeChar?: RegExp;
    },
  ): ReactNode => {
    return (
      <div
        aria-live={AriaLiveRegions.ASSERTIVE}
        className={cx(commonStyles.column, commonStyles.withGap)}
      >
        <LabelledContent
          labelKey={`addressInfo.fields.${fieldName}.label`}
          className={commonStyles.withGap}
        >
          <TextInput
            state='Filled'
            style='Default'
            size='Regular'
            name={fieldName}
            textValue={shippingAddress[fieldName]}
            onTextChanged={onShippingAddressChanged}
            onBlurChanged={onShippingAddressBlurred}
            textPlaceholder={t(`addressInfo.fields.${fieldName}.placeholder`)}
            maxLength={options?.maxLength}
            removeChar={options?.removeChar}
            ariaLabel={t(`addressInfo.fields.${fieldName}.ariaLabel`)}
            className={cx(commonStyles.textInput, { [commonStyles.thickTextInputError]: shippingAddressErrors[fieldName] })}
          />
        </LabelledContent>
        {shippingAddressErrors[fieldName] && (
          <Text
            size='Small'
            style='Regular'
            colour='Negative'
            value={t(`addressInfo.fields.${fieldName}.error`)}
            ariaRole={AriaRoles.ALERT}
            ariaAtomic={true}
          />
        )}
      </div>
    );
  };

  const getShippingAddressDropdown = (
    fieldName: keyof BillingAddress & ('state' | 'country'),
    textDropdownOptions: TextDropdownOption[],
  ): ReactNode => {
    return (
      <div
        aria-live={AriaLiveRegions.ASSERTIVE}
        className={cx(commonStyles.column, commonStyles.withGap)}
      >
        <TextDropdown
          label={t(`addressInfo.fields.${fieldName}.label`)}
          options={textDropdownOptions}
          selectedValue={shippingAddress[fieldName]}
          onChange={onShippingAddressChanged}
          onBlur={onShippingAddressBlurred}
          name={fieldName}
          theme='light'
          ariaLabel={t(`addressInfo.fields.${fieldName}.ariaLabel`, { option: shippingAddress[fieldName] || 'none' })}
          className={styles.selectContainer}
          selectClassName={cx(commonStyles.select, { [commonStyles.thickSelectError]: shippingAddressErrors[fieldName] })}
        />
        {shippingAddressErrors[fieldName] && (
          <Text
            size='Small'
            style='Regular'
            colour='Negative'
            value={t(`addressInfo.fields.${fieldName}.error`)}
            ariaRole={AriaRoles.ALERT}
            ariaAtomic={true}
          />
        )}
      </div>
    );
  };

  const shippingAddressContent: ReactNode = (
    <div className={cx(commonStyles.column, styles.section, commonStyles.withGap)}>
      {shippingAddressTitle}
      {invalidShippingAddressErrorContent}
      {invalidPostalCodeErrorContent}
      {sameAsBillingAddressCheckbox}
      {getShippingAddressInput('firstName')}
      {getShippingAddressInput('lastName')}
      {getShippingAddressInput('streetAddress')}
      {getShippingAddressInput('streetAddressLine2')}
      {getShippingAddressInput('city', { maxLength: CITY_INPUT_FIELD_MAX_LENGTH, removeChar: CITY_INPUT_FIELD_REMOVE_INVALID_CHAR_REGEX })}
      {getShippingAddressDropdown('state', stateProvinceOptions)}
      {getShippingAddressInput('postalCode', { maxLength: postalCodeMaxLength, removeChar: POSTAL_CODE_INPUT_FIELD_REMOVE_INVALID_CHAR_REGEX })}
      {getShippingAddressDropdown('country', countryOptions)}
      {getShippingAddressInput('phoneNumber', { maxLength: PHONE_NUMBER_LENGTH, removeChar: INVALID_INTEGER_CHARS_REGEX })}
    </div>
  );

  return (
    <div className={cx(commonStyles.column, className)}>
      {billingAddressContent}
      {shippingAddressContent}
    </div>
  );
};
