import { useEffect, useMemo, useState } from 'react';
import type { ArrowDirectionEnum } from '../../../lib/accessibility';
import { runInNextCycle } from '../../../lib/util';
import type { SubCategoryMenuItemState } from '../../../modules/navigation/Navigation.types';
import type { SportsMenuTabListCombinedProps } from '../../molecules/SportsMenuTabList/types';
import type { SubmenuItemCombinedProps } from '../../molecules/SubmenuItem/types';
import type { SubmenuItemListCombinedProps } from '../../molecules/SubmenuItemList/types';
import type { SubmenuTabItemCombinedProps, SubmenuTabItemProps } from '../../molecules/SubmenuTabItem/types';
import styles from './SubmenuWithTabs.module.scss';
import type { SubmenuWithTabsCombinedProps } from './types';
import { handleExploreArrowNavigation } from '../SubmenuWithDoubleTabs/SubmenuWithDoubleTabs.utils';

const useSubmenuWithTabsPresenter = (props: SubmenuWithTabsCombinedProps): SubmenuWithTabsCombinedProps => {
  const { data: categoryMenuItemStates, id: openCategoryId, onItemClicked, shouldAutoFocus = false } = props;

  // Memoize the current category and its subcategories
  const currentCategory = useMemo(() => categoryMenuItemStates?.find((category) => category.id === openCategoryId), [openCategoryId, categoryMenuItemStates]);
  const subCategoryList = useMemo(() => currentCategory?.subCategories, [currentCategory]);
  const firstSubCategory = useMemo(() => currentCategory?.subCategories[0]?.name, [currentCategory]);

  const [hoveredSubCategory, setHoveredSubCategory] = useState<string | undefined>(firstSubCategory);
  const [focusPending, setFocusPending] = useState<boolean>(false);

  // Refs to track subcategory and performer elements
  const [arrayOfSubCategoryRefs, setArrayOfSubCategoryRefs] = useState<HTMLAnchorElement[]>([]);
  const [arrayOfPerformerRefs, setArrayOfPerformerRefs] = useState<HTMLAnchorElement[]>([]);

  /**
   * Adds a subcategory ref, preventing duplicates.
   */
  const addSubCategoryRef = (element: HTMLAnchorElement) => {
    if (element) {
      setArrayOfSubCategoryRefs((prevArray) => (prevArray.includes(element) ? prevArray : [...prevArray, element]));
    }
  };

  /**
   * Adds a performer ref, preventing duplicates.
   */
  const addPerformerRef = (element: HTMLAnchorElement) => {
    if (element) {
      setArrayOfPerformerRefs((prevArray) => (prevArray.includes(element) ? prevArray : [...prevArray, element]));
    }
  };

  /**
   * Sets the hovered subcategory.
   */
  const selectSubCategory = (subCatName: string | undefined) => {
    if (subCatName) {
      setHoveredSubCategory(subCatName);
    }
  };

  // Set the first subcategory as selected when the category changes
  useEffect(() => {
    setHoveredSubCategory(firstSubCategory);
  }, [firstSubCategory]);

  // Find the selected subcategory and its index
  const selectedSubCategory: SubCategoryMenuItemState | undefined = useMemo(() => subCategoryList?.find((league) => league.name === hoveredSubCategory), [hoveredSubCategory, subCategoryList]);
  const selectedSubCategoryIndex: number = useMemo(() => subCategoryList?.findIndex((league) => league.name === hoveredSubCategory), [hoveredSubCategory, subCategoryList]) || 0;
  const currentPerformers = useMemo(() => selectedSubCategory?.performers, [selectedSubCategory]);

  // Reset subcategory refs when the subCategoryList changes
  useEffect(() => {
    if (subCategoryList) {
      setArrayOfSubCategoryRefs([]);
    }
  }, [subCategoryList]);

  // Reset performer refs when the selectedSubCategory changes
  useEffect(() => {
    if (currentPerformers) {
      setArrayOfPerformerRefs([]);
    }
  }, [currentPerformers]);

  /**
   * Generates the performer list with navigation handlers for selected subcategory.
   */
  const performerList: SubmenuItemListCombinedProps = {
    submenuItems: currentPerformers?.map((performer, index): SubmenuItemCombinedProps => {
      return {
        text: {
          value: performer.name,
          colour: 'SubduedDark',
        },
        linkPath: performer.slug,
        elementRef: addPerformerRef,
        onItemClicked: onItemClicked,
        // SHIFT handler
        handleTab: (forward) => {
          const newFocusedIndex = forward ? selectedSubCategoryIndex + 1 : selectedSubCategoryIndex - 1;
          if ((subCategoryList || [])[newFocusedIndex]) {
            selectSubCategory((subCategoryList || [])[newFocusedIndex].name);
            // Using runInNextCycle asserts that the league tab is active before focusing it
            runInNextCycle(() => !focusPending && arrayOfSubCategoryRefs[newFocusedIndex].focus());
          }
        },
        handleArrowNavigation: (direction: ArrowDirectionEnum) => handleExploreArrowNavigation(direction, index, arrayOfPerformerRefs),
        classes: {
          text: performer.isBoldText ? styles.boldText : '',
        },
      } as SubmenuItemCombinedProps;
    }),
  };

  /**
   * Generates the subcategory tabs with navigation handlers.
   */
  const subCategoryTabItems: SubmenuTabItemCombinedProps[] | undefined =
    currentCategory?.subCategories.map((sport, index): SubmenuTabItemProps => {
      const item: SubmenuTabItemCombinedProps = {
        state: hoveredSubCategory === sport.name ? 'Selected' : 'Default',
        text: {
          value: sport.name,
          colour: 'SubduedDark',
        },
        linkPath: sport.slug && sport.slug?.length > 0 ? sport.slug : undefined,
        onItemClicked: onItemClicked,
        id: sport.name, // TODO: replace with slug?
        elementRef: addSubCategoryRef,
        handleArrowNavigation: (direction: ArrowDirectionEnum) => handleExploreArrowNavigation(direction, index, arrayOfSubCategoryRefs,
          (nextIndex: number) => {
            const nextSubCategory = subCategoryList?.[nextIndex];
            if (nextSubCategory) {
              setHoveredSubCategory(nextSubCategory.name);
            }
          },
        ),
      };
      return item;
    });

  // Focus handling for shift+tabbing
  useEffect(() => {
    // forces focus to go to the first element, to be triggered when shift+tabbing
    if (focusPending && arrayOfPerformerRefs.length > 0) {
      setFocusPending(false);
      arrayOfPerformerRefs[0].focus();
    }
  }, [focusPending, arrayOfPerformerRefs]);

  // Auto-focus the first subcategory if needed
  useEffect(() => {
    if (shouldAutoFocus && subCategoryList && arrayOfSubCategoryRefs.length > 0) {
      arrayOfSubCategoryRefs?.[0]?.focus();
    }
  }, [arrayOfSubCategoryRefs, shouldAutoFocus, subCategoryList]);

  return {
    ...props,
    sportsMenuTabList: {
      submenuTabItems: subCategoryTabItems,
      activeTab: hoveredSubCategory,
    } as SportsMenuTabListCombinedProps,
    submenuItemList: performerList,
    hoveredLeague: hoveredSubCategory,
    selectLeague: selectSubCategory,
  };
};

export default useSubmenuWithTabsPresenter;
