import { SUPPORTED_PROGRAM_TYPE_TAGS, SUPPORTED_REWARD_TYPE_TAGS } from '../../../lib/constants';
import type { AccountCardDetail } from '../../../modules/auth';
import i18n from '../../../modules/locale/i18n';
import type { ExclusiveBadgeProps, ExclusiveBadgeTheme } from './ExclusiveBadge.types';

/** Filters account card details that match specified tags.*/
export const getAccountCardDetailsWithMatchingTags = (
  params: {
    /** Input array of account details */
    accountCardDetails: AccountCardDetail[];
    /** Tags to match against, default is an empty array */
    tagsToBeChecked: string[] | undefined;
  }): AccountCardDetail[] => {
  const { accountCardDetails, tagsToBeChecked = [] } = params;
  const tagsSet = new Set(tagsToBeChecked);

  // Filter account details based on matching tags
  const accountsWithMatchingTags = accountCardDetails.filter(accountDetail => {
    const { accountProgramTypeTag } = accountDetail;
    if (accountProgramTypeTag && tagsSet.has(accountProgramTypeTag)) {
      tagsSet.delete(accountProgramTypeTag); // Remove the tag from the set once matched
      return true; // Include this account detail in the filtered result
    }
    return false; // Exclude this account detail if no match
  });

  return accountsWithMatchingTags; // Return the filtered account details
};

/** Builds the properties for an exclusive badge based on user and account details */
export const buildEventCardExclusiveBadgeProps = (params: {
  /** Indicates whether the user is signed out */
  isSignedOut: boolean;
  /** The tags associated with the current event or listings */
  currentTags: string[] | undefined;
  /** The account card details available for the user */
  accountCardDetails: AccountCardDetail[];
  /** The theme of the exclusive badge */
  theme: ExclusiveBadgeTheme;
}): ExclusiveBadgeProps => {
  const { isSignedOut, currentTags = [], accountCardDetails, theme } = params;

  // Early return for signed-out users
  if (isSignedOut) {
    return {
      iconInfo: {
        iconAsset: 'Lock',
        iconStyle: 'CoreBlue50',
      },
      textValue: i18n.t('exclusiveBadge.exclusiveAccess'),
      theme,
    };
  }

  // Default badge for missing tags or insufficient account card details
  if (!currentTags?.length || !accountCardDetails.length || accountCardDetails.length <= 1) {
    return {
      iconInfo: {
        iconAsset: 'Sparkles',
        iconStyle: 'Yellow',
      },
      textValue: i18n.t('exclusiveBadge.cardholderExclusive'),
      theme,
    };
  }

  // Find account details matching the current tags
  const accountsWithMatchingTags = getAccountCardDetailsWithMatchingTags({
    accountCardDetails,
    tagsToBeChecked: currentTags,
  });

  if (accountsWithMatchingTags.length === 1) {
    return {
      iconInfo: {
        iconAsset: 'Unlock',
        iconStyle: 'CoreBlue50',
      },
      textValue: i18n.t('exclusiveBadge.limitedEligibility'),
      theme,
    };
  }

  // Default badge for multiple matches
  return {
    iconInfo: {
      iconAsset: 'Sparkles',
      iconStyle: 'Yellow',
    },
    textValue: i18n.t('exclusiveBadge.cardholderExclusive'),
    theme,
  };
};

/**
 * Builds the properties for an exclusive badge on a listing card based on user and account details.
 * @returns ExclusiveBadgeProps | undefined - Properties to render the exclusive badge or undefined if no badge is applicable.
 */
export const buildListingCardExclusiveBadgeProps = (params: {
  /** Indicates whether the user is signed out */
  isSignedOut: boolean;
  /** The tags associated with the current event or listings */
  currentTags: string[] | undefined;
  /** The account card details available for the user */
  accountCardDetails: AccountCardDetail[];
  /** The theme of the exclusive badge */
  theme?: ExclusiveBadgeTheme;
}): ExclusiveBadgeProps | undefined => {
  const { isSignedOut, currentTags = [], accountCardDetails, theme = 'dark' } = params;

  // Return undefined if no tags are present on listing.
  if (!currentTags?.length) {
    return undefined;
  }

  const listingProgramTypeTags: string[] = [];
  const listingRewardsTypeTags: string[] = [];

  // Filter tags into respective supported program and reward type arrays.
  currentTags.forEach((tag) => {
    if (SUPPORTED_PROGRAM_TYPE_TAGS[tag]) listingProgramTypeTags.push(tag);
    if (SUPPORTED_REWARD_TYPE_TAGS[tag]) listingRewardsTypeTags.push(tag);
  });

  // Return undefined if neither program nor reward type tags exist.
  if (!listingProgramTypeTags.length && !listingRewardsTypeTags.length) {
    return undefined;
  }

  // If the user is signed out or has no account card details, return a lock badge.
  if (isSignedOut || !accountCardDetails.length) {
    return {
      iconInfo: {
        iconAsset: 'Lock',
        iconStyle: 'CoreBlue50',
      },
      textValue: i18n.t('exclusiveBadge.exclusiveAccess'),
      theme,
    };
  }

  // If no program type tags exist, return undefined.
  if (!listingProgramTypeTags.length) {
    return undefined;
  }

  // Find account card details that match the listing tags.
  const accountsWithMatchingTags = getAccountCardDetailsWithMatchingTags({
    accountCardDetails,
    tagsToBeChecked: listingProgramTypeTags,
  });

  // If exactly one matching account is found, return unlock badge.
  if (accountsWithMatchingTags.length === 1) {
    return {
      iconInfo: {
        iconAsset: 'Unlock',
        iconStyle: 'CoreBlue50',
      },
      textValue: i18n.t('exclusiveBadge.limitedEligibility'),
      theme,
    };
  }

  return undefined;
};
