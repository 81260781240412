import type { ErrorBlockCombinedProps } from '../legacy/blocks/ErrorBlock';
import { AccountTags } from '../modules/auth';
import i18n from '../modules/locale/i18n';
import GenericErrorAsset from '../resources/icons/GenericError.svg';
import type { RewardUnit } from './util';

export const MIN_INTEGER = -2147483648;
export const MAX_INTEGER = 2147483647;

/**
 * Important: Value must be the same as in cmd/stitch-deprecated-and-latest-code.js.
 * Search for 'optimizelyUserId'.
 */
export const USER_ID = 'userId';

export const EVENT_ID_PARAM = 'eventId';
export const TAB_KEY_PARAM = 'tab';
export const REGION_PARAM = 'region';
export const DATE_RANGE_PARAM = 'date_range';
export const START_DATE_PARAM = 'date_start';
export const END_DATE_PARAM = 'date_end';
export const HOME_OR_AWAY_PARAM = 'home_or_away';
export const CATEGORY_PARAM = 'category';
export const PERFORMER_PARAM = 'performer';
export const LISTING_ID_PARAM = 'ticket_id';
export const QUANTITY_PARAM = 'quantity';
export const EXCLUSIVE_LISTINGS_PARAM = 'exclusive_listings';
export const DELIVERY_ID_PARAM = 'delivery_id';
export const QUANTITY_FILTER_PARAM = 'quantity_filter';
export const MIN_PRICE_FILTER_PARAM = 'min_price';
export const MAX_PRICE_FILTER_PARAM = 'max_price';
export const STEP_PARAM = 'step';
export const MODAL_PARAM = 'modal';
export const HISTORY_STATE_PARAM = 'historyState';

export const CURRENT_LOCATION_FALLBACK_VALUE = 'current_location';
export const CHECKOUT_BRAINTREE_FORM = 'checkout-brainTree-form';
export const CHECKOUT_NONE_BRAINTREE_FORM = 'checkout-none-brainTree-form';
export const CHECKOUT_SHIPPING_FORM = 'checkout-shipping-form';
export const CONTENT_ID = 'content';

export const C1_BROKER_ID = '29014';

export const TAYLOR_SWIFT_PERFORMER_ID = 12245;

export const IsIntegerRewardUnit = {
  miles: true,
  rewards_cash: false,
  'rewards cash': false,
  cash: false,
  points: true,
} as const satisfies Record<Lowercase<RewardUnit | (string & {})>, boolean>;

type LocationItem = {
  name: string,
  code: string,
};

export const COUNTRIES = [
  { name: 'Canada', code: 'CA' },
  { name: 'United States', code: 'US' },
] as const satisfies ReadonlyArray<LocationItem>;

export const CA_PROVINCES = [
  { name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Northwest Territories', code: 'NT' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Nunavut', code: 'NU' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' },
  { name: 'Yukon', code: 'YT' },
] as const satisfies ReadonlyArray<LocationItem>;

export const US_STATES = [
  { name: 'Alaska', code: 'AK' },
  { name: 'Alabama', code: 'AL' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District of Columbia', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Guam', code: 'GU' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Palau', code: 'PW' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'U.S. Virgin Islands', code: 'VI' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
] as const satisfies ReadonlyArray<LocationItem>;

export const BREAKPOINTS = {
  mobile: 375,
  tablet: 768,
  desktop: 1024,
  desktopXl: 1440,
} as const;

export const LOW_TICKETS_THRESHOLD = 5;

export const URLs = {
  AllEventsPage: '/all-events',
  CheckoutPage: '/checkout/:listingId',
  ErrorPage: '/error',
  EventPage: '/events/:eventId',
  ExclusiveDiningPage: 'https://www.capitalone.com/credit-cards/benefits/dining',
  ExclusiveEventsPage: '/exclusive-events',
  HelpPage: '/help',
  HomePage: '/',
  Logout: '/logout',
  MlbPage: '/mlb',
  MlsPage: '/mls',
  MyTicketsPage: '/mytickets',
  NbaPage: '/nba',
  NflPage: '/nfl',
  NhlPage: '/nhl',
  OrderConfirmationPage: '/confirmation',
  PerformerPage: '/performers/:performerId',
  SearchResultsPage: '/search',
  VenuePage: '/venues/:venueId',
} as const;

export const PROTECTED_ROUTES = [
  URLs.CheckoutPage,
  URLs.MyTicketsPage,
  URLs.OrderConfirmationPage,
] as const;

export const SPORTS_ROUTES: string[] = [
  URLs.NbaPage,
  URLs.NhlPage,
  URLs.NflPage,
  URLs.MlsPage,
  URLs.MlbPage,
];

export const PAGE_TYPES = {
  AllEventsPage: 'all_events_page',
  CheckoutPage: 'checkout_page',
  ErrorPage: 'error_page',
  EventPage: 'event_page',
  ExclusiveDiningPage: undefined,
  ExclusiveEventsPage: 'exclusive_events_page',
  HelpPage: 'help_page',
  HomePage: 'home_page',
  Logout: undefined,
  MlbPage: 'mlb_page',
  MlsPage: 'mls_page',
  MyTicketsPage: 'my_tickets_page',
  NbaPage: 'nba_page',
  NflPage: 'nfl_page',
  NhlPage: 'nhl_page',
  OrderConfirmationPage: 'order_confirmation_page',
  PerformerPage: 'performer_page',
  SearchResultsPage: 'search_results_page',
  VenuePage: 'venue_page',
} as const satisfies Record<keyof typeof URLs, string | undefined>;

export const EXCLUSIVE_EVENTS_PAGE_ANCHORS = {
  sports: 'sports',
  music: 'music',
  dining: 'dining',
  getaways: 'getaways',
  artTheater: 'artTheater',
} as const;

export const EVENT_TAGS = {
  AlwaysSuppressVsInventory: 'ALWAYS_SUPPRESS_VSINVENTORY',
  CapitalOne: 'CAPITAL_ONE',
  Collapsed: 'COLLAPSED',
  CultureArts: 'C1_CULTUREARTS',
  Dining: 'DINING',
  Exclusive: 'C1_EXCLUSIVE',
  GuestList: 'GUEST_LIST',
  LoyaltyRewardsPayment: 'LOYALTY_REWARDS_PAYMENT',
  Music: 'MUSIC',
  NotCollapsed: 'NOT COLLAPSED',
  NotExclusive: 'NOT C1_EXCLUSIVE',
  PromotedProduction: 'PROMOTED_PRODUCTION',
  PurchaseLimit: 'PURCHASE_LIMIT',
  SoldOut: 'SOLD_OUT',
  SplitPayment: 'SPLIT_PAYMENT',
  Sports: 'SPORTS',
  Travel: 'C1_TRAVEL',
  UnAuth: 'C1_UNAUTH',
} as const;

export const EXCLUSIVE_CATEGORY = 'exclusive';

export const EXCLUSIVE_CATEGORY_MAP = {
  sports: EVENT_TAGS.Sports,
  music: EVENT_TAGS.Music,
  dining: EVENT_TAGS.Dining,
  getaways: EVENT_TAGS.Travel,
  artTheater: EVENT_TAGS.CultureArts,
};

export const UnableToAccessEventErrorProps = {
  image: { imageSrc: GenericErrorAsset },
  title: { value: i18n.t('errors.unableToAccessEvent.title') },
  message: { value: i18n.t('errors.unableToAccessEvent.message') },
} as const satisfies ErrorBlockCombinedProps;

export const OrderErrorProps = {
  image: { imageSrc: GenericErrorAsset },
  title: { value: i18n.t('errors.orderError.title') },
  message: { value: i18n.t('errors.orderError.message') },
} as const satisfies ErrorBlockCombinedProps;

export const CLEAR_CATEGORY_PARAM_FOR_TAGS = [EXCLUSIVE_CATEGORY, EVENT_TAGS.CultureArts, EVENT_TAGS.Dining, EVENT_TAGS.Music, EVENT_TAGS.PromotedProduction, EVENT_TAGS.Travel, EVENT_TAGS.Sports];

export const SUPPORTED_PROGRAM_TYPE_TAGS = {
  CAPITAL_ONE_CARD: true,
  CAPITAL_ONE_CASH: true,
  CAPITAL_ONE_MILES: true,
  CAPITAL_ONE_SMALL_BUSINESS: true,
  CAPITAL_ONE: true,
  JOURNEY: true,
  PLATINUM_REWARDS: true,
  PLATINUM: true,
  QUICKSILVER_SECURED: true,
  QUICKSILVER: true,
  QUICKSILVERONE: true,
  SAVOR: true,
  SAVORONE: true,
  SPARK_BUSINESS: true,
  SPARK_CASH_PLUS: true,
  SPARK_CASH_PREFERRED: true,
  SPARK_CASH_SELECT: true,
  SPARK_CASH: true,
  SPARK_CLASSIC_MILES: true,
  SPARK_CLASSIC: true,
  SPARK_MILES_SELECT: true,
  SPARK_MILES: true,
  SPARK_PRO_CHARGE_CARD: true,
  SPARK_PRO: true,
  VELOCITY: true,
  VENTURE_X_BUSINESS: true,
  VENTURE_X: true,
  VENTURE: true,
  VENTUREONE: true,
};

export const SUPPORTED_REWARD_TYPE_TAGS = {
  C1_MILES: true,
  C1_CASH_REWARDS: true,
  C1_POINTS: true,
};

export const SUPPORTED_NETWORK_TYPE_TAGS = {
  VISA: true,
  MASTERCARD: true,
};

export const DEFAULT_SELECTED_CARD_INDEX: number = 0;

export const EMPTY_ACCOUNT_TAGS: AccountTags = {
  accountLoyaltyUnitTags: [],
  accountProgramTypeTags: [],
  accountProcessingNetworkTags: [],
};

export const NO_EXCLUSIVES_RESTRICTION = 'NO_EXCLUSIVES';
