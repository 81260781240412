import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { UnableToAccessEventErrorProps, URLs } from '../../../lib/constants';
import { MapProvider } from '../../../modules/map';
import { Spinner } from '../../atoms/Spinner';
import { LimitedAccessBlock } from '../../blocks/LimitedAccessBlock/LimitedAccessBlock';
import { GuestListEventPage } from '../GuestListEventPage';
import { NonGuestListEventPage } from '../NonGuestListEventPage';
import { usePresenter } from './EventPage.presenter';
import type { EventPagePresenterProps, EventPageProps } from './EventPage.types';

export const EventPage: React.FC<EventPageProps> = (props) => {
  const presenterProps: EventPagePresenterProps = usePresenter(props);

  const {
    isRequiredDataLoading,
    eventMetadata,
    areListingsLoading,
    listingsMetadata,
    unfilteredListings,
    isEventError,
    shouldOpenLimitedAccessBlock,
    restrictEventPage,
  } = presenterProps;

  if (isRequiredDataLoading) {
    return <Spinner />;
  }

  if (!eventMetadata || isEventError || restrictEventPage) {
    return (
      <div/>
    );
  }

  // Show limited access block if user is signed in and event is invalid
  if (shouldOpenLimitedAccessBlock) {
    return <LimitedAccessBlock />;
  }

  // Show full page spinner only when listings are loading for the first time (listingsMetadata is undefined).
  // This will happen when event page loads for the first time or when eventId changes, e.g. when user is already on event page and they use search to navigate to another event.
  // If only query parameters change (e.g. min/max price filter) while eventId remains the same then skip this code.
  // We will re-fetch listings for new query parameters but we keep the previous data (See keepPreviousData in EventPage.presenter.ts).
  // In this case spinner will be shown on top of the current page in overlay mode (See Spinner in GuestListEventPage.tsx).
  if (areListingsLoading && !listingsMetadata) {
    return <Spinner />;
  }

  return eventMetadata.shouldShowGuestListEventPage
    ? (
      <GuestListEventPage
        {...presenterProps}
        eventMetadata={eventMetadata}
        listingsMetadata={listingsMetadata}
      />
    )
    : (
      <MapProvider
        svgMapJsonUrl={listingsMetadata?.svgMapJsonUrl}
        staticImageMapUrl={listingsMetadata?.staticImageMapUrl ?? ''}
        filteredListings={listingsMetadata?.listings}
        unfilteredListings={unfilteredListings}
      >
        <NonGuestListEventPage
          {...presenterProps}
          eventMetadata={eventMetadata}
          listingsMetadata={listingsMetadata}
        />
      </MapProvider>
    );
};
