import axios from 'axios';
import { BFF_URL } from '../../lib/config';
import { createSessionCookie, getSessionCookie } from '../../lib/util';
import { ApiError } from '../error/types';
import type { Account, AssociatedAccountJwtRequest, AssociatedAccountJwtResponse, AuthResponse, VerifyTokenPayload } from './types';

export const getAuthData = async (): Promise<Account> => {
  try {
    const { data } = await axios.get<Account>(
      `${BFF_URL}/auth/me`,
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorResponseStatus = error.response.status;
      // extract message from response if available
      let errorMessage;
      try {
        errorMessage = errorResponseStatus === 406 && error.response.data
          ? JSON.stringify(error.response.data)
          : error.message;
      } catch {
        errorMessage = error.message;
      }
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: errorMessage,
      });
    }
    throw error;
  }
};

export const verifyToken = async (payload: VerifyTokenPayload): Promise<AuthResponse> => {
  try {
    const { data } = await axios.post<AuthResponse>(
      `${BFF_URL}/auth`,
      payload, { withCredentials: true },
    );

    // Manually set cookie if it was not set due to domain mismatch
    if (data.jwt_token && !getSessionCookie()) {
      createSessionCookie({ jwtToken: data.jwt_token });
    }

    return data;
  } catch (error) {
    // return {
    //   jwt_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0dGwiOjE5MjIwMTI5NDUwMDB9.SPG-FWZOjMUy-Kd8nMOb6rUlFTLTrS86xTv4dL7EwC4',
    // };
    if (axios.isAxiosError(error) && error.response) {
      const errorResponseStatus = error.response.status;
      // extract message from response if available
      let errorMessage;
      try {
        errorMessage = errorResponseStatus === 406 && error.response.data
          ? JSON.stringify(error.response.data)
          : error.message;
      } catch {
        errorMessage = error.message;
      }
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: errorMessage,
      });
    }
    throw error;
  }
};

/**
 * Gets JWT for the requested associated account (account card).
 * This should be called right before placing order to get JWT for the selected account card.
 */
export const getAssociatedAccountJwt = async (payload: AssociatedAccountJwtRequest): Promise<AssociatedAccountJwtResponse> => {
  try {
    const { data } = await axios.post<AssociatedAccountJwtResponse>(
      `${BFF_URL}/user/associate/jwt/exchange`,
      payload,
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const logoutUser = async (): Promise<void> => {
  try {
    const { data } = await axios.post<void>(
      `${BFF_URL}/user/logout`, {},
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const updateNewUserExperience = async (): Promise<void> => {
  try {
    const { data } = await axios.post<void>(
      `${BFF_URL}/user/experience`, {},
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};
