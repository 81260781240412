import { useContext, useMemo, useState } from 'react';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { EXCLUSIVE_ID } from '../../../modules/navigation/Navigation.constants';
import { TopNavContext } from '../../../modules/topNav';
import type { SubmenuTabItemCombinedProps } from '../../molecules/SubmenuTabItem/types';
import type { SubmenuWithoutTabsCombinedProps } from '../SubmenuWithoutTabs/types';
import type { SubmenuWithTabsCombinedProps } from '../SubmenuWithTabs/types';
import { buildCategoryTabs } from './SubmenuWithDoubleTabs.utils';
import type { SubmenuWithDoubleTabsCombinedProps } from './types';

const useSubmenuWithDoubleTabsPresenter = (props: SubmenuWithDoubleTabsCombinedProps): SubmenuWithDoubleTabsCombinedProps => {
  const { trackEvent } = useAnalyticsManager();

  // Get the top navigation data from context
  const { categoryMenuItemStates, hasCategoryMenuItemStates } = useContext(TopNavContext);

  // State to keep track of the currently open dropdown ID
  const [openDropdownId, setOpenDropdownId] = useState<string | undefined>(categoryMenuItemStates && categoryMenuItemStates[0]?.id);

  // Array to store category refs (for managing DOM elements)
  // Important: This is a regular array, so it won’t persist across renders.
  const arrayOfCategoryRefs: HTMLAnchorElement[] = [];

  // Function to set a category ref
  // Pushes the element into the array if it's not null
  const setCategoryRef = (element: HTMLAnchorElement) => {
    if (element !== null) {
      arrayOfCategoryRefs.push(element);
    }
  };

  // Find the currently open category based on the dropdown ID
  const openCategory = useMemo(() => categoryMenuItemStates?.find((tab) => tab?.id === openDropdownId), [categoryMenuItemStates, openDropdownId]);
  // Handle hover event to set the open dropdown ID
  const onTabHover = (id: string | undefined) => setOpenDropdownId(id);

  // Constructing the desktop navigation tabs for categories
  const categoryTabList: SubmenuTabItemCombinedProps[] = buildCategoryTabs(
    categoryMenuItemStates,
    setOpenDropdownId,
    onTabHover,
    setCategoryRef,           // Pass the ref setter function
    arrayOfCategoryRefs,      // Pass the array to collect refs
    openDropdownId,
  );

  // Submenu configuration with tabs for Category which has SubCategory
  const submenuWithTabs: SubmenuWithTabsCombinedProps = {
    data: categoryMenuItemStates,
    isOpen: true,
    id: openDropdownId,
    shouldAutoFocus: true,
  };

  // Submenu configuration with tabs for Category without SubCategory.
  const submenuWithoutTabs: SubmenuWithoutTabsCombinedProps = {
    data: categoryMenuItemStates,
    id: openDropdownId,
    isOpen: true,
    onItemClicked: (event) => {
      // Track analytics event if the category is exclusive and not "Presales"
      if (openCategory?.id === EXCLUSIVE_ID && event?.name !== 'Presales') {
        trackEvent('select_content_c1x_navigation-menu');
      }
    },
    shouldAutoFocus: true,
  };

  return {
    ...props,
    hasCategoryMenuItemStates,
    openDropdownId,
    sportsMenuTabList: {
      submenuTabItems: categoryTabList,
    },
    activeMenu: openCategory,
    submenuWithTabs: submenuWithTabs,
    submenuWithoutTabs: submenuWithoutTabs,
  };
};

export default useSubmenuWithDoubleTabsPresenter;
