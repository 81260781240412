import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { URLs } from '../../../lib/constants';
import { AuthContext } from '../../../modules/auth';
import { useUrlTracker } from '../../../modules/localstorage/useUrlTracker';
import { getCurrentHistoryState } from '../../../modules/navigation/Navigation.utils';
import type { LimitedAccessBlockPresenterProps, LimitedAccessBlockProps } from './LimitedAccessBlock.types';

export const usePresenter = (props: LimitedAccessBlockProps): LimitedAccessBlockPresenterProps => {
  const { isSignedIn } = useContext(AuthContext);

  const { secondLastVisitedUrl: lastAccessibleUrl } = useUrlTracker();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isSignedIn) {
      navigate(URLs.HomePage);
    }
  }, [isSignedIn, navigate]);

  const onContinueBrowsing = useCallback(() => {
    const goBackHistoryState: unknown = getCurrentHistoryState();

    // If goBackHistoryState is set then it is not the first page visit
    if (goBackHistoryState && lastAccessibleUrl) {
      // Navigate to the previous page if it is not the first page visit
      // Remove location.origin to convert absolute URL to relative
      navigate(lastAccessibleUrl.replace(window.location.origin, ''), { replace: true });
    } else {
      // Navigate to All Events page when it is the first page visit
      navigate(URLs.AllEventsPage, { replace: true });
    }
  }, [navigate, lastAccessibleUrl]);

  return {
    ...props,
    onContinueBrowsing,
  };
};
