import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { getClickAccessibilityProps } from '../../../lib/accessibility';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import styles from './SubmenuTabItem.module.scss';
import usePresenter from './SubmenuTabItem.presenter';
import getProps from './SubmenuTabItem.props';
import type { SubmenuTabItemCombinedProps } from './types';

const SubmenuTabItem: React.FC<SubmenuTabItemCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    selectedItemDivider,
    hasSubMenu,
    onMouseOver,
    onMouseLeave,
    linkPath,
    onItemClicked,
    handleTab,
    focusable = true,
    elementRef,
    dividerPosition = 'None',
    handleArrowNavigation,
    role,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`submenuTabItem${variantName}`];

  const accessibilityProps = getClickAccessibilityProps({
    actions: {
      onClick: onItemClicked,
      onTab: handleTab,
      onArrowNavigation: handleArrowNavigation,
    },
  });

  const dividerView = dividerPosition !== 'None'
    ? <Divider
      type='Horizontal'
      style='Thin'
      colour='Grey20'
    />
    : undefined;

  let componentView: JSX.Element;

  switch (variantName) {
    case 'Default':
    case 'Selected': {
      componentView = (
        <Link
          to={linkPath ?? ''}
          ref={elementRef}
          onClick={onItemClicked}
          tabIndex={focusable ? 0 : -1}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          className={cx(currentStyle, className)}
          {...accessibilityProps}
          aria-haspopup={!!hasSubMenu}
          role={role || 'menuitem'}
          aria-selected={state === 'Selected'}
        >
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text} />
          <Divider
            className={cx(styles.selectedItemDivider, classes?.selectedItemDivider)}
            {...internalProps.selectedItemDivider}
            {...selectedItemDivider} />
        </Link>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return <>
    {dividerPosition === 'Top' && dividerView}
    {componentView}
    {dividerPosition === 'Bottom' && dividerView}
  </>;
};

export default SubmenuTabItem;
